import React, {useState} from "react";
import { EditOutlined, EllipsisOutlined, DeleteOutlined, FolderOpenOutlined } from '@ant-design/icons';
import {Menu, Dropdown, Card, Tooltip} from 'antd';

import { Icon, CustomImage } from "../../../../components/common";
import placeholderImg from '../../../../assets/images/item-placeholder.png';
import "./subcategory-card.scss";

const {Meta} = Card;
const styles = {
  wrapper: "categories__subcategory-card__wrapper",
  image: "categories__subcategory-card__image",
  name: "categories__subcategory-card__name",
  description: "categories__subcategory-card__description",
  footer: "categories__subcategory-card__footer",
  content: "categories__subcategory-card__content",
  info: "categories__subcategory-card__info",
  shadow: "categories__subcategory-card__shadow",
};
function SubCategoryCard(props) {

  const [isExpanded, expandCard] = useState(false);
  const toggleExpand = () => {
    expandCard(!isExpanded);
  }

    const handleMenuItemClick = (action) => {
        switch (action) {
          case "edit":
            props.editItem(props.data);
            return;
          case "delete":
            props.deleteItem(props.data);
            return;
          default:
            return;
        }
      };
      const menu = (
        <Menu onClick={handleMenuItemClick}>
          <Menu.Item key="edit">Edit</Menu.Item>
          <Menu.Item key="delete">Delete</Menu.Item>
        </Menu>
      );


  const getDesc = () => {
    if(!props.data.description) {
      return "---"
    }

    if(props.data.description.length <= 40) {
      return props.data.description;
    } else if(isExpanded) {
      return props.data.description
    } else {
      return <>{`${props.data.description.slice(0, 37)}...`}<code>See More</code></>
    }
  }
      
  return (
    <Card
    hoverable
    style={{ width: 250}}
    className={styles.shadow}
    cover={
      <CustomImage
        alt={props.data.categoryName}
        src={props.data.categoryImage}
      />
    }
    actions={[
      <Tooltip title="Edit"><EditOutlined onClick={() => {handleMenuItemClick("edit")}} key="edit" value="edit" /></Tooltip>,
      <Tooltip title="Delete"><DeleteOutlined onClick={() => {handleMenuItemClick("delete")}} key="delete" value="delete" /></Tooltip>,
    ]}
  >
    <Meta
      // avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
      title={props.data.categoryName}
      onClick={toggleExpand}
      description={getDesc()}
    />
  </Card>
  )
  return (
    <div className={styles.wrapper}>
        <img className={styles.image} src={props.data.categoryImage ? props.data.categoryImage : placeholderImg} alt={props.data.categoryName}/>
      <div className={styles.content}>
        <div className={styles.info}>
          <div className={styles.name}>
              {props.data.categoryName}
          </div>
          <div className={styles.description}>{props.data.description}</div>
        </div>
        <div className={styles.footer}>
            <Dropdown overlay={menu}>
            <div>
        <Icon name="MENU" size="small" />
        </div>
        </Dropdown>
        </div>
      </div>
    </div>
  );
}

export default SubCategoryCard;
