import React, { useState, useEffect } from "react";
import {connect} from 'react-redux';
import {Button, Input} from 'antd';
import {useIntl} from "react-intl";
import {Helmet} from 'react-helmet';
import { Icon } from "../../../components/common";
import "./forget-password.scss";
// import LogoImage from "../../assets/images/logo.png";
import LogoImage from "../../../assets/images/logo.png";
// import HashLoader from "react-spinners/HashLoader";
// import { useAlert } from "react-alert";
import { toastr } from "react-redux-toastr";
function validatePassword(value) {
  let state = {
    value,
    isValid: false,
    error: "",
  };
  // let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)” + “(?=.*[-+_!@#$%^&*., ?]).+$/;
  if (!value.length) {
    state.error = "Password cannot be empty";
    return state;
  }

  if(!/[a-z]/.test(value)) {
    state.error = "Please enter atleast 1 lowercase letter";
    return state;
  }
  
  if(!/[A-Z]/.test(value)) {
    state.error = "Please enter atleast 1 uppercase letter";
    return state;
  }

  if(!/\d/.test(value)) {
    state.error = "Please enter atleast 1 number";
    return state;
  }

  if(!/[$&+,:;=?@#|'<>.^*()%!-]/.test(value)){
    state.error = "Please enter atleast 1 special character";
    return state;
  }
  if(value.length < 8) {
    state.error = "Minimum length should be 8";
    return state;
  }

  state.isValid = true;
  return state;
}

function validateConfirm(value, password) {
  let state = {
    value,
    isValid: false,
    error: "",
  };
  if (value !== password) {
    state.error = "Passwords do not match";
    return state;
  }
  state.isValid = true;
  return state;
}

function ForgetPassword(props) {
  const intl = useIntl();
  const [token, setToken] = useState("");
  const [loading, toggleLoading] = useState(false);
  const [form, handleFormChange] = useState({
    password: validatePassword(""),
    confirm: validateConfirm("", ""),
  });
  const [isPasswordVisible,changeIsPasswordVisible] = useState(false);
  const [isPristine, togglePristine] = useState(true);

  useEffect(() => {
    console.log("PROPS: ",props.enterprice);
    // alert.show("Oh look, an alert!");
    setToken(props.match.params.token);
  }, []);

  const handlePasswordChange = (e) => {
    handleFormChange({
      confirm: validateConfirm(form.confirm.value, e.target.value),
      password: validatePassword(e.target.value),
    });
  };

  const togglePassword = () => {
    changeIsPasswordVisible(!isPasswordVisible);
  };

  const handleConfirmChange = (e) => {
    handleFormChange({
      password: validatePassword(form.password.value),
      confirm: validateConfirm(e.target.value, form.password.value),
    });
  };

  const handleSubmit = () => {
    togglePristine(false);
    if (!form.password.isValid || !form.confirm.isValid || loading) {
      return;
    }
    // const formData  = new FormData();
    // formData.append('passwordResetToken', token);
    // formData.append('password', form.password);
    // console.log(formData)
    const data = {
      passwordResetToken: token,
      password: form.password.value,
      // enterpriceReferenceId: 'c0367056fc679a370b5b453128c09f20',
    };
    toggleLoading(true);
     let url = "https://dev-api.pawminded.com/customers/resetPassword";
    // if(props.isDriver) {
    //   url = "https://delivery-api.pawminded.co/api/driver/verifyTokenForForgotPassword"
    // }
    fetch(
      url,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        toggleLoading(false);
        if (res.statusCode === 200) {
          props.history.replace("/passwordResetSuccessfully");
        } else {
          toastr.error(res.message);
        }
      })
      .catch((err) => {
        toggleLoading(false);
        toastr.error("Something went wrong");
      });
  };
  return (
    // <Helmet title={`${props.enterprice.name || 'App'} || Forgot Password`}>
    <div className="external-container">
      <Helmet title={`Forgot Password`} />
      <div className="forget-password__wrapper">
        <div className="forget-password__container">
          <div className="forget-password__logo">
            <img src={LogoImage} alt="Pawminded" />
          </div>
          <div className="form-control">
            <label htmlFor="password">{intl.formatMessage({ id: "app.field.newPassword" })}</label>
            <div style={{ display:"flex", flexDirection:"row"}}>
            {/* <input
              name="password"
              placeholder={intl.formatMessage({ id: "app.placeholder.password" })}
              onChange={handlePasswordChange}
              style={{flex:"1"}}
              type={isPasswordVisible ? "text" : "password"}
              value={form.password.value}
            /> */}
            <Input.Password
              onChange={handlePasswordChange}
              value={form.password.value}
              type={isPasswordVisible ? "text" : "password"}
              icon="key"
              iconPosition="left"
              placeholder="Enter New Password"
            />
            {!isPristine && (
              <div className="error-message">{form.password.error}</div>
            )}
            {/* <div>
              <Icon onClick={togglePassword} name="EYE" pointer={1}/>
            </div> */}
            </div>
          </div>
          <div className="form-control">
            <label htmlFor="password">{intl.formatMessage({ id: "app.field.confirmPassword" })}</label>
            {/* <input
              name="confirm"
              placeholder={intl.formatMessage({ id: "app.field.confirmPassword" })}
              onChange={handleConfirmChange}
              type="password"
              value={form.confirm.value}
            /> */}
            <Input
              onChange={handleConfirmChange}
              value={form.confirm.value}
              type="password"
              icon="key"
              iconPosition="left"
              placeholder="Confirm New Password"
            />
            {!isPristine && (
              <div className="error-message">{form.confirm.error}</div>
            )}
          </div>
          <Button loading={loading} type="primary" size="medium" shape="" style={{width: '100%', backgroundColor:"#2671E1", borderColor:"transparent", marginBottom: '1rem'}} onClick={handleSubmit}>
          {intl.formatMessage({ id: "app.button.resetPassword" })}
          </Button>
        </div>
      </div>
    </div>
    // </Helmet>
  );
}

const mapStateToProps = (state) => ({
  enterprice: state.user.enterprice,
})

export default connect(mapStateToProps)(ForgetPassword);
