import React from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import { PieChart, BarChart, LineChart, CustomLegend } from "../";
import {
  CHART_COLORS,
  LABEL_FORMAT,
} from "../../../../shared/constants/orders";
import "./chart-container.scss";

const styles = {
  wrapper: "order-reports__chart-container__wrapper",
  header: "order-reports__chart-container__header",
  footer: "order-reports__chart-container__footer",
  chartItem: "order-reports__chart-container__chart-item",
  chartItemWrapper: "order-reports__chart-container__chart-item-wrapper custom-section",
  pieChartItem: "order-reports__chart-container__pie-chart-item",
  pieChartItemWrapper: "order-reports__chart-container__pie-chart-item-wrapper custom-section",
};

function pieChartDataUtil(data) {
  return Object.keys(data).reduce((acc, val) => {
    if (val !== "totalOrders" && val !== "graphsData" && data[val] !== 0) {
      acc.push({
        name: val,
        value: data[val],
        fillColor: CHART_COLORS[val],
      });
    }
    return acc;
  }, []);
}

function dateUtil(data,start,end) {
  console.log("DATA: ",data);
  // return data;
  return data.map((i) => {
    // if(start === end){
    //   i.date = moment(start).format("D MMM");
    // }else{
    //   i.date = `${moment(start).format("D MMM")} - ${moment(
    //     end
    //   ).format("D MMM")}`;
    // }
    i["subscribers"] = i.subscribers;
    i["certificates"] = i.certificates;
    return i;
  })
}

const chartTypes = [0, 1, 2];
function ChartContainer(props) {
  const intl = useIntl();
  // if(props.reportType === 0){
  //   const { graphsData, ...pieData } = props.data.weekly;
  // }else{
  //   const { graphsData, ...pieData } = props.data.monthly;
  // }
  // const [graphsData,changeGraphsData] = props.data)
  const graphsData = props.data;
  // const { graphsData } = props.data;
  // console.log(pieChartDataUtil(pieData));
  // if (chartTypes.indexOf(props.reportType) === -1) {
  //   return null;
  // }
  console.log("graphsdata", dateUtil(graphsData,props.reportType));
  return (
    <div className={styles.wrapper}>
      {/* <div className={styles.pieChartItemWrapper}>
  <div className={styles.header}>Total Subscriptions</div>
        <div className={styles.pieChartItem}>
          <PieChart
          color={props.color}
            total={pieData.totalOrders}
            data={pieChartDataUtil(pieData)}
          />
        </div>
        <div className={styles.footer}>
          <CustomLegend
            legend="totalPendingOrders"
            label={intl.formatMessage({
              id: LABEL_FORMAT["totalPendingOrders"],
            })}
            value={`: ${Math.round(
              (pieData.totalPendingOrders /
                (pieData.totalOrders === 0 ? 1 : pieData.totalOrders)) *
                100
            )}%`}
          />
          <CustomLegend
            legend="totalDeliveredOrders"
            label={intl.formatMessage({
              id: LABEL_FORMAT["totalDeliveredOrders"],
            })}
            value={`: ${Math.round(
              (pieData.totalDeliveredOrders /
                (pieData.totalOrders === 0 ? 1 : pieData.totalOrders)) *
                100
            )}%`}
          />
        </div>
        <div className={styles.footer}>
          <CustomLegend
            legend="totalAcceptedOrders"
            label={intl.formatMessage({
              id: LABEL_FORMAT["totalAcceptedOrders"],
            })}
            value={`: ${Math.round(
              (pieData.totalAcceptedOrders /
                (pieData.totalOrders === 0 ? 1 : pieData.totalOrders)) *
                100
            )}%`}
          />
          <CustomLegend
            legend="totalCancelledOrders"
            label={intl.formatMessage({
              id: LABEL_FORMAT["totalCancelledOrders"],
            })}
            value={`: ${Math.round(
              (pieData.totalCancelledOrders /
                (pieData.totalOrders === 0 ? 1 : pieData.totalOrders)) *
                100
            )}%`}
          />
        </div>
      </div> */}
      <div className={styles.chartItemWrapper}>
              <div className={styles.header}>{intl.formatMessage({id: 'app.field.details'})}</div>
        <div style={{ marginBottom: "1rem" }} className={styles.footer}>
          <CustomLegend
            legend="subscribers"
            label={intl.formatMessage({
              id: LABEL_FORMAT["subscribers"],
            })}
          />
          <CustomLegend
            legend="certificates"
            label={intl.formatMessage({
              id: LABEL_FORMAT["certificates"],
            })}
          />
        </div>
        <div className={styles.chartItem}>
          {/* {props.reportType !== 0 && <BarChart color={props.color} data={dateUtil(graphsData)} />} */}
          <LineChart color={props.color} data={graphsData} reportType={props.reportType} />
          {/* <LineChart color={props.color} data={dateUtil(graphsData,props.reportType)} reportType={props.reportType} /> */}
        </div>
      </div>
    </div>
  );
}

export default ChartContainer;
