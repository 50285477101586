import React, {useState, useEffect} from 'react';

import Icon from '../icon/icon';
import styles from './custom-image.module.scss';

const CustomImage = ({isTable, style, src, alt, icon, className, onClick, hoverable}) => {
    const [isPlaceholder, togglePlaceholder] = useState(false);
    const setPlaceholder = (e) => {
        // debugger;
        
        togglePlaceholder(true);
    }
    useEffect(() => {
        if(!src) {
            togglePlaceholder(true)
        } else {
            togglePlaceholder(false)
        }
    }, [src])

    return (
        <div onClick={onClick} style={style} className={`${className ? className: ''} ${isTable ? styles.wrapper__table : styles.wrapper}`}>
            {
                isPlaceholder ? <Icon name={icon ? icon : "PLACEHOLDER"} size={isTable ? "large": "huge"} /> : <img onError={setPlaceholder} className={`${className} ${hoverable ? styles.hoverable : ''}`} src={src} alt={alt}/> 
            }
        </div>
    )
}

export default CustomImage;