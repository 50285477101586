const removeEmptyKeys = (obj) => {
  return Object.keys(obj).reduce((acc, val) => {
    if (obj[val] !== undefined && obj[val] !== "" && obj[val] !== null) {
      acc[val] = obj[val];
    }
    return acc;
  }, {});
};

function nestedParseJSON(data, isArray = Array.isArray(data)) {
  return Object.keys(data).reduce(
    (acc, val) => {
      if (
        typeof data[val] === "string" &&
        (data[val].includes("{") || data[val].includes("["))
      ) {
        try {
          acc[val] = nestedParseJSON(JSON.parse(data[val]));
        } catch (e) {
          acc[val] = data[val];
        }
      } else if (Array.isArray(data[val])) {
        acc[val] = nestedParseJSON(data[val]);
      } else if (
        typeof data[val] === "string" ||
        typeof data[val] === "boolean" ||
        typeof data[val] === "number" ||
        data[val] === null ||
        data[val] === undefined
      ) {
        acc[val] = data[val];
      } else {
        acc[val] = nestedParseJSON(data[val]);
      }
      return acc;
    },
    isArray ? [] : {}
  );
}

const numberWithCommas = (x) => {
  if (x === null) return 0;
  return parseInt(x)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

function abbreviateNumber(value) {
  var newValue = value;
  if (value >= 1000) {
    var suffixes = ["", "k", "m", "b", "t"];
    var suffixNum = Math.floor(("" + value).length / 3);
    var shortValue = "";
    for (var precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum != 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(precision)
      );
      var dotLessShortValue = (shortValue + "").replace(/[^a-zA-Z 0-9]+/g, "");
      if (dotLessShortValue.length <= 2) {
        break;
      }
    }
    if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
    newValue = shortValue + suffixes[suffixNum];
  }
  return newValue;
}

export { removeEmptyKeys, numberWithCommas, abbreviateNumber, nestedParseJSON };
