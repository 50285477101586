import React from "react";
import { useIntl } from "react-intl";
import moment from "moment";

import { CustomModal } from "../../../../components/common";
import { numberWithCommas } from "../../../../shared/utils/object-manipulation";
import {PAYMENT_METHODS_MAPPING} from '../../../../shared/constants/orders';
import "./order-history-modal.scss";

const styles = {
  wrapper: "order-history-modal__wrapper",
  bookingInfo: {
    container: "order-history-modal__booking-info__container",
    item: "order-history-modal__booking-info__item",
    footer: "order-history-modal__booking-info__footer",
  },

  billing: {},
};

function OrderHistoryModal(props) {
  const intl = useIntl();

  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={props.onCloseIconClick}
      useRawHeader
      header={`${intl.formatMessage({ id: "app.field.bookingNumber" })} #${
        props.data.bookingId
      }`}
      isVisible={props.isVisible}
    >
      <div className={styles.wrapper}>
        <div className={styles.bookingInfo.container}>
          <div className={styles.bookingInfo.item}>
            <label>
              {intl.formatMessage({ id: "app.field.customerName" })}
            </label>
            <div>{`${props.data.customer?.customerName}`}</div>
          </div>
          <div className={styles.bookingInfo.item}>
            <label>
              {intl.formatMessage({ id: "app.field.customerPhone" })}
            </label>
            <div>{props.data.customer?.phoneNo}</div>
          </div>
          {/* <div className={styles.bookingInfo.item}>
            <label>{intl.formatMessage({ id: "app.field.bookingType" })}</label>
            <div>{props.data.bookingType}</div>
          </div> */}
          <div className={styles.bookingInfo.item}>
            <label>
              {intl.formatMessage({ id: "app.field.slotStartTime" })}
            </label>
            <div>
              {props.data.bookingSlotStartTime}
            </div>
          </div>
          <div className={styles.bookingInfo.item}>
            <label>{intl.formatMessage({ id: "app.field.slotEndTime" })}</label>
            <div>
              {props.data.bookingSlotEndTime}
            </div>
          </div>
          <div className={styles.bookingInfo.item}>
            <label>{intl.formatMessage({ id: "app.field.bookingTime" })}</label>
            <div>
              {moment(props.data.createdAt).format(
                "HH:MM MMMM Do YYYY"
              )}
            </div>
          </div>
          <div className={styles.bookingInfo.item}>
            <label>{intl.formatMessage({ id: "app.field.paymentMode" })}</label>
            <div>{PAYMENT_METHODS_MAPPING[props.data.modeOfPaymentStatus]}</div>
          </div>
          <div className={styles.bookingInfo.item}>
            <label>{intl.formatMessage({ id: "app.field.subTotal" })}</label>
            <div>{numberWithCommas(props.data.totalAmount)}</div>
          </div>
          <div className={styles.bookingInfo.item}>
            <label>{intl.formatMessage({ id: "app.field.discount" })}*</label>
            <div style={{ color: "red" }}>
              -
              {numberWithCommas(
                props.data.discount
              )}
            </div>
          </div>
          {props.data.creditsUsed > 0 && (
            <div className={styles.bookingInfo.item}>
              <label>
                {intl.formatMessage({ id: "app.field.creditsUsed" })}
              </label>
              <div style={{ color: "red" }}>
                -{numberWithCommas(props.data.creditsUsed)}
              </div>
            </div>
          )}
        </div>
        <div className={styles.bookingInfo.item}>
          <label>
            {intl.formatMessage({ id: "app.field.deliveryCharge" })}
          </label>
          <div>+{numberWithCommas(props.data.deliveryCharges)}</div>
        </div>
        <div className={styles.bookingInfo.footer}>
          <label>{intl.formatMessage({ id: "app.field.totalAmount" })}</label>
          <div>{numberWithCommas(props.data.totalPaidAmount)}</div>
        </div>
      </div>
    </CustomModal>
  );
}

export default OrderHistoryModal;
