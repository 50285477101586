import { getPanelType } from "../../shared/constants/dropdown";
import { getLanguage } from "../../shared/utils/rtl";
import userActionTypes from "./user-action-types";

const initialState = {
  locale: getLanguage(),
  isRtl: false,
  isLoading: true,
  userInfo: {},
  course: undefined,
  panelType: getPanelType(),
  isAddDisabled: getLanguage() !== 'en',
  enterprice: {
    name: "Pawminded",
    color: "#2671E1",
    pickerFlow: 1,
    goodFleetsEnabled: 1,
    panelType: getPanelType(),
  },
  selectedCountry: undefined,
  selectedCities: [],
  languages: [],
};

const getCountriesInfo =(state, payload) => {
  const panelType = getPanelType();
  if(panelType === 0) {
    return state;
  }
  if(panelType === 1) {
    return {
      ...state,
      selectedCountry: payload.countryId,
      selectedCities: payload.cityIds,
      languages: [],
    }
  }
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userActionTypes.SET_USER_INFO:
      return {
        ...getCountriesInfo(state, action.payload),
        isLoading: false,
        userInfo: {
          ...action.payload,
        },
          
      };

    case userActionTypes.CHANGE_LANGUAGE:
      return {
        ...state,
        locale: action.payload,
        isAddDisabled: action.payload !== "en"
      };

      case userActionTypes.CHANGE_COURSE:
        return {
          ...state,
          course: action.payload,
        };

    case userActionTypes.SET_LANGUAGE_DATA:
      return {
        ...state,
        languages: action.payload,
      };
    case userActionTypes.CHANGE_COUNTRY:
      return {
        ...state,
        selectedCountry: action.payload,
      };
    
    case userActionTypes.CHANGE_CITY:
      return {
        ...state,
        selectedCities: action.payload,
      }

    default:
      return state;
  }
};

export default userReducer;
