import React, { useState } from "react";
import { Popconfirm, Tooltip, Switch } from "antd";
import { useIntl } from "react-intl";
import classNames from "classnames";
import moment from "moment";

import { Panel, Icon } from "../../../../components/common";
import { numberWithCommas } from "../../../../shared/utils/object-manipulation";
import {PAYMENT_METHODS_MAPPING, ORDER_STATUS_MAPPING} from '../../../../shared/constants/orders';
import "./customer-details-table.scss";

const styles = {
  table: "custom-table",
  tableActions: "custom-table__table-actions",
  actionIcon: "custom-table__action-icon",
  panelContainer: "customer-details-table__panel-container",
  stickyAction: "sticky-action",
};
function CustomerDetailsTable(props) {
  const intl = useIntl();

  return (
    <Panel
      isLoading={props.isLoading}
      isEmpty={props.isEmpty}
      emptyText={intl.formatMessage({ id: "app.field.noData" })}
      containerClass={styles.panelContainer}
    >
      <table className={styles.table}>
        <tbody>
          <tr>
            <th>{intl.formatMessage({ id: "app.field.index" })}</th>
            <th>{intl.formatMessage({ id: "app.field.bookingNumber" })}</th>
            <th>{intl.formatMessage({ id: "app.field.storeName" })}</th>
            <th>{intl.formatMessage({ id: "app.field.deliveryAddress" })}</th>
            {/* <th>{intl.formatMessage({ id: "app.field.bookingType" })}</th> */}
            <th>{intl.formatMessage({ id: "app.field.bookingTime" })}</th>
            <th>{intl.formatMessage({ id: "app.field.paymentMode" })}</th>
            <th>{intl.formatMessage({ id: "app.field.status" })}</th>
            <th className={styles.stickyAction}>
              {intl.formatMessage({ id: "app.field.action" })}
            </th>
          </tr>
          {props.data.map((item, index) => (
            <tr key={item.bookingId}>
              <td>{(props.recordsPerPage*(props.currentPage-1) + index + 1)}</td>
              <td>{item.bookingId}</td>
              <td>{item.branch?.branchName}</td>
              <td>{item.address?.address}</td>
          {/* <td>{item.bookingType}</td> */}
              <td>{moment(item.bookingDateTime).format("HH:MM MMMM Do YYYY")}</td>
              <td>{PAYMENT_METHODS_MAPPING[item.modeOfPaymentStatus]}</td>
              <td>{ORDER_STATUS_MAPPING[item.status]}</td>
              <td className={styles.stickyAction}>
                <div className={styles.tableActions}>
                  <Tooltip title={intl.formatMessage({ id: "app.field.viewDetails" })} placement="top">
                    <span
                      onClick={() => {
                        props.viewDetails(item);
                      }}
                      className={styles.actionIcon}
                    >
                      <Icon name="DETAILS" size="small" />
                    </span>
                  </Tooltip>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Panel>
  );
}

export default CustomerDetailsTable;
