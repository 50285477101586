import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { Header, Icon, Panel } from "../../components/common";
import { Form, ChartContainer } from "./components";
import axios from "../../shared/api/axiosConfig";
import Validations from "../../shared/validations/Validations";
import { removeEmptyKeys } from "../../shared/utils/object-manipulation";
import "./order-reports.scss";

const styles = {
  wrapper: "order-reports__wrapper",
  content: "order-reports__content",
  formWrapper: "order-reports__form-wrapper custom-section",
  chartWrapper: "order-reports__chart-wrapper",
};
function OrderReports(props) {
  const [isLoading, toggleIsLoading] = useState(false);
  const [stores, updateStores] = useState([]);
  const [reportType, changeReportType] = useState("");
  const [reportsData, updateReportsData] = useState({
    weekly: [],
    monthly: [],
  });
  const [startDate, updateStartDate] = useState(
    Validations.validateStartEndDate(undefined, undefined).startDate
  );
  const [endDate, updateEndDate] = useState(
    Validations.validateStartEndDate(undefined, undefined).endDate
  );

  const getReportsData = (data) => {
    updateStartDate(data.startDate);
    updateEndDate(data.endDate);
    toggleIsLoading(true);
    axios
      .get("/reports", {
        params: removeEmptyKeys({
          startDate: data.startDate,
          endDate: data.endDate,
          reportType: data.reportType,
        }),
      })
      .then((res) => {
        toggleIsLoading(false);
        changeReportType(data.reportType);
        if(res.data.data.monthly && res.data.data.monthly.length>0){
          updateReportsData(old=>({
            ...old,
            monthly:res.data.data.monthly
          }))
        }
        if(res.data.data.weeklySubscribers && res.data.data.weeklySubscribers.length>0){
          updateReportsData(old=>({
            ...old,
            weekly:res.data.data.weeklySubscribers
          }))
        }
      })
      .catch((err) => {
        toggleIsLoading(false);
      });
  };
  return (
    <div className={styles.wrapper}>
      <Header title="Report" />
        <div className={styles.content}>
          <div className={styles.formWrapper}>
            <Form
              stores={stores}
              isLoading={isLoading}
              branchId={props.branchId}
              panelType={props.panelType}
              handleSubmit={getReportsData}
            />
          </div>
          </div>
          {(reportsData.weekly.length > 0 || reportsData.monthly.length > 0) && (<div className={styles.chartWrapper}>
            <ChartContainer
              color={props.color}
              data={reportType === 0 ? reportsData.weekly : reportsData.monthly}
              startDate={startDate}
              endDate={endDate}
              reportType={reportType}
            />
          </div>)}
        
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    color: state.user.enterprice.color,
    masterStoreId: state.user.enterprice.masterStoreId,
    branchId: state.user.userInfo.branchId,
    isRestaurant: state.user.userInfo.isRestaurant,
    panelType: state.user.enterprice.panelType,
  };
};
export default connect(mapStateToProps)(OrderReports);
