import React from "react";
import {useIntl} from "react-intl"
import { Tag } from "antd";

const TAG_COLORS = {
  ACTIVE: "green",
  BLOCKED: "volcano",
  LOCKED: "gold",
  UNLOCKED: "green",
  SUPERADMIN: "green",
};

const CustomTag = ({ isBlocked, isLocked, isEnabled, isVerified, isOpen, inStock, isYes, isSuperAdmin,isActive }) => {
  const intl = useIntl();
  if (
    isBlocked === undefined &&
    isLocked === undefined &&
    isEnabled === undefined &&
    isVerified === undefined &&
    inStock === undefined &&
    isYes === undefined &&
    isOpen === undefined && 
    isActive === undefined &&
    isSuperAdmin === undefined
  ) {
    return "---";
  }

  if (isLocked !== undefined && isLocked !== null) {
    return isLocked ? (
      <Tag style={{ margin: 0 }} color={TAG_COLORS.LOCKED}>
        {intl.formatMessage({ id: "app.tag.locked" })}
      </Tag>
    ) : (
      <Tag style={{ margin: 0 }} color={TAG_COLORS.UNLOCKED}>
        {intl.formatMessage({ id: "app.tag.open" })}
      </Tag>
    );
  }
  if (isBlocked !== undefined && isBlocked !== null) {
    return isBlocked ? (
      <Tag style={{ margin: 0 }} color={TAG_COLORS.BLOCKED}>
        {intl.formatMessage({ id: "app.tag.blocked" })}
      </Tag>
    ) : (
      <Tag style={{ margin: 0 }} color={TAG_COLORS.UNLOCKED}>
        {intl.formatMessage({ id: "app.tag.active" })}
      </Tag>
    );
  }
  if (isActive !== undefined && isActive !== null) {
    return isActive ? (
      <Tag style={{ margin: 0 }} color={TAG_COLORS.UNLOCKED}>
        Active
      </Tag>
    ) : (
      <Tag style={{ margin: 0 }} color={TAG_COLORS.BLOCKED}>
        InActive
      </Tag>
    );
  }
  if (isSuperAdmin !== undefined && isSuperAdmin !== null) {
    return isSuperAdmin == 1? (
      <Tag style={{ margin: 0 }} color={TAG_COLORS.SUPERADMIN}>
        {/* {intl.formatMessage({ id: "app.tag.active" })} */}
        Super-Admin
      </Tag>
    ) : (
      <Tag style={{ margin: 0 }} color={TAG_COLORS.SUPERADMIN}>
        Sub-Admin
        {/* {intl.formatMessage({ id: "app.tag.active" })} */}
      </Tag>
    );
  }
  if (isEnabled !== undefined && isEnabled !== null) {
    return !isEnabled ? (
      <Tag style={{ margin: 0 }} color={TAG_COLORS.BLOCKED}>
        {intl.formatMessage({ id: "app.tag.disabled" })}
      </Tag>
    ) : (
      <Tag style={{ margin: 0 }} color={TAG_COLORS.UNLOCKED}>
        {intl.formatMessage({ id: "app.tag.enabled" })}
      </Tag>
    );
  }
  if (isVerified !== undefined && isVerified !== null) {
    return !isVerified ? (
      <Tag style={{ margin: 0 }} color={TAG_COLORS.BLOCKED}>
        {intl.formatMessage({ id: "app.tag.unverified" })}
      </Tag>
    ) : (
      <Tag style={{ margin: 0 }} color={TAG_COLORS.UNLOCKED}>
        {intl.formatMessage({ id: "app.tag.verified" })}
      </Tag>
    );
  }
  if (isOpen !== undefined && isOpen !== null) {
    return !isOpen ? (
      <Tag style={{ margin: 0 }} color={TAG_COLORS.LOCKED}>
        {intl.formatMessage({ id: "app.tag.closed" })}
      </Tag>
    ) : (
      <Tag style={{ margin: 0 }} color={TAG_COLORS.UNLOCKED}>
        {intl.formatMessage({ id: "app.tag.open" })}
      </Tag>
    );
  }

  if (inStock !== undefined && inStock !== null) {
    return !inStock ? (
      <Tag style={{ margin: 0 }} color={TAG_COLORS.BLOCKED}>
        {intl.formatMessage({ id: "app.tag.outOfStock" })}
      </Tag>
    ) : (
      <Tag style={{ margin: 0 }} color={TAG_COLORS.UNLOCKED}>
        {intl.formatMessage({ id: "app.tag.inStock" })}
      </Tag>
    );
  }

  if (isYes !== undefined && isYes !== null) {
    return !isYes ? (
      <Tag style={{ margin: 0 }} color={TAG_COLORS.LOCKED}>
        {intl.formatMessage({ id: "app.tag.no" })}
      </Tag>
    ) : (
      <Tag style={{ margin: 0 }} color={TAG_COLORS.UNLOCKED}>
        {intl.formatMessage({ id: "app.tag.yes" })}
      </Tag>
    );
  }
  return "---";
};

export default CustomTag;
