import React, { useState, useEffect } from "react";
import { Input, Button, Select } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import {
  CustomModal,
  Panel,
  ErrorMessage,
  UploadButton,
} from "../../../../components/common";
import axios from "../../../../shared/api/axiosConfig";
import Validations from "../../../../shared/validations/Validations";
import placeholderImg from "../../../../assets/images/item-placeholder.png";
import "./add-edit-brand.scss";

const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  imageContainer: "brands__add-edit-brand-modal__image-container",
  imageContainerImage: "brands__add-edit-brand-modal__image-container__image",
  imageContainerForm: "brands__add-edit-brand-modal__image-container__form",
};

const { Option } = Select;

function AddEditBrand(props) {
  const intl = useIntl();

  const [isPristine, togglePristine] = useState(true);
  const [name, changeName] = useState(Validations.validateTextField(""));
  const [rank, changeRank] = useState(Validations.validateNumericValue(null));
  const [imageURL, changeImageURL] = useState(
    Validations.validateTextField("")
  );
  const [store, changeStore] = useState(Validations.validateTextField(""));

  const [stores, updateStores] = useState({
    selected: undefined,
    isLoading: true,
    data: [],
    notFound: false,
  });

  const resetForm = () => {
    togglePristine(true);
    changeName(Validations.validateTextField(null));
    changeRank(Validations.validateNumericValue(null));
    changeImageURL(Validations.validateTextField(null));
    changeStore(Validations.validateTextField(""));
  };

  const setForm = () => {
    togglePristine(true);
    changeName(Validations.validateTextField(props.data.brandName));
    changeRank(Validations.validateNumericValue(props.data.brandRank));
    changeImageURL(Validations.validateTextField(props.data.brandLogo));
    changeStore(Validations.validateTextField(props.data.branchId));
  };
  useEffect(() => {
    if(props.isVisible) {
      if (props.modalToShow === "EDIT_BRAND") {
        setForm();
        //   setForm();
      } else if (props.modalToShow === "ADD_BRAND") {
        resetForm();
      }
    }
  }, [props.isVisible, props.data]);

  useEffect(() => {
    axios
      .get("/getBranches", {
        params: {
          onlyNames: 1,
        }
      })
      .then((res) => {
        if (res.data.data.branchesData.length) {
          updateStores({
            selected: undefined,
            data: res.data.data.branchesData,
            isLoading: false,
            notFound: false,
            count: res.data.data.branchCount,
          });
        } else {
          updateStores({
            ...stores,
            notFound: true,
          });
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
      });
  }, []);

  const handleFieldChange = (e) => {
    const { value } = e.target;

    switch (e.target.name) {
      case "name":
        changeName(Validations.validateTextField(value));
        break;
      case "rank":
        changeRank(Validations.validateNumericValue(value));
        break;
      default:
        break;
    }
  };

  const handleStoreChange = (selected) => {
    updateStores({
      ...stores,
      selected,
    });
    changeStore(Validations.validateTextField(selected));
  };

  const getData = () => ({
    brandName: name.value,
    brandLogo: imageURL.value,
    brandLogoIcon: imageURL.value,
    brandRank: rank.value,
    branchId: props.modalToShow === "ADD_BRAND" ? store.value : "",
  });

  const handleAdd = () => {
    // TODO
    props.addBrand(getData());
  };

  const handleEdit = () => {
    // TODO
    props.editBrand(getData());
  };

  const isFormValid = () => {
    let isValid = name.isValid && rank.isValid && imageURL.isValid;
    if(props.modalToShow === 'ADD_BRAND') {
      isValid = isValid && store.isValid;
    }
    return isValid;
  };

  const closeModal = () => {
    props.onCloseIconClick();
  };

  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const formAction = () => {
    togglePristine(false);
    if (!isFormValid()) {
      return;
    }
    if (props.modalToShow === "ADD_BRAND") {
      handleAdd();
    } else if (props.modalToShow === "EDIT_BRAND") {
      handleEdit();
    }
  };

  const handleImageChange = (image) => {
    changeImageURL(Validations.validateTextField(image));
  };

  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={closeModal}
      header={
        props.modalToShow === "ADD_BRAND"
          ? "app.field.addBrand"
          : "app.field.editBrand"
      }
      isVisible={props.isVisible}
    >
      <Panel isLoading={props.isLoading} className={styles.formModal}>
        <div className={styles.imageContainer}>
          <div className={styles.imageContainerImage}>
            <img
              src={imageURL.value ? imageURL.value : placeholderImg}
              alt=""
            />
            <UploadButton
              text="Upload Image"
              resultType="url"
              accept="image/*"
              onChange={handleImageChange}
            />
            {!isPristine && <ErrorMessage field={imageURL} />}
          </div>

          <div className={styles.imageContainerForm}>
            <div className={styles.formItem}>
              <label>
                {intl.formatMessage({
                  id: "app.field.rank",
                })}
              </label>
              <Input
                name="rank"
                type="number"
                onChange={handleFieldChange}
                value={rank.value}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.rank",
                })}
              />
              {!isPristine && <ErrorMessage field={rank} />}
            </div>
            <div className={styles.formItem}>
              <label>
                {intl.formatMessage({
                  id: "app.field.brandName",
                })}
              </label>
              <Input
                name="name"
                onChange={handleFieldChange}
                value={name.value}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.brandName",
                })}
              />
              {!isPristine && <ErrorMessage field={name} />}
            </div>
          </div>
        </div>
{ props.modalToShow === 'ADD_BRAND' &&  <div className={styles.formGroup}>
          <div className={styles.formItem}>
            <label>
              <FormattedMessage id="app.field.storeName" />
            </label>
            <Select
              showSearch
              placeholder={intl.formatMessage({
                id: "app.placeholder.store",
              })}
              onChange={handleStoreChange}
              value={store.value}
              optionFilterProp="children"
              filterOption={filterOption}
            >
              {stores.data.map((store) => (
                <Option key={store.branchId} value={store.branchId}>
                  {store.branchName}
                </Option>
              ))}
            </Select>
            {!isPristine && <ErrorMessage field={store} />}
          </div>
        </div>}
        <div className={styles.formAction}>
          <Button type="default" shape={undefined} onClick={closeModal}>
          {intl.formatMessage({id: 'app.button.cancel'})}
          </Button>
          <Button type="primary" shape={undefined} onClick={formAction}>
          {intl.formatMessage({id: 'app.button.submit'})}
          </Button>
        </div>
      </Panel>
    </CustomModal>
  );
}

export default AddEditBrand;
