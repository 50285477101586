import React from "react";
import { FormattedMessage } from "react-intl";
import { Modal, Drawer } from "antd";

import Icon from "../icon/icon";
import classNames from "classnames";
import "./custom-modal.scss";
import { connect } from "react-redux";
import {checkRtl} from '../../../shared/utils/rtl';

const styles = {
  header: "custom-modal__header",
  headerRow: "custom-modal__header-row",
  wrapper: "custom-modal__wrapper",
  closeIconWrapper: "custom-modal__close-icon-wrapper",
  contentContainer: "custom-modal__content-container",
  antModalWrapper: "custom-modal__ant-modal-wrapper",
  action: "custom-modal__action",
};
class CustomModal extends React.Component {
  onClose = () => {
    this.props.onCloseIconClick();
  };

  componentDidUpdate() {
    if (this.props.isVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.removeAttribute("style");
    }
  }

  fixOverflow = () => {
    document.body.removeAttribute("style");
  };

  componentWillUnmount() {
    this.fixOverflow();
  }

  getPlacement = () => {
    const isRTL = this.props.isRTL;
    if(!this.props.placement) {
      return isRTL ? 'left': 'right';
    } else {
      if(this.props.placement === 'left') {
        return  isRTL ?  "right" : 'left';
      }
      if(this.props.placement === 'right') {
        return  isRTL ?  "left" : 'right';
      }
    }
    return this.props.placement;
  }
  render() {
    return (
      <Drawer
        width={this.props.width || 700}
        height={this.props.height}
        // style={{ top: 20 }}
        maskClosable={false}
        title={
          this.props.useRawHeader ? (
            this.props.header
          ) : (
            <div className={styles.headerRow}>
              <FormattedMessage id={this.props.header} />
            </div>
          )
        }
        className={styles.antModalWrapper}
        // closeIcon={false}
        bodyStyle={{ padding: 0, borderRadius: "1rem" }}
        footer={<div dir={this.props.isRTL ? 'rtl' : 'ltr'} className={styles.action}>{this.props.action}</div>}
        closable={true}
        maskClosable={false}
        onClose={this.props.onCloseIconClick}
        afterClose={this.fixOverflow}
        visible={this.props.isVisible}
        placement={this.getPlacement()}
      >
        <div className={styles.wrapper}>
          {/* <div className={styles.header}>
            {this.props.useRawHeader ? (
              this.props.header
            ) : (
              <FormattedMessage id={this.props.header} />
            )}
            <div
              onClick={this.props.onCloseIconClick}
              className={styles.closeIconWrapper}
            >
              <Icon name="CLOSE" size="small" fill="#fff" />
            </div>
          </div> */}
          <div
          id="custom-modal-body"
          dir={this.props.isRTL ? 'rtl' : 'ltr'}
            className={classNames({
              [styles.contentContainer]: true,
              [this.props.className]: this.props.className,
            })}
          >
            {this.props.children}
          </div>
        </div>
      </Drawer>
    );
    // return (
    //   <TransitionablePortal
    //     open={this.props.isVisible}
    //     animation="fade"
    //     duration={500}
    //   >
    //     <Modal
    //       style={{ borderRadius: "1rem" }}
    //       centered={this.props.centered}
    //       onClose={this.props.onClose}
    //       open={this.props.isVisible}
    //       size={this.props.size}
    //     >
    //       <div className={styles.wrapper}>
    //         <div className={styles.header}>
    //           <FormattedMessage id={this.props.header} />
    //           <div
    //             onClick={this.props.onCloseIconClick}
    //             className={styles.closeIconWrapper}
    //           >
    //             <Icon name="close" />
    //           </div>
    //         </div>
    //         <div
    //           className={classNames({
    //             [styles.contentContainer]: true,
    //             [this.props.className]: this.props.className,
    //           })}
    //         >
    //           {this.props.children}
    //         </div>
    //       </div>
    //     </Modal>
    //   </TransitionablePortal>
    // );
  }

  static defaultProps = {
    centered: false,
  };
}
const mapState = (state) => ({
isRTL: checkRtl(state.user.locale)
})
export default connect(mapState)(CustomModal);
