import React, { useState } from "react";
import { Popconfirm, Tooltip, Switch, Menu, Dropdown } from "antd";
import { useIntl } from "react-intl";
import classNames from "classnames";
import moment from "moment";

import { Panel, Icon, CustomTable, CustomTag, Label} from "../../../../components/common";
import { numberWithCommas } from "../../../../shared/utils/object-manipulation";
import "./customers-table.scss";

const styles = {
  table: "custom-table",
  tableActions: "custom-table__table-actions",
  actionIcon: "custom-table__action-icon",
  panelContainer: "custom-table-panel-container",
  stickyAction: "sticky-action",
};
function CustomersTable(props) {
  const intl = useIntl();

  const columns = [
    {
      dataIndex: "",
      // sorter: true,
      title: intl.formatMessage({ id: "app.field.sNo" }),
      // fixed: 'left',
      render: (e, item, index) => {
        return (
          <span>
            {props.pagination.recordsPerPage * (props.pagination.currentPage - 1) + index + 1}
          </span>
        );
      },
    },
    {
      dataIndex: "name",
      title: intl.formatMessage({ id: "app.field.name" }),
      // render: (a, b) => `${b.firstName || ""} ${b.lastName || ""}`,
      // fixed: 'left',
    },

    {
      dataIndex: "email",
      title: intl.formatMessage({ id: "app.field.email" }),
    },
    {
      dataIndex: "phoneNo",
      title: intl.formatMessage({ id: "app.field.phoneNumber" }),
      render: (i, p) => `${p.countryCode || ""} ${p.phoneNo || "---"}`,
    },
    {
      dataIndex: "createdAt",
      title: intl.formatMessage({ id: "app.field.registrationDate" }),
      render: (i, item) => `${moment.utc(item.createdAt).format("MMMM Do YYYY")}`,
    },
   
    // {
    //   dataIndex: "address",
    //   title: intl.formatMessage({ id: "app.field.address" }),
    //   render: (add) => {
    //     let i = add?.address || "---";
    //     // debugger;
    //     if (typeof i === "string") {
    //       // add = JSON.parse(add);
    //     }
    //     return i.length > TABLE_TEXT_LIMIT.ADDRESS ? (
    //       <Tooltip title={i}>
    //         <span style={{ cursor: "pointer" }}>
    //           {textTrimmer(i, TABLE_TEXT_LIMIT.ADDRESS)}
    //         </span>
    //       </Tooltip>
    //     ) : (
    //       i
    //     );
    //   },
    // },
    // {
    //   dataIndex: "dateOfBirth",
    //   title: intl.formatMessage({ id: "app.field.age" }),
    //   render: (dateOfBirth) =>
    //     dateOfBirth ? moment().diff(dateOfBirth, "years") || "N/A" : "N/A",
    // },
    // {
    //   dataIndex: "gender",
    //   title: intl.formatMessage({ id: "app.field.gender" }),
    // },
    // {
    //   dataIndex: "walletAmount",
    //   title: intl.formatMessage({ id: "app.field.walletMoney" }),
    // },
    
    {
      dataIndex: "isBlocked",
      title: intl.formatMessage({ id: "app.field.status" }),
      render: (item) => {
        // return item.isBlocked ? <Tag color="volcano">BLOCKED</Tag> : <Tag color="green">ACTIVE</Tag>
        return <CustomTag isBlocked={item} />;
      },
    },

    // {
    //   dataIndex: "appVersion",
    //   title: intl.formatMessage({ id: "app.field.appVersion" }),
    // },
    {
      dataIndex: "deviceType",
      title: intl.formatMessage({ id: "app.field.deviceType" }),
    },
  ];

  return (
    <CustomTable
    isLoading={props.isLoading}
    columns={columns}
    rowSelection={{
      type: 'checkbox',
      ...props.rowSelection,
    }}
    dataSource={props.data}
    // dataSource={props.data.filter(i => (i.firstName?.toLowerCase().includes(props.search.toLowerCase()) || i.lastName?.toLowerCase().includes(props.search.toLowerCase())))}
    isEmpty={props.isEmpty}
    pagination={props.pagination}
    rowKey={i => i.customerId}
    onChange={props.onChange}
    totalRecords={props.totalRecords}
  />
  );
}

export default CustomersTable;
