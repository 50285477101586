import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Select, Button, Input } from "antd";
import { toastr } from "react-redux-toastr";

import Validations from "../../../../shared/validations/Validations";
import axios from "../../../../shared/api/axiosConfig";
import {
  Panel,
  CustomModal,
  ErrorMessage,
  Label,
  CustomTable,
} from "../../../../components/common";
import { numberWithCommas } from "../../../../shared/utils/object-manipulation";
import {
  PAYMENT_METHODS_MAPPING,
  PICKER_STATUS,
  PAYMENT_METHODS,
} from "../../../../shared/constants/orders";
import "./cancel-order-modal.scss";

const styles = {
  table: "custom-table",
  tableWrapper: "cancel-order-modal__table-wrapper",
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  wrapper: "cancel-order-modal__wrapper",
  bookingInfo: {
    container: "cancel-order-modal__booking-info__container",
    item: "cancel-order-modal__booking-info__item",
  },

  footer: "cancel-order-modal__footer",
};

const { Option } = Select;

function CancelOrderModal(props) {
  const intl = useIntl();

  const [tableControls, updateTableControls] = useState({
    isLoading: false,
    data: [],
    isEmpty: false,
  });

  const [refundType, changeRefundType] = useState(
    Validations.validateTextField(PAYMENT_METHODS.WALLET)
  );

  const [isPristine, changeIsPristine] = useState(true);

  const handleRefundTypeChange = (e) => {
    changeRefundType(Validations.validateTextField(e));
  };

  const getProducts = (bookingId) => {
    updateTableControls({
      data: [],
      isLoading: true,
      isEmpty: false,
    });
    axios
      .get("/getGroceryOrderDetails", {
        params: {
          bookingId,
        },
      })
      .then((res) => {
        if (!res.data.data.booking.length) {
          toastr.error("Error", "Something Went Wrong");
        } else {
          const products = JSON.parse(res.data.data.booking[0].products);
          if (products.length) {
            updateTableControls({
              data: products,
              isLoading: false,
              isEmpty: false,
            });
          } else {
            updateTableControls({
              data: [],
              isLoading: false,
              isEmpty: true,
            });
          }
        }
      })
      .catch((err) => {
        // toastr.error("Error", "Something Went Wrong");
        updateTableControls({
          data: [],
          isLoading: false,
          isEmpty: true,
        });
      });
  };
  // console.log("props", props);
  useEffect(() => {
    if (props.isVisible) {
      changeRefundType(Validations.validateTextField(PAYMENT_METHODS.WALLET));
      getProducts(props.data.bookingId);
    }
  }, [props.isVisible]);

  const handleCancelOrder = () => {
    changeIsPristine(false);
    // if (cancellationReason.isValid) {
    props.cancelOrder(refundType.value);
    // }
  };

  const columns = [
    {
      dataIndex: 'name',
      title: intl.formatMessage({ id: "app.field.item" }),
    },
    {
      dataIndex: 'pickerJobStatus',
      title: intl.formatMessage({ id: "app.field.status" }),
      render: (item) => item === PICKER_STATUS.PICKED ? "Picked" : "Not Picked",
      filterOut: !props.pickerFlow,
    },
    {
      dataIndex: 'quantityBooked',
      title: intl.formatMessage({ id: "app.field.quantity" }),
    },
    {
      dataIndex: 'actualPrice',
      title: intl.formatMessage({ id: "app.field.unitPrice" }),
      render: (item) => numberWithCommas(item),
    },
  ].filter((i) => i.filterOut ? false : true)

  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={props.onCloseIconClick}
      useRawHeader
      header={`${intl.formatMessage({ id: "app.field.bookingNumber" })} #${
        props.data.bookingId
      }`}
      isVisible={props.isVisible}
      action={<>
       <Button loading={props.isLoading} onClick={handleCancelOrder} type="primary" shape={undefined}>
        <FormattedMessage id="app.field.cancelOrder" />
          </Button>
          <Button onClick={props.onCloseIconClick} shape={undefined}>
            <FormattedMessage id="app.field.close" />
          </Button>
          </>}
    >
      <Panel isModal isLoading={props.isLoading} className={styles.wrapper}>
        <div className={styles.bookingInfo.container}>
          <div className={styles.bookingInfo.item}>
            <Label value="app.field.customerName" />
            <div>{`${props.data.customer?.customerName}`}</div>
          </div>
          <div className={styles.bookingInfo.item}>
            <Label value="app.field.customerPhone" />
            <div>{props.data.customer?.phoneNo}</div>
          </div>
          {/* <div className={styles.bookingInfo.item}>
            <label>{intl.formatMessage({ id: "app.field.bookingType" })}</label>
            <div>{props.data.bookingType}</div>
          </div> */}
          <div className={styles.bookingInfo.item}>
            <Label value="app.field.paymentMode" />
            <div>{PAYMENT_METHODS_MAPPING[props.data.modeOfPaymentStatus]}</div>
          </div>
        </div>

      <CustomTable
        dataSource={tableControls.data}
        isLoading={tableControls.isLoading}
        pagination={false}
        size="small"
        columns={columns}
       />
        <div className={styles.footer}>
          {props.data.modeOfPaymentStatus === PAYMENT_METHODS.AREEBA && (
            <div className={styles.formItem}>
              <label>
                {intl.formatMessage({ id: "app.field.refundType" })}
              </label>
              <Select
                onChange={handleRefundTypeChange}
                value={refundType.value}
              >
                <Option
                  key={PAYMENT_METHODS.WALLET}
                  value={PAYMENT_METHODS.WALLET}
                >
                  {PAYMENT_METHODS_MAPPING[PAYMENT_METHODS.WALLET]}
                </Option>
                <Option key={PAYMENT_METHODS.CARD} value={PAYMENT_METHODS.CARD}>
                  {PAYMENT_METHODS_MAPPING[PAYMENT_METHODS.CARD]}
                </Option>
              </Select>
              {!isPristine && <ErrorMessage field={refundType} />}
            </div>
          )}
        </div>
      </Panel>
    </CustomModal>
  );
}

export default CancelOrderModal;
