import userActionTypes from './user-action-types';
import modalActions from '../modal/modal-actions';
import {toastr} from 'react-redux-toastr';
import history from '../../shared/utils/history';
import { setLanguage } from '../../shared/utils/rtl';
const userActions  = {
    setUserInfo: (payload) => ({type: userActionTypes.SET_USER_INFO, payload}),
    logout: () => () => {
        localStorage.removeItem('trollyAdmin_jwt');
        localStorage.removeItem('trollyAdmin_userInfo');
        localStorage.removeItem('trollyAdmin_roles');
        toastr.info('Info', 'Logged out');
        history.replace('/auth/login')
    },
    setLanguage:(payload) => ({type: userActionTypes.CHANGE_LANGUAGE, payload}),
    changeLanguage: (payload) => (dispatch) => {
        setLanguage(payload);
        dispatch(userActions.setLanguage(payload));
        window.location.reload()
    },
    setLanguageData: (payload) => ({type: userActionTypes.SET_LANGUAGE_DATA, payload}),
    changeCountry: (payload) => ({type: userActionTypes.CHANGE_COUNTRY, payload}),
    changeCourse: (payload) => ({type: userActionTypes.CHANGE_COURSE, payload}),
    changeCity: (payload) => ({type: userActionTypes.CHANGE_CITY, payload}),
    sessionExpired: () => (dispatch) => {
        dispatch(modalActions.showModal('SESSION_EXPIRED'));
        setTimeout(() => {
            dispatch(userActions.logout())
            dispatch(modalActions.hideModal())
        }, 3000)
    }
}


export default userActions;