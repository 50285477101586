import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Select, Button } from "antd";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import { CaretDownOutlined, SearchOutlined } from "@ant-design/icons";

import { AdvertisementsTable, AddEditAdvertisement } from "./components";
import { Header, DelayedInput } from "../../components/common";

import axios from "../../shared/api/axiosConfig";
import "./advertisements.scss";
import userActions from "../../redux/user-info/user-actions";
import {
  removeEmptyKeys,
  multiSort,
} from "../../shared/utils/object-manipulation";
import { checkRtl } from "../../shared/utils/rtl";

const styles = {
  wrapper: "advertisements__wrapper",
  menu: "custom-menu",
  content: "custom-section",
};


const { Option } = Select;

function Advertisements(props) {
  const intl = useIntl();

  const [search, updateSearch] = useState("");
  const [searchQuery, changeSearchQuery] = useState('');
  const handleSearchChange = (val) => {
    updateSearch(val);
  };

  const handleSearch = (val) => {
    changeSearchQuery(val);
  };

  const [advertisements, updateAdvertisements] = useState({
    isLoading: true,
    data: [],
    notFound: false,
  });

  const [modalControls, updateModalControls] = useState({
    isAddModalVisible: false,
    isEditModalVisible: false,
    modalType: "",
    data: {},
    isLoading: false,
  });

  const openAddAdvertisementModal = () => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      data: {},
      modalType: "ADD",
    });
  };

  const openEditAdvertisementModal = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      data,
      modalType: "EDIT",
    });
  };

  const handleModalClose = () => {
    updateModalControls({
      ...modalControls,
      isVisible: false,
      modalType: "",
      data: {},
    });
  };

  // Mount

  const getAdvertisements = () => {
    updateAdvertisements({
      ...advertisements,
      isLoading: true,
      notFound: false,
    });
    axios
      .get("/banners", {
        params: removeEmptyKeys(
          {
            // branchId: stores.selected,
            search: searchQuery,
          }
        ),
      })
      .then((res) => {
        if (res.data.data.length) {
          updateAdvertisements({
            data: res.data.data,
            isLoading: false,
            notFound: false,
          });
        } else {
          updateAdvertisements({
            ...advertisements,
            isLoading: false,
            data: res.data.data,
            notFound: true,
          });
        }
      })
      .catch((err) => {
        updateAdvertisements({
          ...advertisements,
          isLoading: false,
        })
      });
  };

  const addAdvertisement = (data) => {
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });

    axios
      .post("/banners", data)
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isVisible: res.data.statusCode !== 200,
        });

        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getAdvertisements();
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((err) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
        });
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
      });
  };

  const toggleAdvertisement = (bannerData, type) => {
    let key = "";
    switch (type) {
      case "lock":
        key = "isLocked";
        break;
      case "active":
        key = "isActive";
        break;
      case "delete":
        key = "isDeleted";
        break;
      default:
        return;
    }
    const data = {
      // bannerId: bannerData.bannerId,
      image: bannerData.image,
      bannerType: bannerData.bannerType,
      startDate: bannerData.startDateTime,
      endDate: bannerData.endDateTime,
      // categoryId: bannerData.categoryId,
      // subCategoryId: bannerData.subCategoryId,
      // productId: bannerData.subCategoryId,
      // branchId: bannerData.branchId,
      // [key]: bannerData[key] ? 0 : 1,
    }

    updateAdvertisements({
      ...advertisements,
      data: advertisements.data.map((i) => {
        if (i.bannerId === bannerData.bannerId) {
          i[key] = i[key] ? 0 : 1
        }
        return i;
      }),
    });
    if(type === "delete") {
      axios
      .delete(`/banners/${bannerData.bannerId}`, data)
      .then((res) => {
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
        } else {
          updateAdvertisements({
            ...advertisements,
            data: advertisements.data.map((i) => {
              if (i.bannerId === bannerData.bannerId) {
                i[key] = i[key] ? 0 : 1
              }
              return i;
            }),
          });
          toastr.error(res.data.message);
        }
      })
      .catch((err) => {
        updateAdvertisements({
          ...advertisements,
          data: advertisements.data.map((i) => {
            if (i.bannerId === bannerData.bannerId) {
              i[key] = i[key] ? 0 : 1
            }
            return i;
          }),
        });
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
      });
    }else
{    axios
      .put(`/banners/${bannerData.bannerId}`, data)
      .then((res) => {
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
        } else {
          updateAdvertisements({
            ...advertisements,
            data: advertisements.data.map((i) => {
              if (i.bannerId === bannerData.bannerId) {
                i[key] = i[key] ? 0 : 1
              }
              return i;
            }),
          });
          toastr.error(res.data.message);
        }
      })
      .catch((err) => {
        updateAdvertisements({
          ...advertisements,
          data: advertisements.data.map((i) => {
            if (i.bannerId === bannerData.bannerId) {
              i[key] = i[key] ? 0 : 1
            }
            return i;
          }),
        });
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
      });}

  };

  const editAdvertisement = (data) => {
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });

    // data.bannerId = modalControls.data.bannerId;

    axios
      .put(`/banners/${modalControls.data.bannerId}`, data)
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isVisible: res.data.statusCode !== 200,
        });

        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getAdvertisements();
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((err) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
        });
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
      });
  };

  const handleStoreChange = (store) => {
    // updateStores({
    //   ...stores,
    //   selected: store,
    // });
  };

  // Advertisements
  useEffect(() => {
    getAdvertisements();
  }, [searchQuery]);

  return (
    <div className={styles.wrapper}>
      <Header title="app.pageTitle.banners" />
      {modalControls.isVisible && (
        <AddEditAdvertisement
          // editStoreId={stores.selected}
          modalType={modalControls.modalType}
          data={modalControls.data}
          // stores={stores.data}
          isVisible={modalControls.isVisible}
          onCloseIconClick={handleModalClose}
          addAdvertisement={addAdvertisement}
          editAdvertisement={editAdvertisement}
          isLoading={modalControls.isLoading}
        />
      )}
      <div className={styles.content}>
        <div className={styles.menu}>
          <div></div>
          {/* <header>
            {" "}
            <div>
              <Select
                showSearch
                style={{ width: 250 }}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.store",
                })}
                onChange={handleStoreChange}
                value={stores.selected}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {stores.data.map((store) => (
                  <Option key={store.branchId} value={store.branchId}>
                    {store.branchName}
                  </Option>
                ))}
              </Select>
            </div>
          </header> */}
          <section>
            <div>
              <Button
                onClick={openAddAdvertisementModal}
                type="primary"
                shape={undefined}
                disabled={props.isAddDisabled}
                size="medium"
              >Add Banner
                {/* {intl.formatMessage({ id: "app.button.addAdvertisement" })} */}
              </Button>
            </div>
          </section>
        </div>
        <AdvertisementsTable
          isLoading={advertisements.isLoading }
          data={advertisements.data}
          panelType={0}
          // pagination={pagination}
          notFound={advertisements.notFound}
          handleEdit={openEditAdvertisementModal}
          toggleAdvertisement={toggleAdvertisement}
        />
      </div>
    </div>
  );
}

export default Advertisements;
