import React, {useState} from "react";
import {useIntl} from 'react-intl'
import { CaretDownOutlined, SearchOutlined } from "@ant-design/icons";

import { CategoryCard } from "../";
import {
  DelayedInput,
} from "../../../../components/common";
import "./categories-list.scss";


const styles = {
  wrapper: "categories-list__wrapper",
  content: "categories-list__content",
  count: "categories-list__count",
  menu: 'custom-menu',
};
function CategoriesList(props) {
  const intl = useIntl();
  const [search, updateSearch] = useState("");
  const handleSearchChange = (val) => {
    updateSearch(val);
  };

  const filteredData = () => {
    return props.data.filter((i) => {
      if(search === '') {
        return true;
      } else {
        return i.categoryName.toLowerCase().includes(search.toLowerCase());
      }
    })
  }

  const handleSearch = () => {

  }
  return (
      <div className={styles.wrapper}>
        {/* <div className={styles.menu}>
        <header className={styles.count}>{`${intl.formatMessage({id: 'app.field.categories'})} (${props.count}):`}</header>
        <section>
        <DelayedInput
            onChange={handleSearchChange}
            value={search}
            callback={handleSearch}
            delay={500}
            minLength={3}
            size="medium"
            prefixIcon={<SearchOutlined />}
            placeholder={intl.formatMessage({ id: "app.placeholder.search" })}
          />
        </section>
        </div> */}

    <div className={styles.content}>
      {props.addCard}
      {filteredData().map((item) => (
        <CategoryCard
          key={item.categoryId}
          data={item}
          viewItem={props.viewItem}
          editItem={props.editItem}
          deleteItem={props.deleteItem}
        />
      ))}
    </div>
    </div>
  );
}

export default CategoriesList;
