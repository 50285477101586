import React, { useState, useEffect } from "react";
import { Select, Dropdown, Button, Menu, Tooltip } from "antd";
import { useIntl } from "react-intl";
import { connect } from 'react-redux';
import { toastr } from "react-redux-toastr";
import {
  CaretDownOutlined,
  SearchOutlined,
  MoreOutlined,
} from "@ant-design/icons";

import {
  RECORDS_PER_PAGE,
  textTrimmer,
  TABLE_TEXT_LIMIT,
} from "../../shared/constants/dropdown";
import {
  Header,
  PaginationInfo,
  DelayedInput,
  CustomTable,
  CustomTag,
  Label,
  CustomImage,
} from "../../components/common";
import { StoresTable, StoresModal } from "./components";
import axios from "../../shared/api/axiosConfig";

import "./stores.scss";
import {
  removeEmptyKeys,
  nestedParseJSON,
} from "../../shared/utils/object-manipulation";
import { checkRtl } from "../../shared/utils/rtl";

const { Option } = Select;

const styles = {
  wrapper: "stores__wrapper",
  content: "custom-section",
  menu: "custom-menu",
  menuLeft: "stores__menu--left",
  menuRight: "stores__menu--right",
  pageSize: "stores__records-per-page",
};

function Stores(props) {
  const intl = useIntl();

  const [search, updateSearch] = useState("");
  const [categories, updateCategories] = useState({
    data: [],
    isLoading: true,
  });
  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    data: {},
    type: "",
    isLoading: false,
  });

  const [tableControls, updateTableControls] = useState({
    isLoading: false,
    data: [],
    count: 0,
    isEmpty: false,
  });

  const [pagination, updatePagination] = useState({
    recordsPerPage: 10,
    skip: 0,
    search: "",
    currentPage: 1,
  });

  const handlePaginationChange = (page) => {
    updatePagination({
      ...pagination,
      currentPage: page,
      skip: (page - 1) * pagination.recordsPerPage,
    });
  };

  const handleRecordsPerPageChange = (val) => {
    updatePagination({
      ...pagination,
      recordsPerPage: val,
    });
  };

  const handleSearchChange = (val) => {
    updateSearch(val);
  };

  const handleSearch = (val) => {
    updatePagination({
      ...pagination,
      currentPage: 1,
      search: val,
      skip: 0,
    });
  };

  const openAddStoreModal = () => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      type: "ADD",
    });
  };

  const addStore = (data) => {
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });
    if (!data.isFreeDeliveryEnabled) {
      delete data.limitForFreeDelivery;
    }
    axios
      .post("/addBranches", data)
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isVisible: res.data.statusCode !== 200,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getStoreData();
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
        });
        // toastr.error('Error', intl.formatMessage({id: 'app.message.error'}));
      });
  };

  const editStore = (data) => {
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });
    data.branchId = modalControls.data.branchId;

    if (!data.isFreeDeliveryEnabled) {
      delete data.limitForFreeDelivery;
    }
    axios
      .put("/updateBranch", removeEmptyKeys(data))
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isVisible: res.data.statusCode !== 200,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getStoreData();
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
        });
        // toastr.error('Error', intl.formatMessage({id: 'app.message.error'}));
      });
  };

  const openEditStoreModal = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      type: "EDIT",
      data,
    });
  };

  const handleModalClose = () => {
    updateModalControls({
      ...modalControls,
      isVisible: false,
    });
  };

  const getStoreData = () => {
    updateTableControls({
      ...tableControls,
      isEmpty: false,
      isLoading: true,
    });
    axios
      .get("getBranches", {
        params: removeEmptyKeys({
          limit: pagination.recordsPerPage,
          skip: (pagination.currentPage - 1) * pagination.recordsPerPage,
          search: pagination.search,
        }),
      })
      .then((res) => {
        updateTableControls({
          ...tableControls,
          data: nestedParseJSON(res.data.data.branchesData),
          isEmpty: res.data.data.branchesData.length === 0,
          isLoading: false,
          count: res.data.data.branchCount,
        });
      })
      .catch((err) => {
        // toastr.error("Error", "Something Went Wrong");
        updateTableControls({
          ...tableControls,
          isEmpty: true,
          isLoading: false,
        });
      });
  };


  const toggleStore = (store) => {
    const data = {
      branchId: store.branchId,
      isEnabled: store.isEnabled ? 0 : 1,
      latitude: store.latitude,
      longitude: store.longitude,
    };
    updateTableControls({
      ...tableControls,
      data: tableControls.data.map((i) => {
        if (i.branchId === data.branchId) {
          i.isEnabled = store.isEnabled ? 0 : 1;
        }
        return i;
      }),
    });
    axios
      .put("/updateBranch", data)
      .then((res) => {
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
        } else {
          updateTableControls({
            ...tableControls,
            data: tableControls.data.map((i) => {
              if (i.branchId === data.branchId) {
                i.isEnabled = i.isEnabled ? 0 : 1;
              }
              return i;
            }),
          });
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        updateTableControls({
          ...tableControls,
          data: tableControls.data.map((i) => {
            if (i.branchId === data.branchId) {
              i.isEnabled = store.isEnabled ? 0 : 1;
            }
            return i;
          }),
        });
        // toastr.error('Error', intl.formatMessage({id: 'app.message.error'}));
      });
  };

  useEffect(() => {
    getStoreData();
  }, [pagination.recordsPerPage, pagination.currentPage, pagination.search]);

  const getStoreCategories = () => {
    axios
      .get("/getSuperCategories", {
        params: {
          onlyNames: 1,
        },
      })
      .then((res) => {
        updateCategories({
          isLoading: false,
          data: res.data.data.superCategories,
        });
      })
      .catch((err) => {
        updateCategories({
          ...categories,
          isLoading: false,
        });
      });
  };
  useEffect(() => {
    getStoreCategories();
  }, []);


  const columns = [
    {
      dataIndex: "",
      // sorter: true,
      title: intl.formatMessage({ id: "app.field.sNo" }),
      fixed: 'left',
      render: (e, item, index) => {
        return (
          <span>
            {pagination.recordsPerPage * (pagination.currentPage - 1) + index + 1}
          </span>
        );
      },
    },
    {
      fixed: "left",
      dataIndex: "branchName",
      title: intl.formatMessage({ id: "app.field.storeName" }),
    },
    {
      dataIndex: "email",
      title: intl.formatMessage({ id: "app.field.email" }),
    },

    {
      dataIndex: "phoneNo",
      title: intl.formatMessage({ id: "app.field.contact" }),
      render: (i, item) =>
        `${item.countryCode ? "+" : ""}${item.countryCode || ""} ${i}`,
    },
    {
      dataIndex: "address",
      title: intl.formatMessage({ id: "app.field.address" }),
      render: (i) => {
        if (!i) {
          return "---";
        }
        return i.length > TABLE_TEXT_LIMIT.ADDRESS ? (
          <Tooltip title={i}>
            <span style={{ cursor: "pointer" }}>
              {textTrimmer(i, TABLE_TEXT_LIMIT.ADDRESS)}
            </span>
          </Tooltip>
        ) : (
          i
        );
      },
    },
    {
      dataIndex: "isFreeDeliveryEnabled",
      title: intl.formatMessage({ id: "app.field.isFreeDeliveryEnabled" }),
      render: (i) =>
        i === 1
          ? intl.formatMessage({ id: "app.field.yes" })
          : intl.formatMessage({ id: "app.field.no" }),
    },
    {
      dataIndex: "limitForFreeDelivery",
      title: intl.formatMessage({ id: "app.field.limitForFreeDelivery" }),
    },
    {
      dataIndex: "isEnabled",
      title: intl.formatMessage({ id: "app.field.status" }),
      render: (i) => {
        return <CustomTag isEnabled={i} />;
      },
    },
    {
      title: intl.formatMessage({ id: "app.field.action" }),
      fixed: "right",
      render: (item) => {
        return (
          <Dropdown overlay={getMenu(item)}>
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  const getMenu = (item) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            openEditStoreModal(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.edit" })}
        </Menu.Item>
        {item.isEnabled == 0 && (
          <Menu.Item
            onClick={() => {
              toggleStore(item);
            }}
          >
            {intl.formatMessage({ id: "app.field.enable" })}
          </Menu.Item>
        )}
        {item.isEnabled == 1 && (
          <Menu.Item
            onClick={() => {
              toggleStore(item);
            }}
          >
            {intl.formatMessage({ id: "app.field.disable" })}
          </Menu.Item>
        )}

        {/* <Menu.Item
          onClick={() => {
            handleDisable(item, true);
          }}
        >
          {intl.formatMessage({ id: "app.field.delete" })}
        </Menu.Item> */}
      </Menu>
    );
  };


  return (
    <div className={styles.wrapper}>
      <Header title="app.pageTitle.stores" />
      <StoresModal
        isVisible={modalControls.isVisible}
        modalType={modalControls.type}
        onCloseIconClick={handleModalClose}
        addStore={addStore}
        storeCategories={categories.data}
        data={modalControls.data}
        editStore={editStore}
        isLoading={modalControls.isLoading}
      />
     <div className={styles.content}>
        <div className={styles.menu}>
          <header></header>
          <section>
            <DelayedInput
              onChange={handleSearchChange}
              value={search}
              callback={handleSearch}
              delay={500}
              minLength={3}
              size="medium"
              prefixIcon={<SearchOutlined />}
              placeholder={intl.formatMessage({ id: "app.placeholder.search" })}
            />
            <Button
              type="primary"
              shape={undefined}
              size="medium"
              disabled={props.isAddDisabled}
              loading={categories.isLoading}
              onClick={openAddStoreModal}
            >
              {intl.formatMessage({ id: "app.button.addStore" })}
            </Button>
          </section>
        </div>
        <CustomTable
          columns={columns}
          dataSource={tableControls.data}
          isLoading={tableControls.isLoading || categories.isLoading}
          isEmpty={tableControls.isEmpty}
          pagination={pagination}
          totalRecords={tableControls.count}
          editItem={openEditStoreModal}
          onChange={updatePagination}
          toggleItem={toggleStore}
          offset={43}
        />
        {/* <StoresTable
          data={tableControls.data}
          isLoading={tableControls.isLoading || categories.isLoading}
          isEmpty={tableControls.isEmpty}
          editItem={openEditStoreModal}
          toggleItem={toggleStore}
        /> */}
      </div>
    </div>
  );
}

export default Stores;
