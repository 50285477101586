import React from "react";
import { useIntl } from "react-intl";
import classNames from "classnames";
import { Icon, MenuItem } from "../../../../components/common";
import "./menu.scss";

const styles = {
  wrapper: "content-management__menu__wrapper",
  container: "content-management__menu__container",
  title: "content-management__menu__title",
  item: "content-management__menu__item",
  itemActive: "content-management__menu__item--active",
  icon: "content-management__menu__item__icon",
};
function Menu(props) {
  const intl = useIntl();
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {Object.keys(props.data)
          .filter((key) => key !== props.data.CONTACT_US.key)
          .map((key) => (
            <MenuItem
              text={intl.formatMessage({ id: props.data[key].text })}
              onClick={props.onChange}
              isActive={props.selected === key}
              icon={props.data[key].icon}
              callbackData={key}
            />
          ))}
      </div>
      {/* <div className={styles.title}>
        {intl.formatMessage({ id: props.data[props.selected].text })}
      </div> */}
    </div>
  );
}

export default Menu;
