import React from "react";
import './ErrorMessage.scss';
const styles = {
  wrapper: "error-message__wrapper",
  text: "error-message__text",
};
function ErrorMessage({field, text, float}) {
  if(text) {
    return (
      <div style={float ? {position: 'absolute', bottom: '-12px'} : {}} className={styles.wrapper}>
        <span className={styles.text}>{text}</span>
      </div>
    );
  }
    if(!field.errMsg.length) {
        return null;
    }
  return (
    <div style={float ? {position: 'absolute', bottom: '-12px'} : {}} className={styles.wrapper}>
      <span className={styles.text}>{field.errMsg[0]}</span>
    </div>
  );
}

export default ErrorMessage;
