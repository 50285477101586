import ModalActionTypes from "./modal-action-types";

const initialState = {
  modalType: "",
  isVisible: false,
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case ModalActionTypes.SHOW_MODAL:
      return {
        modalType: action.modalType,
        isVisible: true,
      };
    case ModalActionTypes.HIDE_MODAL:
      return initialState;
    default:
      return state;
  }
};

export default modalReducer;
