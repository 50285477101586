import React, { useEffect } from "react";
import ReduxToastr from "react-redux-toastr";
import {LoadScript} from '@react-google-maps/api'
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { Router, Route, Switch, Redirect } from "react-router-dom";
import ScrollToTop from 'react-router-scroll-top'
import ForgotPassword from "./containers/external/forget-password/forget-password";
import ForgotPasswordSuccess from "./containers/external/forget-password/forget-password-success";
import Auth from "./auth/auth-container";
import Home from "./containers/home-container";
import PrivacyPolicy from './components/privacy-policy/privacy-policy';
import AboutUs from "./components/about-us/about-us";
import Faq from "./components/faq/faq";
import Certificate from "./components/certificate/certificate";
import TermsAndConditions from "./components/terms-and-conditions/terms-and-conditions";
import history from './shared/utils/history';
import {Placeholder} from './components/common';
import GlobalModal from './components/modal/global/GlobalModal';
import "./App.less";

import English from './languages/en-US.js';
import Arabic from './languages/ar.json';
import French from "./languages/fr.json";
import Greek from "./languages/grk.json";
import { getPanelType } from "./shared/constants/dropdown";
// import French from "./languages/fr.json";

const messages = {
  'en': English,
  'ar': Arabic,
  'fr': French,
  'el': Greek,
}
// const ForgotPassword = React.lazy(() => import('./containers/external/forget-password/forget-password'));
// const ForgotPasswordSuccess = React.lazy(() => import('./containers/external/forget-password/forget-password-success'));

function NoMatch() {
  return <div>Not found</div>;
}
const libraries = ['drawing', 'places'];

function App(props) {
  useEffect(() => {
    if(getPanelType() == 1) {
      document.title = "Store | Pawminded"
    }
  }, [])
  return (
    <IntlProvider locale={props.locale} messages={messages[props.locale]}>

      <ReduxToastr
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            closeOnToastrClick
      />
      <GlobalModal />
      {/* <LoadScript
      loadingElement={<Placeholder type="loading" text="Loading" />}
    googleMapsApiKey ="AIzaSyCSHHKKFCGHd8jd0_jlhytWadlpFLX6h9k"
    libraries={libraries}
    > */}
      <Router history={history}>
        <ScrollToTop>
        <Switch>
          <Route path="/auth" component={Auth} />
          <Route path="/home" component={Home} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/terms-and-conditions" component={TermsAndConditions} />
          <Route path="/faq" component={Faq} />
          <Route path="/about-us" component={AboutUs} />
          <Route
            path="/"
            exact
            component={() => <Redirect to="/auth/login" />}
          />
          <Route
            exact={true}
            path="/passwordResetSuccessfully"
            component={ForgotPasswordSuccess}
          />
          <Route
            exact={true}
            path="/passwordResetToken/:token"
            component={ForgotPassword}
          />
          <Route
            exact={true}
            path="/certificates/:token"
            component={Certificate}
          />
          <Route
            exact={true}
            path="/passwordResetToken/driver/:token"
            component={(props) => <ForgotPassword {...props} isDriver={true} />}
          />
          <Route component={NoMatch} />
        </Switch>
        </ScrollToTop>
      </Router>
      {/* </LoadScript> */}
    </IntlProvider>
  );
}

const mapStateToProps = (state) => ({
  locale: state.user.locale,
})

export default connect(mapStateToProps)(App);
