const userActionTypes = {
    SET_USER_INFO: 'USER_INFO/SET_USER_INFO',
    LOGOUT: 'USER_INFO/LOGOUT',
    CHANGE_LANGUAGE: 'USER_INFO/CHANGE_LANGUAGE',
    CHANGE_COUNTRY: 'USER_INFO/CHANGE_COUNTRY',
    CHANGE_COURSE: 'USER_INFO/CHANGE_COURSE',
    CHANGE_CITY: 'USER_INFO/CHANGE_CITY',
    SET_LANGUAGE_DATA: 'USER_INFO/SET_LANGUAGE_DATA',
};

export default userActionTypes;