import React, { useState, useEffect } from "react";
import { Button, Select, Menu, Dropdown, Popconfirm } from "antd";
import { useIntl } from "react-intl";
import { SearchOutlined, MoreOutlined } from "@ant-design/icons";
import { toastr } from "react-redux-toastr";

import { StoreCategoriesTable, StoreCategoriesModal } from "./components";
import { connect } from "react-redux";

import {
  Header,
  PaginationInfo,
  DelayedInput,
  CustomTag,
  CustomTable,
  CustomImage,
} from "../../components/common";
import { RECORDS_PER_PAGE } from "../../shared/constants/dropdown";
import axios from "../../shared/api/axiosConfig";
import "./store-categories.scss";
import { removeEmptyKeys } from "../../shared/utils/object-manipulation";
import { checkRtl } from "../../shared/utils/rtl";

const styles = {
  wrapper: "store-categories__wrapper",
  content: "custom-section",
  menu: "custom-menu",
  menuLeft: "store-categories__menu--left",
  menuRight: "store-categories__menu--right",
  pageSize: "store-categories__records-per-page",
};
const { Option } = Select;
function StoreCategories(props) {
  const intl = useIntl();

  const [search, updateSearch] = useState("");

  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    data: {},
    type: "",
    isLoading: false,
  });

  const [tableControls, updateTableControls] = useState({
    isLoading: false,
    data: [],
    count: 0,
    isEmpty: false,
  });

  const [pagination, updatePagination] = useState({
    recordsPerPage: 10,
    skip: 0,
    search: "",
    currentPage: 1,
  });

  const handlePaginationChange = (page) => {
    updatePagination({
      ...pagination,
      currentPage: page,
      skip: (page - 1) * pagination.recordsPerPage,
    });
  };

  const handleRecordsPerPageChange = (val) => {
    updatePagination({
      ...pagination,
      recordsPerPage: val,
    });
  };

  const handleStoreCategoryDelete = (data) => {};

  const handleSearchChange = (val) => {
    updateSearch(val);
  };

  const handleSearch = (val) => {
    updatePagination({
      ...pagination,
      currentPage: 1,
      search: val,
      skip: 0,
    });
  };

  const handleModalClose = () => {
    updateModalControls({
      isVisible: false,
      data: {},
      type: "",
    });
  };

  const openAddStoreCategoryModal = () => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      type: "ADD_CATEGORY",
    });
  };

  const openEditStoreCategoryModal = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      data,
      type: "EDIT_CATEGORY",
    });
  };

  const getSuperCategories = () => {
    updateTableControls({
      ...tableControls,
      isLoading: true,
      isEmpty: false,
    });

    axios
      .get("/getSuperCategories", {
        params: removeEmptyKeys({
          limit: pagination.recordsPerPage,
          skip: (pagination.currentPage - 1) * pagination.recordsPerPage,
        }),
      })
      .then((res) => {
        updateTableControls({
          isLoading: false,
          isEmpty: res.data.data.count === 0,
          data: res.data.data.superCategories,
          count: res.data.data.count,
        });
      })
      .catch((err) => {
        updateTableControls({
          isLoading: false,
          isEmpty: true,
          data: [],
        });
      });
  };

  const handleStoreCategoryAdd = (data) => {
    //   data.isEnabled = 1;
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });

    axios
      .post("/addSuperCategory", removeEmptyKeys(data))
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isVisible: false,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getSuperCategories();
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
        });
      });
  };

  const handleStoreCategoryEdit = (data) => {
    delete data.adminId;
    data.superCatId = modalControls.data.superCatId;
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });

    axios
      .post("/updateSuperCartegory", removeEmptyKeys(data))
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isVisible: res.data.statusCode !== 200,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getSuperCategories();
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateModalControls({
          ...modalControls,
          isLoading: false,
        });
      });
  };

  const tableActions = (data, deleteItem) => {
    delete data.adminId;
    if (deleteItem) {
      data.isDeleted = 1;
    } else {
      data.isEnabled = data.isEnabled === 0 ? 1 : 0;
    }
    updateTableControls({
      ...tableControls,
      isLoading: true,
      isEmpty: false,
    });
    axios
      .post("/updateSuperCartegory", removeEmptyKeys(data))
      .then((res) => {
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getSuperCategories();
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateTableControls({
          ...tableControls,
          isLoading: false,
        });
      });
  };

  useEffect(() => {
    getSuperCategories();
  }, [pagination.currentPage, pagination.recordsPerPage]);

  const columns = [
    {
      dataIndex: "",
      // sorter: true,
      title: intl.formatMessage({ id: "app.field.sNo" }),
      fixed: "left",
      render: (e, item, index) => {
        return (
          <span>
            {pagination.recordsPerPage * (pagination.currentPage - 1) +
              index +
              1}
          </span>
        );
      },
    },
    {
      fixed: "left",
      dataIndex: "categoryName",
      title: intl.formatMessage({ id: "app.field.categoryName" }),
    },
    {
      dataIndex: "categoryImage",
      title: intl.formatMessage({ id: "app.field.image" }),
      render: (i) => <CustomImage isTable src={i} />,
    },

    {
      dataIndex: "priority",
      title: intl.formatMessage({ id: "app.field.rank" }),
    },
    {
      dataIndex: "isEnabled",
      title: intl.formatMessage({ id: "app.field.status" }),
      render: (i) => {
        return <CustomTag isEnabled={i} />;
      },
    },
    {
      title: intl.formatMessage({ id: "app.field.action" }),
      fixed: "right",
      render: (item) => {
        return (
          <Dropdown overlay={getMenu(item)}>
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  const getMenu = (item) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            openEditStoreCategoryModal(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.edit" })}
        </Menu.Item>
        {item.isEnabled == 0 && (
          <Menu.Item
            onClick={() => {
              tableActions(item);
            }}
          >
            {intl.formatMessage({ id: "app.field.enable" })}
          </Menu.Item>
        )}
        {item.isEnabled == 1 && (
          <Menu.Item
            onClick={() => {
              tableActions(item);
            }}
          >
            {intl.formatMessage({ id: "app.field.disable" })}
          </Menu.Item>
        )}
        <Menu.Item
        onClick={() => {
          tableActions(item, true);
        }}
        >
          {intl.formatMessage({ id: "app.field.delete" })}
          <Popconfirm
            title={intl.formatMessage({
              id: "app.message.deleteWarning",
            })}
            onConfirm={() => {}}
            placement="topRight"
            okText={intl.formatMessage({ id: "app.field.yes" })}
            cancelText={intl.formatMessage({ id: "app.field.no" })}
          ></Popconfirm>
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <div className={styles.wrapper}>
      {modalControls.isVisible && (
        <StoreCategoriesModal
          isVisible={modalControls.isVisible}
          onClose={handleModalClose}
          onCloseIconClick={handleModalClose}
          modalToShow={modalControls.type}
          data={modalControls.data}
          addCategory={handleStoreCategoryAdd}
          editCategory={handleStoreCategoryEdit}
          isLoading={modalControls.isLoading}
        />
      )}
      <Header title="app.pageTitle.storeCategories" />
      {/* <div className={styles.menu}>
      <div className={styles.menuLeft}></div>
        <div className={styles.menuRight}>
          <Button
            type="primary"
            shape=""
            size="medium"
            onClick={openAddStoreCategoryModal}
          >
            {intl.formatMessage({ id: "app.button.addStoreCategory" })}
          </Button>
        </div>
      </div> */}
      <div className={styles.content}>
        <div className={styles.menu}>
          <header></header>
          <section className={styles.menuRight}>
            {/* <DelayedInput
              onChange={handleSearchChange}
              value={search}
              callback={handleSearch}
              delay={500}
              minLength={3}
              size="medium"
              prefixIcon={<SearchOutlined />}
              placeholder={intl.formatMessage({
                id: "app.placeholder.search",
              })}
            /> */}

            <Button
              type="primary"
              shape={undefined}
              size="medium"
              disabled={props.isAddDisabled}
              onClick={openAddStoreCategoryModal}
            >
              {intl.formatMessage({ id: "app.button.addStoreCategory" })}
            </Button>
          </section>
        </div>

        <CustomTable
          isEmpty={tableControls.isEmpty}
          columns={columns}
          isLoading={tableControls.isLoading}
          //
          // dataSource={filteredData()}
          dataSource={tableControls.data}
          //
          editItem={openEditStoreCategoryModal}
          deleteItem={tableActions}
          toggleItem={tableActions}
          onChange={updatePagination}
          pagination={pagination}
          totalRecords={tableControls.count}
        />

        {/* <div className={styles.footer}>
        <PaginationInfo
          currentPage={pagination.current}
          recordsPerPage={pagination.pageSize}
        
          onChange={handlePaginationChange}
        />
      </div> */}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isAddDisabled: state.user.isAddDisabled,
  isRTL: checkRtl(state.user.locale),
});
export default connect(mapStateToProps)(StoreCategories);
