import orderActionTypes from './orders-action-types';
import Push from 'push.js';
import sound from '../../assets/sounds/notification.mp3';
const audio = new Audio(sound);
// 
const orderActions = {
    triggerRefetch: () => ({type: orderActionTypes.TRIGGER_REFETCH}),
    sendNewOrderNotification: (data) => (dispatch) => {
        if(Push.Permission.has()) {
            audio.play();
            Push.create("New Order!", {
                body: "A new order has arrived!",
                timeout: 6000,
                onClick: function () {
                    window.focus();
                    this.close();
                }
            });
        }
        dispatch(orderActions.triggerRefetch());
    },

    sendCancelOrderNotification: (data) => (dispatch) => {
        if(Push.Permission.has()) {
            audio.play();
            Push.create("Order Cancelled!", {
                body: "An order has been cancelled",
                timeout: 6000,
                onClick: function () {
                    window.focus();
                    this.close();
                }
            });
        }
        dispatch(orderActions.triggerRefetch());
    }
}

export default orderActions;