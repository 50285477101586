import React, { useState, useEffect } from "react";
import { Input, Button, Select } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { toastr } from "react-redux-toastr";

import {
  CustomModal,
  Panel,
  ErrorMessage,
  UploadButton,
} from "../../../components/common";
import axios from "../../../shared/api/axiosConfig";
import Validations from "../../../shared/validations/Validations";
import placeholderImg from "../../../assets/images/picker-placeholder.png";
import countryCodes from "../../../shared/constants/countries.json";
import "./../add-edit-picker/add-edit-picker.scss";

const { Option } = Select;

const TRANSPORT_TYPES = [
  { type: 0, name: "Bike" },
  { type: 1, name: "Truck" },
];

const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formGroup: "custom-form-group",
  formAction: "custom-form-action",
  imageContainer: "pickers__add-edit-picker-modal__image-container",
  imageContainerImage: "pickers__add-edit-picker-modal__image-container__image",
  imageContainerForm: "pickers__add-edit-picker-modal__image-container__form",
};

function AddEditPicker(props) {
  const intl = useIntl();

  const [stores, updateStores] = useState({
    selected: undefined,
    isLoading: false,
    data: [],
    notFound: false,
  });

  const [isPristine, togglePristine] = useState(true);
  const [name, changeName] = useState(Validations.validateTextField(""));
  const [email, changeEmail] = useState(Validations.validateEmail(""));
  const [password, changePassword] = useState(Validations.validatePassword(""));
  const [phoneNumber, changePhoneNumber] = useState(
    Validations.validatePhoneNumber("")
  );
  const [phoneCountryCode, changePhoneCountryCode] = useState(
    Validations.validateTextField(undefined)
  );
  const [transportType, changeTransportType] = useState(
    Validations.validateNumericValue(undefined)
  );
  const [address, changeAddress] = useState(Validations.validateTextField(""));
  const [description, changeDescription] = useState(
    Validations.validateTextField("")
  );
  const [store, changeStore] = useState(Validations.validateTextField(""));
  const [imageURL, changeImageURL] = useState(
    Validations.validateTextField("")
  );

  const resetForm = () => {
    togglePristine(true);
    updateStores({
      ...stores,
      data: props.stores,
    });
    changeName(Validations.validateTextField(null));
    changeEmail(Validations.validateEmail(""));
    changePassword(Validations.validatePassword(""));
    changePhoneCountryCode(Validations.validateTextField(undefined));
    changePhoneNumber(Validations.validatePhoneNumber(""));
    changeTransportType(Validations.validateNumericValue(undefined));
    changeStore(Validations.validateTextField(undefined));
    changeDescription(Validations.validateTextField(null));
    changeAddress(Validations.validateTextField(null));
    changeImageURL(Validations.validateTextField(null));
  };

  const setForm = () => {
    togglePristine(true);
    updateStores({
      ...stores,
      data: props.stores,
    });
    changeName(Validations.validateTextField(props.data.name));
    changeEmail(Validations.validateEmail(props.data.email));
    changePassword(Validations.validatePassword(""));
    changePhoneNumber(Validations.validatePhoneNumber(props.data.phoneNo));
    changeDescription(Validations.validateTextField(props.data.description));
    changeAddress(Validations.validateTextField(props.data.address));
    changePhoneCountryCode(
      Validations.validateTextField(+props.data.countryCode)
    );
    changeTransportType(
      Validations.validateNumericValue(props.data.transportType)
    );
    changeImageURL(Validations.validateTextField(props.data.profileImage));
    changeStore(
      Validations.validateTextField(JSON.parse(props.data.branches)[0]?.branchId)
    );
  };

  useEffect(() => {
    if (!props.isVisible) return;
    if (props.modalToShow === "EDIT_PICKER") {
      // getStores();
      setForm();
    } else if (props.modalToShow === "ADD_PICKER") {
      // getStores();
      resetForm();
    }
  }, [props.isVisible]);

  //mount
  const getStores = () => {
    updateStores({
      ...stores,
      isLoading: true,
    })
    axios
      .get("/getBranches", {
        params: {
          onlyNames: 1
        }
      })
      .then((res) => {
        if (res.data.data.branchesData.length) {
          updateStores({
            selected: undefined,
            data: res.data.data.branchesData,
            isLoading: false,
            notFound: false,
            count: res.data.data.branchCount,
          });
        } else {
          updateStores({
            ...stores,
            data:[],
            isLoading: false,
            notFound: true,
          });
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
      });
  };

  const handleFieldChange = (e) => {
    const { value } = e.target;

    switch (e.target.name) {
      case "name":
        changeName(Validations.validateTextField(value));
        break;
      case "email":
        changeEmail(Validations.validateEmail(value));
        break;
      case "password":
        changePassword(Validations.validatePassword(value));
        break;
      case "phoneNumber":
        changePhoneNumber(Validations.validatePhoneNumber(value));
        break;
      case "address":
        changeAddress(Validations.validateAddress(value));
        break;
      case "description":
        changeDescription(Validations.validateTextField(value));
        break;
      default:
        break;
    }
  };

  const handleCountryCodeChange = (value) => {
    changePhoneCountryCode(Validations.validateTextField(value));
  };

  const handleTransportTypeChange = (value) => {
    changeTransportType(Validations.validateNumericValue(value));
  };

  const handleStoreChange = (selected) => {
    updateStores({
      ...stores,
      selected,
    });
    changeStore(Validations.validateTextField(selected));
  };

  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const getData = () => ({
    name: name.value,
    phoneNo: phoneNumber.value,
    countryCode: phoneCountryCode.value.toString(),
    email: email.value,
    password: password.value,
    transportType: transportType.value,
    branchIds: [store.value],
    address: address.value,
    description: description.value,
    profileImage: imageURL.value,
  });

  const handleAdd = () => {
    // TODO
    props.addPicker(getData());
  };

  const handleEdit = () => {
    // TODO
    props.editPicker(getData());
  };
  const isFormValid = () => {
    console.log(transportType);
    if (props.modalToShow === "ADD_PICKER") {
      let isValid = true;
      isValid = isValid && (
        name.isValid &&
        email.isValid &&
        password.isValid &&
        phoneCountryCode.isValid &&
        phoneNumber.isValid &&
        // transportType.isValid &&
        address.isValid &&
        description.isValid &&
        // store.isValid &&
        imageURL.isValid
      );
      // if(stores.isVisible){
      //   isValid = isValid && stores.isValid;
      // }
      if(transportType.value == 0 || transportType.value == 1){
        isValid = isValid;
      }else{
        isValid = false;
      }
      return isValid;
    } else if (props.modalToShow === "EDIT_PICKER") {
      let isValid = true;
      isValid = isValid && (
        name.isValid &&
        email.isValid &&
        phoneCountryCode.isValid &&
        phoneNumber.isValid &&
        // transportType.isValid &&
        address.isValid &&
        description.isValid &&
        store.isValid &&
        imageURL.isValid
      );
      if(password.value.length) {
        isValid = isValid && password.isValid
      }
      if(transportType.value == 0 || transportType.value == 1){
        isValid = isValid;
      }else{
        isValid = false;
      }
      return isValid;
    }
  };

  const closeModal = () => {
    props.onCloseIconClick();
  };

  const handleImageError = (e) => {
    e.target.src = placeholderImg;
  };

  const formAction = () => {
    togglePristine(false);
    if (!isFormValid()) {
      return;
    }
    if (props.modalToShow === "ADD_PICKER") {
      handleAdd();
    } else if (props.modalToShow === "EDIT_PICKER") {
      handleEdit();
    }
  };

  const handleImageChange = (image) => {
    changeImageURL(Validations.validateTextField(image));
  };

  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={closeModal}
      header={
        props.modalToShow === "ADD_PICKER"
          ? "app.placeholder.addPicker"
          : "app.placeholder.editPicker"
      }
      isVisible={props.isVisible}
    >
      <Panel className={styles.formModal} isLoading={props.isLoading} >
        <div className={styles.imageContainer}>
          <div className={styles.imageContainerImage}>
            <img
              src={imageURL.value ? imageURL.value : placeholderImg}
              alt=""
              onError={handleImageError}
            />
            <UploadButton
              text={intl.formatMessage({ id: "app.field.uploadImage" })}
              resultType="url"
              accept="image/*"
              onChange={handleImageChange}
            />
            {!isPristine && <ErrorMessage field={imageURL} />}
          </div>

          <div className={styles.imageContainerForm}>
            <div className={styles.formItem}>
              <label>
                {intl.formatMessage({
                  id: "app.field.name",
                })}
              </label>
              <Input
                name="name"
                type="text"
                onChange={handleFieldChange}
                value={name.value}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.name",
                })}
              />
              {!isPristine && <ErrorMessage field={name} />}
            </div>
            <div>
              <div className={styles.formItem}>
                <label>
                  <FormattedMessage id="app.field.email" />
                </label>
                <Input
                  name="email"
                  onChange={handleFieldChange}
                  value={email.value}
                  style={{ marginRight: "2rem", }}
                  placeholder={intl.formatMessage({
                    id: "app.placeholder.email",
                  })}
                />
                {!isPristine && <ErrorMessage field={email} />}
              </div>
                <div className={styles.formItem} style={{ flex: 1 }}>
                  <label>
                    <FormattedMessage id="app.field.password" />
                  </label>
                  <Input
                    name="password"
                    onChange={handleFieldChange}
                    value={password.value}
                    type="password"
                    placeholder={intl.formatMessage({
                      id: "app.placeholder.password",
                    })}
                  />
                  {props.modalToShow === 'ADD_PICKER' && !isPristine && <ErrorMessage field={password} />}
                  {props.modalToShow === 'EDIT_PICKER' && !isPristine && password.value.length !== 0 && <ErrorMessage field={password} />}
                </div>
              </div>
            </div>
          </div>
        <div>
          <label>
            <FormattedMessage id="app.field.phoneNumber" />
          </label>
          <div className={styles.formGroup}>
            <div className={styles.formItem}>
              <Select
                showSearch
                // style={{ marginRight: "2rem",}}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.countryCode",
                })}
                onChange={handleCountryCodeChange}
                value={phoneCountryCode.value}
                optionFilterProp="children"
                filterOption={filterOption}
              >
                {countryCodes.map((item, index) => (
                  <Option
                    key={index}
                    value={item.dial_code}
                  >{`${item.dial_code}(${item.name})`}</Option>
                ))}
              </Select>
              {!isPristine && <ErrorMessage field={phoneCountryCode} />}
            </div>
            <div className={styles.formItem}>
              <Input
                name="phoneNumber"
                onChange={handleFieldChange}
                value={phoneNumber.value}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.phoneNumber",
                })}
              />
              {!isPristine && <ErrorMessage field={phoneNumber} />}
            </div>
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.formItem}>
            <label>
              <FormattedMessage id="app.field.transportType" />
            </label>
            <Select
              showSearch
              placeholder={intl.formatMessage({
                id: "app.placeholder.transportType",
              })}
              onChange={handleTransportTypeChange}
              value={transportType.value}
              optionFilterProp="children"
              filterOption={filterOption}
            >
              {TRANSPORT_TYPES.map((item, index) => (
                <Option key={index} value={item.type}>
                  {item.name}
                </Option>
              ))}
            </Select>
            {!isPristine && transportType.value == undefined && <ErrorMessage field={transportType} />}
          </div>
          {true && (<div className={styles.formItem}>
            <label>
              <FormattedMessage id="app.field.storeName" />
            </label>
            <Select
              showSearch
              placeholder={intl.formatMessage({
                id: "app.placeholder.store",
              })}
              onChange={handleStoreChange}
              value={store.value}
              optionFilterProp="children"
              filterOption={filterOption}
            >
              {stores.data.map((store) => (
                <Option key={store.branchId} value={store.branchId}>
                  {store.branchName}
                </Option>
              ))}
            </Select>
            {!isPristine && <ErrorMessage field={store} />}
          </div>)}
        </div>
        <div className={styles.formItem}>
          <label>
            {intl.formatMessage({
              id: "app.field.address",
            })}
          </label>
          <Input
            name="address"
            onChange={handleFieldChange}
            value={address.value}
            placeholder={intl.formatMessage({
              id: "app.placeholder.address",
            })}
          />
          {!isPristine && <ErrorMessage field={address} />}
        </div>
        <div className={styles.formItem}>
          <label>
            {intl.formatMessage({
              id: "app.field.description",
            })}
          </label>
          <Input
            name="description"
            onChange={handleFieldChange}
            value={description.value}
            placeholder={intl.formatMessage({
              id: "app.placeholder.description",
            })}
          />
          {!isPristine && <ErrorMessage field={description} />}
        </div>
        <div className={styles.formAction}>
          <Button type="default" shape="" onClick={closeModal}>
          {intl.formatMessage({id: 'app.button.cancel'})}
          </Button>
          <Button type="primary" shape="" onClick={formAction}>
          {intl.formatMessage({id: 'app.button.submit'})}
          </Button>
        </div>
      </Panel>
    </CustomModal>
  );
}

export default AddEditPicker;
