const DAYS = [
    {
        value: 0,
        text: 'Sunday',
    },
    {
        value: 1,
        text: 'Monday',
    },
    {
        value: 2,
        text: 'Tuesday',
    },
    {
        value: 3,
        text: 'Wednesday',
    },
    {
        value: 4,
        text: 'Thursday',
    },
    {
        value: 5,
        text: 'Friday',
    },
    {
        value: 6,
        text: 'Saturday',
    },
];

export {
    DAYS
}