import axios from "axios";
import userActions from "../../redux/user-info/user-actions";
import store from "../../redux/store";
import { toastr } from "react-redux-toastr";
import { getPanelType } from "../constants/dropdown";

const panelType = getPanelType();
// let baseURL = 'https://api.my-trolley.com/api/admin';
let baseURL = 'https://dev-api.pawminded.com/admin';
if(panelType !== 0) {
  baseURL = baseURL.replace("admin", "admin")
}

const axiosClient = axios.create({
  baseURL,
});

axiosClient.interceptors.request.use(function (config) {
  const jwt = localStorage.getItem("trollyAdmin_jwt");
  const language = localStorage.getItem("trollyAdmin_language");


  if (jwt) {
    config.headers["access-token"] = jwt;
  }
  config.headers["content-language"] = language ? language : "en";
  return config;
});

axiosClient.interceptors.response.use(
  function (response) {
    console.log("RESPONSE: ", response.config.url, response.data);
    return response;
  },
  function (error) {
    if (error?.response?.data?.statusCode === 401) {
      store.dispatch(userActions.sessionExpired());
    } else {
      if (error?.response?.data?.message) {
        toastr.error(error.response.data.message);
      } else {
        toastr.error("Error", "Something Went Wrong");
      }
    }

    return Promise.reject(error.response);
  }
);

export default axiosClient;
