import React from "react";
import { Popconfirm, Tooltip, Switch } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import classNames from "classnames";

import { Panel, Icon } from "../../../../components/common";
import placeholderImg from "../../../../assets/images/item-placeholder.png";
import "./brands-table.scss";

const styles = {
  stickyRight: "brands__sticky-right",
  stickyAction: "sticky-action",
  image: "brands__image",
  tableActions: "brands__table-actions",
  actionIcon: "brands__action-icon",
  actionIconHidden: "brands__action-icon--hidden",
  table: "custom-table",
  tableContainer: "brands__table-container",
};

function BrandsTable(props) {
  const intl = useIntl();
  const handleImageError = (e) => {
    e.target.src = placeholderImg;
  };

  return (
    <Panel
      containerClass={styles.tableContainer}
      isLoading={props.isLoading}
      isEmpty={props.notFound}
      emptyText="No Data"
    >
      <table className={styles.table}>
        <tbody>
          <tr>
            <th>
              <FormattedMessage id="app.field.index" />
            </th>
            <th>
              <FormattedMessage id="app.field.image" />
            </th>
            <th>
              <FormattedMessage id="app.field.name" />
            </th>
            <th>
              <FormattedMessage id="app.field.store" />
            </th>
            <th>
              <FormattedMessage id="app.field.action" />
            </th>
          </tr>

          {props.data
            .filter((brand) => brand.isDeleted !== 1)
            .map((item, index) => (
              <tr key={item.brandId}>
                <td>
                  {" "}
                  {index + 1 + props.recordsPerPage * (props.currentPage - 1)}
                </td>
                <td>
                  <img
                    src={
                      item.brandLogoIcon ? item.brandLogoIcon : placeholderImg
                    }
                    onError={handleImageError}
                    className={styles.image}
                  />
                </td>
                <td>{item.brandName}</td>
                <td>{item.branchName}</td>
                <td>
                  <div className={styles.tableActions}>
                    <Tooltip title="Edit" placement="top">
                      <span
                        onClick={() => {
                          props.handleEdit(item);
                        }}
                        className={classNames({
                          [styles.actionIcon]: true,
                          [styles.actionIconHidden]: false,
                        })}
                      >
                        <Icon name="PEN" size="small" />
                      </span>
                    </Tooltip>
                    <Tooltip
                      title={item.isEnabled ? "Disable" : "Enable"}
                      placement="top"
                    >
                      <span>
                        <Switch
                          checked={item.isEnabled}
                          onChange={() => {
                            props.toggleBrand(item);
                          }}
                        />
                      </span>
                    </Tooltip>
                    <Tooltip title="Delete" placement="top">
                      <Popconfirm
                        title="Are you sure you want to delete?"
                        onConfirm={() => {
                          props.deleteBrand(item);
                        }}
                        placement="topRight"
                        okText="Yes"
                        cancelText="No"
                      >
                        <span className={styles.actionIcon}>
                          <Icon name="TRASH" size="small" />
                        </span>
                      </Popconfirm>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </Panel>
  );
}

export default BrandsTable;
