import React, { useState, useEffect } from "react";
import { Input, Button, Select, Dropdown, Menu, Popconfirm, Tooltip, Form, Typography, Table } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { FormattedMessage, useIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import { removeEmptyKeys } from "../../../../shared/utils/object-manipulation";
import {
  CustomModal,
  Panel,
  ErrorMessage,
  UploadButton,
  Label,
  CustomTable,
  Icon
} from "../../../../components/common";
import axios from "../../../../shared/api/axiosConfig";
import Validations from "../../../../shared/validations/Validations";
import placeholderImg from "../../../../assets/images/item-placeholder.png";
import "./add-edit-part.scss";

const styles = {
  formItem: "custom-form-item",
  formGroup: "custom-form-group",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  // imageContainer: "parts__add-edit-part-modal__image-container",
  imageContainerImage: "parts__add-edit-part-modal__image-container__image",
  imageContainerForm: "parts__add-edit-part-modal__image-container__form",
};

const { Option } = Select;
const errorText = "Field cannot be empty";
const {TextArea}  = Input;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'text' ? <TextArea /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

function AddEditPart(props) {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [isPristine, togglePristine] = useState(true);
  const [isContentPristine, toggleContentPristine] = useState(true);
  const [name, changeName] = useState(Validations.validateTextField(""));
//   const [overview, changeOverview] = useState(Validations.validateTextField(""));
  const [intro, changeIntro] = useState(Validations.validateYoutubeLink(""));
  const [lead, changeLead] = useState(Validations.validateTextField(""));
  const [content, changeContent] = useState([]);

  const [heading, changeHeading] = useState(Validations.validateTextField(""));
  const [text, changeText] = useState(Validations.validateTextField(""));
  const [videoTime, changeVideoTime] = useState(Validations.validateTextField(null));
  const [rank, changeRank] = useState(Validations.validateNumericValue(null));
  const [imageURL, changeImageURL] = useState(
    Validations.validateTextField("")
  );
  const [lessonType, updateLessonType] = useState({
    selected: undefined,
    data: [
      {
        key: "reading",
        value: "Reading",
      },
      {
        key: "video",
        value: "Video",
      },
    ],
  });

  const [editingKey, setEditingKey] = useState('');

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      heading: '',
      text: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...content];
      const index = content.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = content[index];
        newData.splice(index, 1, { ...item, ...row });
        changeContent(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        changeContent(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const handleLessonTypeChange = (sec) => {
    updateLessonType({
      ...lessonType,
      selected: sec,
    });
  };

  const resetForm = () => {
    togglePristine(true);
    changeName(Validations.validateTextField(null));
    // changeOverview(Validations.validateTextField(null));
    changeIntro(Validations.validateYoutubeLink(""));
    updateLessonType({
      ...lessonType,
      selected: undefined,
    });
    changeLead(Validations.validateTextField(null));
    changeContent([]);
    changeVideoTime(Validations.validateTextField(null));
    changeRank(Validations.validateNumericValue(null));
    changeImageURL(Validations.validateTextField(null));
  };

  const setForm = () => {
    togglePristine(true);
    changeName(Validations.validateTextField(props.data.title));
    let con = props.data.content.map((el) => {
      let obj = {};
      obj.key = el.key;
      obj.value = el.value;
      return obj;
    });
    changeLead(Validations.validateTextField(props.data.lead));
    changeContent(con);
    // changeOverview(Validations.validateTextField(props.data.overview));
    updateLessonType({
      ...lessonType,
      selected: props.data.lessonType
    });
    changeVideoTime(Validations.validateTextField(props.data.videoTime));
    changeIntro(Validations.validateYoutubeLink(props.data.videoLink));
    // changeRank(Validations.validateNumericValue(props.data.brandRank));
    changeImageURL(Validations.validateTextField(props.data.cover));
  };
  useEffect(() => {
    if(props.isVisible) {
      if (props.modalToShow === "EDIT_PART") {
        setForm();
      } else if (props.modalToShow === "ADD_PART") {
        resetForm();
      }
    }
  }, [props.isVisible, props.data]);


  const handleFieldChange = (e) => {
    const { value } = e.target;
    switch (e.target.name) {
      case "name":
        changeName(Validations.validateTextField(value));
        break;
      case "heading":
        changeHeading(Validations.validateTextField(value));
        break;
      case "lead":
        changeLead(Validations.validateTextField(value));
        break;
      case "text":
        changeText(Validations.validateTextField(value));
        break;
      case "intro":
        changeIntro(Validations.validateYoutubeLink(value));
        break;
      case "rank":
        changeRank(Validations.validateNumericValue(value));
        break;
      case "videoTime":
        changeVideoTime(Validations.validateTextField(value));
        break;
      default:
        break;
    }
  };

  const getMenu = (item) => {
    return (
      <Menu>
        {/* <Menu.Item
          onClick={() => {
            editContent(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.edit" })}
        </Menu.Item> */}
        <Menu.Item
        >
          <Popconfirm
            title={intl.formatMessage({
              id: "app.message.deleteWarning",
            })}
            onConfirm={() => {
              deleteContent(item);
            }}
            placement="topRight"
            okText={intl.formatMessage({ id: "app.field.yes" })}
            cancelText={intl.formatMessage({ id: "app.field.no" })}
          >
            {intl.formatMessage({ id: "app.field.delete" })}
          </Popconfirm>
        </Menu.Item>
      </Menu>
    );
  };

  const contentColumns = [
    {
      dataIndex : "key",
      title : "Heading",
      editable: true,
    },
    {
      dataIndex : "value",
      title : "Text",
      editable: true,
    },
    {
      dataIndex: "",
      title: intl.formatMessage({ id: "app.field.action" }),
      fixed: "right",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <Typography.Link disabled={editingKey !== ''}>
              <Tooltip title="Edit" placement="top">
                <span
                  onClick={() => {edit(record)}}
                  style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                >
                  <Icon name="PEN" pointer={1} size="small" />
                </span>
              </Tooltip>
            </Typography.Link>
            <Typography.Link>
              <Tooltip title="Delete" placement="top">
                <span
                  onClick={() => {deleteContent(record)}}
                  style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                >
                  <Icon name="TRASH" pointer={1} size="small" />
                </span>
              </Tooltip>
            </Typography.Link>
          </div>
        );
      },
      // render: (item) =>  <div style={{ display:"flex", justifyContent:"space-evenly" }}>
      //   <Tooltip title="Edit" placement="top">
      //     <span
      //       onClick={() => {editContent(item)}}
      //       style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
      //     >
      //       <Icon name="PEN" pointer={1} size="small" />
      //     </span>
      //     </Tooltip>
      //     <Tooltip title="Delete" placement="top">
      //       <span
      //         onClick={() => {deleteContent(item)}}
      //         style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
      //       >
      //         <Icon name="TRASH" pointer={1} size="small" />
      //       </span>
      //     </Tooltip>
      //   </div>
    }
  ];

  const mergedColumns = contentColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'Text' ? 'text' : 'heading',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  const getData = () => {
    const data = removeEmptyKeys({
      title: name.value,
      metaType: "lesson",
      lessonType: lessonType.selected,
      videoLink: intro.value,
      cover: imageURL.value,
      lead: lead.value,
      videoTime: videoTime.value,
    });
    if(pair.length > 0){
      data.content = pair;
    }else{
      let co = {};
      content.map((item) => {
        co[item.key] = item.value;
      });
      data.content = co;
    }
    return data;
  }

  const handleAdd = () => {
    // TODO
    props.addPart(getData());
  };

  const handleEdit = () => {
    // TODO
    props.editPart(getData());
  };

  const isFormValid = () => {
    let isValid = name.isValid && lead.isValid;
    if(intro.value){
      isValid = intro.isValid;
      isValid = videoTime.isValid;
    }else{
      isValid = imageURL.isValid;
    }
    return isValid;
  };

  const closeModal = () => {
    props.onCloseIconClick();
  };

  let pair = [];
  const addContent = () => {
    toggleContentPristine(true);
    if(heading.value === "" || text.value === ""){
      toggleContentPristine(false);
      return;
    }
    let cc = [];
    cc.push({
      key: heading.value, value: text.value,
    });
    changeContent(content => [...content, {
      key: heading.value,
      value: text.value,
    }]);
    changeHeading(Validations.validateTextField(""));
    changeText(Validations.validateTextField(""));
  };

  const deleteContent = (item) => {
    let index = content.findIndex(
      (time) => time.key === item.key && time.value === item.value
    );
    if (index !== -1) content.splice(index, 1);
    changeContent([...content]);
  };
  const editContent = (item) => {
    // changeContent(content.data.filter(data.key == item.key && data.value == item.value));
    // content.data.map((data) => {
    //   if(data.key == item.key && data.value == item.value ){
    //     // changeContent(content.data.filter(data.key == item.key && data.value == item.value));
    //   }
    // })
    // let index = pair.findIndex(
    //   (pr) => pr.key === item.key || pr.value == item.value
    // );
    // if (index !== -1) pair.splice(index, 1);
    // changeContent(data => [...data, { key: heading.value, value: text.value }]);
    // changeContent({
    //   ...content,
    //   data: pair,
    // });
    let index = content.findIndex(
      (time) => time.key === item.key && time.value === item.value
    );
    // if (index !== -1) content.splice(index, 1);
    // changeContent([...content]);
    changeHeading(Validations.validateTextField(item.key));
    changeText(Validations.validateTextField(item.value));
  };

  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const formAction = () => {
    togglePristine(false);
    if (!isFormValid()) {
      return;
    }
    if (props.modalToShow === "ADD_PART") {
      handleAdd();
    } else if (props.modalToShow === "EDIT_PART") {
      handleEdit();
    }
  };

  const handleImageChange = (image) => {
    changeImageURL(Validations.validateTextField(image));
  };

  return (
    <CustomModal
      size="tiny"
      onCloseIconClick={closeModal}
      header={
        props.modalToShow === "ADD_PART"
          ? "Add Part"
          : "Edit Part"
      }
      isVisible={props.isVisible}
    >
      <Panel isLoading={props.isLoading} className={styles.formModal}>
        <div className={styles.imageContainer}>
          {lessonType.selected === "reading" && (<div className={styles.imageContainerImage}>
            <img
              src={imageURL.value ? imageURL.value : placeholderImg}
              alt=""
            />
            <UploadButton
              text="Upload Image"
              resultType="url"
              accept="image/*"
              onChange={handleImageChange}
            />
            {!isPristine && <ErrorMessage field={imageURL} />}
          </div>)}
          <div className={styles.imageContainerForm}>
            {/* <div className={styles.formItem}>
              <label>
                {intl.formatMessage({
                  id: "app.field.rank",
                })}
              </label>
              <Input
                name="rank"
                type="number"
                onChange={handleFieldChange}
                value={rank.value}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.rank",
                })}
              />
              {!isPristine && <ErrorMessage field={rank} />}
            </div> */}
            <div className={styles.formItem}>
              <label>
                  Title
              </label>
              <Input
                name="name"
                onChange={handleFieldChange}
                value={name.value}
                placeholder="Enter Title"
              />
              {!isPristine && <ErrorMessage field={name} />}
            </div>

            <div className={styles.formItem}>
              <label>
                  Introduction Text
              </label>
              <Input
                name="lead"
                onChange={handleFieldChange}
                value={lead.value}
                placeholder="Enter Introduction of the Part"
              />
              {!isPristine && <ErrorMessage field={lead} />}
            </div>
            <div className={styles.formItem}>
              <label>
                  Part Type
              </label>
              <Select
              placeholder="Select Part Type"
                onChange={handleLessonTypeChange}
                value={lessonType.selected}
              >
                {lessonType.data.map((type) => (
                  <Option key={type.key} value={type.key}>
                    {type.value}
                  </Option>
                ))}
              </Select>
              {!isPristine && lessonType.selected === undefined && (
                <ErrorMessage text={errorText} />
              )}
            </div>
            {lessonType.selected === "video" && (
              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <label>
                      Introduction Video
                  </label>
                  <Input
                    name="intro"
                    onChange={handleFieldChange}
                    value={intro.value}
                    placeholder="Enter Introduction Video Link"
                  />
                  {!isPristine && <ErrorMessage field={intro} />}
                </div>
                <div className={styles.formItem}>
                  <label>Duration of video</label>
                  <Input
                    name="videoTime"
                    onChange={handleFieldChange}
                    value={videoTime.value}
                    placeholder="Enter Duration of Video"
                  />
                  {!isPristine && <ErrorMessage field={videoTime} />}
                </div>
              </div>)}
          </div>
          <div className={styles.imageContainerForm}>
            <div className={styles.formGroup}>
              <label style={{ fontSize : "20px", textTransform : "uppercase", flex:"2" }}>CONTENT</label>
            </div>
            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <label>
                  Heading
                </label>
                <Input
                  name="heading"
                  onChange={handleFieldChange}
                  value={heading.value}
                  placeholder="Enter Heading"
                />
              {!isContentPristine && <ErrorMessage field={heading}/>}
              </div>
              <div className={styles.formItem}>
                <label>
                  Text
                </label>
                <TextArea
                  row={5}
                  name="text"
                  onChange={handleFieldChange}
                  value={text.value}
                  placeholder="Enter Text"
                />
                {!isContentPristine && <ErrorMessage field={text} />}
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button type="primary" shape={undefined} onClick={addContent}>Add</Button>
              </div>
            </div>
            <div>
              {/* <Table columns={contentColumns} size="large" dataSource={content} pagination={false} /> */}
              <Form form={form} component={false}>
                <Table
                className="custom-table"
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                bordered
                dataSource={content}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{
                  onChange: cancel,
                }} 
              />
              </Form>
            </div>
          </div>
        </div>
        <div className={styles.formAction}>
          <Button type="default" shape={undefined} onClick={closeModal}>
          {intl.formatMessage({id: 'app.button.cancel'})}
          </Button>
          <Button type="primary" shape={undefined} onClick={formAction}>
          {intl.formatMessage({id: 'app.button.submit'})}
          </Button>
        </div>
      </Panel>
    </CustomModal>
  );
}

export default AddEditPart;