import React, { useState, useEffect } from "react";
import { Input, Button, Select } from "antd";
import { useIntl } from "react-intl";

import {
  CustomModal,
  Panel,
  ErrorMessage,
  UploadButton,
} from "../../../../components/common";
import Validations from "../../../../shared/validations/Validations";
import placeholderImg from "../../../../assets/images/item-placeholder.png";
import "./store-categories-modal.scss";

const { Option } = Select;

const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
};

function AddEditStoreCategory(props) {
  const intl = useIntl();

  const [isPristine, togglePristine] = useState(true);
  const [cat, changeCat] = useState(Validations.validateTextField(undefined));
  const [rank, changeRank] = useState(Validations.validateNumericValue(null));

  const resetForm = () => {
    togglePristine(true);
    changeRank(Validations.validateNumericValue(null));
    changeCat(Validations.validateTextField(undefined));
  };

  const setForm = () => {
    togglePristine(true);
    changeCat(Validations.validateTextField(props.data.superCatId));
    changeRank(Validations.validateNumericValue(props.data.priority));
    // changeImageURL(Validations.validateTextField(props.data.categoryImage));
  };
  useEffect(() => {
    if (props.isVisible) {
      if (props.modalToShow === "EDIT_CATEGORY") {
        setForm();
        //   setForm();
      } else if (props.modalToShow === "ADD_CATEGORY") {
        resetForm();
      }
    }
  }, [props.isVisible]);

  const getData = () => {
    const [currentCat] = props.categories.filter((i) => i.superCatId === cat.value) 
    return {
      superCatId: currentCat.superCatId,
      categoryName: currentCat.categoryName,
      priority: rank.value,
    }

  };
  const handleAdd = () => {
    // TODO
    // getData();
    props.addCategory(getData());
  };

  const handleEdit = () => {
    // TODO
    props.editCategory(getData());
  };

  const isFormValid = () => {
    return cat.isValid && rank.isValid;
  };

  const closeModal = () => {
    props.onCloseIconClick();
  };

  const handleCategoryChange = (val) => {
    changeCat(Validations.validateTextField(val));
  };

  const handleRankChange = (e) => {
    changeRank(Validations.validateNumericValue(e.target.value))
  }

  const formAction = () => {
    togglePristine(false);
    if (!isFormValid()) {
      return;
    }
    if (props.modalToShow === "ADD_CATEGORY") {
      handleAdd();
    } else if (props.modalToShow === "EDIT_CATEGORY") {
      handleEdit();
    }
  };

  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={closeModal}
      header={
        props.modalToShow === "ADD_CATEGORY"
          ? "app.placeholder.addCategory"
          : "app.placeholder.editCategory"
      }
      isVisible={props.isVisible}
    >
      <Panel isLoading={props.isLoading} className={styles.formModal}>
        <div className={styles.formItem}>
          <label htmlFor="">
            {intl.formatMessage({ id: "app.field.category" })}
          </label>
          <Select
            onChange={handleCategoryChange}
            value={cat.value}
            placeholder={intl.formatMessage({ id: "app.placeholder.category" })}
          >
            {props.categories.map((item) => (
              <Option key={item.superCatId} value={item.superCatId}>
                {item.categoryName}
              </Option>
            ))}
          </Select>
          {!isPristine && <ErrorMessage field={cat} />}
        </div>
        <div className={styles.formItem}>
        <label htmlFor="">
            {intl.formatMessage({ id: "app.field.rank" })}
          </label>
          <Input onChange={handleRankChange} value={rank.value} placeholder={intl.formatMessage({ id: "app.placeholder.rank" })} />
          {!isPristine && <ErrorMessage field={rank} />}
        </div>
        <div className={styles.formAction}>
          <Button type="default" shape={undefined} onClick={closeModal}>
            {intl.formatMessage({ id: "app.button.cancel" })}
          </Button>
          <Button type="primary" shape={undefined} onClick={formAction}>
            {intl.formatMessage({ id: "app.button.submit" })}
          </Button>
        </div>
      </Panel>
    </CustomModal>
  );
}

export default AddEditStoreCategory;
