import React from "react";
import { Popconfirm, Tooltip, Switch, Button, message } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import classNames from "classnames";
import moment from "moment";

import {
  Panel,
  Icon,
  CustomImage,
  CustomTable,
} from "../../../../components/common";
import placeholderImg from "../../../../assets/images/item-placeholder.png";
import "./images-table.scss";
import {
  TABLE_TEXT_LIMIT,
  textTrimmer,
} from "../../../../shared/constants/dropdown";

const styles = {
  stickyRight: "oyelabs__images-upload__table__sticky-right",
  stickyAction: "sticky-action",
  image: "oyelabs__images-upload__table__image",
  tableActions: "oyelabs__images-upload__table__table-actions",
  actionIcon: "oyelabs__images-upload__table__action-icon",
  actionIconHidden: "oyelabs__images-upload__table__action-icon--hidden",
  table: "custom-table",
  tableContainer: "oyelabs__images-upload__table__table-container",
  limitCell: "oyelabs__images-upload__table__limit-cell",
};

const copyToClipboard = (text) => {
  const a = document.createElement("input");
  a.value = text;
  document.body.appendChild(a);
  a.select();
  a.setSelectionRange(0, 99999);
  document.execCommand("copy");

  document.body.removeChild(a);
};

function ImagesTable(props) {
  const intl = useIntl();
  const handleImageError = (e) => {
    // e.target.src = placeholderImg;
  };

  const handleCopyImage = (text) => {
    copyToClipboard(text);
    message.success("URL Copied To Clipboard");
  };

  const columns = [
    {
      dataIndex: "id",
      title: intl.formatMessage({ id: "app.field.sNo" }),

      render: (item, object, index) => {
        return (
          <span>
            {props.pagination.pageSize * (props.pagination.current - 1) +
              index +
              1}
          </span>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "app.field.image" }),
      dataIndex: "imageUrl",
      render: (item) => <div>{<CustomImage isTable src={item} />}</div>,
    },
    {
      title: intl.formatMessage({ id: "app.field.imageURL" }),
      dataIndex: "imageUrl",

      render: (item) => (
        <Button
          onClick={() => {
            handleCopyImage(item);
          }}
          type="dashed"
        >
          {intl.formatMessage({ id: "app.field.copyImage" })}
        </Button>
      ),
    },
    {
      title: intl.formatMessage({ id: "app.field.iconURL" }),
      dataIndex: "imageIcon",

      // width: 120,
      render: (item) => (
        <Button
          onClick={() => {
            handleCopyImage(item);
          }}
          type="dashed"
        >
          {textTrimmer(
            intl.formatMessage({ id: "app.field.copyIcon" }),
            50,
            "..."
          )}
          {/* {intl.formatMessage({ id: "app.field.copyIcon" })} */}
        </Button>
      ),
    },
    {
      title: intl.formatMessage({ id: "app.field.name" }),
      dataIndex: "fileName",
    },
    {
      title: intl.formatMessage({ id: "app.field.description" }),
      dataIndex: "description",
    },
    {
      title: intl.formatMessage({ id: "app.field.createdAt" }),
      dataIndex: "createdAt",
      fixed: "right",

      render: (item) => moment(item).format("MMMM Do YYYY"),
    },
  ];

  return (
    <CustomTable
      columns={columns}
      isLoading={props.isLoading}
      dataSource={props.data}
      onChange={props.handlePaginationChange}
      pagination={props.pagination}
      columnWidth={150}
    />
  );
}

export default ImagesTable;

/*

  <table className={styles.table}>
        <tbody>
          <tr>
            <th>
              <FormattedMessage id="app.field.index" />
            </th>
            <th>
              <FormattedMessage id="app.field.image" />
            </th>
            <th>Image URL</th>
            <th>Icon URL</th>
            <th>
              <FormattedMessage id="app.field.name" />
            </th>
            <th>
              <FormattedMessage id="app.field.description" />
            </th>
            <th>
              <FormattedMessage id="app.field.createdAt" />
            </th>
          </tr>




          {props.data
            // .filter((brand) => brand.isDeleted !== 1)
            .map((item, index) => (
              <tr key={item.id}>
                <td> {index + 1 + props.pageSize * (props.current - 1)}</td>
                <td style={{ padding: "0.5rem" }}>
                  <img
                    src={item.imageUrl}
                    onError={handleImageError}
                    className={styles.image}
                  />
                </td>
                <td className={styles.limitCell}>
   
                </td>
                <td className={styles.limitCell}>
                  <Button
                    onClick={() => {
                      handleCopyImage(item.imageIcon);
                    }}
                    type="dashed"
                  >
                    Copy Icon
                  </Button>
                </td>
                <td>{item.fileName || <i style={{ color: "#aaa" }}>N/A</i>}</td>
                <td>
                  {item.description || <i style={{ color: "#aaa" }}>N/A</i>}
                </td>
                <td>{moment(props.data.createdAt).format("MMMM Do YYYY")}</td>

                </tr>
                ))}
            </tbody>
          </table>

 */
