import React, { useState } from "react";
import { Popconfirm, Tooltip, Switch } from "antd";
import { useIntl } from "react-intl";
import classNames from "classnames";
import moment from "moment";

import { Panel, Icon } from "../../../../components/common";
import "./schedule-table.scss";

const styles = {
  table: "custom-table",
  tableActions: "custom-table__table-actions",
  actionIcon: "custom-table__action-icon",
  panelContainer: "advertisements-table__panel-container",
};

function timeFix(time) {
    let [hours, minutes] = time.split(':');
    if(hours.length === 1) {
        hours = `0${hours}`;
    }
    if(minutes.length === 1) {
        minutes = `0${minutes}`;
    }
    return `${hours}:${minutes}`;
}


const transportType = ["Bike", "Truck"];

const bannerTypes = ["Category", "Sub-Category", "Product"];
function ScheduleTable(props) {
  const intl = useIntl();


const sortComparison = (a, b) => {
  let [aHours, aMin, aSecs] = a.startTime.split(":").map(i => parseInt(i));
  let [bHours, bMin, bSecs] = b.startTime.split(":").map(i => parseInt(i));
  
  let comparison = (aHours*60*60 + aMin*60 +aSecs > bHours*60*60 + bMin*60 + bSecs) ? 1 : -1
  if(props.sortBy === 'descending') {
    comparison = comparison * -1;
  }
  return comparison
      }
  
     const sortSlots = (slots) => {
        return [...slots].sort(sortComparison)
      }

  return (
    <Panel
      isLoading={props.isLoading}
      isEmpty={props.isEmpty}
      emptyText="No Data"
      containerClass={styles.panelContainer}
    >
      <table className={styles.table}>
        <tbody>
          <tr>
            <th>{intl.formatMessage({ id: "app.field.day" })}</th>
            <th>{intl.formatMessage({ id: "app.field.deliveryMode" })}</th>
            <th>{intl.formatMessage({ id: "app.field.startTime" })}</th>
            <th>{intl.formatMessage({ id: "app.field.endTime" })}</th>
            <th>{intl.formatMessage({ id: "app.field.maxOrders" })}</th>
            <th>{intl.formatMessage({ id: "app.field.action" })}</th>
          </tr>
          {props.data.filter(item => !item.isDeleted).map((item, index) => (
            <tr key={item.sId}>
              <td>{intl.formatMessage({id: props.day})}</td>
              <td>{transportType[item.vehicleType]}</td>
              <td>{timeFix(item.startTime)}</td>
              <td>{timeFix(item.endTime)}</td>
              <td>{item.maxOrders}</td>
              <td>
                <div className={styles.tableActions}>
                  { (props.panelType == 0 || item.isLocked == 0) && <>
                  <Tooltip title="Edit" placement="top">
                    <span
                      onClick={() => {
                        props.editItem(item);
                      }}
                      className={styles.actionIcon}
                    >
                      <Icon name="PEN" size="small" />
                    </span>
                  </Tooltip>
                 {props.panelType == 0 && <Tooltip
                    title={item.isLocked ? "Unlock" : "Lock"}
                    placement="top"
                  >
                    <Popconfirm
                      title={intl.formatMessage({
                        id: item.isLocked
                          ? "app.message.unlockWarning"
                          : "app.message.lockWarning",
                      })}
                      onConfirm={() => {
                        props.toggleSlot(item, 'lock');
                      }}
                      placement="topRight"
                      okText="Yes"
                      cancelText="No"
                    >
                      <span className={styles.actionIcon}>
                      {(item.isLocked === 1 || item.isLocked === true) && <Icon name="LOCKED" />}
                       {(item.isLocked === 0 || item.isLocked === false) && <Icon name="UNLOCKED" />}
                      </span>
                    </Popconfirm>
                  </Tooltip>}
                  <Tooltip title="Delete" placement="top">
                    <Popconfirm
                      title={intl.formatMessage({
                        id: "app.message.deleteWarning",
                      })}
                      onConfirm={() => {
                        props.toggleSlot(item, 'delete');
                      }}
                      placement="topRight"
                      okText="Yes"
                      cancelText="No"
                    >
                      <span className={styles.actionIcon}>
                        <Icon name="TRASH" size="small" />
                      </span>
                    </Popconfirm>
                  </Tooltip>
                  </>}
                  {
                    props.panelType == 1 && item.isLocked == 1 && <span>{intl.formatMessage({id: 'app.field.locked'})}</span>
                  }
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Panel>
  );
}

export default ScheduleTable;
