import React from 'react';
import {Drawer} from 'antd';
import {Icon} from '../../';
import './full-page-drawer.scss';

const styles = {
    header: 'custom-full-page-drawer__header',
    wrapper: 'custom-full-page-drawer__wrapper',
}
function Header(props) {
    return (
        <div className={styles.header}>
            <div>{props.title}</div>
            <Icon pointer={1} onClick={props.onClose} name="CLOSE" size="small" />
            </div>
    )
}

function FullPageDrawer(props) {
    if(props.isVisible) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.removeAttribute('style');
      }
    return (
        <Drawer
        title={<Header title={props.title} onClose={props.onCloseIconClick} />}
        placement="bottom"
        className={styles.wrapper}
        closable={false}
        visible={props.isVisible}
        height="100%"
        key={props.key}
      >
          {props.children}
      </Drawer>
    )
}

export default FullPageDrawer;