import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import moment from "moment";
import { Select, Dropdown, Button, Menu, DatePicker } from "antd";
import {
  CaretDownOutlined,
  SearchOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import Pagination from "react-js-pagination";
import {
  OrdersTable,
  AssignPickerModal,
  OrderDetailsModal,
  OrderHistoryModal,
  CancelOrderModal,
} from "./components";
import { RECORDS_PER_PAGE, getBaseURL } from "../../shared/constants/dropdown";
import {
  Header,
  Icon,
  PaginationInfo,
  DelayedInput,
  Label,
} from "../../components/common";
import userActions from "../../redux/user-info/user-actions";
import { toastr } from "react-redux-toastr";
import {
  removeEmptyKeys,
  // multiSort,
} from "../../shared/utils/object-manipulation";
import { ORDER_STATUS, PAYMENT_METHODS } from "../../shared/constants/orders";
import axios from "../../shared/api/axiosConfig";

import "./orders.scss";

function downloadFileFromLink(filename, link) {
  const element = document.createElement("a");
  element.setAttribute("href", link);
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

const styles = {
  wrapper: "orders__wrapper",
  menu: "custom-menu",
  links: "orders__links",
  content: "custom-section",
};

const { Option } = Select;
const SORT_BY = [
  {
    value: 1,
    text: "Ascending Slot Time",
  },
  {
    value: -1,
    text: "Descending Slot Time",
  },
];

const routeMap = {
  all: {
    title: "app.pageTitle.allOrders",
    status: "",
  },
  accepted: {
    title: "app.pageTitle.acceptedOrders",
    status: 3,
  },
  delivered: {
    title: "app.pageTitle.deliveredOrders",
    status: 4,
  },
  cancelled: {
    title: "app.pageTitle.cancelledOrders",
    status: 5,
  },
  upcoming: {
    title: "app.pageTitle.upcomingOrders",
    status: 2,
  },
  "being-picked": {
    title: "app.pageTitle.beingPickedOrders",
    status: 6,
  },
  "picked": {
    title: "app.pageTitle.pickedOrders",
    status: 7,
  },
  "shipped": {
    title: "app.pageTitle.shippedOrders",
    status: 9,
  },
};

function Orders(props) {
  const intl = useIntl();
  const [pagination, updatePagination] = useState({
    pageType: undefined,
    pageSize: 10,
    skip: 0,
    current: 1,
    search: "",
  });

  const [startDate, changeStartDate] = useState(moment());
  const [endDate, changeEndDate] = useState(moment());
  const [sortBy, changeSortBy] = useState(1);
  const [stores, updateStores] = useState({
    selected: props.storeId ? props.storeId : "",
    data: [],
    notFound: false,
    isLoading: true,
  });
  const [searchDelayed, updateSearchDelayed] = useState('');
  const [search, updateSearch] = useState("");
  const [pageTitle, updatePageTitle] = useState("all");
  const [modalControls, updateModalControls] = useState({
    pickerModalVisible: false,
    historyModalVisible: false,
    detailsModalVisible: false,
    cancelOrderModalVisible: false,
    data: {},
    isLoading: false,
  });

  const [tableControls, updateTableControls] = useState({
    isTableLoading: true,
    isTableEmpty: false,
    data: [],
    totalRecords: 0,
  });

  const [exportLoading, toggleExportLoading] = useState(false);

  const handleSearch = (val) => {
    updatePagination({
      ...pagination,
      current: 1,
      skip: 0,
    });

    updateSearchDelayed(val);
  };

  const handleSearchChange = (val) => {
    updateSearch(val);
  };

  const handleStoreChange = (storeId) => {
    updateStores({
      ...stores,
      selected: storeId,
    });
    // props.dispatch(userActions.setStore(storeId));
  };

  const handlePaginationChange = ({current, pageSize, total}) => {
    // debugger;
    updatePagination({
      ...pagination,
      current: current,
      total,
      pageSize,
      skip: (current - 1) * pageSize,
    });
  };

  // Locationon Change

  const handleRouteChange = (key) => {
    console.log("DELIVERED: ",key);
    let info = {};
    // debugger;
    if (!routeMap[key]) {
      info = routeMap.all;
      updatePageTitle("all");
    } else {
      console.log("KEY: ",key);
      info = routeMap[key];
      updatePageTitle(key);
    }

    updatePagination({
      search: "",
      pageType: info.status,
      pageSize: 10,
      current: 1,
      skip: 0,
    });
    // debugger
  };

  const getStores = () => {
    axios
      .get("/getBranches", {
        params: {
          onlyNames: 1,
        },
      })
      .then((res) => {
        if (res.data.data.branchesData.length) {
          // res.data.data.branchesData = multiSort(res.data.data.branchesData, {
          //   branchName: "asc",
          // });
          updateStores({
            ...stores,
            selected: props.storeId  ? props.storeId : "",
            data: res.data.data.branchesData,
          });
        }
      });
  };
  useEffect(() => {
    if (props.panelType == 0) {
      getStores();
    } else {
      updateStores({
        ...stores,
        selected: props.branchId,
        isLoading: false,
      });
    }
    handleRouteChange(props.match.params.type);
  }, [props.location.pathname]);

  const getOrdersData = () => {
    updateTableControls({
      ...tableControls,
      isTableLoading: true,
      isTableEmpty: false,
      data: [],
    });
    const sortCriterias = JSON.stringify([
      {
        criteria: 0,
        order: sortBy,
      },
    ]);
    axios
      .get("/getAllOrders", {
        params: removeEmptyKeys({
          branchId: stores.selected,
          limit: pagination.pageSize,
          skip: (pagination.current - 1) * pagination.pageSize,
          status: pagination.pageType,
          search: searchDelayed,
          startDate: new Date(startDate.toISOString()).toUTCString(),
          endDate: new Date(endDate.toISOString()).toUTCString(),
          sortCriterias,
        }),
      })
      .then((res) => {
        // debugger;
        if (res.data.statusCode !== 200) {
          updatePagination({
            ...pagination,
            total: 0,
          })
          toastr.error("Error", res.data.message);
          updateTableControls({
            ...tableControls,
            data: [],
            totalRecords: res.data.data.totalBooking,
            isTableLoading: false,
            isTableEmpty: true,
          });
          return;
        }
        if (res.data.data.bookingData.length) {
          updateTableControls({
            ...tableControls,
            data: res.data.data.bookingData.map((i) => {
              i.customer = JSON.parse(i.customer);
              i.branch = JSON.parse(i.branch);
              i.picker = JSON.parse(i.picker);
              i.address = JSON.parse(i.address)
              return i;
            }),
            totalRecords: res.data.data.totalBooking,
            isTableLoading: false,
            isTableEmpty: false,
          });
          updatePagination({
            ...pagination,
            total: res.data.data.totalBooking,
          })
        } else {
          updateTableControls({
            ...tableControls,
            data: [],
            totalRecords: res.data.data.totalBooking,
            isTableLoading: false,
            isTableEmpty: true,
          });
          updatePagination({
            ...pagination,
            total: 0,
          })
        }
      })
      .catch((err) => {
        updateTableControls({
          ...tableControls,
          data: [],
          totalRecords: 0,
          isTableLoading: false,
          isTableEmpty: true,
        });
        updatePagination({
          ...pagination,
          total: 0,
        })
      });
  };
  useEffect(() => {
    if (pagination.pageType !== undefined) {
      getOrdersData();
    }
  }, [
    searchDelayed,
    pagination.pageType,
    pagination.pageSize,
    pagination.current,
    pagination.skip,
    props.hack,
    stores.selected,
    startDate,
    endDate,
    sortBy,
  ]);

  const openAssignPickerModal = (order) => {
    updateModalControls({
      ...modalControls,
      data: order,
      pickerModalVisible: true,
    });
  };

  const openOrderDetailsModal = (order) => {
    // console.log(order);
    updateModalControls({
      ...modalControls,
      data: order,
      detailsModalVisible: true,
    });
  };

  const handleExportOrder = () => {
    toggleExportLoading(true);
    const sortCriterias = JSON.stringify([
      {
        criteria: 0,
        order: sortBy,
      },
    ]);
    console.log(pagination);
    axios
      .get("/getAllOrders", {
        params: removeEmptyKeys({
          branchId: stores.selected,
          // limit: pagination.pageSize,
          // skip: (pagination.current - 1) * pagination.pageSize,
          status: pagination.pageType,
          exportOrders: 1,
          // search: searchDelayed,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          sortCriterias,
        }),
      })
      .then((res) => {
        toggleExportLoading(false);
        downloadFileFromLink("orders", res.data.data.file_link);
      })
      .catch((err) => {
        toggleExportLoading(false);
        console.log(err);
      });
  };

  const openOrderHistoryModal = (order) => {
    // console.log(order);
    updateModalControls({
      ...modalControls,
      data: order,
      historyModalVisible: true,
    });
  };

  const openCancelOrderModal = (order) => {
    // console.log(order);
    updateModalControls({
      ...modalControls,
      data: order,
      cancelOrderModalVisible: true,
    });
  };

  const closeModal = () => {
    updateModalControls({
      ...modalControls,
      pickerModalVisible: false,
      historyModalVisible: false,
      detailsModalVisible: false,
      cancelOrderModalVisible: false,
      isLoading: false,
    });
  };

  const assignPicker = (picker) => {
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });
    const data = {
      pickerId: picker,
      bookingId: modalControls.data.bookingId,
    };

    axios
      .post("/assignPicker", data)
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          pickerModalVisible: res.data.statusCode !== 200,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getOrdersData();
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateModalControls({
          ...modalControls,
          isLoading: false,
        });
      });
  };

  const acceptOrder = (order) => {
    updateTableControls({
      ...tableControls,
      isLoading: true,
    });
    const data = {
      status: ORDER_STATUS.ACCEPTED,
      bookingId: order.bookingId,
    };

    axios
      .post("/changeBookingStatus", data)
      .then((res) => {
        updateTableControls({
          ...tableControls,
          isLoading: false,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getOrdersData();
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateTableControls({
          ...tableControls,
          isLoading: false,
        });
      });
  };

  const markAsComplete = (order) => {
    updateTableControls({
      ...tableControls,
      isLoading: true,
    });
    const data = {
      status: ORDER_STATUS.COMPLETED,
      bookingId: order.bookingId,
    };

    axios
      .post("/changeBookingStatus", data)
      .then((res) => {
        updateTableControls({
          ...tableControls,
          isLoading: false,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getOrdersData();
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateTableControls({
          ...tableControls,
          isLoading: false,
        });
      });
  };

  const sendForShipping = (order) => {
    updateTableControls({
      ...tableControls,
      isLoading: true,
    });
    const data = {
      status: ORDER_STATUS.ON_THE_WAY,
      bookingId: order.bookingId,
    };

    axios
      .post("/changeBookingStatus", data)
      .then((res) => {
        updateTableControls({
          ...tableControls,
          isLoading: false,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getOrdersData();
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateTableControls({
          ...tableControls,
          isLoading: false,
        });
      });
  };

  const cancelOrder = (refundType) => {
    // console.log(reason);
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });
    const data = {
      // cancellationReason: reason,
      bookingId: modalControls.data.bookingId,
    };
    if (modalControls.data.modeOfPaymentStatus === PAYMENT_METHODS.AREEBA) {
      data.refundMethod = refundType;
    }
    axios
      .put("/cancelBooking", data)
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          cancelOrderModalVisible: res.data.statusCode !== 200,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getOrdersData();
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateModalControls({
          ...modalControls,
          isLoading: false,
        });
      });
  };

  const handleOrderEdit = (order) => {
    props.history.push(getBaseURL(`/orders/edit/${order.bookingId}`));
  };
  return (
    <div className={styles.wrapper}>
      <AssignPickerModal
        isVisible={modalControls.pickerModalVisible}
        data={modalControls.data}
        assignPicker={assignPicker}
        onCloseIconClick={closeModal}
        isLoading={modalControls.isLoading}
      />
      <OrderDetailsModal
        isVisible={modalControls.detailsModalVisible}
        data={modalControls.data}
        pickerFlow={props.pickerFlow}
        onCloseIconClick={closeModal}
      />

      <OrderHistoryModal
        isVisible={modalControls.historyModalVisible}
        data={modalControls.data}
        pickerFlow={props.pickerFlow}
        onCloseIconClick={closeModal}
      />

      <CancelOrderModal
        isVisible={modalControls.cancelOrderModalVisible}
        data={modalControls.data}
        onCloseIconClick={closeModal}
        isLoading={modalControls.isLoading}
        pickerFlow={props.pickerFlow}
        cancelOrder={cancelOrder}
      />

      <Header title={routeMap[pageTitle].title} />
      <div className={styles.content} style={{ marginBottom: "32px" }}>
        <div className={styles.menu}>
          <header>
            <div className={styles.pageSize}>
              <Label value="app.field.startDate" horizontal />
              <DatePicker
                value={startDate}
                onChange={changeStartDate}
                placeholder={intl.formatMessage({ id: "app.placeholder.date" })}
                allowClear={false}
              />
            </div>
            <div className={styles.upperMenuDropdown}>
              <Label value="app.field.endDate" horizontal />
              <DatePicker
                value={endDate}
                onChange={changeEndDate}
                placeholder={intl.formatMessage({ id: "app.placeholder.date" })}
                allowClear={false}
              />
            </div>
          </header>
          <section>
            <Button
              disabled={tableControls.isTableEmpty}
              loading={exportLoading}
              onClick={handleExportOrder}
              type="primary"
              shape={undefined}
            >
              {intl.formatMessage({  id: "app.field.exportAsCsv",})}
            </Button>
          </section>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.menu}>
          <header>
            {props.panelType == 0 && <div>
              <Label value="app.field.filterBy" horizontal />
              <Select
                showSearch
                placeholder={intl.formatMessage({
                  id: "app.placeholder.store",
                })}
                onChange={handleStoreChange}
                style={{ width: "180px" }}
                value={stores.selected}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option key="allStores" value="">
                  {intl.formatMessage({ id: "app.field.allStores" })}
                </Option>
                {stores.data.map((store) => (
                  <Option key={store.branchId} value={store.branchId}>
                    {store.branchName}
                  </Option>
                ))}
              </Select>
            </div>}
            <div>
              <Label value="app.field.sortBy" horizontal />
              <Select value={sortBy} onChange={changeSortBy}>
                {SORT_BY.map((i) => (
                  <Option key={i.value} value={i.value}>
                    {i.value === 1?intl.formatMessage({id: "app.field.ascendingSlotTime"}):intl.formatMessage({id:"app.field.descendingSlotTime"})}
                    {/* {i.text} */}
                  </Option>
                ))}
              </Select>
            </div>
          </header>
          <section>
            <DelayedInput
              onChange={handleSearchChange}
              value={search}
              callback={handleSearch}
              delay={500}
              minLength={1}
              size="medium"
              prefixIcon={<SearchOutlined />}
              placeholder={intl.formatMessage({ id: "app.placeholder.search" })}
            />
          </section>
        </div>

        <OrdersTable
          isLoading={tableControls.isTableLoading}
          pickerFlow={props.pickerFlow}
          isEmpty={tableControls.isTableEmpty}
          offset={pagination.skip}
          data={tableControls.data}
          pagination={pagination}
          assignPicker={openAssignPickerModal}
          showOrderDetails={openOrderDetailsModal}
          acceptOrder={acceptOrder}
          showOrderHistory={openOrderHistoryModal}
          cancelOrder={openCancelOrderModal}
          editOrder={handleOrderEdit}
          markAsComplete={markAsComplete}
          sendForShipping={sendForShipping}
          handlePaginationChange={handlePaginationChange}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  hack: state.orders.timestamp,
  storeId: state.user.enterprice.storeId,
  branchId: state.user.userInfo.branchId,
  panelType: state.user.enterprice.panelType,
  pickerFlow: state.user.enterprice.pickerFlow,
});

export default connect(mapStateToProps)(Orders);
