import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { CustomModal, Panel, ErrorMessage } from "../../../components/common";
import { Button, Input, Select } from "antd";
import Validations from "../../../shared/validations/Validations";
import countryCodes from "../../../shared/constants/countries.json";
import sidebarData from "../../../shared/constants/sidebar-data.json";
import { toastr } from "react-redux-toastr";
const { Option } = Select;
const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  // formHeader: "add-edit-user-modal__",
};
function AddEditUser(props) {

  const [name, changeName] = useState(Validations.validateName(""));
  const [email, changeEmail] = useState(Validations.validateEmail(""));
  const [password, changePassword] = useState(Validations.validatePassword(""));
  const [phoneCountryCode, changePhoneCountryCode] = useState(
    Validations.validateTextField(undefined)
  );
  const [phoneNumber, changePhoneNumber] = useState(
    Validations.validatePhoneNumber("")
  );
  const [userType, changeUserType] = useState(
    Validations.validateTextField(undefined)
  );
  const [privilages, changePrivilages] = useState(
    Validations.validateMultiSelect([])
  );
  const [isPristine, changeIsPristine] = useState(true);

  const setForm = () => {
    changeName(Validations.validateName(props.data.name));
    changeEmail(Validations.validateEmail(props.data.email));
    // changePassword(Validations.validatePassword(props.data));
    changePhoneCountryCode(
      Validations.validateTextField(props.data.countryCode)
    );
    changePhoneNumber(Validations.validatePhoneNumber(props.data.phoneNo));
    changeIsPristine(true);
    if(props.data.isSuperAdmin === 1){
      changeUserType(Validations.validateTextField("superadmin"))
    }else{
      changeUserType(Validations.validateTextField("subadmin"));
    }
    const roles = props.data.adminroles.reduce((acc, val) => {
      if(val.status) {
        acc.push(val.menuId);
      }
      return acc;
    }, []);
    changePrivilages(Validations.validateMultiSelect(roles));
  };

  const resetForm = () => {
    changeName(Validations.validateName(""));
    changeEmail(Validations.validateEmail(""));
    changePassword(Validations.validatePassword(""));
    changePhoneCountryCode(Validations.validateTextField(undefined));
    changePhoneNumber(Validations.validatePhoneNumber(""));
    changeIsPristine(true);
    changeUserType(Validations.validateTextField(undefined));
    changePrivilages(Validations.validateMultiSelect([]));
  };

  useEffect(() => {
    if(props.isVisible) {
      if (props.modalToShow === "EDIT") {
        console.log(props.data);
        setForm();
      } else {
        resetForm();
      }
    }
  }, [props.isVisible]);

  const handleFieldChange = (e) => {
    const { value } = e.target;
    switch (e.target.name) {
      case "name":
        changeName(Validations.validateName(value));
        break;
      case "email":
        changeEmail(Validations.validateEmail(value));
        break;
      case "password":
        changePassword(Validations.validatePassword(value));
        break;
      case "phoneNumber":
        changePhoneNumber(Validations.validatePhoneNumber(value));
        break;
      default:
        return;
    }
  };

  const handleCountryCodeChange = (value) => {
    changePhoneCountryCode(Validations.validateTextField(value));
  };

  const handleUserTypeChange = (value) => {
    changeUserType(Validations.validateTextField(value));
  };

  const handlePrivilagesChange = (value) => {
    changePrivilages(Validations.validateMultiSelect(value));
  };
  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const addStatusToSidebarRoles = (lsRoles) => {
    lsRoles = lsRoles.reduce((acc, val) => {
      if (val.status) {
        acc.push(val.menuId);
      }
      return acc;
    }, []);
    return sidebarData.map((i) => {
      if (lsRoles.indexOf(i.menuId) !== -1) {
        i.status = 1;
      } else {
        i.status = 0;
      }
      return i;
    });
  };

  const getRoles = () => {
    let lsRoles = localStorage.getItem("trollyAdmin_roles");
    if (lsRoles) {
      lsRoles = JSON.parse(lsRoles);
    } else return [];
    return addStatusToSidebarRoles(lsRoles);
  };


  const closeModal = () => {
    props.onCloseIconClick();
  };

  const getRolesDataForSubmission = () => {
    const lsRoles = JSON.parse(localStorage.getItem("trollyAdmin_roles"));
    return lsRoles.reduce((acc, val) => {
      acc.push({
        menuId: val.menuId,
        status: privilages.value.indexOf(val.menuId) === -1 ? 0 : 1,
      });
      return acc;
    }, []);
  };

  const handleAdd = () => {
    console.log(getRolesDataForSubmission());
    const data = {
      name: name.value,
      phoneNo: phoneNumber.value,
      email: email.value,
      password: password.value,
      countryCode: phoneCountryCode.value,
      userType: userType.value,
      roles: getRolesDataForSubmission(),
    };

    props.addAdmin(data);
  };

  const handleEdit = () => {
    const data = {
      name: name.value,
      phoneNo: phoneNumber.value,
      email: email.value,
      countryCode: phoneCountryCode.value,
      userType: userType.value,
      roles: getRolesDataForSubmission(),
    };

    props.editAdmin(data);
  };

  const formAction = () => {
    changeIsPristine(false);
    if(!isFormValid()) {
      toastr.warning(props.intl.formatMessage({id: 'app.message.formCheck'}));
      return;
    }
    if (props.modalToShow === "ADD") {
      handleAdd();
    } else {
      handleEdit();
    }
  };

  const isFormValid = () => {
    if (props.modalToShow === "ADD") {
      return (
        name.isValid &&
        email.isValid &&
        password.isValid &&
        phoneCountryCode.isValid &&
        phoneNumber.isValid &&
        // userType.isValid &&
        privilages.isValid
      );
    } else {
      return (
        name.isValid &&
        email.isValid &&
        // password.isValid &&
        phoneCountryCode.isValid &&
        phoneNumber.isValid &&
        // userType.isValid &&
        privilages.isValid
      );
    }
  };

  const USER_TYPES = [
    {
      key: "superadmin",
      value: "superadmin",
      text: "Super Admin",
    },
    {
      key: "subadmin",
      value: "subadmin",
      text: "Sub-Admin",
    },
  ];

  return (
    <CustomModal
      size="tiny"
      onCloseIconClick={closeModal}
      header={
        props.modalToShow === "ADD"
        ? "Add Admin"
        : "Edit Admin"
      }
      isVisible={props.isVisible}
    >
      <Panel isLoading={props.isLoading} className={styles.formModal}>
        <div>
          <div style={{marginBottom: '1rem', fontSize: '1.1rem', fontWeight: 'bold'}}>
            <FormattedMessage id="app.field.details" />
          </div>
          <div>
            <div className={styles.formItem}>
              <label>
                <FormattedMessage id="app.field.name" />
              </label>
              <Input
                name="name"
                onChange={handleFieldChange}
                value={name.value}
                placeholder={props.intl.formatMessage({
                  id: "app.placeholder.name",
                })}
              />
              {!isPristine && <ErrorMessage field={name} />}
            </div>
            <div className={styles.formItem}>
              <label>
                <FormattedMessage id="app.field.email" />
              </label>
              <Input
                name="email"
                onChange={handleFieldChange}
                value={email.value}
                placeholder={props.intl.formatMessage({
                  id: "app.placeholder.email",
                })}
              />
              {!isPristine && <ErrorMessage field={email} />}
            </div>
            {props.modalToShow === "ADD" && (
              <div className={styles.formItem}>
                <label>
                  <FormattedMessage id="app.field.password" />
                </label>
                <Input
                  name="password"
                  onChange={handleFieldChange}
                  value={password.value}
                  type="password"
                  placeholder={props.intl.formatMessage({
                    id: "app.placeholder.password",
                  })}
                />
                {!isPristine && <ErrorMessage field={password} />}
              </div>
            )}
            <div>
              <label>
                <FormattedMessage id="app.field.phoneNumber" />
              </label>
              <div style={{ display: "flex" }}>
                <div className={styles.formItem}>
                  <Select
                    showSearch
                    style={{ marginRight: "2rem", width: "150px" }}
                    placeholder={props.intl.formatMessage({
                      id: "app.placeholder.countryCode",
                    })}
                    onChange={handleCountryCodeChange}
                    value={phoneCountryCode.value}
                    optionFilterProp="children"
                    filterOption={filterOption}
                  >
                    {countryCodes.map((item, index) => (
                      <Option
                        key={index}
                        value={item.dial_code}
                      >{`${item.dial_code}(${item.name})`}</Option>
                    ))}
                  </Select>
                  {!isPristine && <ErrorMessage field={phoneCountryCode} />}
                </div>
                <div className={styles.formItem} style={{ flex: 1 }}>
                  <Input
                    name="phoneNumber"
                    onChange={handleFieldChange}
                    value={phoneNumber.value}
                    placeholder={props.intl.formatMessage({
                      id: "app.placeholder.phoneNumber",
                    })}
                  />
                  {!isPristine && <ErrorMessage field={phoneNumber} />}
                </div>
              </div>
            </div>
            <div className="custom-form-item">
              <label>
                <FormattedMessage id="app.field.userType" />
              </label>
              <Select
                placeholder="Select User Type"
                onChange={handleUserTypeChange}
                value={userType.value}
              >
                {USER_TYPES.map((item, index) => (
                  <Option key={item.key} value={item.value}>
                    {item.text}
                  </Option>
                ))}
              </Select>
              {!isPristine && <ErrorMessage field={userType} />}
            </div>
            <div className="custom-form-item">
              <label>
                <FormattedMessage id="app.field.privilages" />
              </label>
              {/* <Dropdown
                fluid
                selection
                multiple
                options={userRoles}
                placeholder={props.intl.formatMessage({
                  id: "app.placeholder.privilages",
                })} */}
              <Select
                mode="multiple"
                optionFilterProp="children"
                filterOption={filterOption}
                placeholder={props.intl.formatMessage({
                  id: "app.placeholder.privilages",
                })}
                onChange={handlePrivilagesChange}
                value={privilages.value}
              >
                {getRoles().map((item) => (
                  <Option
                    disabled={item.status == 0}
                    key={item.menuId}
                    value={item.menuId}
                  >
                    {props.intl.formatMessage({ id: item.name })}
                  </Option>
                ))}
              </Select>
              {!isPristine && <ErrorMessage field={privilages} />}
            </div>
          </div>
          <div className={styles.formAction}>
            <Button type="default" onClick={closeModal}>
              Cancel
            </Button>
            <Button type="primary" onClick={formAction}>
              Submit
            </Button>
          </div>
        </div>
      </Panel>
    </CustomModal>
  );
}

export default injectIntl(AddEditUser);
