import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import classNames from "classnames";
import moment from "moment";

import { Input, Select, Button, DatePicker, Radio } from "antd";
import { ErrorMessage } from "../../../../components/common";
import Validations from "../../../../shared/validations/Validations";

import "./form.scss";

const styles = {
  wrapper: "order-reports__form__wrapper",
  content: "order-reports__form__content",
  formRadioItem: "custom-form-item--radio",
  formItem: "custom-form-item",
  formGroup: "custom-form-group",
  formAction: "custom-form-action",
  radioWrapper: "order-reports__form__radio-wrapper",
};
const { Option } = Select;

function Form(props) {
  const intl = useIntl();
  const [startDate, updateStartDate] = useState(
    Validations.validateStartEndDate(undefined, undefined).startDate
  );
  const [endDate, updateEndDate] = useState(
    Validations.validateStartEndDate(undefined, undefined).endDate
  );
  const [store, changeStore] = useState("");
  const [reportType, changeReportType] = useState(0);
  const [isPristine, toggleIsPristine] = useState(true);
  const handleStartDateChange = (date) => {
    const dates = Validations.validateStartEndDate(date, endDate.value);
    updateStartDate(dates.startDate);
    updateEndDate(dates.endDate);
  };

  const handleEndDateChange = (date) => {
    const dates = Validations.validateStartEndDate(startDate.value, date);
    updateStartDate(dates.startDate);
    updateEndDate(dates.endDate);
  };
  const isFormValid = () => {
    toggleIsPristine(false);
    return startDate.isValid && endDate.isValid;
  };
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };

  useEffect(() => {
    if (props.panelType != 0) {
      changeStore(props.branchId);
    }
  }, [props.branchId]);

  const getFormData = () => ({
    startDate: moment.utc(startDate.value).format("YYYY-MM-DD"),
    endDate: moment.utc(endDate.value).format("YYYY-MM-DD"),
    reportType: reportType,
    branchId: store,
  });
  const handleSubmit = () => {
    if (isFormValid()) {
      props.handleSubmit(getFormData());
    }
  };

  const handleReportTypeChange = (e) => {
    changeReportType(e.target.value);
  };
  console.log("reportType", reportType);
  return (
    <div className={styles.wrapper}>
      <div className={styles.formGroup}>
        <div className={styles.formItem}>
          <label>
            {intl.formatMessage({
              id: "app.field.startDate",
            })}
          </label>
          <DatePicker
            disabledDate={disabledDate}
            value={startDate.value}
            placeholder={intl.formatMessage({ id: "app.placeholder.date" })}
            onChange={handleStartDateChange}
          />
          {!isPristine && <ErrorMessage field={startDate} />}
        </div>
        <div className={styles.formItem}>
          <label>
            {intl.formatMessage({
              id: "app.field.endDate",
            })}
          </label>
          <DatePicker
            disabledDate={disabledDate}
            value={endDate.value}
            placeholder={intl.formatMessage({ id: "app.placeholder.date" })}
            onChange={handleEndDateChange}
          />
          {!isPristine && <ErrorMessage field={endDate} />}
        </div>
      </div>
      <div className={styles.formGroup}>
        {/* {props.panelType == 0 && (
          <div className={styles.formItem}>
            <label>
              {intl.formatMessage({
                id: "app.field.filterBy",
              })}
            </label>
            <Select
              placeholder={intl.formatMessage({
                id: "app.placeholder.store",
              })}
              onChange={changeStore}
              value={store}
            >
              <Option value="">
                {intl.formatMessage({ id: "app.field.allStores" })}
              </Option>
              {props.stores.map((store) => (
                <Option key={store.branchId} value={store.branchId}>
                  {store.branchName}
                </Option>
              ))}
            </Select>
          </div>
        )} */}
        <div className={styles.formItem}></div>
        <div className={styles.formItem}></div>
        <div className={classNames([styles.formItem, styles.formRadioItem])}>
          <label htmlFor="">
            {intl.formatMessage({
              id: "app.field.reportType",
            })}
          </label>
          <div className={styles.radioWrapper}>
            <Radio.Group
              onChange={handleReportTypeChange}
              value={reportType}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              {/* <Radio value={0}>
                {intl.formatMessage({
                  id: "app.field.dailyReport",
                })}
              </Radio> */}
              <Radio value={0}>
                {intl.formatMessage({
                  id: "app.field.weeklyReport",
                })}
              </Radio>
              <Radio value={1}>
                {intl.formatMessage({
                  id: "app.field.monthlyReport",
                })}
              </Radio>
            </Radio.Group>
          </div>
        </div>
      </div>
      <div className={styles.formAction}>
        <Button
          loading={props.isLoading}
          onClick={handleSubmit}
          type="primary"
          shape={undefined}
        >
          {" "}
          {intl.formatMessage({
            id: "app.button.submit",
          })}
        </Button>
      </div>
    </div>
  );
}

export default Form;
