import React, { useState, useEffect } from "react";
import { Input, Button, DatePicker, Select } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import moment from "moment";

import {
  CustomModal,
  Panel,
  ErrorMessage,
  UploadButton,
} from "../../../../components/common";
import axios from "../../../../shared/api/axiosConfig";
import Validations from "../../../../shared/validations/Validations";
import placeholderImg from "../../../../assets/images/item-placeholder.png";
import "./add-edit-promo-modal.scss";

const { Option } = Select;

const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formGroup: "custom-form-group",
  formAction: "custom-form-action",
  imageContainer: "active-promo__add-edit-promo-modal__image-container",
  imageContainerImage:
    "active-promo__add-edit-promo-modal__image-container__image",
  imageContainerForm:
    "active-promo__add-edit-promo-modal__image-container__form",
};

const errorText = "Field cannot be empty";

const promoTypes = [
  {
    value: "1",
    text: "Flat",
  },
  {
    value: "2",
    text: "Percentage",
  },
];

function AddEditPromoContainer(props) {
  const intl = useIntl();

  const [isPristine, togglePristine] = useState(true);
  const [startDate, updateStartDate] = useState(
    Validations.validateStartEndDate(undefined, undefined).startDate
  );
  const [endDate, updateEndDate] = useState(
    Validations.validateStartEndDate(undefined, undefined).endDate
  );
  const [promoCode, updatePromoCode] = useState(
    Validations.validateTextField("")
  );
  const [imageURL, changeImageURL] = useState(
    Validations.validateTextField("")
  );
  const [frequencyPerUser, updateFrequencyPerUser] = useState(
    Validations.validateNumericValue("")
  );
  const [typeOfPromo, updateTypeOfPromo] = useState(
    undefined
  );
  const [promoDiscount, updatePromoDiscount] = useState(
    Validations.validateNumericValue("")
  );
  const [numberIssued, updateNumberIssued] = useState(
    Validations.validateNumericValue("")
  );
  const [promoDescription, updatePromoDescription] = useState(
    Validations.validateTextField("")
  );
  const [store, changeStore] = useState(Validations.validateTextField(""));

  const [stores, updateStores] = useState({
    selected: undefined,
    isLoading: true,
    data: [],
    notFound: false,
  });

  const resetForm = () => {
    togglePristine(true);
    updateStores({
      ...stores,
      data: props.stores,
    });
    updateStartDate(
      Validations.validateStartEndDate(undefined, undefined).startDate
    );
    updateEndDate(
      Validations.validateStartEndDate(undefined, undefined).endDate
    );
    updatePromoCode(Validations.validateTextField(""));
    changeImageURL(Validations.validateTextField(""));
    updateFrequencyPerUser(Validations.validateNumericValue(""));
    updateNumberIssued(Validations.validateNumericValue(""));
    updateTypeOfPromo(undefined);
    changeStore(Validations.validateTextField(undefined));
    updatePromoDiscount(Validations.validateNumericValue(""));
    updatePromoDescription(Validations.validateTextField(""));
  };

  const setForm = () => {
    togglePristine(true);
    const dateData = Validations.validateStartEndDate(
      moment(props.data.startDate),
      moment(props.data.endDate)
    );
    updateStores({
      ...stores,
      data: props.stores,
    });
    updateStartDate(dateData.startDate);
    updateEndDate(dateData.endDate);
    updatePromoCode(Validations.validateTextField(props.data.code));
    changeImageURL(Validations.validateTextField(props.data.imageUrl));
    updateFrequencyPerUser(
      Validations.validateNumericValue(props.data.perUser)
    );
    updateNumberIssued(
      Validations.validateNumericValue(props.data.numberIssued)
    );
    updateTypeOfPromo(props.data.promoType.toString());
    updatePromoDiscount(Validations.validateNumericValue(props.data.credits));
    updatePromoDescription(
      Validations.validateTextField(props.data.description)
    );
    changeStore(Validations.validateTextField(props.data.branchId));
  };
  useEffect(() => {
    if (!props.isVisible) return;
    if (props.modalToShow === "EDIT") {
      setForm();
      //   setForm();
    } else if (props.modalToShow === "ADD") {
      resetForm();
    }
  }, [props.isVisible]);

  // useEffect(() => {
  //   axios
  //     .get("/getBranches", {
  //       params: {
  //         onlyNames: 1,
  //       }
  //     })
  //     .then((res) => {
  //       if (res.data.data.branchesData.length) {
  //         updateStores({
  //           selected: res.data.data.branchesData[0].branchId,
  //           data: res.data.data.branchesData,
  //           isLoading: false,
  //           notFound: false,
  //           count: res.data.data.branchCount,
  //         });
  //       } else {
  //         updateStores({
  //           ...stores,
  //           notFound: true,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
  //     });
  // }, []);

  const getData = () => ({
    startDate: moment(startDate.value).format("YYYY-MM-DD"),
    endDate: moment(endDate.value).format("YYYY-MM-DD"),
    code: promoCode.value,
    imageUrl: imageURL.value,
    perUser: frequencyPerUser.value,
    numberIssued: numberIssued.value,
    promoType: typeOfPromo,
    description: promoDescription.value,
    credits: promoDiscount.value,
    branchId: store.value,
  });
  const handleAdd = () => {
    // TODO
    props.addPromo(getData());
  };

  const handleEdit = () => {
    // TODO
    props.editPromo(getData());
  };

  const handleStartDateChange = (date) => {
    const dates = Validations.validateStartEndDate(date, endDate.value);
    updateStartDate(dates.startDate);
    updateEndDate(dates.endDate);
  };

  const handleEndDateChange = (date) => {
    const dates = Validations.validateStartEndDate(startDate.value, date);
    updateStartDate(dates.startDate);
    updateEndDate(dates.endDate);
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "promoCode":
        updatePromoCode(Validations.validateTextField(value));
        break;
      case "frequencyPerUser":
        updateFrequencyPerUser(Validations.validateNumericValue(value));
        break;
      case "numberIssued":
        updateNumberIssued(Validations.validateNumericValue(value));
        break;
      case "promoDiscount":
        updatePromoDiscount(Validations.validateNumericValue(value));
        break;
      case "promoDescription":
        updatePromoDescription(Validations.validateTextField(value));
        break;
      default:
        return;
    }
  };

  const handleTypeOfPromoChange = (type) => {
    updateTypeOfPromo(type);
  };

  const handleStoreChange = (selected) => {
    updateStores({
      ...stores,
      selected,
    });
    changeStore(Validations.validateTextField(selected));
  };

  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const isFormValid = () => {
    return (
      imageURL.isValid &&
      startDate.isValid &&
      endDate.isValid &&
      store.isValid &&
      promoCode.isValid &&
      frequencyPerUser.isValid &&
      numberIssued.isValid &&
      typeOfPromo &&
      promoDiscount.isValid &&
      promoDescription.isValid
    );
  };

  const closeModal = () => {
    props.onCloseIconClick();
  };

  const formAction = () => {
    togglePristine(false);
    if (!isFormValid()) {
      return;
    }
    if (props.modalToShow === "ADD") {
      handleAdd();
    } else if (props.modalToShow === "EDIT") {
      handleEdit();
    }
  };

  const handleImageChange = (image) => {
    changeImageURL(Validations.validateTextField(image));
  };
  console.log("renderrr", startDate, endDate);
  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={closeModal}
      header={
        props.modalToShow === "ADD"
          ? "app.pageTitle.addPromo"
          : "app.pageTitle.editPromo"
      }
      isVisible={props.isVisible}
    >
      <Panel className={styles.formModal} isLoading={props.isLoading}>
        <div className={styles.imageContainer}>
          <div className={styles.imageContainerImage}>
            <img
              src={imageURL.value ? imageURL.value : placeholderImg}
              alt=""
            />
            <UploadButton
              text="Upload Image"
              resultType="url"
              accept="image/*"
              onChange={handleImageChange}
            />
            {!isPristine && <ErrorMessage field={imageURL} />}
          </div>

          <div className={styles.imageContainerForm}>
            <div className={styles.formItem}>
              <label>
                {intl.formatMessage({
                  id: "app.field.startDate",
                })}
              </label>
              <DatePicker
                value={startDate.value}
                onChange={handleStartDateChange}
              />
              {!isPristine && <ErrorMessage field={startDate} />}
            </div>
            <div className={styles.formItem}>
              <label>
                {intl.formatMessage({
                  id: "app.field.endDate",
                })}
              </label>
              <DatePicker
                value={endDate.value}
                onChange={handleEndDateChange}
              />
              {!isPristine && <ErrorMessage field={endDate} />}
            </div>
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.formItem}>
            <label>{intl.formatMessage({ id: "app.field.promoCode" })}</label>
            <Input
              name="promoCode"
              onChange={handleFieldChange}
              value={promoCode.value}
              placeholder={intl.formatMessage({
                id: "app.placeholder.promoCode",
              })}
            />
            {!isPristine && <ErrorMessage field={promoCode} />}
          </div>
          <div className={styles.formItem}>
            <label>
              {intl.formatMessage({ id: "app.field.frequencyPerUser" })}
            </label>
            <Input
              name="frequencyPerUser"
              onChange={handleFieldChange}
              value={frequencyPerUser.value}
              placeholder={intl.formatMessage({
                id: "app.placeholder.frequencyPerUser",
              })}
            />
            {!isPristine && <ErrorMessage field={frequencyPerUser} />}
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.formItem}>
            <label>{intl.formatMessage({ id: "app.field.typeOfPromo" })}</label>
            <Select
              onChange={handleTypeOfPromoChange}
              placeholder={intl.formatMessage({
                id: "app.placeholder.typeOfPromo",
              })}
              value={typeOfPromo}
            >
              {promoTypes.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.text}
                </Option>
              ))}
            </Select>
            {!isPristine && !typeOfPromo && <ErrorMessage text={errorText} />}
          </div>
          <div className={styles.formItem}>
            <label>
              {intl.formatMessage({ id: "app.field.promoDiscount" })}
            </label>
            <Input
              name="promoDiscount"
              onChange={handleFieldChange}
              value={promoDiscount.value}
              placeholder={intl.formatMessage({
                id: "app.placeholder.promoDiscount",
              })}
            />
            {!isPristine && <ErrorMessage field={promoDiscount} />}
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.formItem}>
            <label>{intl.formatMessage({ id: "app.field.totalCount" })}</label>
            <Input
              name="numberIssued"
              onChange={handleFieldChange}
              value={numberIssued.value}
              placeholder={intl.formatMessage({
                id: "app.placeholder.totalCount",
              })}
            />
            {!isPristine && <ErrorMessage field={numberIssued} />}
          </div>
          <div className={styles.formItem}>
            <label>
              {intl.formatMessage({ id: "app.field.promoDescription" })}
            </label>
            <Input
              name="promoDescription"
              onChange={handleFieldChange}
              value={promoDescription.value}
              placeholder={intl.formatMessage({
                id: "app.placeholder.promoDescription",
              })}
            />
            {!isPristine && <ErrorMessage field={promoDescription} />}
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.formItem}>
            <label>
              <FormattedMessage id="app.field.storeName" />
            </label>
            <Select
              showSearch
              placeholder={intl.formatMessage({
                id: "app.placeholder.store",
              })}
              onChange={handleStoreChange}
              value={store.value}
              optionFilterProp="children"
              filterOption={filterOption}
            >
              {stores.data.map((store) => (
                <Option key={store.branchId} value={store.branchId}>
                  {store.branchName}
                </Option>
              ))}
            </Select>
            {!isPristine && (
                  <ErrorMessage field={store} />
          )}
          </div>
        </div>
        <div className={styles.formAction}>
          <Button type="default" shape="" onClick={closeModal}>
            {intl.formatMessage({ id: "app.button.cancel" })}
          </Button>
          <Button type="primary" shape="" onClick={formAction}>
            {intl.formatMessage({ id: "app.button.submit" })}
          </Button>
        </div>
      </Panel>
    </CustomModal>
  );
}

export default AddEditPromoContainer;
