import React, { useState, useEffect } from "react";
import moment from "moment";
import { MoreOutlined } from "@ant-design/icons";
import classNames from "classnames";
import { Popconfirm, Menu, Dropdown, Tooltip, Tag } from "antd";
import { Panel, Icon, CustomTable } from "../../../../components/common";
import { useIntl } from "react-intl";
import { numberWithCommas } from "../../../../shared/utils/object-manipulation";
import {
  ORDER_STATUS,
  ORDER_STATUS_MAPPING,
  PICKER_STATUS,
  PAYMENT_METHODS_MAPPING,
} from "../../../../shared/constants/orders";
import "./orders-table.scss";

const styles = {
  tableActions: "custom-table__table-actions",
  actionIcon: "custom-table__action-icon",
  table: "custom-table",
  wrapper: "orders-table__wrapper",
  stickyAction: "sticky-action",
};

const badgeMapping = {
  0: "New",
  1: "Bronze",
  2: "Silver",
  3: "Gold",
  4: "Platinum",
};
const showEditIcon = (item) => {
  return (
    (item.status === ORDER_STATUS.ACCEPTED ||
      item.status === ORDER_STATUS.ASSIGNED ||
      item.status === ORDER_STATUS.REQUESTED ||
      item.status === ORDER_STATUS.PARTIAL_REQUESTED) &&
    (item.pickerJobStatus === PICKER_STATUS.JOB_NOT_ASSIGNED ||
      item.pickerJobStatus === PICKER_STATUS.ACCEPTED)
  );
};
const partial = [ORDER_STATUS.ACCEPTED];
const resolveStatus = (item) => {
  if (partial.indexOf(item.status) !== -1) {
    if (item.pickerJobStatus == PICKER_STATUS.ACCEPTED) {
      return "Being Picked";
    }

    if (item.pickerJobStatus == PICKER_STATUS.PICKED) {
      return "Ready For Delivery";
    }
  }
  return ORDER_STATUS_MAPPING[item.status];
};

const showAssignPickerIcon = (item) => {
  return (
    item.status === ORDER_STATUS.ACCEPTED &&
    (item.pickerJobStatus === PICKER_STATUS.JOB_NOT_ASSIGNED ||
      item.pickerJobStatus === PICKER_STATUS.REQUESTED)
  );
};

const showCancelIcon = (item) => {
  return (
    (item.status === ORDER_STATUS.ACCEPTED ||
      item.status === ORDER_STATUS.ASSIGNED ||
      item.status === ORDER_STATUS.REQUESTED ||
      item.status === ORDER_STATUS.PARTIAL_REQUESTED) &&
    (item.pickerJobStatus === PICKER_STATUS.JOB_NOT_ASSIGNED ||
      item.pickerJobStatus === PICKER_STATUS.REQUESTED)
  );
};

const showSendForShippingIcon = (item) => {
  return (
    (item.status === ORDER_STATUS.ACCEPTED ||
      item.status === ORDER_STATUS.ASSIGNED) &&
    item.pickerJobStatus === PICKER_STATUS.PICKED
  );
};

const showMarkAsCompleteIcon = (item) => {
  return (
    (item.status === ORDER_STATUS.REACHED ||
      item.status === ORDER_STATUS.ON_THE_WAY) &&
    item.pickerJobStatus === PICKER_STATUS.PICKED
  );
};

function OrdersTable(props) {
  const intl = useIntl();

  const getMenu = (item) => {
    const menu = (
      <Menu className="menuWrappper">
        <Menu.Item>
          {" "}
          <span
            style={{ margin: 0, display: "flex", alignItems: "center" }}
            onClick={() => {
              props.showOrderHistory(item);
            }}
            className={styles.actionIcon}
          >
            <Icon name="HISTORY" size="small" />
            {intl.formatMessage({ id: "app.field.history" })}
          </span>
        </Menu.Item>
        <Menu.Item>
          {" "}
          <span
            style={{ margin: 0, display: "flex", alignItems: "center" }}
            onClick={() => {
              props.showOrderDetails(item);
            }}
            className={styles.actionIcon}
          >
            <Icon name="DETAILS" size="small" />
            {intl.formatMessage({ id: "app.field.details" })}
          </span>
        </Menu.Item>
        {showEditIcon(item) && (
          <Menu.Item>
            <span
              style={{ margin: 0, display: "flex", alignItems: "center" }}
              onClick={() => {
                props.editOrder(item);
              }}
              className={styles.actionIcon}
            >
              <Icon name="PEN" size="small" />
              {intl.formatMessage({ id: "app.field.edit" })}
            </span>
          </Menu.Item>
        )}
        {showAssignPickerIcon(item) && (
          <Menu.Item
            title={intl.formatMessage({ id: "app.field.assignPicker" })}
            placement="topRight"
          >
            <span
              onClick={() => {
                props.assignPicker(item);
              }}
              style={{ margin: 0, display: "flex", alignItems: "center" }}
              className={styles.actionIcon}
            >
              <Icon name="USER" size="small" />
              {intl.formatMessage({ id: "app.field.assignPicker" })}
            </span>
          </Menu.Item>
        )}
        {/* item.statusType === "ACCEPTED" &&
        item.pickerJobStatus === -1 &&  */}
        {showCancelIcon(item) && (
          <Menu.Item
            title={intl.formatMessage({ id: "app.field.cancelOrder" })}
            placement="topRight"
          >
            <span
              onClick={() => {
                props.cancelOrder(item);
              }}
              style={{ margin: 0, display: "flex", alignItems: "center" }}
              className={styles.actionIcon}
            >
              <Icon name="NOT_ALLOWED" size="small" />
              {intl.formatMessage({ id: "app.button.cancel" })}
            </span>
          </Menu.Item>
        )}
        {/* item.statusType === "ACCEPTED" &&
        item.pickerJobStatus === 3 &&  */}
        {showSendForShippingIcon(item) && (
          <Menu.Item
            title={intl.formatMessage({ id: "app.field.sendForShipping" })}
            placement="topRight"
          >
            {/* <Popconfirm
            title={intl.formatMessage({
              id: "app.message.sendForShipping",
            })}
            onConfirm={() => {
              props.sendForShipping(item);
            }}
            placement="topRight"
            okText="Yes"
            cancelText="No"
          > */}
            <span
              onClick={() => {
                props.sendForShipping(item);
              }}
              className={styles.actionIcon}
              style={{ margin: 0, display: "flex", alignItems: "center" }}
            >
              <Icon name="CARGO_TRUCK" size="small" />
              {intl.formatMessage({ id: "app.field.deliver" })}
            </span>
            {/* </Popconfirm> */}
          </Menu.Item>
        )}
        {/* item.statusType === "Shipped" && item.pickerJobStatus === 3 &&  */}
        {showMarkAsCompleteIcon(item) && (
          <Menu.Item
            title={intl.formatMessage({ id: "app.field.markAsComplete" })}
            placement="topRight"
          >
            {/* <Popconfirm
            title={intl.formatMessage({
              id: "app.message.markOrderComplete",
            })}
            onConfirm={() => {
              
            }}
            placement="topRight"
            okText="Yes"
            cancelText="No"
          > */}
            <span
              onClick={() => {
                props.markAsComplete(item);
              }}
              style={{ margin: 0, display: "flex", alignItems: "center" }}
              className={styles.actionIcon}
            >
              <Icon name="TASK_COMPLETE" size="small" />
              {intl.formatMessage({ id: "app.field.finish" })}
            </span>
            {/* </Popconfirm> */}
          </Menu.Item>
        )}
      </Menu>
    );

    return menu;
  };

  // const getActions = (item) => {
  //   return <><Tooltip title="Order History" placement="topRight">
  //   <span
  //     onClick={() => {
  //       props.showOrderHistory(item);
  //     }}
  //     className={styles.actionIcon}
  //   >
  //     <Icon name="HISTORY" size="small" />
  //   </span>
  // </Tooltip>
  // <Tooltip title="Order Details" placement="topRight">
  //   <span
  //     onClick={() => {
  //       props.showOrderDetails(item);
  //     }}
  //     className={styles.actionIcon}
  //   >
  //     <Icon name="DETAILS" size="small" />
  //   </span>
  // </Tooltip>
  // {/* item.statusType === "ACCEPTED" &&
  //   item.pickerJobStatus === 1 && */}
  // {showEditIcon(item) && (
  //   <Tooltip title="Edit" placement="topRight">
  //     <span
  //       onClick={() => {
  //         props.editOrder(item);
  //       }}
  //       className={styles.actionIcon}
  //     >
  //       <Icon name="PEN" size="small" />
  //     </span>
  //   </Tooltip>
  // )}
  // {/* item.statusType === "ACCEPTED" &&
  //   item.pickerJobStatus === -1 && */}
  // {showAssignPickerIcon(item) && (
  //   <Tooltip title="Assign Picker" placement="topRight">
  //     <span
  //       onClick={() => {
  //         props.assignPicker(item);
  //       }}
  //       className={styles.actionIcon}
  //     >
  //       <Icon name="USER" size="small" />
  //     </span>
  //   </Tooltip>
  // )}
  // {/* item.statusType === "ACCEPTED" &&
  //   item.pickerJobStatus === -1 &&  */}
  // {showCancelIcon(item) && (
  //   <Tooltip title="Cancel Order" placement="topRight">
  //     <span
  //       onClick={() => {
  //         props.cancelOrder(item);
  //       }}
  //       className={styles.actionIcon}
  //     >
  //       <Icon name="NOT_ALLOWED" size="small" />
  //     </span>
  //   </Tooltip>
  // )}
  // {/* item.statusType === "ACCEPTED" &&
  //   item.pickerJobStatus === 3 &&  */}
  // {showSendForShippingIcon(item) && (
  //   <Tooltip title="Send for Shipping" placement="topRight">
  //     <Popconfirm
  //       title={intl.formatMessage({
  //         id: "app.message.sendForShipping",
  //       })}
  //       onConfirm={() => {
  //         props.sendForShipping(item);
  //       }}
  //       placement="topRight"
  //       okText="Yes"
  //       cancelText="No"
  //     >
  //       <span className={styles.actionIcon}>
  //         <Icon name="CARGO_TRUCK" size="small" />
  //       </span>
  //     </Popconfirm>
  //   </Tooltip>
  // )}
  // {/* item.statusType === "Shipped" && item.pickerJobStatus === 3 &&  */}
  // {showMarkAsCompleteIcon(item) && (
  //   <Tooltip title="Mark as Complete" placement="topRight">
  //     <Popconfirm
  //       title={intl.formatMessage({
  //         id: "app.message.markOrderComplete",
  //       })}
  //       onConfirm={() => {
  //         props.markAsComplete(item);
  //       }}
  //       placement="topRight"
  //       okText="Yes"
  //       cancelText="No"
  //     >
  //       <span className={styles.actionIcon}>
  //         <Icon name="TASK_COMPLETE" size="small" />
  //       </span>
  //     </Popconfirm>
  //   </Tooltip>
  // )}
  // </>
  // }

  const getActions = (item) => {
    return (
      <Dropdown overlay={getMenu(item)}>
        <td className={styles.stickyAction}>
          <MoreOutlined />
        </td>
      </Dropdown>
    );
  };

  const columns = [
    {
      fixed: "left",
      dataIndex: "bookingId",
      title: intl.formatMessage({ id: "app.field.bookingNumber" }),
      render: (e, item, index) => {
        return (
          <div>
            <span>{item.bookingId}</span>
            {item.isHighlighted === 1 ? <p className="asap">ASAP</p> : null}
          </div>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "app.field.slotTime" }),
      render: (item) => `${item.bookingSlotStartTime} -
    ${item.bookingSlotEndTime}`,
    },
    // {
    //   dataIndex: "",
    //   title: intl.formatMessage({ id: "app.pageTitle.customerDetails" }),
    //   render: (e, item) => {
    //     return(
    //     <div>
    //         <Tooltip
    //           title={`${item.customer.customerName}: ${
    //             badgeMapping[item.badgeType]
    //           }`}
    //         >
    //           <Tag
    //             className="break"
    //             style={{
    //               backgroundColor: item.badgeColor,
    //               borderColor: item.badgeColor,
    //               padding: "0.25rem 0.5rem",
    //               color: "#fff",
    //               whiteSpace: "pre-wrap",
    //             }}
    //           >
    //             <span
    //               className="STRONKE"
    //               style={{ cursor: "pointer" }}
    //               onClick={() => {
    //                 props.customerDetails(item.customer);
    //               }}
    //             >
    //               {item.customer.customerName}
    //             </span>
    //           </Tag>
    //         </Tooltip>
    //       <div style={{marginTop:"5px"}}>
    //         {item.customer.phoneNo}
    //       </div>
    //       <div style={{marginTop:"10px"}}>
    //       <span
    //       onClick={() => {
    //         props.showLocation(item.address);
    //       }}
    //       className={styles.actionIcon}
    //     >
    //       {item.address.address}
    //     </span>
    //       </div>
    //     </div>
    //   )}
    // },

    {
      // fixed: "left",
      dataIndex: "customerName",
      title: intl.formatMessage({ id: "app.field.customerName" }),
      render: (e, item) => {
        return item.customer.customerName;
        return      <Tooltip
        title={`${item.customer.customerName}: ${
          badgeMapping[item.badgeType]
        }`}
      >
        <Tag
          className="break"
          style={{
            backgroundColor: item.badgeColor,
            borderColor: item.badgeColor,
            padding: "0.25rem 0.5rem",
            color: "#fff",
            whiteSpace: "pre-wrap",
          }}
        >
          <span
            className="STRONKE"
            style={{ cursor: "pointer" }}
            onClick={() => {
              props.customerDetails(item.customer);
            }}
          >
            {item.customer.customerName}
          </span>
        </Tag>
      </Tooltip>
      },
    },
    {
      dataIndex: "customer",
      title: intl.formatMessage({ id: "app.field.customerPhone" }),
      render: (item) => item.phoneNo,
    },
    {
      dataIndex: "address",
      title: intl.formatMessage({ id: "app.field.customerAddress" }),
      render: (item) => (
        <span
          className={styles.actionIcon}
        >
          {item.address}
        </span>
      ),
    },
    {
      dataIndex: "address",
      title: intl.formatMessage({ id: "app.field.landmark" }),
      render: (item) => {
        // debugger;
        return item.landMark ? item.landMark : "---"
      },
    },
    {
      dataIndex: "description",
      title: intl.formatMessage({ id: "app.field.customerNotes" }),
      render: (item) => { return item ? item: "N/A"}
    },
    {
      dataIndex: "branch",
      title: intl.formatMessage({ id: "app.field.storeName" }),
      render: (item) => item.branchName || "---",
    },
    {
      dataIndex: "picker",
      title: intl.formatMessage({ id: "app.field.picker" }),
      filterOut: !props.pickerFlow,
      render: (item) => (item?.name ? item?.name : "---"),
    },
    {
      dataIndex: "overAllTotal",
      title: intl.formatMessage({ id: "app.field.totalAmount"}),
      // render: (item) => {
      //   return(
      //     <span>{numberWithCommas(item.overAllTotal)} <Tag color={"green"}>
      //     {PAYMENT_METHODS_MAPPING[item.modeOfPaymentStatus]}
      //   </Tag></span>
      //   )
      // }
    },
    {
      dataIndex: "modeOfPaymentStatus",
      title: intl.formatMessage({ id: "app.field.paymentMode" }),
      render: (item) =>{return(<Tag color={"green"}>
        {PAYMENT_METHODS_MAPPING[item]}
      </Tag>)}
      // render: (item) => PAYMENT_METHODS_MAPPING[item],
    },
    {
      dataIndex: "overAllTotal",
      title: intl.formatMessage({ id: "app.field.totalAmount" }),
      render: (item) => numberWithCommas(item),
    },
    {
      dataIndex: "productsCount",
      title: intl.formatMessage({ id: "app.field.itemCount" }),
      // render: (item) => ((PAYMENT_METHODS_MAPPING[item])),
    },
    // {
    //   dataIndex: "createdAt",
    //   title: intl.formatMessage({ id: "app.field.createdAt" }),
    //   render: (item) => moment(item).format("HH:mm MMMM Do YYYY"),
    // },

    {
      dataIndex: "bookingDateTime",
      title: intl.formatMessage({ id: "app.field.bookingTime" }),
      render: (item) => moment(item).format("HH:mm MMMM Do YYYY"),
    },
    {
      dataIndex: "createdAt",
      title: intl.formatMessage({ id: "app.field.acceptTime" }),
      render: (item) => item ? moment(item).format("HH:mm MMMM Do YYYY") : "---",
    },
    {
      dataIndex: "status",
      title: intl.formatMessage({ id: "app.field.status" }),
      render: (p, item) => {
        return resolveStatus(item);
      },
    },
    // {
    //   dataIndex: "",
    //   title: intl.formatMessage({ id: "app.field.deviceDetails" }),
    //   render: (item) => {
    //     return (<span>{item.deviceType} ({item.appVersion})</span>)
    //   }
    // },
    {
      dataIndex: "appVersion",
      title: intl.formatMessage({ id: "app.field.appVersion"}),
    },
    {
      dataIndex: "deviceType",
      title: intl.formatMessage({ id: "app.field.deviceType"}),
    },
    {
      title: intl.formatMessage({ id: "app.field.action" }),
      fixed: "right",
      render: (item) => {
        return (
          <Dropdown overlay={getMenu(item)}>
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ].filter((i) => (i.filterOut ? false : true));

  return (
    <CustomTable
      columns={columns}
      pagination={props.pagination}
      dataSource={props.data}
      onChange={props.handlePaginationChange}
      totalRecords={props.totalRecords}
      isLoading={props.isLoading}
    />
  );
}

export default OrdersTable;
