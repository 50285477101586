
import React, { useEffect,useState } from "react";
import {Tooltip, Select} from 'antd';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import {useIntl} from "react-intl";
import {LANGUAGES} from '../../shared/constants/dropdown';
import classNames from "classnames";
import Validations from "../../shared/validations/Validations";
import modalActions from '../../redux/modal/modal-actions';
import userActions from '../../redux/user-info/user-actions';
import {Icon} from '../common/';
import {
  removeEmptyKeys,
  nestedParseJSON,
} from "../../shared/utils/object-manipulation";
import axios from "../../shared/api/axiosConfig";
import placeholderImg from "../../assets/images/profile-placeholder.png";
import "./navbar.scss";
// import cities from "../../containers/cities/cities";
import { checkRtl } from "../../shared/utils/rtl";

const { Option } = Select;

const styles = {
  wrapper: "custom-navbar__wrapper",
  open: "custom-navbar__wrapper--open",
  closed: "custom-navbar__wrapper--closed",
  contentContainer: "custom-navbar__content-container",
  sidebarToggleButton: "custom-navbar__sidebar-toggle-button",
  navbarLeft: "custom-navbar__left-items-container",
  navbarRight: "custom-navbar__right-items-container",
  profileWrapper: "custom-navbar__right-items-container__profile-wrapper",
  profileInfo: "custom-navbar__right-items-container__profile-info",
  avatar: "custom-navbar__right-items-container__avatar",
  name: "custom-navbar__right-items-container__name",
  adminType: "custom-navbar__right-items-container__admin-type",
};

const Navbar = (props) => {
  const intl = useIntl();

  const [countries, updateCountries] = useState({
    isLoading: false,
    data: [],
    selected: undefined,
    count: undefined,
    isEmpty: false,
  })

  const [cities, updateCities] = useState({
    isLoading: false,
    data: [],
    selected: undefined,
    count: 0,
    isEmpty: false,
  });

  const [city, changeCity] = useState(Validations.validateMultiSelect([]));

  const getSidebarTriggerIcon = () => {
    return <Icon name="CATEGORY" />;
  };
  const handleSidebarToggle = () => {
    props.toggleSidebar();
  };
  const handleLanguageChange = (e, data) => {
    props.dispatch(userActions.changeLanguage(data.value))
  }

  const handleCountryChange = (country) => {
    props.dispatch(userActions.changeCountry(country))
    updateCountries({
      ...countries,
      selected: country,
    });
    props.dispatch(userActions.changeCity(null));
    updateCities({
      ...cities,
      isLoading: true,
      isEmpty: false,
    });
  };

  const getLanguages = () => {
    // if(countries.selected || countries.selected === 0 || props.panelType === 1 || 
    //   props.selectedCountry !== null && props.selectedCountry !== undefined && props.selectedCountry !== "") {
    //   if( props.panelType === 1 && props.selectedCountry == undefined){
    //     return;
    //   }
    //   axios.get('/getAllLanguages', {
    //     params: {
    //       countryId: props.panelType === 1 ? props.selectedCountry : countries.selected
    //     }
    //   }).then((res) => {
    //     const data = res.data.data.countries;
    //     props.dispatch(userActions.setLanguageData(data));
    //   }).catch((err) => {

    //   })
    // }
  }

  const handleCityChange = (item) => {
    props.dispatch(userActions.changeCity(item));
    changeCity(Validations.validateMultiSelect(item));
    // updateCities({
    //   ...cities,
    //   selected: city,
    //   isLoading: true,
    // });
  }

  // useEffect(() => {
  //   if(city.value !== null || city.value !== undefined){
  //     props.dispatch(userActions.changeCity(city.value));
  //     updateCities({
  //       ...cities,
  //       isLoading: false,
  //     });
  //   }
  // },[city.value])

  // useEffect(() => {
  //    if(props.panelType === 0 && Object.keys(props.userInfo).length !== 0 && props.userInfo !== null && props.userInfo !== undefined) {
  //     props.dispatch(userActions.changeCountry(props.userInfo.enabledCountries[0].countryId));
  //     updateCountries({
  //       ...countries,
  //       data: props.userInfo.enabledCountries,
  //       selected: props.userInfo.enabledCountries[0].countryId,
  //     });
  //     if(props.userInfo.masterAdminId == 1){
  //       let assigned = JSON.parse(props.citiesAssigned);
  //       props.dispatch(userActions.changeCity([assigned[0]]));
  //       changeCity(Validations.validateMultiSelect(assigned[0]));
  //     }
  //   }
  // },[props.userInfo]);

  // useEffect(() => {
  //   if(countries.selected && props.panelType === 0){
  //     getCities();
  //   }
  // },[countries.selected,props.hack]);

  // useEffect(() => {
  //   getLanguages();
  // }, [countries.selected,props.selectedCountry])

  const handleLogout = () => {
    props.dispatch(modalActions.showModal('LOGOUT'))
  }

    return (
      <div
        dir={props.isRTL ? "rtl" : "ltr"}
        className={classNames({
          [styles.wrapper]: true,
          [styles.open]: props.isSidebarExpanded,
          [styles.closed]: !props.isSidebarExpanded,
        })}
      >
        <div
          dir={props.isRTL ? "rtl" : "ltr"}
          className={styles.contentContainer}
        >
          <div
            dir={props.isRTL ? "rtl" : "ltr"}
            className={styles.navbarLeft}
          >

            {/* <label>Profile</label> */}

            {/* <button
              className={styles.sidebarToggleButton}
              onClick={handleSidebarToggle}
            >
              {getSidebarTriggerIcon()}
            </button> */}
          </div>

          <div
            dir={props.isRTL ? "rtl" : "ltr"}
            className={styles.navbarRight}
          >
 
            <div
              dir={props.isRTL ? "rtl" : "ltr"}
              className={styles.profileWrapper}
            >
            
              <div
                dir={props.isRTL ? "rtl" : "ltr"}
                className={styles.profileInfo}
              >
                <div
                  dir={props.isRTL ? "rtl" : "ltr"}
                  className={styles.name}
                >
                  {props.userInfo.name || props.userInfo.branchName}
                </div>
                {/* <div className={styles.adminType}>{props.userInfo.userType}</div> */}
              </div>
            </div>
            <Tooltip title={intl.formatMessage({ id: "app.button.logout" })} placement="bottomLeft">
              <span>
                <Icon name="POWER" pointer={1} onClick={handleLogout} />
              </span>
            </Tooltip>
          </div>
        </div>

      </div>
    );

}

// const pp = () => (
//   <div className={classNames({[styles.wrapper]: true, [styles.open]: props.isSidebarExpanded, [styles.closed]: !props.isSidebarExpanded})}>
//     <div className={styles.contentContainer}>
//       <div className={styles.navbarLeft}>
//         {/* <button
//           className={styles.sidebarToggleButton}
//           onClick={handleSidebarToggle}
//         >
//           {getSidebarTriggerIcon()}
//         </button> */}
//       </div>
//       <div className={styles.navbarRight}>
//       {props.panelType == 0 &&
//               props.enabledVerticals.length > 1 && (
//                 <Select
//                   value={props.userInfo.selectedVerticalType}
//                   style={{ minWidth:"190px" }}
//                   size="small"
//                   onChange={handleLanguageChange}
//                 >
//                   {props.enabledVerticals.map((vert) => (
//                     <Option
//                       key={verticles[vert].key}
//                       value={verticles[vert].value}
//                     >
//                       {verticles[vert].text}
//                     </Option>
//                   ))}
//                 </Select>
//               )}
//       {/* <Dropdown
//           options={LANGUAGES}
//           selection
//           compact
//           defaultValue={props.language}
//           onChange={handleLanguageChange}
//         /> */}
//         {/* <Select value={props.language} onChange={handleLanguageChange}>
//           {
//             LANGUAGES.map((lang) => (
//               <Option key={lang.key} value={lang.value}>
//                 {lang.text}
//                 </Option>
//             ))
//           }
//           </Select> */}
//         <div className={styles.profileWrapper}>
//           {/* <div className={styles.avatar}>
//             <img src={placeholderImg} alt=""/>
//           </div> */}
//           <div className={styles.profileInfo}>
//       <div className={styles.name}>{props.userInfo.name}</div>
//       {/* <div className={styles.adminType}>{props.userInfo.userType}</div> */}
//           </div>
//           </div>
//           <Tooltip title="Logout" placement="bottomLeft">
//             <span>
//           <Icon name="POWER" onClick={handleLogout} />
//           </span>
//           </Tooltip>
//       </div>
//     </div>
//   </div>
// );

const mapStateToProps = (state) => ({
  language: state.user.locale,
  userInfo: state.user.userInfo,
  // hack: state.cities.timestamp,
  // citiesAssigned: state.user.userInfo.cityIds,
  panelType: state.user.enterprice.panelType || 0,
  isRTL: checkRtl(state.user.locale),
  verticalData: {},
  selectedCountry: state.user.userInfo.countryId,
  enabledVerticals: [0],
});

export default connect(mapStateToProps)(Navbar);