import moment from "moment";

const ErrorMessages = {
  empty: (valueName) => `Please fill the ${valueName || "Field"}`,
  invalid: (valueName) => `${valueName || "Field"} is invalid`,
  numeric: (valueName) => `${valueName || "Field"} should only contain numbers`,
  minCharLimit: (valueName, limit) =>
    `${valueName || "Field"} must contain atleast ${limit} characters.`,
    intLimit: (valueName, limit) =>
    `${valueName || "Field"} cannot exceed ${limit} digits.`,
    minIntLimit: (valueName, limit) =>
    `${valueName || "Field"} must be greater than ${limit}.`,
};

const RegularExpressions = {
  phoneNumber: /^\d{4,14}$/,
  numeric: /^[0-9]+(\.[0-9]+)?$/,
  email: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
  // youtube: /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/,
  // youtube:  /^.*(youtu.be\/|v\/|u\/\w\/\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/,
  // youtube: /^(http\:\/\/)?(youtube\.com|youtu\.be|watch\?v=|\&v=|\?v=)([^#\&\?]*).*)+$/,
};

const FieldNames = {
  NAME: "Name",
  YOUTUBE: "Wistia Link",
  PHONE_NUMBER: "Phone Number",
  EMAIL: "E-Mail",
  PASSWORD: "Password",
  ADDRESS: "Address",
};

// const initialState = {
//     value: '',
//     errMsg: [],
// }
class Validations {
  static validateName = (name) => {
    const state = {
      value: name,
      isValid: false,
      errMsg: [],
    };

    if (!name) {
      state.errMsg.push(ErrorMessages.empty(FieldNames.NAME));
    }
    state.isValid = true;
    return state;
  };

  static validatePhoneNumber = (number) => {
    const state = {
      value: number,
      isValid: false,
      errMsg: [],
    };

    if (!number) {
      state.errMsg.push(ErrorMessages.empty(FieldNames.PHONE_NUMBER));
      return state;
    }

    if (!RegularExpressions.phoneNumber.test(number)) {
      state.errMsg.push(ErrorMessages.invalid(FieldNames.PHONE_NUMBER));
      return state;
    }
    state.isValid = true;
    return state;
  };

  static validateYoutubeLink = (value,field) => {
    const state = {
      value,
      isValid: false,
      errMsg: [],
    };
    if (!value) {
      state.errMsg.push(ErrorMessages.empty(FieldNames.YOUTUBE));
      return state;
    }
    if (value.includes('https://the-teddy-app.wistia.com/medias/')) { 
      // Found world
    }else{
      state.errMsg.push(ErrorMessages.invalid(FieldNames.YOUTUBE));
      return state;
    }
    state.isValid = true;
    return state;
  }

  static validateNumericValue = (value, field, min, max) => {
    const state = {
      value,
      isValid: false,
      errMsg: [],
    };

    if (!value && value !== 0) {
      state.errMsg.push(ErrorMessages.empty(field));
      return state;
    }

    if (!RegularExpressions.numeric.test(value)) {
      state.errMsg.push(ErrorMessages.numeric(field));
      return state;
    } else if(value.toString().length >= 14) {
      state.errMsg.push(ErrorMessages.intLimit(field, 14));
      return state; 
    }

    if(min !== undefined && value <= min) {
      state.errMsg.push(ErrorMessages.minIntLimit(field, min));
      return state;
    }

    state.isValid = true;
    return state;
  };

  static validatePassword = (value) => {
    const state = {
      value,
      isValid: false,
      errMsg: [],
    };

    if (!value) {
      state.errMsg.push(ErrorMessages.empty(FieldNames.PASSWORD));
      return state;
    }

    if (value.length < 8) {
      state.errMsg.push(ErrorMessages.minCharLimit(FieldNames.PASSWORD, 8));
      return state;
    }
    
  if(!/[a-z]/.test(value)) {
    state.errMsg.push("Please enter atleast 1 lowercase letter");
    return state;
  }
  
  if(!/[A-Z]/.test(value)) {
    state.errMsg.push("Please enter atleast 1 uppercase letter");
    return state;
  }

  if(!/\d/.test(value)) {
    state.errMsg.push("Please enter atleast 1 number");
    return state;
  }

  if(!/[$&+,:;=?@#|'<>.^*()%!-]/.test(value)){
    state.errMsg.push("Please enter atleast 1 special character");
    return state;
  }
    state.isValid = true;
    return state;
  };

  static validateAddress = (value) => {
    const state = {
      value,
      isValid: false,
      errMsg: [],
    };

    if (!value) {
      state.errMsg.push(ErrorMessages.empty(FieldNames.ADDRESS));
      return state;
    }

    if (value.length < 5) {
      state.errMsg.push(ErrorMessages.minCharLimit(FieldNames.ADDRESS, 5));
      return state;
    }
    state.isValid = true;
    return state;
  };

  static validateTextField = (value, field) => {
    const state = {
      value,
      isValid: false,
      errMsg: [],
    };

    if (!value) {
      state.errMsg.push(ErrorMessages.empty(field));
      return state;
    }

    state.isValid = true;
    return state;
  };

  static validateEmail = (value) => {
    const state = {
      value,
      isValid: false,
      errMsg: [],
    };

    if (!value) {
      state.errMsg.push(ErrorMessages.empty(FieldNames.EMAIL));
      return state;
    }

    if (!RegularExpressions.email.test(value)) {
      state.errMsg.push(ErrorMessages.invalid(FieldNames.EMAIL));
      return state;
    }

    state.isValid = true;
    return state;
  };

  static validateStartEndDate = (start, end, allowSameDates) => {
    const startState = {
      value: start,
      isValid: true,
      errMsg: [],
    };

    const endState = {
      value: end,
      isValid: true,
      errMsg: [],
    };

    if (!start) {
      startState.errMsg.push(ErrorMessages.empty());
      startState.isValid = false;
    }

    if (!end) {
      endState.errMsg.push(ErrorMessages.empty());
      endState.isValid = false;
    }

    if (start && end) {
      console.log("jackpot");
      if (moment(end).diff(moment(start), "days") === 0 && !allowSameDates) {
        startState.errMsg.push("Start Date can't be equal to End Date");
        startState.isValid = false;
        endState.errMsg.push("End Date can't be equal to Start Date");
        endState.isValid = false;
      } else if (moment(end).diff(moment(start), "days") < 0) {
        startState.errMsg.push("Start Date cannot be ahead of End Date");
        startState.isValid = false;
        endState.errMsg.push("End Date cannot be behind Start Date");
        endState.isValid = false;
      }
    }
    return {
      startDate: startState,
      endDate: endState,
    };
  };

  static validateSourceDestDay = (source, dest) => {
    const sourceState = {
      value: source,
      isValid: true,
      errMsg: [],
    };

    const destState = {
      value: dest,
      isValid: true,
      errMsg: [],
    };

    if (!source && source !== 0) {
      sourceState.errMsg.push(ErrorMessages.empty());
      sourceState.isValid = false;
    }

    if (!dest && dest !== 0) {
      destState.errMsg.push(ErrorMessages.empty());
      destState.isValid = false;
    }

    if (source && dest) {
      console.log("jackpot");
      if (source === dest) {
        sourceState.isValid = false;
        destState.isValid = false;
        sourceState.errMsg.push("Source & Destination day can't be same");
        destState.errMsg.push("Source & Destination day can't be same");
      }
    }
    console.log("source", sourceState);
    return {
      sourceDay: sourceState,
      destDay: destState,
    };
  };

  static validateMultiSelect = (value, field) => {
    const state = {
      value,
      isValid: false,
      errMsg: [],
    };

    if (!value.length) {
      state.errMsg.push(ErrorMessages.empty(field));
      return state;
    }

    state.isValid = true;
    return state;
  };
}

export default Validations;
