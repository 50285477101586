import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import axios from "axios";
// import axios from '../../../shared/api/axiosConfig';
import './upload-button.scss';
import { toastr } from 'react-redux-toastr';

const styles = {
    input: 'custom-upload-button__input',
    label: 'custom-upload-button__label',
}
function UploadButton(props) {
    const intl = useIntl();
    const [isLoading, changeIsLoading] = useState(false);
    let baseURL = 'https://dev-api.pawminded.com';
    const axiosClient = axios.create({
        baseURL,
      });
      
      axiosClient.interceptors.request.use(function (config) {
        const jwt = localStorage.getItem("trollyAdmin_jwt");
        const language = localStorage.getItem("trollyAdmin_language");
      
      
        if (jwt) {
          config.headers["access-token"] = jwt;
        }
        config.headers["content-language"] = language ? language : "en";
        return config;
      });
      
      axiosClient.interceptors.response.use(
        function (response) {
          console.log("RESPONSE: ", response.config.url, response.data);
          return response;
        },
        function (error) {
          if (error?.response?.data?.statusCode === 401) {
            // store.dispatch(userActions.sessionExpired());
          } else {
            if (error?.response?.data?.message) {
              toastr.error(error.response.data.message);
            } else {
              toastr.error("Error", "Something Went Wrong");
            }
          }
      
          return Promise.reject(error.response);
        }
      );
    const uploadFile = (file) => {
        if(file) {
            changeIsLoading(true);
        }
        const data = new FormData();
        data.append('image', file);
        axiosClient.post('/fileUpload',data,{
            headers: {
                'content-type': 'multipart/form-data',
            }
        }).then((res) => {
            if(res.data.statusCode === 200) {
                toastr.success("Image uploaded successfully")
                if(props.compressed) {
                    props.onChange(res.data.data.location);
                } else {
                    props.onChange(res.data.data.location);
                }
            }
            changeIsLoading(false)
        }).catch((err) => {
            // toastr.error('Error', intl.formatMessage({id: 'app.message.imageError'}))
            props.onChange('');
            changeIsLoading(false)
        })
    }
    const handleChange = (ev) => {
        console.log(ev.target.files[0]);
        const reader = new FileReader();
        reader.onload = function(e) {
                props.onChange(e.target.result);
        }
        if(props.resultType === 'binary') {
            reader.readAsArrayBuffer(ev.target.files[0]);
        } else if(props.resultType === 'base64') {
            reader.readAsDataURL(ev.target.files[0]);
        } else if(props.resultType === 'url') {
            uploadFile(ev.target.files[0]);
        }
    }
    return (
        <>
        <input disabled={isLoading} id="uploadFile" className={styles.input} name="uploadFile" type="file" accept={props.accept} onChange={handleChange} />
    <label className={styles.label} htmlFor="uploadFile">{!isLoading? props.text : 'Loading...'}</label>
        </>
    )
}

UploadButton.propTypes = {
    resultType: PropTypes.oneOf(['binary', 'base64', 'url']).isRequired,
    accept: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    text: PropTypes.string,
}

UploadButton.defaultProps = {
    resultType: 'base64',
    accept: "*",
    text: "Upload Image",
    onChange: () => {},
}

export default UploadButton;