export const RECORDS_PER_PAGE = [
    {
        key: 5,
        text: 5,
        value: 5,
    },
    {
        key: 10,
        text: 10,
        value: 10,
    },
    {
        key: 25,
        text: 25,
        value: 25,
    },
    {
        key: 50,
        text: 50,
        value: 50,
    },
    {
        key: 100,
        text: 100,
        value: 100,
    },
]

export const LANGUAGES = [
    {
        key: 'en',
        text: 'English',
        value: 'en',
    },
    {
        key: 'ar',
        text: 'Arabic',
        value: 'ar',
    },
    {
        key: 'fr',
        text: 'French',
        value: 'fr',
    },
    {
        key: 'el',
        text: 'Greek',
        value: 'el',
    },
]


export const getBaseURL = (append) => {
    return `/home${append}`;
  };

  export const getPanelType = () => {
    return 0;
    if(window.location.origin.includes("admin")) {
      return 0;
    }
    return 1;
  }
  



export const TABLE_TEXT_LIMIT = {
    ADDRESS: 20,
    DESCRIPTION: 20,
  };
  
  export const textTrimmer = (text, len = 40, append = "") => {
    if (!text) {
      return "";
    }
  
    if (text.length > len) {
      return `${text.slice(0, len - 3)}...${append}`;
    }
    return text;
  };
  

export const devSettings = {
    clients: [
      {
        value: 0,
        name: "Pawminded",
        panel: [
          {
            key: "apsifohg313",
            url: "http://206.189.178.139:8334/api/admin",
            text: "Admin",
            value: 0,
          },
          {
            key: "ojafhg391",
            url: "http://206.189.178.139:8334/api/admin",
            value: 1,
            text: "Store (Grocery)",
          },
        ],
      },
    ],
  };
  

export const tipMethod = {
    0: {
        value: 0,
        key: 0,
        text: 'Flat',
    },
    1: {
        value: 1,
        key: 1,
        text: 'Percentage',
    },
}