import { useState, useEffect } from "react";
import useOptimized from "./useOptimized";

import { Modal, style } from "antd";
const { confirm } = Modal;

const useConfirm = (callBack, mode) => {
  const handleDeleteClick = useOptimized((...args) => {
    confirm({
      // style: { opacity: "90%" },
      title: "Are you sure ?",
      // icon: <ExclamationCircleOutlined />,
      content: "Warning",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        callBack(...args);
      },
    });
  });
  return handleDeleteClick;
};

export default useConfirm;
