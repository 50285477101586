import React, { useState, useRef } from "react";
import { Button, Input } from "antd";
import {connect} from 'react-redux';

import GoogleMap from "../../../../components/google-map/google-map";

import {
  Marker,
  Polygon,
  InfoWindow,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import { useEffect } from "react";

function GeofenceMap(props) {
  const resetInfoWindow = () => {
    updateInfoWindow("");
  };

  const [infoWindow, updateInfoWindow] = useState("");
  const [showPolygons, togglePolygons] = useState(true);
  const locationSearchRef = useRef(null);
  const [mapControls, updateMapControls] = useState({
    showDrawTools: true,
    newGeofence: null,
    geofences: [],
    mapRef: null,
  });

  // const getInfoWindow = (data) => {
  //   return (
  //     <InfoWindow
  //       position={{
  //         lat: parseFloat(store.latitude),
  //         lng: parseFloat(store.longitude),
  //       }}
  //       onCloseClick={resetInfoWindow}
  //     >
  //       <div>
  //         <div>
  //           <b>Store Name</b>
  //         </div>
  //         <div>{data.branchName.en}</div>
  //         <div>
  //           <b>Address</b>
  //         </div>
  //         <div>{data.address}</div>
  //       </div>
  //     </InfoWindow>
  //   );
  // };

  const handleMarkerClick = (marker, store) => {
    updateInfoWindow(store.branchId);
  };

  const handleMapLoad = (mapRef) => {
    updateMapControls({
      ...mapControls,
      mapRef,
    });
  };

  // Don't make this an arrow function
  const handleGeofenceDraw = function (e) {
    // this = DrawingManager
    this.setDrawingMode(null);
    updateMapControls({
      ...mapControls,
      showDrawTools: false,
      newGeofence: e,
    });
    props.handleGeofenceChange(e);
  };

  const handleEdit = (e) => {
    let bounds =  new window.google.maps.LatLngBounds();
    e.getPath().getArray().forEach((coords) => {
      console.log(coords);
      bounds.extend(coords);
    })
    mapControls.mapRef.fitBounds(bounds)
    // map
    // mapControls.mapRef.fitBounds();
    updateMapControls({
      ...mapControls,
      showDrawTools: false,
      newGeofence: e,
    });
    props.handleGeofenceChange(e);
  }


  const handleReset = () => {
    console.log(mapControls.mapRef);
    if (mapControls.newGeofence) {
      mapControls.newGeofence.setMap(null);
      updateMapControls({
        ...mapControls,
        newGeofence: null,
        showDrawTools: true,
      });
      props.handleGeofenceChange(null);
    }
  };

  const handleLocationSearch = () => {
    const places = locationSearchRef.current.getPlaces();
    if (places.length) {
      const bounds = new window.google.maps.LatLngBounds();
      places.forEach((place) => {
        if (!place.geometry) {
          console.log("No geometry");
          return;
        }
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      mapControls.mapRef.fitBounds(bounds);
    }
  };

  const handleLocationSearchLoad = (ref) => {
    locationSearchRef.current = ref;
  };

  const handleDMLoad = (d) => {
    console.log(mapControls.mapRef, 'crooooomge')
    window.google.maps.event.addListener(d, "drawingmode_changed", function() {
      if(d.getDrawingMode()) {
        togglePolygons(false)
      } else {
        togglePolygons(true);
      }
    })
  }

  useEffect(() => {
    
    if(props.hack !== undefined) {
      handleReset();
    }
  }, [props.hack])
  return (
    <GoogleMap
      onLoad={handleMapLoad}
      drawingControl={mapControls.showDrawTools}
      onPolygonComplete={handleGeofenceDraw}
      drawingManagerOnLoad={handleDMLoad}
      polygonFillColor={props.color}
    >
      <StandaloneSearchBox
        onLoad={handleLocationSearchLoad}
        onPlacesChanged={handleLocationSearch}
      >
        <Input
          placeholder="Search location"
          style={{
            position: "absolute",
            top: 0,
            left: "37%",
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `240px`,
            height: `32px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`,
          }}
        />
      </StandaloneSearchBox>
      {props.geofences.data.map((geofence) => {
        return props.editId == geofence.geofenceId ? (
          <Polygon
            key={geofence.geofenceId}
            draggable={true}
            editable={true}
            paths={geofence.latLngArray}
            options={{
              fillColor: props.color,
              fillOpacity: 0.5,
              strokeWeight: 5,
            }}
            onLoad={handleEdit}
          />
        ) : geofence.branchId == props.selectedStore && mapControls.showDrawTools && props.editId === ""
         ? <Polygon key={geofence.geofenceId} paths={geofence.latLngArray} /> : null
        // (mapControls.showDrawTools && showPolygons) ? (
        //   <Polygon key={geofence.geofenceId} paths={geofence.latLngArray} />
        // ) : null
      })}
      {props.stores.data.filter((store) => {
        if(showPolygons && mapControls.showDrawTools) {
          return store.branchId == props.selectedStore;
        } else {
          console.log('what teh fuck',store, props.selectedStore)
          return store.branchId == props.selectedStore
        }
      }).map((store) => (
        <Marker
          key={store.branchId}
          position={{
           lat: parseFloat(store.latitude),
            lng: parseFloat(store.longitude),
          }}
          clickable={true}
          onClick={(e) => {
            handleMarkerClick(e, store);
          }}
        >
          {infoWindow === store.branchId && (
            <InfoWindow onCloseClick={resetInfoWindow}>
              <div>
                <div>
                  <b>Store Name</b>
                </div>
                <div>{store.branchName}</div>
                <div>
                  <b>Address</b>
                </div>
                <div>{store.address}</div>
              </div>
            </InfoWindow>
          )}
        </Marker> 
      ))}
      {!mapControls.showDrawTools && (
        <Button
          style={{ position: "absolute", bottom: 0, left: "45%" }}
          onClick={handleReset}
        >
          Reset
        </Button>
      )}
    </GoogleMap>
  );
}

GeofenceMap.defaultProps = {
  editId: "",
};

const mapStateToProps = (state) => ({
  color: state.user.enterprice.color
})
export default connect(mapStateToProps)(GeofenceMap);
