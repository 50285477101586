const rtl = {
  ar: true,
  en: false,
  fr: false,
  de: false,
  tr: false,
  el: false,
};

const checkRtl = () => {
  const lang = localStorage.getItem("trollyAdmin_language");
  if (lang) {
    return rtl[lang] === true;
  }
  return false;
};

const getLanguage = () => {
  const lang = localStorage.getItem("trollyAdmin_language");
  // debugger;
  if (!lang) return "en";
  return lang;
};
const setLanguage = (language) => {
  localStorage.setItem("trollyAdmin_language", language);
};
export { checkRtl, getLanguage, setLanguage };
