import React, { useState, useEffect } from "react";
import {connect} from 'react-redux';
import moment from "moment";
import {Button} from 'antd';
import {useIntl} from "react-intl";
import axios from "../../shared/api/publicAxios";
import {Helmet} from 'react-helmet';
import "./certificate.scss";
import Sign from "../../assets/images/sign.png";
import AdoptImage from "../../assets/images/adoption.png";
import LogoImage from "../../assets/images/adopt-logo.png";
import { toastr } from "react-redux-toastr";

function Certificate(props) {
  const intl = useIntl();
  const [token, setToken] = useState("");
  const [loading, toggleLoading] = useState(false);
  const [name, changeName] = useState("");
  const [issuedAt, changeIssuedAt] = useState("");
  const [userId, changeUserId] = useState("");

  const getData = () => {
    axios
    .get(`/certificates/${props.match.params.token}`)
    .then((res) => {
      changeName(res.data.data.name);
      changeIssuedAt(res.data.data.issuedAt);
      changeUserId(res.data.data.userId);
    })
    .catch((err) => {
    });
  };

  useEffect(() => {
    console.log("PROPS: ",props.enterprice);
    // alert.show("Oh look, an alert!");
    getData();
    setToken(props.match.params.token);
  }, []);

  return (
    <div className="external-container">
      <Helmet title={`Adoption Certificate`} />
      <div className="certificate__wrapper">
        <div className="certificate__container">
            <div className="certificate__heading">
                <h2>VERIFIED</h2>
                <h2>CERITIFICATE</h2>
                <label>By Teddy</label>
            </div>
            <div>
                <img className="certificate__logo-image" src={LogoImage} />
            </div>
            <div className="certificate__name">
              <p>This is to certify that</p>
              <label className="certificate__name__name">{name}</label>
              <p className="certificate__name__text">Succesfully completed the</p>
              <h3>Adoption Fundamentals</h3>
            </div>
            <div>
              <img src={AdoptImage} style={{height: "13rem"}} />
            </div>
            <div className="certificate__footer">
                <div className="certificate__footer__detail">
                  <label></label>
                  <label></label>
                  <label></label>
                    <label>Issued: {issuedAt ? moment(issuedAt).format("HH:mm MMMM Do YYYY") : ""}</label>
                    <label>User Id: {userId}</label>
                </div>
                <div className="certificate__footer__sign">
                  <div>
                    <img src={Sign} />
                  </div>
                    <h3>Ela Brumm</h3>
                    <label>Founder, Trainer</label>
                    <span>Pawminded</span>
                </div>
            </div>
        </div>
      </div>
    </div>
    // </Helmet>
  );
}

const mapStateToProps = (state) => ({
  enterprice: state.user.enterprice,
})

export default connect(mapStateToProps)(Certificate);