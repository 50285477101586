import React, { useState, useEffect } from "react";
import moment from 'moment';

import { Header, CustomTable, Label, Locked, CustomTag } from "../../components/common";

import {
  removeEmptyKeys,
} from "../../shared/utils/object-manipulation";
import axios from "../../shared/api/axiosConfig";

import "./subscriptions.scss";
import { Popconfirm } from "antd";
import { useIntl } from "react-intl";
import axiosClient from "../../shared/api/axiosConfig";

const styles = {
  wrapper: "subscriptions__wrapper",
  menu: "subscriptions__menu",
  leftMenu: "subscriptions__menu--left",
  menuItem: "subscriptions__menu-item",
  table: "custom-table",
  menu: 'custom-menu',
  section: 'custom-section',
};


const Subscriptions = (props) => {
const intl = useIntl()
  const [searchDelayed, updateSearchDelayed] = useState("");

  const [pagination, updatePagination] = useState({
    pageSize: 10,
    skip: 0,
    current: 1,
    search: "",
    delayedcurrent: 1,
    delayedpageSize: 10,
  });

  const [tableControls, updateTableControls] = useState({
    isLoading: true,
    isEmpty: false,
    data: [],
    count: 0,
  });

  const handleTableChange = (pag, filt, sorter) => {
    // debugger;
    updatePagination({
      ...pag,
    });
  };

  function getSubscriptions  () {
    updateTableControls({
      ...tableControls,
      isEmpty: false,
      isLoading: true,
    });
    axios
      .get("/subscriptions", {
        params: removeEmptyKeys({
          limit: pagination.pageSize,
          skip: (pagination.current - 1) * pagination.pageSize,
          search: searchDelayed,
        }),
      })
      .then((res) => {
        // const parsed = nestedParseJSON(res.data.customers);
        updateTableControls({
          isLoading: false,
          isEmpty: res.data.data.length === 0,
          data: res.data.data,
          count: res.data.data.length,
        });

        updatePagination({
          ...pagination,
          total: res.data.data.length,
          // delayedcurrent: pagination.current,
          // delayedpageSize: pagination.pageSize,
        });
      })
      .catch((err) => {
        // toastr.error('Error', intl.formatMessage({id: 'app.message.error'}))
        updateTableControls({
          ...tableControls,
          isEmpty: true,
          isLoading: false,
          data: [],
        });
      });
    }
    function handleDeleteSubscription(item) {
      const {subscriptionId} = item
      axiosClient.delete(`https://dev-api.pawminded.com/admin/subscriptions/delete/${subscriptionId}`).then(res=>{
        console.log(res)
        getSubscriptions();
      }).catch(err=>{
        console.log(err.message)
      })
    }
    useEffect(() => {
        getSubscriptions();
    },[pagination.current, pagination.pageSize, searchDelayed]);


  const columns = [
    {
      dataIndex: "",
      // sorter: true,
      title: "S No.",
      // fixed: 'left',
      render: (item, object, index) => {
        return (
          <span>
            {pagination.pageSize * (pagination.current - 1) + index + 1}
          </span>
        );
      },
    },
    {
      dataIndex: "",
      title: "Customer Name",
      render: (item) => `${item.customer[0]?.name ? item.customer[0].name : "N/A"}`
    },
    {
      dataIndex: "",
      title: "Customer Email",
      render: (item) => `${item.customer[0]?.email ? item.customer[0].email : "N/A"}`
    },
    {
      dataIndex: "createdAt",
      title: "Registration Date",
      render: (i,item) => `${moment.utc(item.createdAt).format("MMMM Do YYYY")}`,
    },
    {
      dataIndex: "startDate",
      title: "Subscription Date",
      render: (i,item) => `${moment.utc(item.updatedAt).format("MMMM Do YYYY")}`,
    },
    // {
    //   dataIndex: "amount",
    //   title: "Amount",
    // },
    {
      dataIndex: "subscriptionState",
      title: "Subscription Status",
    },
    {
      dataIndex: "trialDays",
      title: "Trial Days",
      render: (item) => `${item ? item : "N/A"}`
    },
    {
      dataIndex: "billingStartDate",
      title: "Billing Start Date",
      render: (i,item) => `${moment.utc(item.billingStartDate).format("MMMM Do YYYY")}`,
    },
    {
      dataIndex: "billingPeriod",
      title: "Billing Period",
      render: (item) => `${item ? item : "N/A"}`
    },
    {
      dataIndex: "",
      title: "Action",
      render: (item) => (
        <Popconfirm
          title={intl.formatMessage({
            id: "app.message.deleteWarning",
          })}
          onConfirm={() => {
            handleDeleteSubscription(item);
          }}
          placement="topRight"
          okText={intl.formatMessage({ id: "app.field.yes" })}
          cancelText={intl.formatMessage({ id: "app.field.no" })}
        >
          <button
            style={{
              cursor: "pointer",
              background: "#CE0F0F",
              border: "none",
              color: "white",
              padding: "3px 10px",
              borderRadius: "5px",
              fontSize: "10px",
            }}
          >
            Delete
          </button>
        </Popconfirm>
      ),
    },
  ];

    return (
      <div className={styles.wrapper}>
        <Header title="Subscriptions" />
        <div className="custom-section">
          <CustomTable
            isLoading={tableControls.isLoading}
            columns={columns}
            dataSource={tableControls.data}
            isEmpty={tableControls.isEmpty}
            pagination={pagination}
            onChange={handleTableChange}
            current={pagination.current}
            pageSize={pagination.pageSize}
          />
        </div>
      </div>
    );

}

export default Subscriptions;