import React, { useState, useEffect } from "react";
import { Select, Dropdown, Button } from "antd";
import { useIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";

import { RECORDS_PER_PAGE } from "../../shared/constants/dropdown";
import { CaretDownOutlined, SearchOutlined } from "@ant-design/icons";
import { Header, PaginationInfo, DelayedInput } from "../../components/common";
import { ExpiredPromoTable } from "./components";
import { removeEmptyKeys } from "../../shared/utils/object-manipulation";
import axios from "../../shared/api/axiosConfig";

import "./expired-promo.scss";

const { Option } = Select;

const styles = {
  wrapper: "active-promo__wrapper",
  content: "custom-section",
  menu: "custom-menu",
  menuLeft: "active-promo__menu--left",
  menuRight: "active-promo__menu--right",
  pageSize: "active-promo__records-per-page",
};

function ExpiredPromo(props) {
  const intl = useIntl();

  const [search, updateSearch] = useState("");

  const [stores, updateStores] = useState({
    selected: undefined,
    data: [],
    notFound: false,
    isLoading: true,
  });

  const handleSearchChange = (val) => {
    updateSearch(val);
  };

  const handleSearch = (val) => {
    updatePagination({
      ...pagination,
      currentPage: 1,
      search: val,
      skip: 0,
    });
  };

  const [promocodes, updatePromos] = useState({
    data: [],
    notFound: false,
    isLoading: true,
    count: null,
  });

  const [tableControls, updateTableControls] = useState({
    isTableLoading: true,
    isTableEmpty: false,
  });

  const [pagination, updatePagination] = useState({
    recordsPerPage: 10,
    skip: 0,
    currentPage: 1,
    search: "",
  });

  const handleStoreChange = (selected) => {
    updateStores({
      ...stores,
      selected,
    });
    updateTableControls({
      ...tableControls,
      isTableLoading: true,
      isTableEmpty: false,
    });
    updatePagination({
      ...pagination,
      currentPage: 1,
      skip: 0,
    });
  };

  const handlePaginationChange = (page) => {
    updatePagination({
      ...pagination,
      currentPage: page,
      skip: (page - 1) * pagination.recordsPerPage,
      search: pagination.search,
    });
  };

  const handleRecordsPerPageChange = (val) => {
    updatePagination({
      ...pagination,
      recordsPerPage: val,
    });
  };

  const getPromoData = () => {
    updateTableControls({
      isTableEmpty: false,
      isTableLoading: true,
    });
    axios
      .get(`/getPromoCode`, {
        params: removeEmptyKeys({
          isActive: 0,
          limit: pagination.recordsPerPage,
          skip: (pagination.currentPage - 1) * pagination.recordsPerPage,
          branchId: stores.selected,
          search: pagination.search,
        }),
      })
      .then((res) => {
        if (res.data.data.count) {
          updatePromos({
            ...promocodes,
            data: res.data.data.promoCodes,
            isLoading: false,
            notFound: false,
            count: res.data.data.count,
          });

          updateTableControls({
            isTableEmpty: false,
            isTableLoading: false,
          });
        } else {
          toastr.error(
            "Error",
            `${intl.formatMessage({ id: "app.message.noPromoCodeFound" })}`
          );
          updateTableControls({
            isTableEmpty: true,
            isTableLoading: false,
          });
          updatePromos({
            ...promocodes,
            data: [],
            isLoading: false,
            notFound: true,
            count: 0,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        updateTableControls({
          ...tableControls,
          isTableLoading: false,
        });
      });
  };

  const removePromo = (data) => {
    console.log(data);
  };

  useEffect(() => {
    if (
true
    ) {
      axios
        .get("/getBranches", {
          params: {
            onlyNames: 1,
            // countryId: props.selectedCountry,
            // cityIds: props.selectedCities,
          },
        })
        .then((res) => {
          if (res.data.data.branchesData.length) {
            updateStores({
              selected: res.data.data.branchesData[0].branchId,
              data: res.data.data.branchesData,
              isLoading: false,
              notFound: false,
              count: res.data.data.branchCount,
            });
          } else {
            updateStores({
              ...stores,
              isLoading: false,
              data: [],
              notFound: true,
            });
          }
        })
        .catch((err) => {
          // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        });
    }
  }, [props.selectedCountry, props.selectedCities]);

  useEffect(() => {
    if (
      stores.selected !== null &&
      stores.selected !== undefined &&
      stores.selected !== ""
    ) {
      getPromoData();
    }
  }, [
    pagination.recordsPerPage,
    pagination.currentPage,
    pagination.search,
    stores.selected,
  ]);

  return (
    <div className={styles.wrapper}>
      <Header title="app.pageTitle.inActivePromo" />
      <div className={styles.content}>
        <div className={styles.menu}>
          <header>
            <div>
            <Select
              placeholder={intl.formatMessage({ id: "app.placeholder.store" })}
              onChange={handleStoreChange}
              value={stores.selected}
              style={{ width: 200 }}
            >
              {stores.data.map((store) => (
                <Option key={store.branchId} value={store.branchId}>
                  {store.branchName}
                </Option>
              ))}
            </Select>
            </div>
          </header>
          <section>
            <div>
              <DelayedInput
                onChange={handleSearchChange}
                value={search}
                callback={handleSearch}
                delay={500}
                minLength={3}
                size="medium"
                prefixIcon={<SearchOutlined />}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.search",
                })}
              />
            </div>
          </section>
        </div>

        <ExpiredPromoTable
          data={promocodes.data}
          isLoading={promocodes.isLoading}
          isEmpty={promocodes.notFound}
          pagination={pagination}
          totalRecords={promocodes.count}
          onChange={handlePaginationChange}
        />
      </div>
    </div>
  );
}


const mapStateToProps = (state) => ({
  // selectedCountry: state.user.selectedCountry,
  // selectedCities: state.user.selectedCities,
});
export default connect(mapStateToProps)(ExpiredPromo);

