import { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import orderActions from "../redux/orders/orders-action";
import axios from "../shared/api/axiosConfig";
import { getPanelType } from "../shared/constants/dropdown";

const getLocalData = () => {
  let data = localStorage.getItem("trollyAdmin_userInfo");
  if (!data) {
    return undefined;
  } else {
    data = JSON.parse(data);
  }
  return {
    branchId: data.branchId,
    // cityId: data.cityId,
    // countryId: data.countryId,
  };
};
const useBranch = (emptyCallback) => {
  const localData = getLocalData();
  // debugger;
  const dispatch = useDispatch();
  // const { selectedCities, selectedCountry } = useSelector(
  //   (state) => state.user,
  //   shallowEqual
  // );
  // const branchId = useSelector((state) => state.orders.branchId, shallowEqual);
  const [branchId, updateBranchId] = useState(
    getPanelType() === 0 ? undefined : localData.branchId
  );
  const [branches, updateBranches] = useState([]);
  const [controls, updateControls] = useState({
    isLoading: false,
    isEmpty: branches.length === 0,
  });

  useEffect(() => {
    // if (!branches.length) {
    getbranches();
    // }
  }, []);

  const getbranches = () => {
    if (getPanelType() === 1) {
      return;
    }
    updateControls({
      ...branches,
      isLoading: true,
    });
    axios
      .get("/getBranches", {
        params: {
          onlyNames: 1,
          // countryId: selectedCountry,
          // cityIds: selectedCities,
        },
      })
      .then((res) => {
        updateControls({
          ...branches,
          isEmpty: res?.data?.data?.branchesData?.length === 0,
          isLoading: false,
        });
        if (res.data.data.branchesData) {
          updateBranches(res.data.data.branchesData);
          updateBranchId(res.data.data.branchesData[0].branchId);
        } else {
          updateBranches([]);
          updateBranchId(undefined);
          if (emptyCallback) {
            emptyCallback();
          }
        }
      })
      .catch((err) => {
        updateBranches([]);
        updateBranchId(undefined);
        if (emptyCallback) {
          emptyCallback();
        }
        updateControls({
          ...branches,
          isEmpty: true,
          isLoading: false,
        });
      });
  };

  const updateCity = (id) => {
    updateBranchId(id);
  };

  const getCityRef = useRef(getbranches);
  const updateCityRef = useRef(updateCity);

  const getCityMemo = useCallback(() => {
    getCityRef.current();
  }, []);

  const updateCityMemo = useCallback((data) => {
    updateCityRef.current(data);
  }, []);

  useEffect(() => {
    getCityRef.current = getbranches;
    updateCityRef.current = updateCity;
  });

  return {
    stores: {
      selected:
        branchId === undefined || branchId === null ? undefined : branchId,
      data: branches,
      isLoading: controls.isLoading,
      notFound: controls.isEmpty,
      isVisible: getPanelType() === 0,
    },
    getStores: getCityMemo,
    updateStores: updateCityMemo,
  };
};

export default useBranch;
