import React, { useState, useEffect } from "react";
import { Input, Button } from "antd";
import { useIntl } from "react-intl";

import {
  CustomModal,
  Panel,
  ErrorMessage,
  UploadButton,
} from "../../../../components/common";
import Validations from "../../../../shared/validations/Validations";
import placeholderImg from "../../../../assets/images/item-placeholder.png";
import "./store-categories-modal.scss";

const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  imageContainer: "store-categories__add-edit-modal__image-container",
  imageContainerImage:
    "store-categories__add-edit-modal__image-container__image",
  imageContainerForm:
    "store-categories__add-edit-modal__image-container__form",
};

function AddEditStoreCategory(props) {
  const intl = useIntl();

  const [isPristine, togglePristine] = useState(true);
  const [name, changeName] = useState(Validations.validateTextField(""));
  const [rank, changeRank] = useState(Validations.validateNumericValue(null));
  const [imageURL, changeImageURL] = useState(
    Validations.validateTextField("")
  );

  const resetForm = () => {
    togglePristine(true);
    changeName(Validations.validateTextField(null));
    // changeDescription(Validations.validateTextField(null));
    changeRank(Validations.validateNumericValue(null));
    changeImageURL(Validations.validateTextField(null));
  };

  const setForm = () => {
    togglePristine(true);
    changeName(Validations.validateTextField(props.data.categoryName));
    // changeDescription(Validations.validateTextField(props.data.description));
    changeRank(Validations.validateNumericValue(props.data.priority));
    changeImageURL(Validations.validateTextField(props.data.categoryImage));
  };
  useEffect(() => {
    if (props.isVisible) {
      if (props.modalToShow === "EDIT_CATEGORY") {
        setForm();
        //   setForm();
      } else if (props.modalToShow === "ADD_CATEGORY") {
        resetForm();
      }
    }
  }, [props.isVisible]);

  const handleFieldChange = (e) => {
    const { value } = e.target;

    switch (e.target.name) {
      case "name":
        changeName(Validations.validateTextField(value));
        break;
      // case "description":
      //   changeDescription(Validations.validateTextField(value));
      //   break;
      case "rank":
        changeRank(Validations.validateNumericValue(value));
        break;
      default:
        break;
    }
  };

  const getData = () => ({
    categoryName: name.value,
    // "description" :  description.value ,
    categoryImage: imageURL.value,
    categoryIcon: imageURL.value,
    priority: rank.value,
  });

  const handleAdd = () => {
    // TODO
    props.addCategory(getData());
  };

  const handleEdit = () => {
    // TODO
    props.editCategory(getData());
  };

  const isFormValid = () => {
    return name.isValid && rank.isValid && imageURL.isValid;
  };

  const closeModal = () => {
    props.onCloseIconClick();
  };

  const formAction = () => {
    togglePristine(false);
    if (!isFormValid()) {
      return;
    }
    if (props.modalToShow === "ADD_CATEGORY") {
      handleAdd();
    } else if (props.modalToShow === "EDIT_CATEGORY") {
      handleEdit();
    }
  };

  const handleImageChange = (image) => {
    changeImageURL(Validations.validateTextField(image));
  };

  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={closeModal}
      header={
        props.modalToShow === "ADD_CATEGORY"
          ? "app.placeholder.addCategory"
          : "app.placeholder.editCategory"
      }
      isVisible={props.isVisible}
    >
      <Panel isLoading={props.isLoading} className={styles.formModal}>
        <div className={styles.imageContainer}>
          <div className={styles.imageContainerImage}>
            <img
              src={imageURL.value ? imageURL.value : placeholderImg}
              alt=""
            />
            <UploadButton
              text={intl.formatMessage({ id: "app.field.uploadImage" })}
              resultType="url"
              accept="image/*"
              onChange={handleImageChange}
            />
            {!isPristine && <ErrorMessage field={imageURL} />}
          </div>

          <div className={styles.imageContainerForm}>
            <div className={styles.formItem}>
              <label>
                {intl.formatMessage({
                  id: "app.field.rank",
                })}
              </label>
              <Input
                name="rank"
                type="number"
                onChange={handleFieldChange}
                value={rank.value}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.rank",
                })}
              />
              {!isPristine && <ErrorMessage field={rank} />}
            </div>
            <div className={styles.formItem}>
              <label>
                {intl.formatMessage({
                  id: "app.field.categoryName",
                })}
              </label>
              <Input
                name="name"
                onChange={handleFieldChange}
                value={name.value}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.categoryName",
                })}
              />
              {!isPristine && <ErrorMessage field={name} />}
            </div>
          </div>
        </div>
        <div className={styles.formAction}>
          <Button type="default" shape="" onClick={closeModal}>
            {intl.formatMessage({ id: "app.button.cancel" })}
          </Button>
          <Button type="primary" shape="" onClick={formAction}>
            {intl.formatMessage({ id: "app.button.submit" })}
          </Button>
        </div>
      </Panel>
    </CustomModal>
  );
}

export default AddEditStoreCategory;
