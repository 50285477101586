import React from "react";
import "./legend.scss";
import {
  CHART_COLORS,
  LABEL_FORMAT,
} from "../../../../shared/constants/orders";
const styles = {
  wrapper: "order-reports__chart__legend__wrapper",
  color: "order-reports__chart__legend__color",
  label: "order-reports__chart__legend__label",
  value: "order-reports__chart__legend__value",
};
function Legend(props) {
  return (
    <div className={styles.wrapper}>
      <div
        style={{ backgroundColor: CHART_COLORS[props.legend] }}
        className={styles.color}
      ></div>
      {props.label && <div className={styles.label}>{props.label}</div>}
      {props.value && <div className={styles.value}>{props.value}</div>}
    </div>
  );
}

export default Legend;
