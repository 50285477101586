import React, { useState, useEffect } from "react";
import { Select, Dropdown, Button } from "antd";
import { useIntl } from "react-intl";
import { CaretDownOutlined, SearchOutlined } from "@ant-design/icons";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";

import { RECORDS_PER_PAGE } from "../../shared/constants/dropdown";
import { removeEmptyKeys } from "../../shared/utils/object-manipulation";
import {
  Header,
  PaginationInfo,
  ConfirmModal,
  DelayedInput,
} from "../../components/common";
import { ActivePromoTable, AddEditPromoModal } from "./components";
import axios from "../../shared/api/axiosConfig";

import "./active-promo.scss";
import { checkRtl } from "../../shared/utils/rtl";

// import { connect } from "react-redux";
const { Option } = Select;
const styles = {
  wrapper: "active-promo__wrapper",
  content: "custom-section",
  menu: "custom-menu",
  menuLeft: "active-promo__menu--left",
  menuRight: "active-promo__menu--right",
  pageSize: "active-promo__records-per-page",
};

function ActivePromo(props) {
  const intl = useIntl();

  const [stores, updateStores] = useState({
    selected: undefined,
    data: [],
    notFound: false,
    isLoading: true,
  });
  const [search, updateSearch] = useState("");

  const handleSearchChange = (val) => {
    updateSearch(val);
  };

  const handleSearch = (val) => {
    updatePagination({
      ...pagination,
      currentPage: 1,
      search: val,
      skip: 0,
    });
  };

  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    data: {},
    type: "",
    isDeleteModalVisible: false,
    isLoading: false,
  });

  const [promocodes, updatePromos] = useState({
    data: [],
    notFound: false,
    isLoading: true,
    count: null,
  });

  const [tableControls, updateTableControls] = useState({
    isTableLoading: true,
    isTableEmpty: false,
  });

  const [pagination, updatePagination] = useState({
    recordsPerPage: 10,
    skip: 0,
    currentPage: 1,
    search: "",
  });

  const handleStoreChange = (selected) => {
    updateStores({
      ...stores,
      selected,
    });
    updateTableControls({
      ...tableControls,
      isTableLoading: true,
      isTableEmpty: false,
    });
    updatePagination({
      ...pagination,
      currentPage: 1,
      skip: 0,
    });
  };

  const handlePaginationChange = (page) => {
    updatePagination({
      ...pagination,
      currentPage: page,
      skip: (page - 1) * pagination.recordsPerPage,
    });
  };

  const handleRecordsPerPageChange = (val) => {
    updatePagination({
      ...pagination,
      recordsPerPage: val,
    });
  };

  const openAddPromoModal = () => {
    updateModalControls({
      ...modalControls,
      isDeleteModalVisible: false,
      isVisible: true,
      type: "ADD",
    });
  };

  const addPromo = (data) => {
    console.log(data);
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });

    axios
      .post("/addPromoCode", removeEmptyKeys(data))
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isVisible: false,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getPromoData();
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateModalControls({
          ...modalControls,
          isLoading: false,
        });

        updateTableControls({
          ...tableControls,
          isTableLoading: false,
        });
      });
  };

  const editPromo = (data) => {
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });
    data.promoId = modalControls.data.promoId;
    console.log(data);
    axios
      .put("/editPromoCode", removeEmptyKeys(data))
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isVisible: false,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getPromoData();
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateModalControls({
          ...modalControls,
          isLoading: false,
        });

        updateTableControls({
          ...tableControls,
          isTableLoading: false,
        });
      });
  };

  const openEditPromoModal = (data) => {
    updateModalControls({
      ...modalControls,
      isDeleteModalVisible: false,
      isVisible: true,
      type: "EDIT",
      data,
    });
  };

  const handleModalClose = () => {
    updateModalControls({
      ...modalControls,
      isDeleteModalVisible: false,
      isVisible: false,
      data: {},
    });
  };

  const getPromoData = () => {
    updateTableControls({
      isTableEmpty: false,
      isTableLoading: true,
    });
    axios
      .get(`/getPromoCode`, {
        params: removeEmptyKeys({
          isActive: 1,
          limit: pagination.recordsPerPage,
          skip: (pagination.currentPage - 1) * pagination.recordsPerPage,
          branchId: stores.selected,
          search: pagination.search,
        }),
      })
      .then((res) => {
        if (res.data.data.count) {
          updatePromos({
            ...promocodes,
            data: res.data.data.promoCodes,
            isLoading: false,
            notFound: false,
            count: res.data.data.count,
          });

          updateTableControls({
            isTableEmpty: false,
            isTableLoading: false,
          });
        } else {
          toastr.error(
            "Error",
            `${intl.formatMessage({ id: "app.message.noPromoCodeFound" })}`
          );
          updateTableControls({
            isTableEmpty: true,
            isTableLoading: false,
          });
          updatePromos({
            ...promocodes,
            data: [],
            isLoading: false,
            notFound: true,
            count: 0,
          });
        }
      })
      .catch((err) => {
        updateTableControls({
          ...tableControls,
          isTableLoading: false,
        });
        updatePromos({
          ...promocodes,
          isLoading: false,
        });
        // console.log(err);
      });
  };

  const removePromo = (data) => {
    console.log(data);
    updateModalControls({
      ...modalControls,
      isVisible: false,
      isDeleteModalVisible: true,
      data,
      type: "",
    });
  };

  const handlePromoCodeDelete = (item) => {
    const data = {
      promoId: item.promoId,
      isDeleted: 1,
    };
    // data.adminId = 1;
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });

    axios
      .put("/editPromoCode", removeEmptyKeys(data))
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isDeleteModalVisible: res.data.statusCode !== 200,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getPromoData();
        } else {
          toastr.error("Error", res.data.message);
          updateModalControls({
            ...modalControls,
            isDeleteModalVisible: false,
          });
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateModalControls({
          ...modalControls,
          isDeleteModalVisible: false,
        });
      });
  };

  useEffect(() => {
    if (true) {
      axios
        .get("/getBranches", {
          params: {
            onlyNames: 1,
            countryId: props.selectedCountry,
            cityIds: props.selectedCities,
          },
        })
        .then((res) => {
          if (res.data.data.branchesData.length) {
            updateStores({
              selected: res.data.data.branchesData[0].branchId,
              data: res.data.data.branchesData,
              isLoading: false,
              notFound: false,
              count: res.data.data.branchCount,
            });
          } else {
            updateStores({
              ...stores,
              notFound: true,
              data: [],
              selected: undefined,
              isLoading: false,
            });
          }
        })
        .catch((err) => {
          updateStores({
            ...stores,
            notFound: true,
            data: [],
            selected: undefined,
            isLoading: false,
          });
          // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        });
    }
  }, []);

  useEffect(() => {
    if (
      stores.selected !== null &&
      stores.selected !== undefined &&
      stores.selected !== ""
    ) {
      getPromoData();
    }
  }, [
    pagination.recordsPerPage,
    pagination.currentPage,
    pagination.search,
    stores.selected,
  ]);

  return (
    <div className={styles.wrapper}>
      <Header title="app.pageTitle.activePromo" />
      <AddEditPromoModal
        isVisible={modalControls.isVisible}
        modalToShow={modalControls.type}
        onCloseIconClick={handleModalClose}
        stores={stores.data}
        addPromo={addPromo}
        data={modalControls.data}
        editPromo={editPromo}
        isLoading={modalControls.isLoading}
      />
      {/* <ConfirmModal
        header={`${intl.formatMessage({ id: "app.field.delete" })} ${modalControls?.data?.code}?`}
        message={intl.formatMessage({
          id: "app.message.deleteWarning",
        })}
        onCloseIconClick={handleModalClose}
        onAction={handlePromoCodeDelete}
        isVisible={modalControls.isDeleteModalVisible}
        actionText={intl.formatMessage({ id: "app.field.delete" })}
        isLoading={modalControls.isLoading}
      /> */}
      <div className={styles.content}>
        <div className={styles.menu}>
          <header>
            <div>
              <Select
                placeholder={intl.formatMessage({
                  id: "app.placeholder.store",
                })}
                onChange={handleStoreChange}
                value={stores.selected}
                style={{ width: 200 }}
              >
                {stores.data.map((store) => (
                  <Option key={store.branchId} value={store.branchId}>
                    {store.branchName}
                  </Option>
                ))}
              </Select>
            </div>
          </header>
          <section>
            <div>
              <DelayedInput
                onChange={handleSearchChange}
                value={search}
                callback={handleSearch}
                delay={500}
                minLength={3}
                size="medium"
                prefixIcon={<SearchOutlined />}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.search",
                })}
              />
            </div>
            <div>
              <Button
                type="primary"
                disabled={props.isAddDisabled}
                shape={undefined}
                size="medium"
                onClick={openAddPromoModal}
              >
                {intl.formatMessage({ id: "app.button.addPromo" })}
              </Button>
            </div>
          </section>
        </div>

        <ActivePromoTable
          data={promocodes.data}
          isLoading={tableControls.isTableLoading}
          isEmpty={tableControls.isTableEmpty}
          editItem={openEditPromoModal}
          removeItem={handlePromoCodeDelete}
          pagination={pagination}
          onChange={updatePagination}
          totalRecords={promocodes.count}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  // selectedCountry: state.user.selectedCountry,
  // selectedCities: state.user.selectedCities,
  storeId: state.user.enterprice.storeId,
  isAddDisabled: state.user.isAddDisabled,
  isRTL: checkRtl(state.user.locale),
});
export default connect(mapStateToProps)(ActivePromo);
