import React from 'react';
import classNames from 'classnames';

import {Icon} from '../';
import './menu-item.scss';

const styles = {
    wrapper: 'custom-menu-item__wrapper',
    wrapperActive: 'custom-menu-item__wrapper--active',
}

function MenuItem(props) {

    const handleClick = () => {
        props.onClick(props.callbackData);
    }

    return (
        <div
        onClick={handleClick}
        className={classNames({
          [styles.wrapper]: true,
          [styles.wrapperActive]: props.isActive,
        })}
      >
          <Icon name={props.icon} />
              <div>
              {props.text}
              </div>
      </div>
    )
}

export default MenuItem;