import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Select, Dropdown, Button, Menu,Popconfirm , Rate} from "antd";
import { CaretDownOutlined, MoreOutlined, SearchOutlined } from "@ant-design/icons";
import { connect } from 'react-redux';
import moment from "moment";

import { RECORDS_PER_PAGE } from "../../shared/constants/dropdown";
import {
  Header,
  PaginationInfo,
  ConfirmModal,
  Label,
  DelayedInput,
  CustomTable,
  CustomImage,
  CustomTag,
} from "../../components/common";
import {
  getBaseURL,
  textTrimmer,
  TABLE_TEXT_LIMIT,
} from "../../shared/constants/dropdown";
import { toastr } from "react-redux-toastr";
import { removeEmptyKeys } from "../../shared/utils/object-manipulation";
import axios from "../../shared/api/axiosConfig";
import AddEditCourse from "./components/add-edit-course/add-edit-course";
import "./courses.scss";
import { checkRtl } from "../../shared/utils/rtl";

const styles = {
  wrapper: "courses__wrapper",
  upperMenu: "courses__upper-menu",
  upperMenuLeft: "courses__upper-menu__left",
  upperMenuRight: "courses__upper-menu__right",
  upperMenuDropdown: "courses__upper-menu__dropdown",
  recordsPerPage: "products__upper-menu__records-per-page",
};

const sections = [
  {
    key: "engage",
    value: "Engage",
  },
  {
    key: "adopt",
    value: "Adopt",
  },
  {
    key: "save",
    value: "Save",
  },
  {
    key: "train",
    value: "Train",
  },
];
const { Option } = Select;

function Courses(props) {
  const intl = useIntl();
  const [courses, updateCourses] = useState({
    data: [],
    notFound: false,
    isLoading: true,
    count: null,
  });

  const [search, updateSearch] = useState("");

  const handleSearchChange = (val) => {
    updateSearch(val);
  };

  const handleSearch = (val) => {
    updatePagination({
      ...pagination,
      currentPage: 1,
      search: val,
      skip: 0,
    });
  };

  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    isDeleteModalVisible: false,
    isDisableModalVisible: false,
    data: {},
    type: "",
  });

  const [pagination, updatePagination] = useState({
    recordsPerPage: 10,
    skip: 0,
    currentPage: 1,
    search: "",
  });

  const [tableControls, updateTableControls] = useState({
    isTableLoading: true,
    isTableEmpty: false,
  });

  const openAddCourseModal = () => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      type: "ADD_COURSE",
    });
  };

  const openEditCourseModal = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      data,
      type: "EDIT_COURSE",
    });
  };

  const openLessonModal = (data) => {
    // return;
    props.history.push(getBaseURL(`/courses/${data.courseId}/lessons`));
  };

  const openDeleteCourseModal = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: false,
      isDeleteModalVisible: true,
      data,
      type: "",
    });
  };

  const handleModalClose = () => {
    updateModalControls({
      isVisible: false,
      isDeleteModalVisible: false,
      data: {},
    });
  };

  const handlePaginationChange = (page) => {
    updatePagination({
      ...pagination,
      currentPage: page,
      skip: (page - 1) * pagination.recordsPerPage,
    });
  };

  const handleRecordsPerPageChange = (val) => {
    updatePagination({
      ...pagination,
      recordsPerPage: val,
    });
  };

  const getCourses = () => {
    updateCourses({
      ...courses,
      isLoading: true,
      notFound: false,
    });
    axios
      .get(`/courses`, {
        params: removeEmptyKeys({
          limit: pagination.recordsPerPage,
          skip: (pagination.currentPage - 1) * pagination.recordsPerPage,
          search: pagination.search,
        }),
      })
      .then((res) => {
        if (res.data.data) {
          updateCourses({
            ...courses,
            data: res.data.data,
            isLoading: false,
            notFound: false,
            count: res.data.data.length,
          });

          updateTableControls({
            isTableEmpty: false,
            isTableLoading: false,
          });
        } else {
          toastr.error("Error", "No courses found");
          updateTableControls({
            isTableEmpty: true,
            isTableLoading: false,
          });
          updateCourses({
            ...courses,
            data: [],
            isLoading: false,
            notFound: true,
            count: 0,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        updateCourses({
          ...courses,
          isLoading: false,
        });
      });
  };

  useEffect(() => {
    getCourses();
  }, [
    pagination.recordsPerPage,
    pagination.currentPage,
    pagination.search,
  ]);

  const handleCourseAdd = (data) => {
    console.log("add called");
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });

    axios
      .post("/courses", removeEmptyKeys(data))
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isVisible: false,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getCourses();
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
        });
      });
  };

  const handleCourseEdit = (data) => {
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });
    // data.courseId = modalControls.data.courseId;
    axios
      .put(`/courses/${modalControls.data.courseId}`, removeEmptyKeys(data))
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isVisible: res.data.statusCode !== 200,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getCourses();
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
        });
      });
  };

  const handleCourseDelete = (item) => {
    const data = {
      courseId: item.courseId,
    };
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });

    axios
      .delete(`/courses/${item.courseId}`, removeEmptyKeys(data))
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isDeleteModalVisible: res.data.statusCode !== 200,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getCourses();
        } else {
          toastr.error("Error", res.data.message);
          updateModalControls({
            ...modalControls,
            isDeleteModalVisible: false,
          });
        }
      })
      .catch((err) => {
        updateModalControls({
          ...modalControls,
          isDeleteModalVisible: false,
        });
      });
  };

  function getId(url) {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length == 11) {
        return match[2];
    } else {
        return 'error';
    }
  }

  const getMenu = (item) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            openEditCourseModal(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.edit" })}
        </Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      dataIndex: "",
      // sorter: true,
      title: intl.formatMessage({ id: "app.field.sNo" }),
      // fixed: 'left',
      render: (e, item, index) => {
        return (
          <span>
            {pagination.recordsPerPage * (pagination.currentPage - 1) +
              index +
              1}
          </span>
        );
      },
    },
    // {
    //   dataIndex: "cover",
    //   title: intl.formatMessage({ id: "app.field.image" }),
    //   render: (i) => {
    //     return <CustomImage isTable src={i} />;
    //   },
    // },
    {
      dataIndex: "section",
      title: "Type of Course",
      render: (item) => (
        sections.map((bt) => {
          if(item == bt.key){
           return <span>{bt.value}</span>
          }
        })
      )
    },
    {
      dataIndex: "overview",
      title: "Overview",
    },
    {
        dataIndex: "lead",
        title: "Introduction Text",
    },
    {
        dataIndex: "createdAt",
        title: "Uploaded At",
        render: (item) => moment.utc(item).format("MMMM Do YYYY"),
    },

    {
        dataIndex: "introductionVideo",
        title: "Introduction Video",
        render: (i) => {
            // return (<iframe width="420" height="345" src={i}>
            // </iframe>)
            return (
                <div>
                <a href={i[0]} target="_blank">{i}</a>
                {/* <iframe src={getId(`//www.youtube.com/embed/+${i}`)} frameborder="0" allowfullscreen ng-show="showvideo"></iframe> */}
                {/* <iframe src="https://www.youtube.com/embed/watch?v=Z6Y2-UWatPQ" frameborder="0" allowfullscreen ng-show="showvideo"></iframe> */}
                </div>
            )
        }
    },
    {
      dataIndex: "rating",
      title: "Rating",
      render: (i) => ( i ? 
        <Rate disabled={true} allowHalf={true} value={i} /> : "N/A"
        )
    },
    {
      dataIndex: "",
      title: intl.formatMessage({ id: "app.field.action" }),
      fixed: "right",
      render: (item) => {
        return (
          <Dropdown overlay={getMenu(item)}>
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  return (
    <div className={styles.wrapper}>
      <AddEditCourse
        isVisible={modalControls.isVisible}
        isLoading={modalControls.isLoading}
        onClose={handleModalClose}
        onCloseIconClick={handleModalClose}
        addCourse={handleCourseAdd}
        editCourse={handleCourseEdit}
        modalToShow={modalControls.type}
        data={modalControls.data}
      />
      <Header title="Courses" />

      <div className="custom-section">
        <div className="custom-menu">
          <header>
          </header>
          <section>
            <DelayedInput
              onChange={handleSearchChange}
              value={search}
              callback={handleSearch}
              delay={500}
              minLength={3}
              size="medium"
              prefixIcon={<SearchOutlined />}
              placeholder={intl.formatMessage({ id: "app.placeholder.search" })}
            />
          </section>
        </div>
        <CustomTable
          isLoading={courses.isLoading}
          dataSource={courses.data}
          columns={columns}
          pagination={pagination}
          totalRecords={courses.count}
          onChange={updatePagination}
        />
      </div>
    </div>
  );
}


const mapStateToProps = (state) => ({
  userInfo: state.user.userInfo,
  isRTL: checkRtl(state.user.locale),
});
export default connect(mapStateToProps)(Courses);
