import React, { useState, useEffect } from "react";
import { Input, Button } from "antd";
import { useIntl } from "react-intl";

import {
  CustomModal,
  Panel,
  ErrorMessage,
  UploadButton,
} from "../../../../components/common";
import Validations from "../../../../shared/validations/Validations";
import "./customer-details-form-modal.scss";

const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  formGroup: "custom-form-group",
};

function CustomerDetailsFormModal(props) {
  const intl = useIntl();
  const [name, changeName] = useState(
    Validations.validateTextField("")
  );
  // const [lastName, changeLastName] = useState(
  //   Validations.validateTextField("")
  // );
  const [email, changeEmail] = useState(Validations.validateEmail(""));
  const [phoneNumber, changePhoneNumber] = useState(
    Validations.validatePhoneNumber("")
  );
  const [walletAmount, changeWalletAmount] = useState(
    Validations.validateNumericValue("")
  );
  
  const [password, updatePassword] = useState(Validations.validatePassword(''));
  const [isPristine, togglePristine] = useState(true);

  const setForm = () => {
    togglePristine(true);
    changeName(Validations.validateTextField(props.data.name))
    // changeLastName(Validations.validateTextField(props.data.lastName))
    changeEmail(Validations.validateEmail(props.data.email))
    changePhoneNumber(Validations.validatePhoneNumber(props.data.phoneNo))
    changeWalletAmount(Validations.validateNumericValue(props.data.walletAmount ? props.data.walletAmount : 0))
    updatePassword(Validations.validatePassword(''));
  };
  useEffect(() => {
    if (props.isVisible) {
      setForm();
    }
  }, [props.isVisible]);

  const handleFieldChange = (e) => {
    const { value } = e.target;

    switch (e.target.name) {
      case "name":
        changeName(Validations.validateTextField(value));
        break;
      case "phoneNumber":
        changePhoneNumber(Validations.validatePhoneNumber(value));
        break;
      case "email":
        changeEmail(Validations.validateEmail(value));
        break;
      // case "walletAmount":
      //   changeWalletAmount(Validations.validateNumericValue(value));
      //   break;
        case "password":
          updatePassword(Validations.validatePassword(value));
          break;
      default:
        break;
    }
  };

  const handleEdit = () => {
    // TODO
    const data = {
      "name": name.value ,
      "email":email.value , 
      "phoneNo": phoneNumber.value ,
      // "walletAmount": walletAmount.value,
      // "countryCode": countryCode, 
      // "customerId" : 1 ,
      // "profilePicURL" : image
    }
    if(password.isValid) {
      data.password = password.value;
    }

    props.editCustomer(data);
  };

  const isFormValid = () => {
    let isValid = true;
    isValid = 
      name.isValid &&
      // phoneNumber.isValid &&
      email.isValid

      if(password.value) {
        isValid = password.isValid && isValid
      }

      return isValid;
  };

  const closeModal = () => {
    props.onCloseIconClick();
  };

  const formAction = () => {
    togglePristine(false);
    if (!isFormValid()) {
      return;
    }
    handleEdit();
  };

  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={closeModal}
      header="app.pageTitle.editCustomerDetails"
      isVisible={props.isVisible}
    >
      <Panel isLoading={props.isLoading} className={styles.formModal}>
        <div>
          <div className={styles.formItem}>
            <label>{intl.formatMessage({ id: "app.field.name" })}</label>
            <Input
              name="name"
              placeholder={intl.formatMessage({
                id: "app.placeholder.name",
              })}
              value={name.value}
              onChange={handleFieldChange}
            />
            {!isPristine && <ErrorMessage field={name} />}
          </div>
          <div className={styles.formItem}>
            <label>{intl.formatMessage({ id: "app.field.email" })}</label>
            <Input
              name="email"
              placeholder={intl.formatMessage({
                id: "app.placeholder.email",
              })}
              value={email.value}
              onChange={handleFieldChange}
            />
            {!isPristine && <ErrorMessage field={email} />}
          </div>
          <div className={styles.formItem}>
            <label>{intl.formatMessage({ id: "app.field.updatePassword" })}</label>
            <Input
              name="password"
              type="password"
              placeholder={intl.formatMessage({ id: "app.placeholder.password" })}
              value={password.value}
              onChange={handleFieldChange}
            />
            {!isPristine && password.value !== '' && <ErrorMessage field={password} />}
          </div>
          <div className={styles.formItem}>
            <label>{intl.formatMessage({ id: "app.field.phoneNumber" })}</label>
            <Input
              name="phoneNumber"
              placeholder={intl.formatMessage({
                id: "app.placeholder.phoneNumber",
              })}
              value={phoneNumber.value}
              onChange={handleFieldChange}
            />
            {!isPristine && <ErrorMessage field={phoneNumber} />}
          </div>
          {/* <div className={styles.formItem}>
            <label>
              {intl.formatMessage({ id: "app.field.walletAmount" })}
            </label>
            <Input
              name="walletAmount"
              placeholder={intl.formatMessage({
                id: "app.placeholder.walletAmount",
              })}
              value={walletAmount.value}
              onChange={handleFieldChange}
            />
            {!isPristine && <ErrorMessage field={walletAmount} />}
          </div> */}
        </div>

        <div className={styles.formAction}>
          <Button type="default" shape="" onClick={closeModal}>
          {intl.formatMessage({id: 'app.button.cancel'})}
          </Button>
          <Button type="primary" shape="" onClick={formAction}>
          {intl.formatMessage({id: 'app.button.submit'})}
          </Button>
        </div>
      </Panel>
    </CustomModal>
  );
}

export default CustomerDetailsFormModal;
