import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Select, Button, Dropdown, Menu } from "antd";
import { MoreOutlined } from "@ant-design/icons";

import {
  Header,
  CustomTable,
  CustomImage,
  BackButton,
} from "../../components/common";
import { EditOrderTable, AddEditItemModal } from "./components";
import axios from "../../shared/api/axiosConfig";
import {
  numberWithCommas,
  removeEmptyKeys,
} from "../../shared/utils/object-manipulation";

import "./edit-order.scss";
import { toastr } from "react-redux-toastr";

const styles = {
  wrapper: "edit-order__wrapper",
  menu: "edit-order__menu",
  orderTotalWrapper: "edit-order__order-total-wrapper",
  menuAction: "edit-order__menu-action",
  tableMenu: "edit-order__table-menu",
  tableWrapper: "edit-order__table-wrapper",
};

function getFinalTotal(products) {
  return products.reduce((acc, val) => {
    acc += val.actualPrice * val.quantityBooked;
    return acc;
  }, 0);
}

function EditOrder(props) {
  const intl = useIntl();

  const [tableControls, updateTableControls] = useState({
    isLoading: false,
    isEmpty: false,
    data: [],
  });

  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    type: "",
    data: {},
    isLoading: false,
  });

  const [orderInfo, updateOrderInfo] = useState({
    bookingNumber: "",
    initialTotal: 0,
    deliveryCharges: 0,
    branchId: "",
  });

  const handleItemDelete = (item) => {
    updateTableControls({
      ...tableControls,
      data: tableControls.data.filter(
        (x) => x.productIdOnBooking !== item.productIdOnBooking
      ),
    });
  };

  const openAddItemModal = () => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      type: "ADD",
    });
  };

  const openEditItemModal = (data) => {
    console.log(data, "editOrderPromsdanata");
    updateModalControls({
      ...modalControls,
      isVisible: true,
      type: "EDIT",
      data,
    });
  };

  const getOrderDetails = () => {
    updateTableControls({
      data: [],
      isLoading: true,
      isEmpty: false,
    });
    axios
      .get("/getGroceryOrderDetails", {
        params: {
          bookingId: props.match.params.orderId,
        },
      })
      .then((res) => {
        if (!res.data.data.booking.length) {
          toastr.error("Error", "Something Went Wrong");
        } else {
          const products = JSON.parse(res.data.data.booking[0].products);
          if (products.length) {
            updateOrderInfo({
              bookingNumber: res.data.data.booking[0].bookingId,
              initialTotal: numberWithCommas(
                res.data.data.booking[0].totalAmount
              ),
              finalTotal: numberWithCommas(
                res.data.data.booking[0].totalAmount
              ),
              deliveryCharges: res.data.data.booking[0].deliveryCharges,
              branchId: res.data.data.booking[0].branchId,
            });
            updateTableControls({
              data: products.map((item) => {
                return {
                  ...item,
                  isOutOfStock:
                    item.quantity === 0 && item.stockUpdatedByPicker === 1,
                };
              }),
              isLoading: false,
              isEmpty: false,
            });
          } else {
            updateTableControls({
              ...tableControls,
              data: [],
              isLoading: false,
              isEmpty: true,
            });
          }
        }
      })
      .catch((err) => {
        // toastr.error("Error", "Something Went Wrong");
        updateTableControls({
          data: [],
          isLoading: false,
          isEmpty: true,
        });
      });
  };

  const closeModal = () => {
    updateModalControls({
      ...modalControls,
      isVisible: false,
    });
  };

  const handleItemAdd = (data) => {
    if (
      tableControls.data.filter(
        (item) => item.productIdOnBooking === data.productIdOnBooking
      ).length
    ) {
      toastr.error("Error", "Duplicates not allowed");
      return;
    }

    updateTableControls({
      ...tableControls,
      data: [...tableControls.data, data],
    });

    updateModalControls({
      ...modalControls,
      isVisible: false,
    });
  };

  const handleItemEdit = (data) => {
    let flag = false;
    // console.log('editItme', modalControls.data.productIdOnBooking, id )
    tableControls.data.forEach((item) => {
      // If A B C items in table; User changes A to C after editing => C B C; A => X and A => A allowed
      //
      if (
        item.productIdOnBooking === data.productIdOnBooking &&
        data.productIdOnBooking !== modalControls.data.productIdOnBooking
      ) {
        flag = true;
      }
    });
    if (flag) {
      toastr.error("Error", "Duplicates not allowed");
      return;
    }
    console.log(data);
    updateTableControls({
      ...tableControls,
      data: tableControls.data.map((item) =>
        item.productIdOnBooking === modalControls.data.productIdOnBooking
          ? data
          : item
      ),
    });

    updateModalControls({
      ...modalControls,
      isVisible: false,
    });
  };

  const handleCancelEditing = () => {
    props.history.goBack();
  };

  const handleOrderEdit = () => {
    console.log("data to update", tableControls.data);
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });

    const data = {};
    data.products = tableControls.data.map((item) => {
      return {
        productId: item.productIdOnBooking,
        quantity: item.quantityBooked,
        actualPrice: item.actualPrice,
      };
    });
    data.bookingId = orderInfo.bookingNumber;

    axios
      .put("/editOrderDetails", removeEmptyKeys(data))
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isVisible: false,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getOrderDetails();
          // props.history.goBack();
        } else {
          toastr.error("Error", res.data.message);
          if (res.data?.data?.outOfStockProducts?.length) {
            console.log("aopsihusgbik[ofbhufajopdeajifpsjiog");
            const alamanac = {};
            res.data.data.outOfStockProducts.forEach((item) => {
              alamanac[item.productId] = true;
            });

            updateTableControls({
              ...tableControls,
              data: tableControls.data.map((item) => {
                if (alamanac[item.productId]) {
                  return {
                    ...item,
                    isOutOfStock: true,
                  };
                } else return item;
              }),
            });
          }
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateModalControls({
          ...modalControls,
          isLoading: false,
        });
      });
  };

  useEffect(() => {
    getOrderDetails();
  }, []);

  const columns = [
    {
      dataIndex: "",
      title: intl.formatMessage({ id: "app.field.sNo" }),
      render: (e, item, index) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      dataIndex: "image",
      title: intl.formatMessage({ id: "app.field.productImage" }),
      render: (item) => <CustomImage src={item} isTable />,
    },
    {
      dataIndex: "name",
      title: intl.formatMessage({ id: "app.field.name" }),
    },
    {
      dataIndex: "quantityBooked",
      title: intl.formatMessage({ id: "app.field.quantity" }),
    },

    {
      dataIndex: "",
      title: intl.formatMessage({ id: "app.field.actualPrice" }),
      render: (item) => {
        return numberWithCommas(item.actualPrice);
      },
    },
    {
      title: intl.formatMessage({ id: "app.field.action" }),
      fixed: "right",
      render: (item) => {
        return (
          <Dropdown overlay={getMenu(item)}>
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  const getMenu = (item) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            openEditItemModal(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.edit" })}
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            handleItemDelete(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.delete" })}
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <div className={styles.wrapper}>
      <AddEditItemModal
        isLoading={modalControls.isLoading}
        data={modalControls.data}
        branchId={orderInfo.branchId}
        isVisible={modalControls.isVisible}
        modalType={modalControls.type}
        onCloseIconClick={closeModal}
        addItem={handleItemAdd}
        editItem={handleItemEdit}
      />
      <Header
        title="app.pageTitle.editOrder"
        subTitle={`#${orderInfo.bookingNumber}`}
      />
      <div style={{ margin: `0 32px` }}>
        <BackButton />
      </div>
      <div className="custom-menu">
        <div className="">
          <div>
            {`${intl.formatMessage({ id: "app.field.originalAmount" })}: `}
            <span>{orderInfo.initialTotal}</span>
          </div>
          <div>
            {`${intl.formatMessage({ id: "app.field.newAmount" })}: `}
            <span>{numberWithCommas(getFinalTotal(tableControls.data))}</span>
          </div>
        </div>
        <div className={styles.menuAction}>
          <Button
            onClick={handleCancelEditing}
            size="medium"
            style={{ marginRight: "2rem" }}
          >
            {intl.formatMessage({ id: "app.button.cancelEditing" })}
          </Button>
          <Button
            disabled={tableControls.isEmpty}
            onClick={handleOrderEdit}
            loading={tableControls.isLoading}
            type="primary"
            size="medium"
          >
            {intl.formatMessage({ id: "app.button.updateOrder" })}
          </Button>
        </div>
      </div>
      <div className="custom-section">
        <div className={"custom-menu"}>
          <header></header>
          <section>
            {" "}
            <Button
              onClick={openAddItemModal}
              disabled={tableControls.isEmpty}
              type="primary"
              shape=""
            >
              {intl.formatMessage({ id: "app.button.addProduct" })}
            </Button>
          </section>
        </div>
        <CustomTable
          isLoading={tableControls.isLoading}
          dataSource={tableControls.data}
          columns={columns}
          isEmpty={tableControls.isEmpty}
          pagination={false}
        />
      </div>
    </div>
  );
}

export default EditOrder;
