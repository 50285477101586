import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Select, Button, Menu, Dropdown, Tag } from "antd";
import {MoreOutlined} from '@ant-design/icons';
import { toastr } from "react-redux-toastr";
import moment from 'moment';
import {connect} from 'react-redux';

import { Header, CustomTable, Label, Locked, CustomTag } from "../../components/common";
import userActions from "../../redux/user-info/user-actions";
import { multiSort } from "../../shared/utils/object-manipulation";
import { ScheduleTable, AddEditScheduleModal } from "./components";

import axios from "../../shared/api/axiosConfig";

import "./schedule.scss";

import { DAYS } from "../../shared/constants/calendar";
import CopySlotsModal from "./components/copy-slots-modal/copy-slots-modal";
import { checkRtl } from "../../shared/utils/rtl";

const styles = {
  wrapper: "schedule__wrapper",
  menu: "schedule__menu",
  leftMenu: "schedule__menu--left",
  menuItem: "schedule__menu-item",
  table: "custom-table",
  menu: 'custom-menu',
  section: 'custom-section',
};

const { Option } = Select;

function timeFix(time) {
  let [hours, minutes] = time.split(':');
  if(hours.length === 1) {
      hours = `0${hours}`;
  }
  if(minutes.length === 1) {
      minutes = `0${minutes}`;
  }
  return `${hours}:${minutes}`;
}


function Schedule(props) {
  const intl = useIntl();

  const [stores, updateStores] = useState({
    selected: undefined,
    isLoading: true,
    data: [],
    notFound: false,
  });

  const [sortBy, updateSortBy] = useState('ascending');

  const [day, changeDay] = useState(new Date().getDay());

  const [tableControls, updateTableControls] = useState({
    isLoading: false,
    data: [],
    isEmpty: false,
  });

  const [modalControls, updateModalControls] = useState({
    isFormModalVisible: false,
    isCopyModalVisible: false,
    modalType: "",
    data: {},
    isLoading: false,
  });

  const openAddSlotModal = () => {
    updateModalControls({
      ...modalControls,
      isFormModalVisible: true,
      data: {},
      modalType: "ADD",
    });
  };

  const openEditSlotModal = (data) => {
    data.day = day;
    updateModalControls({
      ...modalControls,
      isFormModalVisible: true,
      data,
      modalType: "EDIT",
    });
  };

  const openCopySlotModal = () => {
    updateModalControls({
      ...modalControls,
      isCopyModalVisible: true,
      data: {},
      modalType: "COPY",
    });
  };

  const toggleSlot = (slotData, type) => {
    let key = "";
    switch (type) {
      case "lock":
        key = "isLocked";
        break;
      case "active":
        key = "isActive";
        break;
      case "delete":
        key = "isDeleted";
        break;
      default:
        return;
    }

    const data = {
      sId: slotData.sId,
      branchId: slotData.branchId,
      // startTime: slotData.startTime,
      // endTime: slotData.endTime,
      // maxOrders: slotData.maxOrders,
      // dayId: day,
      // vehicleType: slotData.vehicleType,
      [key]: slotData[key] ? 0 : 1,
    };

    updateTableControls({
      ...tableControls,
      data: tableControls.data.map((i) => {
        if (i.sId === slotData.sId) {
          i[key] = i[key] ? 0 : 1;
        }
        return i;
      }),
    });
    if(props.panelType == 1) {
      data.branchId = props.branchId;
    }
    axios
      .put("/updateWorkingSlots", data)
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isFormModalVisible: res.data.statusCode !== 200,
        });

        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getSlots();
        } else {
          toastr.error("Error", res.data.message);
          updateTableControls({
            ...tableControls,
            data: tableControls.data.map((i) => {
              if (i.sId === slotData.sId) {
                i[key] = i[key] ? 0 : 1;
              }
              return i;
            }),
          });
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateTableControls({
          ...tableControls,
          data: tableControls.data.map((i) => {
            if (i.sId === slotData.sId) {
              i[key] = i[key] ? 0 : 1;
            }
            return i;
          }),
        });
      });
  };

  const handleModalClose = () => {
    updateModalControls({
      ...modalControls,
      isFormModalVisible: false,
      isCopyModalVisible: false,
      data: {},
    });
  };

  const addSlot = (data) => {
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });
    data.branchId = stores.selected;
    if(props.panelType == 1) {
     data.branchId = props.branchId;
    }

    // fetch('http://13.244.73.187:3001/api/admin/addWorkingSlots', {
    //   method: 'POST',
    //   body: JSON.stringify(data),
    //   headers: {
    //     'access-token': sessionStorage.getItem('jwt'),
    //     'content-language': 'en',
    //     'content-type': 'application/json'
    //   }
    // }).then(r => r.json()).then((res) => {
    //     updateModalControls({
    //       ...modalControls,
    //       isLoading: false,
    //       isFormModalVisible: res.statusCode !== 200,
    //     });

    //     if (res.statusCode === 200) {
    //       toastr.success(res.message);
    //       if (day === data.dayId) {
    //         getSlots();
    //       }
    //     } else {
    //       toastr.error("Error", res.message);
    //     }
    // }).catch((err) => {
    //     updateModalControls({
    //       ...modalControls,
    //       isLoading: false,
    //       isFormModalVisible: true,
    // })})

    axios
      .post("/addWorkingSlots", data)
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isFormModalVisible: res.data.statusCode !== 200,
        });

        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          if (day === data.dayId) {
            getSlots();
          }
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isFormModalVisible: true,
        });
        // const message = err?.data?.message;
        // toastr.error('Error', message ? message : intl.formatMessage({id: 'app.message.error'}))
      });
  };

  const editSlot = (data) => {
    console.log(data);
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });
    data.branchId = modalControls.data.branchId;
    data.sId = modalControls.data.sId;
    if(props.panelType == 1) {
      data.branchId = props.branchId
    }
    // fetch('http://13.244.73.187:3001/api/admin/updateWorkingSlots', {
    //   method: 'PUT',
    //   body: JSON.stringify(data),
    //   headers: {
    //     'access-token': sessionStorage.getItem('jwt'),
    //     'content-language': 'en',
    //     'content-type': 'application/json'
    //   }
    // }).then(r => r.json()).then((res) => {
    //     updateModalControls({
    //       ...modalControls,
    //       isLoading: false,
    //       isFormModalVisible: res.statusCode !== 200,
    //     });

    //     if (res.statusCode === 200) {
    //       toastr.success(res.message);
    //       if (day === data.dayId) {
    //         getSlots();
    //       }
    //     } else {
    //       toastr.error("Error", res.message);
    //     }
    // }).catch((err) => {
    //     updateModalControls({
    //       ...modalControls,
    //       isLoading: false,
    //       isFormModalVisible: true,
    //     });
    // })

    axios
      .put("/updateWorkingSlots", data)
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isFormModalVisible: res.data.statusCode !== 200,
        });

        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          if (day === data.dayId) {
            getSlots();
          }
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isFormModalVisible: true,
        });
        // const message = err?.data?.message;
        // toastr.error('Error', message ? message : intl.formatMessage({id: 'app.message.error'}))
      });
  };

  const copySlot = (data) => {
    console.log(data);
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });
    data.branchId = stores.selected;
    if(props.panelType == 1) {
      data.branchId = props.branchId
    }
    axios
      .post("/copySlot", data)
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isCopyModalVisible: res.data.statusCode !== 200,
        });

        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getSlots();
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isCopyModalVisible: false,
        });
        // const message = err?.data?.message;
        // toastr.error('Error', message ? message : intl.formatMessage({id: 'app.message.error'}))
      });
  };

  // Mount
  useEffect(() => {

    if(props.panelType == 1) {
      // Store Panel
      updateStores({
        ...stores,
        selected: props.branchId,
        isLoading: false,
      })
      // getSlots()
      return;
    }

    axios
      .get("/getBranches", {
        params: {
          onlyNames: 1
        }
      })
      .then((res) => {
        if (res.data.data.branchesData.length) {
          // res.data.data.branchesData = multiSort(res.data.data.branchesData, {
          //   branchName: 'asc',
          // })
          // console.log("Data of branches: ",res.data.data.branchesData);
          updateStores({
            selected: props.storeId ? props.storeId : res.data.data.branchesData[0].branchId,
            data: res.data.data.branchesData,
            isLoading: false,
            notFound: false,
          });
        } else {
          updateStores({
            ...stores,
            notFound: true,
          });
        }
      })
      .catch((err) => {
        console.log(err)
        updateStores({
          ...stores,
          notFound: true,
        });
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
      });
  }, []);

  const getSlots = () => {
    updateTableControls({
      ...tableControls,
      isLoading: true,
      isEmpty: false,
      data: [],
    });

    // fetch(`http://13.244.73.187:3001/api/admin/getWorkingSlots?dayId=${day}&branchId=${stores.selected}`, {
    //   method: 'GET',
    //   headers: {
    //     'access-token': sessionStorage.getItem('jwt'),
    //     'content-language': 'en',
    //     'content-type': 'application/json',
    //   }
    // }).then(r => r.json()).then((res) => {
    //     if (res.data.branchSlots.length) {

    //       updateTableControls({
    //         data: res.data.branchSlots,
    //         isLoading: false,
    //         isEmpty: false,
    //       });
    //     } else {
    //       updateTableControls({
    //         data: [],
    //         isLoading: false,
    //         isEmpty: true,
    //       });
    //     }
    // }).catch((err) => {
    //     updateTableControls({
    //       data: [],
    //       isLoading: false,
    //       isEmpty: true,
    //     });
    // })
    const data = {
      branchId: stores.selected,
      dayId: day,
    }
    if(props.panelType == 1) {
      data.branchId = props.branchId
    }
    axios
      .get("/getWorkingSlots", {
        params: data,
      })
      .then((res) => {
        if (res.data.data.branchSlots.length) {
          updateTableControls({
            data: res.data.data.branchSlots,
            isLoading: false,
            isEmpty: false,
          });
        } else {
          updateTableControls({
            data: [],
            isLoading: false,
            isEmpty: true,
          });
        }
      })
      .catch((err) => {
        updateTableControls({
          data: [],
          isLoading: false,
          isEmpty: true,
        });
        // const message = err?.data?.message;
        // toastr.error('Error',intl.formatMessage({id: 'app.message.error'}))
      });
  };

  const handleStoreChange = (store) => {
    updateStores({
      ...stores,
      selected: store,
    });
    // props.dispatch(
    //   userActions.setStore(
    //     store
    //   )
    // );
  };

  // Advertisements
  useEffect(() => {
    if (stores.selected !== undefined) {
      getSlots();
    }
  }, [stores.selected, day]);


  const getMenu = (item) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            openEditSlotModal(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.edit" })}
        </Menu.Item>
        {props.panelType == 0 && item.isLocked == 0  && (
          <Menu.Item
            onClick={() => {
              toggleSlot(item, "lock");
            }}
          >
            {intl.formatMessage({ id: "app.field.lock" })}
          </Menu.Item>
        )}
        {props.panelType == 0 && item.isLocked == 1 && (
          <Menu.Item
            onClick={() => {
              toggleSlot(item, "lock");
            }}
          >
            {intl.formatMessage({ id: "app.field.unlock" })}
          </Menu.Item>
        )}

        <Menu.Item
          onClick={() => {
            toggleSlot(item, "delete");
          }}
        >
          {intl.formatMessage({ id: "app.field.delete" })}
        </Menu.Item>
      </Menu>
    );
  };
  const transportType = ["Bike", "Truck"];
  const columns = [
    {
      // sorter: true,
      title: intl.formatMessage({ id: "app.field.day" }),
      render: (i) => intl.formatMessage({id: DAYS[day].text})
    },
    {
      dataIndex: "vehicleType",
      title: intl.formatMessage({ id: "app.field.deliveryMode" }),
      render: (item) => {
        return transportType[item] || "N/A"
      },
    },
    {
      dataIndex: "startTime",
      title: intl.formatMessage({ id: "app.field.startTime" }),
      render: (item) => timeFix(item),
    },
    {
      dataIndex: "endTime",
      title: intl.formatMessage({ id: "app.field.endTime" }),
      render: (item) => timeFix(item),
    },
    {
      dataIndex: "maxOrders",
      title: intl.formatMessage({ id: "app.field.maxOrders" }),
    },
    {
      dataIndex: "isLocked",
      title: intl.formatMessage({ id: "app.field.lockStatus" }),
      render: (item) => {
        return <CustomTag isLocked={item} />
      }
    },
    {
      title: intl.formatMessage({ id: "app.field.action" }),
      dataIndex: "",
      fixed: "right",
      render: (item) => {
        // debugger;
        if(props.panelType != 0 && item.isLocked == 1) {
          return <Locked />
        }
        return (
          <Dropdown overlay={getMenu(item)}>
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  return (
    <div className={styles.wrapper}>
      <Header title="app.pageTitle.schedule" />
      <AddEditScheduleModal
        modalType={modalControls.modalType}
        data={modalControls.data}
        isVisible={modalControls.isFormModalVisible}
        onCloseIconClick={handleModalClose}
        isLoading={modalControls.isLoading}
        addSlot={addSlot}
        editSlot={editSlot}
      />
      <CopySlotsModal
        modalType={modalControls.modalType}
        day={day}
        isVisible={modalControls.isCopyModalVisible}
        onCloseIconClick={handleModalClose}
        isLoading={modalControls.isLoading}
        copySlot={copySlot}
      />

      <div className={styles.section}>

      <div className={styles.menu}>
        <header>
          { props.panelType == 0 && <div className={styles.menuItem}>
            <Label horizontal value="app.field.store">

            </Label>
            <Select
              showSearch
              style={{ width: 180 }}
              size="medium"
              // mode="multiple"
              placeholder={intl.formatMessage({
                id: "app.placeholder.store",
              })}
              onChange={handleStoreChange}
              value={stores.selected}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {stores.data.map((store) => (
                <Option key={store.branchId} value={store.branchId}>
                  {store.branchName}
                </Option>
              ))}
            </Select>
          </div>}
          <div className={styles.menuItem}>
            <Label horizontal value="app.field.day">
            </Label>
            <Select
              style={{ width: 180 }}
              placeholder={intl.formatMessage({
                id: "app.placeholder.day",
              })}
              size="medium"
              onChange={changeDay}
              value={day}
            >
              {DAYS.map((item) => (
                <Option key={item.value} value={item.value}>
                  {intl.formatMessage({id: item.text})}
                </Option>
              ))}
              
            </Select>
          </div>
          {/* <div className={styles.menuItem}>
            <label>
              {intl.formatMessage({
                id: "app.field.sortBy",
              })}
            </label>
            <Select
              style={{ width: 180 }}
              placeholder={intl.formatMessage({
                id: "app.field.sortBy",
              })}
              onChange={updateSortBy}
              value={sortBy}
            >
                <Option key="ascending" value="ascending">
                  {intl.formatMessage({id: 'app.field.ascending'})}
                </Option>
                
                <Option key="descending" value="descending">
                {intl.formatMessage({id: 'app.field.descending'})}
                </Option>
           </Select>
          </div> */}

        </header>
        <section>
        <Button
              onClick={openCopySlotModal}
              type="primary"
              shape={undefined}
              size="medium"
              disabled={props.isAddDisabled}
            >
              {intl.formatMessage({
                id: "app.button.copySlot",
              })}
            </Button>
          <Button
            onClick={openAddSlotModal}
            type="primary"
            shape={undefined}
            disabled={props.isAddDisabled}
            size="medium"
          >
            {intl.formatMessage({ id: "app.button.addSlot" })}
          </Button>
        </section>
      </div>

      <div>
      <CustomTable
          isLoading={tableControls.isLoading}
          dataSource={tableControls.data}
          columns={columns}
          // pagination={false}
          rowKey={(item) => item.scheduleId}
        />
      </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  storeId: state.user.enterprice.storeId,
  panelType: state.user.enterprice.panelType,
  isAddDisabled: state.user.isAddDisabled,
  isRTL: checkRtl(state.user.locale),
  branchId: state.user.userInfo.branchId,
})

export default connect(mapStateToProps)(Schedule);