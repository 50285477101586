import React, { useState, useEffect } from "react";
import { Input, Button, Select, Dropdown, Menu, Popconfirm, Tooltip, Form, Typography, Table } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import {
  CustomModal,
  Panel,
  ErrorMessage,
  UploadButton,
  Icon,
} from "../../../../components/common";
import axios from "../../../../shared/api/axiosConfig";
import Validations from "../../../../shared/validations/Validations";
import placeholderImg from "../../../../assets/images/item-placeholder.png";
import "./add-edit-course.scss";

const styles = {
  formItem: "custom-form-item",
  formGroup: "custom-form-group",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  imageContainer: "courses__add-edit-course-modal__image-container",
  imageContainerImage: "courses__add-edit-course-modal__image-container__image",
  imageContainerForm: "courses__add-edit-course-modal__image-container__form",
};

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'text' ? <TextArea /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const {TextArea}  = Input;
const { Option } = Select;
const errorText = "Field cannot be empty";

function AddEditCourse(props) {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [isContentPristine, toggleContentPristine] = useState(true);
  const [isPristine, togglePristine] = useState(true);
  const [name, changeName] = useState(Validations.validateTextField(""));
  const [subText, changeSubText] = useState(Validations.validateTextField(""));
  const [overview, changeOverview] = useState(Validations.validateTextField(""));
  const [intro, changeIntro] = useState(Validations.validateYoutubeLink(""));
  const [lead, changeLead] = useState(Validations.validateTextField(""));
  const [rank, changeRank] = useState(Validations.validateNumericValue(null));
  const [imageURL, changeImageURL] = useState(
    Validations.validateTextField("")
  );
  const [content, changeContent] = useState([]);
  const [heading, changeHeading] = useState(Validations.validateTextField(""));
  const [text, changeText] = useState(Validations.validateTextField(""));
  const [section, updateSection] = useState({
    selected: undefined,
    data: [
      {
        key: "engage",
        value: "Engage",
      },
      {
        key: "adopt",
        value: "Adopt",
      },
      {
        key: "save",
        value: "Save",
      },
      {
        key: "train",
        value: "Train",
      },
    ],
  });


  const [editingKey, setEditingKey] = useState('');

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      heading: '',
      text: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...content];
      const index = content.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = content[index];
        newData.splice(index, 1, { ...item, ...row });
        changeContent(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        changeContent(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const handleSectionChange = (sec) => {
    updateSection({
      ...section,
      selected: sec,
    });
  };

  const resetForm = () => {
    togglePristine(true);
    changeName(Validations.validateTextField(null));
    changeSubText(Validations.validateTextField(null));
    changeOverview(Validations.validateTextField(null));
    changeLead(Validations.validateTextField(null));
    changeIntro(Validations.validateYoutubeLink(null));
    updateSection({
      ...section,
      selected: undefined,
    });
    changeRank(Validations.validateNumericValue(null));
    changeImageURL(Validations.validateTextField(null));
  };

  const setForm = () => {
    togglePristine(true);
    changeName(Validations.validateTextField(props.data.title));
    changeSubText(Validations.validateTextField(props.data.subText));
    changeOverview(Validations.validateTextField(props.data.overview));
    changeLead(Validations.validateTextField(props.data.lead));
    updateSection({
      ...section,
      selected: props.data.section
    });
    changeIntro(Validations.validateYoutubeLink(props.data.introductionVideo[0]));
    changeImageURL(Validations.validateTextField(props.data.cover));
    // let con = (props.data.content || []).map((el) => {
    //   let obj = {};
    //   obj.key = el.key;
    //   obj.value = el.value;
    //   return obj;
    // });
    // changeContent(con)
  };

  const contentColumns = [
    {
      dataIndex : "key",
      title : "Heading",
      editable: true,
    },
    {
      dataIndex : "value",
      title : "Text",
      editable: true,
    },
    {
      dataIndex: "",
      title: intl.formatMessage({ id: "app.field.action" }),
      fixed: "right",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <Typography.Link disabled={editingKey !== ''}>
              <Tooltip title="Edit" placement="top">
                <span
                  onClick={() => {edit(record)}}
                  style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                >
                  <Icon name="PEN" pointer={1} size="small" />
                </span>
              </Tooltip>
            </Typography.Link>
            <Typography.Link>
              <Tooltip title="Delete" placement="top">
                <span
                  onClick={() => {deleteContent(record)}}
                  style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                >
                  <Icon name="TRASH" pointer={1} size="small" />
                </span>
              </Tooltip>
            </Typography.Link>
          </div>
        );
      },
    }
  ];

  const mergedColumns = contentColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'Text' ? 'text' : 'heading',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  useEffect(() => {
    if(props.isVisible) {
      if (props.modalToShow === "EDIT_COURSE") {
        setForm();
      } else if (props.modalToShow === "ADD_COURSE") {
        resetForm();
      }
    }
  }, [props.isVisible, props.data]);


  const handleFieldChange = (e) => {
    const { value } = e.target;
    switch (e.target.name) {
      case "name":
        changeName(Validations.validateTextField(value));
        break;
      case "subText":
        changeSubText(Validations.validateTextField(value));
        break;
      case "overview":
        changeOverview(Validations.validateTextField(value));
        break;
      case "lead":
        changeLead(Validations.validateTextField(value));
        break;
      case "intro":
        changeIntro(Validations.validateYoutubeLink(value));
        break;
      case "rank":
        changeRank(Validations.validateNumericValue(value));
        break;
      case "heading":
        changeHeading(Validations.validateTextField(value));
        break;
      case "text":
        changeText(Validations.validateTextField(value));
        break;
      default:
        break;
    }
  };

  const getData = () => {
    const data = {
      title: section.selected,
      // cover: imageURL.value,
      // section: section.selected,
      introductionVideo: [intro.value],
      lead: lead.value,
      subText: subText.value,
      overview: overview.value, 
      // rank: rank.value,
    }
    // if(pair.length > 0){
    //   data.content = pair;
    // }else{
    //   let co = {};
    //   content.map((item) => {
    //     co[item.key] = item.value;
    //   });
    //   data.content = co;
    // }
    // if(section.selected !== "save"){
    //   delete data.content;
    // }
    return data;
  };

  const handleAdd = () => {
    // TODO
    props.addCourse(getData());
  };

  const handleEdit = () => {
    // TODO
    props.editCourse(getData());
  };

  const isFormValid = () => {
    let isValid = section.selected  && intro.isValid && overview.isValid && lead.isValid && subText.isValid ;
    return isValid;
  };

  const closeModal = () => {
    props.onCloseIconClick();
  };

  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };
  let pair = [];
  const addContent = () => {
    toggleContentPristine(true);
    if(heading.value === "" || text.value === ""){
      toggleContentPristine(false);
      return;
    }
    let cc = [];
    cc.push({
      key: heading.value, value: text.value,
    });
    changeContent(content => [...content, {
      key: heading.value,
      value: text.value,
    }]);
    changeHeading(Validations.validateTextField(""));
    changeText(Validations.validateTextField(""));
  };

  const deleteContent = (item) => {
    let index = content.findIndex(
      (time) => time.key === item.key && time.value === item.value
    );
    if (index !== -1) content.splice(index, 1);
    changeContent([...content]);
  };
  const editContent = (item) => {
    let index = content.findIndex(
      (time) => time.key === item.key && time.value === item.value
    );
    changeHeading(Validations.validateTextField(item.key));
    changeText(Validations.validateTextField(item.value));
  };

  const formAction = () => {
    togglePristine(false);
    if (!isFormValid()) {
      return;
    }
    if (props.modalToShow === "ADD_COURSE") {
      handleAdd();
    } else if (props.modalToShow === "EDIT_COURSE") {
      handleEdit();
    }
  };

  const handleImageChange = (image) => {
    changeImageURL(Validations.validateTextField(image));
  };

  return (
    <CustomModal
      size="tiny"
      onCloseIconClick={closeModal}
      header={
        props.modalToShow === "ADD_COURSE"
          ? "Add Course"
          : "Edit Course"
      }
      isVisible={props.isVisible}
    >
      <Panel isLoading={props.isLoading} className={styles.formModal}>
        <div className={styles.imageContainer}>
          {/* <div className={styles.imageContainerImage}>
            <img
              src={imageURL.value ? imageURL.value : placeholderImg}
              alt=""
            />
            <UploadButton
              text="Upload Image"
              resultType="url"
              accept="image/*"
              onChange={handleImageChange}
            />
            {!isPristine && <ErrorMessage field={imageURL} />}
          </div> */}

          <div className={styles.imageContainerForm}>
            {/* <div className={styles.formItem}>
              <label>
                {intl.formatMessage({
                  id: "app.field.rank",
                })}
              </label>
              <Input
                name="rank"
                type="number"
                onChange={handleFieldChange}
                value={rank.value}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.rank",
                })}
              />
              {!isPristine && <ErrorMessage field={rank} />}
            </div> */}
            {/* <div className={styles.formItem} style={{ maxHeight:"21rem"}}>
            <img
              src={imageURL.value ? imageURL.value : placeholderImg}
              alt=""
              style={{ maxHeight:"18rem"}}
            />
            <UploadButton
              text="Upload Image(500X500)"
              resultType="url"
              accept="image/*"
              onChange={handleImageChange}
            />
            {!isPristine && <ErrorMessage field={imageURL} />}
            </div> */}
            <div className={styles.formItem}>
              <label>
                Type of Course
              </label>
              <Input
                disabled
                value={section.selected} />
              {/* <Select
                placeholder="Select Type of Course"
                disabled
                onChange={handleSectionChange}
                value={section.selected}
              >
                {section.data.map((type) => (
                  <Option key={type.key} value={type.key}>
                    {type.value}
                  </Option>
                ))}
              </Select> */}
              {/* {!isPristine && section.selected === undefined && (
                <ErrorMessage text={errorText} />
              )} */}
              {/* {!isPristine && <ErrorMessage field={section} />} */}
            </div>

            <div className={styles.formItem}>
              <label>
                  Sub Text
              </label>
              <Input
                name="subText"
                onChange={handleFieldChange}
                value={subText.value}
                placeholder="Enter Sub Text of Course"
              />
              {!isPristine && <ErrorMessage field={subText} />}
            </div>
            <div className={styles.formItem}>
              <label>
                  Introduction Text
              </label>
              <Input
                name="lead"
                onChange={handleFieldChange}
                value={lead.value}
                placeholder="Enter Introduction of the Course"
              />
              {!isPristine && <ErrorMessage field={lead} />}
            </div>
            <div className={styles.formItem}>
              <label>
                  Overview
              </label>
              <TextArea
                row={3}
                name="overview"
                onChange={handleFieldChange}
                value={overview.value}
                placeholder="Enter Overview of the Course"
              />
              {!isPristine && <ErrorMessage field={overview} />}
            </div>
            <div className={styles.formItem}>
              <label>
                  Introduction Video
              </label>
              <Input
                name="intro"
                onChange={handleFieldChange}
                value={intro.value}
                placeholder="Enter Introduction Video Link of the Course"
              />
              {/* <iframe width="450" height="345" src={intro.value} frameborder="0" allowfullscreen ng-show="showvideo"></iframe> */}
            {/* <iframe width="420" height="345" src={intro.value}>
            </iframe> */}
              {!isPristine && <ErrorMessage field={intro} />}
            </div>
              {/* {section.selected === "save" && (<div >
              <div className={styles.formGroup}>
                <label style={{ fontSize : "20px", textTransform : "uppercase", flex:"2" }}>CONTENT</label>
              </div>
              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <label>
                    Heading
                  </label>
                  <Input
                    name="heading"
                    onChange={handleFieldChange}
                    value={heading.value}
                    placeholder="Enter Heading"
                  />
                {!isContentPristine && <ErrorMessage field={heading}/>}
                </div>
                <div className={styles.formItem}>
                  <label>
                    Text/Link
                  </label>
                  <TextArea
                    row={5}
                    name="text"
                    onChange={handleFieldChange}
                    value={text.value}
                    placeholder="Enter Text"
                  />
                  {!isContentPristine && <ErrorMessage field={text} />}
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button type="primary" shape={undefined} onClick={addContent}>Add</Button>
                </div>
              </div>
              <div>
                <Form form={form} component={false}>
                  <Table
                  className="custom-table"
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  bordered
                  dataSource={content}
                  columns={mergedColumns}
                  rowClassName="editable-row"
                  pagination={{
                    onChange: cancel,
                  }} 
                />
                </Form>
              </div>
            </div>)} */}
          </div>
        </div>
        <div className={styles.formAction}>
          <Button type="default" shape={undefined} onClick={closeModal}>
          {intl.formatMessage({id: 'app.button.cancel'})}
          </Button>
          <Button type="primary" shape={undefined} onClick={formAction}>
          {intl.formatMessage({id: 'app.button.submit'})}
          </Button>
        </div>
      </Panel>
    </CustomModal>
  );
}

export default AddEditCourse;