import React, { useState, useEffect } from "react";
import { Input, Button, Select } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import {
  CustomModal,
  Panel,
  ErrorMessage,
  UploadButton,
} from "../../../../components/common";
import axios from "../../../../shared/api/axiosConfig";
import Validations from "../../../../shared/validations/Validations";
import placeholderImg from "../../../../assets/images/item-placeholder.png";
import "./add-edit-lesson.scss";
const {TextArea}  = Input;
const styles = {
    formItem: "custom-form-item",
    formModal: "custom-form-modal",
    formAction: "custom-form-action",
    imageContainer: "lessons__add-edit-lesson-modal__image-container",
    imageContainerImage: "lessons__add-edit-lesson-modal__image-container__image",
    imageContainerForm: "lessons__add-edit-lesson-modal__image-container__form",
};

const { Option } = Select;
const errorText = "Field cannot be empty";

function AddEditLesson(props) {
  const intl = useIntl();

  const [isPristine, togglePristine] = useState(true);
  const [name, changeName] = useState(Validations.validateTextField(""));
  const [overview, changeOverview] = useState(Validations.validateTextField(""));
  const [intro, changeIntro] = useState(Validations.validateTextField(""));
  const [lead, changeLead] = useState(Validations.validateTextField(""));
  // const [section, changeSection] = useState(Validations.validateTextField(""));
  const [rank, changeRank] = useState(Validations.validateNumericValue(null));
  const [imageURL, changeImageURL] = useState(
    Validations.validateTextField("")
  );
  const [lessonType, updateLessonType] = useState({
    selected: undefined,
    data: [
      {
        key: "video",
        value: "Video",
      },
      {
        key: "reading",
        value: "Reading",
      },
    ],
  });


  const handleLessonChange = (sec) => {
    updateLessonType({
      ...lessonType,
      selected: sec,
    });
  };

  const resetForm = () => {
    togglePristine(true);
    changeName(Validations.validateTextField(null));
    // changeOverview(Validations.validateTextField(null));
    changeLead(Validations.validateTextField(null));
    // changeIntro(Validations.validateTextField(null));
    updateLessonType({
      ...lessonType,
      selected: undefined,
    });
    // changeSection(Validations.validateTextField(null));
    changeRank(Validations.validateNumericValue(null));
    changeImageURL(Validations.validateTextField(null));
  };

  const setForm = () => {
    togglePristine(true);
    changeName(Validations.validateTextField(props.data.title));
    changeOverview(Validations.validateTextField(props.data.overview));
    changeLead(Validations.validateTextField(props.data.lead));
    // changeSection(Validations.validateTextField(props.data.section));
    updateLessonType({
      ...lessonType,
      selected: props.data.lessonType
    });
    changeIntro(Validations.validateTextField(props.data.introduction));
    changeRank(Validations.validateNumericValue(props.data.lessonIndex));
    changeImageURL(Validations.validateTextField(props.data.cover));
  };
  useEffect(() => {
    if(props.isVisible) {
      if (props.modalToShow === "EDIT_LESSON") {
        setForm();
        //   setForm();
      } else if (props.modalToShow === "ADD_LESSON") {
        resetForm();
      }
    }
  }, [props.isVisible, props.data]);


  const handleFieldChange = (e) => {
    const { value } = e.target;

    switch (e.target.name) {
      case "name":
        changeName(Validations.validateTextField(value));
        break;
      case "overview":
        changeOverview(Validations.validateTextField(value));
        break;
      case "lead":
        changeLead(Validations.validateTextField(value));
        break;
      // case "section":
      //   changeSection(Validations.validateTextField(value));
      //   break;
      case "intro":
        changeIntro(Validations.validateTextField(value));
        break;
      case "rank":
        changeRank(Validations.validateNumericValue(value));
        break;
      default:
        break;
    }
  };

  const getData = () => ({
    title: name.value,
    cover: imageURL.value,
    // lessonType: lessonType.selected,
    // introduction: intro.value,
    lead: lead.value,
    // overview: overview.value, 
    lessonIndex: rank.value,
  });

  const handleAdd = () => {
    // TODO
    props.addLesson(getData());
  };

  const handleEdit = () => {
    // TODO
    props.editLesson(getData());
  };

  const isFormValid = () => {
    let isValid = name.isValid && imageURL.isValid && rank.isValid && lead.isValid ;
    // if(props.modalToShow === 'ADD_COURSE') {
    //   isValid = isValid && store.isValid;
    // }
    return isValid;
  };

  const closeModal = () => {
    props.onCloseIconClick();
  };

  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const formAction = () => {
    togglePristine(false);
    if (!isFormValid()) {
      return;
    }
    if (props.modalToShow === "ADD_LESSON") {
      handleAdd();
    } else if (props.modalToShow === "EDIT_LESSON") {
      handleEdit();
    }
  };

  const handleImageChange = (image) => {
    changeImageURL(Validations.validateTextField(image));
  };

  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={closeModal}
      header={
        props.modalToShow === "ADD_LESSON"
          ? "Add Lesson"
          : "Edit Lesson"
      }
      isVisible={props.isVisible}
    >
      <Panel isLoading={props.isLoading} className={styles.formModal}>
        <div className={styles.imageContainer}>
          <div className={styles.imageContainerImage}>
            <img
              src={imageURL.value ? imageURL.value : placeholderImg}
              alt=""
            />
            <UploadButton
              text="Upload Image"
              resultType="url"
              accept="image/*"
              onChange={handleImageChange}
            />
            {!isPristine && <ErrorMessage field={imageURL} />}
          </div>

          <div className={styles.imageContainerForm}>
            <div className={styles.formItem}>
              <label>
                {intl.formatMessage({
                  id: "app.field.rank",
                })}
              </label>
              <Input
                name="rank"
                type="number"
                onChange={handleFieldChange}
                value={rank.value}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.rank",
                })}
              />
              {!isPristine && <ErrorMessage field={rank} />}
            </div>
            {/* <div className={styles.formItem} style={{ height:"21rem"}}>
            <img
              src={imageURL.value ? imageURL.value : placeholderImg}
              alt=""
            />
            <UploadButton
              text="Upload Image"
              resultType="url"
              accept="image/*"
              onChange={handleImageChange}
            />
            {!isPristine && <ErrorMessage field={imageURL} />}
            </div> */}
            <div className={styles.formItem}>
              <label>
                  Title
                {/* {intl.formatMessage({
                  id: "app.field.brandName",
                })} */}
              </label>
              <Input
                name="name"
                onChange={handleFieldChange}
                value={name.value}
                placeholder="Enter Title"
                // placeholder={intl.formatMessage({
                //   id: "app.placeholder.brandName",
                // })}
              />
              {!isPristine && <ErrorMessage field={name} />}
            </div>
            <div className={styles.formItem}>
              <label>
                  Introduction Text
                {/* {intl.formatMessage({
                  id: "app.field.brandName",
                })} */}
              </label>
              <Input
                name="lead"
                onChange={handleFieldChange}
                value={lead.value}
                placeholder="Enter Introduction "
                // placeholder={intl.formatMessage({
                //   id: "app.placeholder.brandName",
                // })}
              />
              {!isPristine && <ErrorMessage field={lead} />}
            </div>
            {/* <div className={styles.formItem}>
              <label>
                  Overview
              </label>
              <TextArea
              row={3}
                name="overview"
                onChange={handleFieldChange}
                value={overview.value}
                placeholder="Enter Overview "
                placeholder={intl.formatMessage({
                  id: "app.placeholder.brandoverview",
                })}
              />
              {!isPristine && <ErrorMessage field={overview} />}
            </div> */}
            {/* <div className={styles.formItem}>
              <label>
                  Lesson Type
              </label>
              <Select
              placeholder="Select Lesson Type"
                onChange={handleLessonChange}
                value={lessonType.selected}
              >
                {lessonType.data.map((type) => (
                  <Option key={type.key} value={type.key}>
                    {type.value}
                  </Option>
                ))}
              </Select>
              {!isPristine && lessonType.selected === undefined && (
                <ErrorMessage text={errorText} />
              )}
            </div> */}
            {/* <div className={styles.formItem}>
              <label>
                  Introduction Video
              </label>
              <Input
                name="intro"
                onChange={handleFieldChange}
                value={intro.value}
                placeholder="Enter Introduction Video Link "
                
              />
            </div> */}
          </div>
        </div>
        <div className={styles.formAction}>
          <Button type="default" shape={undefined} onClick={closeModal}>
          {intl.formatMessage({id: 'app.button.cancel'})}
          </Button>
          <Button type="primary" shape={undefined} onClick={formAction}>
          {intl.formatMessage({id: 'app.button.submit'})}
          </Button>
        </div>
      </Panel>
    </CustomModal>
  );
}

export default AddEditLesson;