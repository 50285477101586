import React from "react";
import { useIntl } from "react-intl";
import { Tooltip, Popconfirm, Switch } from "antd";
import classNames from "classnames";
import { Panel, Icon } from "../../../../components/common";
import placeholderImg from "../../../../assets/images/item-placeholder.png";
import "./products-table.scss";

const styles = {
  wrapper: "products-table__wrapper",
  stickyRight: "products-table__sticky-right",
  panelContainer: "custom-table-panel-container",
  productDetails: {
    wrapper: "products-table__product-details__wrapper",
    info: "products-table__product-details__info",
    image: "products-table__product-details__image",
    name: "products-table__product-details__name",
    description: "products-table__product-details__description",
  },
  table: "custom-table",
  tableActions: "products-table__table-actions",
  actionIcon: "products-table__action-icon",
};

const handleImageError = (e) => {
  e.target.src = placeholderImg;
};

function ProductsTable(props) {
  const intl = useIntl();
  return (
    <Panel
      isLoading={props.isLoading}
      isEmpty={props.isEmpty}
      emptyText="No Data"
      className={styles.wrapper}
      containerClass={styles.panelContainer}
    >
      <table className={styles.table}>
        <tbody>
          <tr>
            <th>{intl.formatMessage({ id: "app.field.rank" })}</th>
            <th>{intl.formatMessage({ id: "app.field.productDetails" })}</th>
            <th>{intl.formatMessage({ id: "app.field.skuId" })}</th>
            <th>{intl.formatMessage({ id: "app.field.manufacturer" })}</th>
            <th>{intl.formatMessage({ id: "app.field.brand" })}</th>
            <th>{intl.formatMessage({ id: "app.field.costPrice" })}</th>
            <th>{intl.formatMessage({ id: "app.field.actualPrice" })}</th>
            <th>{intl.formatMessage({ id: "app.field.vat" })}</th>
            <th>{intl.formatMessage({ id: "app.field.margin" })}</th>
            <th>{intl.formatMessage({ id: "app.field.quantity" })}</th>
            <th>{intl.formatMessage({ id: "app.field.length" })}</th>
            <th>{intl.formatMessage({ id: "app.field.breadth" })}</th>
            <th>{intl.formatMessage({ id: "app.field.height" })}</th>
            <th>{intl.formatMessage({ id: "app.field.weight" })}</th>
            <th>{intl.formatMessage({ id: "app.field.redeemable" })}</th>
            <th>{intl.formatMessage({ id: "app.field.redemptionPoints" })}</th>
            <th>{intl.formatMessage({ id: "app.field.barcode" })}</th>
            <th>{intl.formatMessage({ id: "app.field.featureFlag" })}</th>
            <th className={styles.stickyRight}>
              {intl.formatMessage({ id: "app.field.action" })}
            </th>
          </tr>

          {props.data.map((product) => (
            <tr key={product.productId}>
              <td>{product.priority}</td>
              <td>
                <div className={styles.productDetails.wrapper}>
                  <img
                    src={
                      product.image.split(',').length ? product.image.split(',')[0] : placeholderImg
                    }
                    onError={handleImageError}
                    className={styles.productDetails.image}
                  />
                  <div className={styles.productDetails.info}>
                    <div className={styles.productDetails.name}>
                      {product.name}
                    </div>
                    <div className={styles.productDetails.description}>
                      {product.description}
                    </div>
                  </div>
                </div>
              </td>
              <td>{product.skuId}</td>
              <td>{product.manufacturer}</td>
              <td>{product.brandName ? product.brandName : "N/A"}</td>
              <td>{product.costPrice}</td>
              <td>{product.actualPrice}</td>
              <td>{product.vat}</td>
              <td>{product.margin}</td>
              <td>{product.quantity}</td>
              <td>{product.length}</td>
              <td>{product.breadth}</td>
              <td>{product.height}</td>
              <td>{product.weight}</td>
              <td>{product.isRedeemable ? "Yes" : "No"}</td>
              <td>{product.redemptionPoints}</td>
              <td>{product.barcode}</td>
              <td>{product.featureFlag ? "Yes" : "No"}</td>
              <td className={styles.stickyRight}>
                <div className={styles.tableActions}>
                  <Tooltip title="Edit" placement="top">
                    <span
                      onClick={() => {
                        props.handleEdit(product);
                      }}
                      className={classNames({
                        [styles.actionIcon]: true,
                        [styles.actionIconHidden]: false,
                      })}
                    >
                      <Icon name="PEN" pointer={1} size="small" />
                    </span>
                  </Tooltip>
                  <Tooltip
                    title={product.isEnabled ? "Disable" : "Enable"}
                    placement="top"
                  >
                    <span>
                      <Switch
                        checked={product.isEnabled}
                        onChange={() => {
                          props.toggleProduct(product);
                        }}
                      />
                    </span>
                  </Tooltip>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Panel>
  );
}

export default ProductsTable;
