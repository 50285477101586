import React, { useState } from "react";
import { useIntl } from "react-intl";
import { numberWithCommas } from "../../../../shared/utils/object-manipulation";
import { PieChart, Pie, Legend, Tooltip, Cell, Sector, Label } from "recharts";
import { LABEL_FORMAT } from "../../../../shared/constants/orders";
import "./pie-chart.scss";

const styles = {};

function CustomLabel({ viewBox, value1, value2 }) {
  const { cx, cy } = viewBox;
  return (
    <>
      <text
        x={cx}
        y={cy}
        fill="#4d4f5c"
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
      >
        <tspan alignmentBaseline="middle" fontSize="26">
          {numberWithCommas(value1)}
        </tspan>
      </text>
      <text
        x={cx}
        y={cy + 26}
        fill="#4d4f5c"
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
      >
        <tspan alignmentBaseline="middle" fontSize="20">
          {value2}
        </tspan>
      </text>
    </>
  );
}

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`PV ${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

function ReportsPieChart(props) {
  const [activeIndex, changeActiveIndex] = useState(0);
  const renderLabel = (entry) => {
    return `${LABEL_FORMAT[entry.name]}: ${Math.round(
      (entry.value / props.total) * 100
    )}%`;
  };
  console.log(props.data);
  const onPieEnter = (data, index) => {
    changeActiveIndex(index);
  };
  return (
    <PieChart width={300} height={300}>
      <Pie
        dataKey="value"
        isAnimationActive={false}
        data={props.data}
        cx={150}
        cy={150}
        innerRadius={80}
        outerRadius={120}
        fill={props.color}
        onMouseEnter={onPieEnter}
      >
        {props.data.map((entry) => (
          <Cell fill={entry.fillColor} />
        ))}
        <Label
          width={30}
          position="center"
          content={<CustomLabel value1={props.total} value2="Orders" />}
        ></Label>
      </Pie>
    </PieChart>
  );
}

export default ReportsPieChart;
