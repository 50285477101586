import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import classNames from "classnames";

import Login from "./login/login";
import Register from "./register/register";
import "./auth-container.scss";
import {toastr} from 'react-redux-toastr';
const styles = {
  wrapper: "auth-container__wrapper",
  contentContainer: "auth-container__content-container",
};

class Auth extends React.Component {
  componentDidMount() {
    if(localStorage.getItem('trollyAdmin_jwt')) {
      toastr.success('Redirecting', 'Already Logged In', {
        timeOut: 1500,
      });
      setTimeout(() => {
        this.props.history.replace('/home/dashboard');
      }, 1500)
    }
  }
  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.contentContainer}>
          <Switch>
            <Route path="/auth/login" component={Login} />
            <Route path="/auth/register" component={Register} />
            <Route path="/auth" exact component={() => <Redirect to="/auth/login" />} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default Auth;
