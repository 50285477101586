import React from 'react';
import { useIntl } from "react-intl";
import { CustomModal, Panel } from "../";
import { Button } from "antd";

const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  message: "confirm-modal__message",
};

function ConfirmModal(props) {
  const intl = useIntl();
  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={props.onCloseIconClick}
      header={props.header}
      isVisible={props.isVisible}
    >
      <Panel isLoading={props.isLoading} className={styles.formModal}>
        <h3 className={styles.message}>{props.message}</h3>
        <div className={styles.formAction}>
          <Button type="default" shape="" onClick={props.onCloseIconClick}>
          {intl.formatMessage({ id: "app.button.cancel" })}
          </Button>
          <Button type="primary" shape="" onClick={props.onAction}>
            {props.actionText}
          </Button>
        </div>
      </Panel>
    </CustomModal>
  );
}
export default ConfirmModal;