import React, { useState, useEffect } from "react";
import { Input, Select, Dropdown, Button } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { toastr } from "react-redux-toastr";

import { Header, Panel, ErrorMessage } from "../../components/common";
import Validations from "../../shared/validations/Validations";
import countryCodes from "../../shared/constants/countries.json";
import axios from "../../shared/api/axiosConfig";
import "./admin-settings.scss";

const styles = {
  wrapper: "admin-settings__wrapper",
  content: "admin-settings__content",
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formGroup: "custom-form-group",
  formAction: "custom-form-action",
  formHeader: "admin-settings__form-header",
  formWrapper: "admin-settings__form-wrapper",
};

const { Option } = Select;
const errorText = "Field cannot be empty";

function AdminSettings() {
  const intl = useIntl();
  const [isPristine, togglePristine] = useState(true);
  const [isLoading, toggleLoading] = useState(false);
  const [dailyScheduler, changeDailyScheduler] = useState(
    Validations.validateNumericValue(null)
  );
  const [weeklyScheduler, changeWeeklyScheduler] = useState(
    Validations.validateNumericValue(null)
  );
  const [monthlyScheduler, changeMonthlyScheduler] = useState(
    Validations.validateNumericValue(null)
  );
  const [referredToPoints, changeReferredToPoints] = useState(
    Validations.validateNumericValue(null)
  );
  const [referredByPoints, changeReferredByPoints] = useState(
    Validations.validateNumericValue(null)
  );

  const handleFieldChange = (e) => {
    const { value, name } = e.target;
    switch (name) {
      case "dailyScheduler":
        changeDailyScheduler(Validations.validateNumericValue(value));
        break;
      case "weeklyScheduler":
        changeWeeklyScheduler(Validations.validateNumericValue(value));
        break;
      case "monthlyScheduler":
        changeMonthlyScheduler(Validations.validateNumericValue(value));
        break;
      case "referredToPoints":
        changeReferredToPoints(Validations.validateNumericValue(value));
        break;
      case "referredByPoints":
        changeReferredByPoints(Validations.validateNumericValue(value));
        break;
      default:
        return;
    }
  };

  const setData = (data) => {
    changeDailyScheduler(
      Validations.validateNumericValue(data.dailySchedulerThreshold)
    );
    changeWeeklyScheduler(
      Validations.validateNumericValue(data.weeklySchedulerThreshold)
    );
    changeMonthlyScheduler(
      Validations.validateNumericValue(data.monthlySchedulerThreshold)
    );
    changeReferredByPoints(
      Validations.validateNumericValue(data.refferedByPoints)
    );
    changeReferredToPoints(
      Validations.validateNumericValue(data.refferedToPoints)
    );
  };

  const getData = () => {
    toggleLoading(true);
    axios
      .get("/site-data")
      .then((res) => {
        console.log("RES: ",res);
        toggleLoading(false);
        setData(res.data.data.settings);
      })
      .catch((err) => {
        toggleLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const saveSettings = (data) => {
    toggleLoading(true);
    axios
      .put("/updateSettings", data)
      .then((res) => {
        toggleLoading(false);
        toastr.success(res.data.message);
      })
      .catch((err) => {
        toggleLoading(false);
        console.log(err);
      });
  };

  const isFormValid = () =>
    dailyScheduler.isValid &&
    weeklyScheduler.isValid &&
    monthlyScheduler.isValid &&
    referredByPoints.isValid &&
    referredByPoints.isValid;

  const handleSubmit = () => {
    togglePristine(false);
    if (isFormValid()) {
      const data = {
        dailySchedulerThreshold: dailyScheduler.value,
        weeklySchedulerThreshold: weeklyScheduler.value,
        monthlySchedulerThreshold: monthlyScheduler.value,
        refferedByPoints: referredByPoints.value,
        refferedToPoints: referredToPoints.value,
      };

      saveSettings(data);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Header title="app.pageTitle.adminSettings" />
      <Panel isLoading={isLoading}>
        <div className={styles.content}>
          <div className={styles.formWrapper}>
            <div className={styles.formHeader}>
              {intl.formatMessage({ id: "app.field.schedulerSettings" })}
            </div>
            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <label>
                  {intl.formatMessage({
                    id: "app.field.dailySchedulerThreshhold",
                  })}
                </label>
                <Input
                  name="dailyScheduler"
                  onChange={handleFieldChange}
                  value={dailyScheduler.value}
                  placeholder={intl.formatMessage({
                    id: "app.field.dailySchedulerThreshhold",
                  })}
                />
                {!isPristine && <ErrorMessage field={dailyScheduler} />}
              </div>
              <div className={styles.formItem}>
                <label>
                  {intl.formatMessage({
                    id: "app.field.weeklySchedulerThreshhold",
                  })}
                </label>
                <Input
                  name="weeklyScheduler"
                  onChange={handleFieldChange}
                  value={weeklyScheduler.value}
                  placeholder={intl.formatMessage({
                    id: "app.field.weeklySchedulerThreshhold",
                  })}
                />
                {!isPristine && <ErrorMessage field={weeklyScheduler} />}
              </div>
              <div className={styles.formItem}>
                <label>
                  {intl.formatMessage({
                    id: "app.field.monthlySchedulerThreshhold",
                  })}
                </label>
                <Input
                  name="monthlyScheduler"
                  onChange={handleFieldChange}
                  value={monthlyScheduler.value}
                  placeholder={intl.formatMessage({
                    id: "app.field.monthlySchedulerThreshhold",
                  })}
                />
                {!isPristine && <ErrorMessage field={monthlyScheduler} />}
              </div>
            </div>
          </div>
          <div className={styles.formWrapper}>
            <div className={styles.formHeader}>
              {intl.formatMessage({ id: "app.field.referralSettings" })}
            </div>
            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <label>
                  {intl.formatMessage({
                    id: "app.field.referredByPoints",
                  })}
                </label>
                <Input
                  name="referredByPoints"
                  onChange={handleFieldChange}
                  value={referredByPoints.value}
                  placeholder={intl.formatMessage({
                    id: "app.field.referredByPoints",
                  })}
                />
                {!isPristine && <ErrorMessage field={referredByPoints} />}
              </div>
              <div className={styles.formItem}>
                <label>
                  {intl.formatMessage({
                    id: "app.field.referredToPoints",
                  })}
                </label>
                <Input
                  name="referredToPoints"
                  onChange={handleFieldChange}
                  value={referredToPoints.value}
                  placeholder={intl.formatMessage({
                    id: "app.field.referredToPoints",
                  })}
                />
                {!isPristine && <ErrorMessage field={referredToPoints} />}
              </div>
            </div>
          </div>
          {/* <div className={styles.formWrapper}>
            <div className={styles.formHeader}>
              {intl.formatMessage({
                id: "app.field.region",
              })}
            </div>
            <div className={styles.formItem}>
              <label>
                {intl.formatMessage({
                  id: "app.field.monthlySchedulerThreshhold",
                })}
              </label>
            </div>
          </div> */}
                  <div className={styles.formAction}>
          <Button type="primary" shape={undefined} onClick={handleSubmit}>
            {intl.formatMessage({ id: "app.button.save" })}
          </Button>
        </div>
        </div>
      </Panel>
    </div>
  );
}

export default AdminSettings;
