import React from "react";
import "./forget-password.scss";
// import logo from "../assets/Pawminded.png";

function ForgetPasswordSuccess() {
  return (
    <div className="external-container">
      <div className="forget-password__wrapper">
        <div className="forget-password__container">
          {/* <div className="forget-password__logo">
            <img src={logo} alt="Pawminded" />
          </div> */}
          <h2>Your Password Has Been Reset Successfully</h2>
        </div>
      </div>
    </div>
  );
}

export default ForgetPasswordSuccess;
