import React, { useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { Input, Button, Select, DatePicker } from "antd";
import {toastr} from 'react-redux-toastr';
import moment from 'moment';
import {
  Panel,
  CustomModal,
  UploadButton,
  FullPageDrawer,
  ErrorMessage,
} from "../../../../components/common";
import placeholderImg from "../../../../assets/images/item-placeholder.png";
import Validations from "../../../../shared/validations/Validations";
import axios from "../../../../shared/api/axiosConfig";
import "./add-edit-advertisement.scss";

const errorText = "Field cannot be empty";

const styles = {
  wrapper: "add-advertisement__wrapper",
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  imageContainer: "add-advertisement__image-container",
  leftForm: "add-advertisement__left-form",
  rightForm: "add-advertisement__right-form",
  form: "add-advertisement__form",
};

const { Option } = Select;

function AddEditAdvertisement(props) {
  const intl = useIntl();
  const chainTimeout = useRef();
  const [isPristine, togglePristine] = useState(true);
  const [store, updateStore] = useState(undefined);
  const [image, updateImage] = useState(Validations.validateTextField(""));
  const [advertisementType, updateAdvertisementType] = useState({
    selected: undefined,
    data: [
      {
        key: "advertisement",
        value: "Advertisement",
      },
      {
        key: "informational",
        value: "Informational",
      },
      {
        key: "update",
        value: "Update",
      },
    ],
  });
  const [link, updateLink] = useState(Validations.validateTextField(""));

  const [categories, updateCategories] = useState({
    selected: undefined,
    isLoading: false,
    data: [],
    notFound: false,
  });

  const [subCategories, updateSubCategories] = useState({
    selected: undefined,
    isLoading: false,
    data: [],
    notFound: false,
  });

  const [products, updateProducts] = useState({
    selected: undefined,
    isLoading: false,
    data: [],
    notFound: false,
  });

  const [startDate, updateStartDate] = useState(Validations.validateStartEndDate(undefined, undefined).startDate);
  const [endDate, updateEndDate] = useState(Validations.validateStartEndDate(undefined, undefined).endDate);
  const [isActive, updateIsActive] = useState({
    selected: 1,
    data: [
      {
        key: 0,
        value: "Inactive",
      },
      {
        key: 1,
        value: "Active",
      },
    ],
  });
  const handleStoreChange = (store) => {
    updateStore(store);
    updateCategories({
      ...categories,
      selected: undefined,
      data: [],
      notFound: false,
    });

    updateSubCategories({
      ...subCategories,
      selected: undefined,
      data: [],
      notFound: false,
    });
    updateProducts({
      ...products,
      selected: undefined,
      data: [],
      notFound: false,
    });
  };

  const handleAdvertisementTypeChange = (ad) => {
    updateAdvertisementType({
      ...advertisementType,
      selected: ad,
    });
  };

  const handleCategoryChange = (category) => {
    updateCategories({
      ...categories,
      selected: category,
    });

    updateSubCategories({
      ...subCategories,
      selected: undefined,
      data: [],
      notFound: false,
    });
    updateProducts({
      ...products,
      selected: undefined,
      data: [],
      notFound: false,
    });
  };

  const handleSubCategoryChange = (subCategory) => {
    updateSubCategories({
      ...subCategories,
      selected: subCategory,
    });
    updateProducts({
      ...products,
      selected: undefined,
      data: [],
      notFound: false,
    });
  };

  const handleProductChange = (product) => {
    updateProducts({
      ...products,
      selected: product,
    });
  };

  const handleImageChange = (image) => {
    updateImage(Validations.validateTextField(image));
  };

  const handleStartDateChange = (date) => {
    const dates = Validations.validateStartEndDate(date, endDate.value);
    updateStartDate(dates.startDate)
    updateEndDate(dates.endDate)
  };

  const handleEndDateChange = (date) => {
    const dates = Validations.validateStartEndDate(startDate.value, date);
    updateStartDate(dates.startDate)
    updateEndDate(dates.endDate)
  };

  const handleStatusChange = (status) => {
    updateIsActive({
      ...isActive,
      selected: status,
    });
  };

  const resetForm = () => {
    updateStore(undefined);
    togglePristine(true);
    updateImage(Validations.validateTextField(undefined));
    updateIsActive({
      ...isActive,
      selected: 1,
    });
    updateCategories({
      selected: undefined,
      isLoading: false,
      data: [],
      notFound: false,
    });
    updateSubCategories({
      selected: undefined,
      isLoading: false,
      data: [],
      notFound: false,
    });
    updateProducts({
      selected: undefined,
      isLoading: false,
      data: [],
      notFound: false,
    });
    updateLink(Validations.validateTextField(""));
    updateStartDate(Validations.validateStartEndDate(undefined, undefined).startDate);
    updateEndDate(Validations.validateStartEndDate(undefined, undefined).endDate);
    updateAdvertisementType({
      ...advertisementType,
      selected: undefined,
    });
  };

  const isFormValid = () => {
    let validity = true;

    validity =
      image.isValid &&
      startDate.isValid &&
      endDate.isValid &&
      link.isValid &&
      // categories.selected !== undefined &&
      advertisementType.selected !== undefined;

    if (props.modalType === "ADD") {
      // validity = validity && store !== undefined;
    }

    if (advertisementType.selected === 1) {
      // validity = validity && subCategories.selected !== undefined;
    }

    if (advertisementType.selected === 2) {
      // validity = validity && products.selected !== undefined;
    }

    console.log(validity);
    return validity;
  };
  console.log(startDate, endDate)
  const handleSubmit = () => {
    togglePristine(false);
    if(!isFormValid()) {
      toastr.warning('Error', intl.formatMessage({id: 'app.message.formCheck'}))
      return;
    }
    const data = getFormData();
    if(data.bannerType === 0) {
      // delete data.subCategoryId;
      // delete data.productId;
    }

    if(data.bannerType === 1) {
      // delete data.productId;
    }

    if(props.modalType === 'ADD') {
      props.addAdvertisement(data)
    } else if(props.modalType === 'EDIT') {
      props.editAdvertisement(data)
    }
  };

  const handleClose = () => {
    resetForm();
    props.onCloseIconClick();
  };

  const initEdit = () => {
    // updateStore(props.editStoreId);
    updateAdvertisementType({
      ...advertisementType,
      selected: props.data.bannerType
    });
    const dateData = Validations.validateStartEndDate(moment(props.data.startDate), moment(props.data.endDate));
    updateStartDate(dateData.startDate);
    updateEndDate(dateData.endDate);
    updateLink(Validations.validateTextField(props.data.link));
    updateImage(Validations.validateTextField(props.data.image))
    updateIsActive({
      ...isActive,
      selected: props.data.isActive ? 1 : 0,
    })
    console.log(props.data);
    if(props.data.bannerType === 0) {
      // updateCategories({
      //   ...categories,
      //   selected: props.data.categoryId
      // })
    } else if(props.data.bannerType === 1) {
      // updateCategories({
      //   ...categories,
      //   selected: props.data.categoryId
      // })
      // updateSubCategories({
      //   ...subCategories,
      //   selected: props.data.subCategoryId
      // })

    } else if(props.data.bannerType === 2) {
      // updateCategories({
      //   ...categories,
      //   selected: props.data.categoryId
      // })
      // updateSubCategories({
      //   ...subCategories,
      //   selected: props.data.subCategoryId
      // })
      // updateProducts({
      //   ...products,
      //   selected: props.data.productId
      // })
    }
  };


  useEffect(() => {
    if(props.isVisible) {
      if (props.modalType === "ADD") {
        resetForm();
      }
  
      if (props.modalType === "EDIT") {
        initEdit();
      }
    }
  }, [props.isVisible]);

  const handleImageError = (e) => {
    e.target.src = placeholderImg;
  };


  const handleFieldChange = (e) => {
    const { value } = e.target;

    switch (e.target.name) {
      case "link":
        updateLink(Validations.validateTextField(value));
        break;
      default:
        break;
    }
  };

  const getFormData = () => ({
    // "branchId": store,
    "image": image.value,
    "bannerType": advertisementType.selected,
    "startDate": startDate.value,
    "endDate": endDate.value,
    "link": link.value,
    // "categoryId": categories.selected,
    // "subCategoryId": subCategories.selected,
    // "productId": products.selected,
    // "isActive": isActive.selected,
  })

  return (
    <CustomModal
      width={800}
      // onClose={props.onClose}
      onCloseIconClick={handleClose}
      header={props.modalType === 'ADD' ? "Add Banner" : 'Edit Banner'}
      // header={props.modalType === 'ADD' ? "app.pageTitle.addAdvertisement" : 'app.pageTitle.editAdvertisement'}
      isVisible={props.isVisible}
    >
      <Panel isLoading={categories.isLoading || subCategories.isLoading || products.isLoading || props.isLoading} className={styles.formModal}>
        <div className={styles.form}>
          <div className={styles.leftForm}>
            <div className={styles.imageContainer}>
              <img
                src={image.value ? image.value : placeholderImg}
                onError={handleImageError}
                alt="Banner"
              />
              <UploadButton
                onChange={handleImageChange}
                accept="image/*"
                resultType="url"
                text="Upload Image"
              />
              {!isPristine && <ErrorMessage field={image} />}
            </div>
            <div className={styles.formItem}>
              <label>
                {intl.formatMessage({
                  id: "app.field.startDate",
                })}
              </label>
              <DatePicker value={startDate.value} onChange={handleStartDateChange} />
              {!isPristine  && (
                <ErrorMessage field={startDate} />
              )}
            </div>
            <div className={styles.formItem}>
              <label>
                {intl.formatMessage({
                  id: "app.field.endDate",
                })}
              </label>
              <DatePicker value={endDate.value} onChange={handleEndDateChange} />
              {!isPristine && (
                <ErrorMessage field={endDate} />
              )}
            </div>
            {/* <div className={styles.formItem}>
              <label>
                {intl.formatMessage({
                  id: "app.field.status",
                })}
              </label>
              <Select value={isActive.selected} onChange={handleStatusChange}>
                {isActive.data.map((status) => (
                  <Option key={status.key} value={status.key}>
                    {status.value}
                  </Option>
                ))}
              </Select>
            </div> */}
          </div>
          <div className={styles.rightForm}>
{/* {       props.modalType === 'ADD' &&     <div className={styles.formItem}>
              <label>
                {intl.formatMessage({
                  id: "app.placeholder.store",
                })}
              </label>
              <Select
                placeholder={intl.formatMessage({
                  id: "app.placeholder.store",
                })}
                onChange={handleStoreChange}
                value={store}
              >
                {props.stores.map((store) => (
                  <Option key={store.branchId} value={store.branchId}>
                    {store.branchName}
                  </Option>
                ))}
              </Select>
              {!isPristine && store === undefined && (
                <ErrorMessage text={errorText} />
              )}
            </div>} */}
            <div
              style={{ marginBottom: "margin-bottom: 8.51rem;" }}
              className={styles.formItem}
            >
              <label>
                Banner Type
                {/* {intl.formatMessage({
                  id: "app.field.advertisementType",
                })} */}
              </label>
              <Select
              placeholder="Select Banner Type"
                // placeholder={intl.formatMessage({
                //   id: "app.placeholder.advertisementType",
                // })}
                onChange={handleAdvertisementTypeChange}
                value={advertisementType.selected}
              >
                {advertisementType.data.map((type) => (
                  <Option key={type.key} value={type.key}>
                    {type.value}
                  </Option>
                ))}
              </Select>
              {!isPristine && advertisementType.selected === undefined && (
                <ErrorMessage text={errorText} />
              )}
            </div>
            <div className={styles.formItem}>
              <label>Advertisement Link</label>
              <Input
                name="link"
                onChange={handleFieldChange}
                value={link.value}
                placeholder="Enter Advertisement Link"
              />
              {!isPristine && <ErrorMessage field={link} />}
            </div>

            {store && advertisementType.selected >= 0 && (
              <div className={styles.formItem}>
                <label>
                  {intl.formatMessage({ id: "app.placeholder.category" })}
                </label>
                <Select
                  placeholder={intl.formatMessage({
                    id: "app.placeholder.category",
                  })}
                  onChange={handleCategoryChange}
                  value={categories.selected}
                >
                  {categories.data.map((category) => (
                    <Option key={category.categoryId} value={category.categoryId}>
                      {category.categoryName}
                    </Option>
                  ))}
                </Select>
                {!isPristine && categories.selected === undefined && (
                  <ErrorMessage text={errorText} />
                )}
              </div>
            )}
            {advertisementType.selected >= 1 && categories.selected && (
              <div className={styles.formItem}>
                <label>
                  {intl.formatMessage({ id: "app.placeholder.subCategory" })}
                </label>
                <Select
                  placeholder={intl.formatMessage({
                    id: "app.placeholder.subCategory",
                  })}
                  onChange={handleSubCategoryChange}
                  value={subCategories.selected}
                >
                  {subCategories.data.map((subCategory) => (
                    <Option key={subCategory.categoryId} value={subCategory.categoryId}>
                      {subCategory.categoryName}
                    </Option>
                  ))}
                </Select>
                {!isPristine && subCategories.selected === undefined && (
                  <ErrorMessage text={errorText} />
                )}
              </div>
            )}
            {advertisementType.selected >= 2 && subCategories.selected && (
              <div className={styles.formItem}>
                <label>
                  {intl.formatMessage({ id: "app.placeholder.product" })}
                </label>
                <Select
                  placeholder={intl.formatMessage({
                    id: "app.placeholder.product",
                  })}
                  onChange={handleProductChange}
                  value={products.selected}
                >
                  {products.data.map((product) => (
                    <Option key={product.productId} value={product.productId}>
                      {product.name}
                    </Option>
                  ))}
                </Select>
                {!isPristine && products.selected === undefined && (
                  <ErrorMessage text={errorText} />
                )}
              </div>
            )}
          </div>
        </div>
        <div className={styles.formAction}>
          <Button shape={undefined} onClick={handleClose}>
            {intl.formatMessage({id: 'app.button.cancel'})}
          </Button>
          <Button type="primary" shape={undefined} onClick={handleSubmit}>
          {intl.formatMessage({id: 'app.button.submit'})}
          </Button>
        </div>
      </Panel>
    </CustomModal>
  );
}

export default AddEditAdvertisement;
