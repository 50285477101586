import citiesActionTypes from "./cities-action-types";

const initialState = {
  timestamp: "",
};

function citiesReducer(state = initialState, action) {
  switch (action.type) {
    case citiesActionTypes.TRIGGER_REFETCH:
      return {
        ...state,
        timestamp: new Date().getTime(),
      };
      default:
          return state
  }
}

export default citiesReducer;