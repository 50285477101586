import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Select, Dropdown, Button, Upload, message, Empty } from "antd";
import {
  CaretDownOutlined,
  SearchOutlined,
  InboxOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

import { connect } from "react-redux";

import { RECORDS_PER_PAGE } from "../../shared/constants/dropdown";
import {
  Header,
  PaginationInfo,
  ConfirmModal,
  Label,
  DelayedInput,
} from "../../components/common";

import { toastr } from "react-redux-toastr";
import { removeEmptyKeys } from "../../shared/utils/object-manipulation";
import axios from "../../shared/api/axiosConfig";
// import foodAxios from "../../shared/api/foodConfig";
// import BrandsTable from "./components/brands-table/brands-table";
import "./images-upload.scss";
import { ImagesTable, UploadImage, ImageCard } from "./components";
import store from "../../redux/store";
// import { ImageCardSkeleton } from "./components/image-card/image-card";
import { checkRtl } from "../../shared/utils/rtl";
// import { verticles, getField } from "../../shared/constants/dropdown";
// import axiosClient from "../../shared/api/notifications";


const styles = {
  wrapper: "oyelabs__images-upload__wrapper",
  menu: "custom-menu",
  upperMenu: "oyelabs__images-upload__upper-menu",
  upperMenuLeft: "oyelabs__images-upload__upper-menu__left",
  upperMenuRight: "oyelabs__images-upload__upper-menu__right",
  upperMenuDropdown: "oyelabs__images-upload__upper-menu__dropdown",
  pageSize: "products__upper-menu__records-per-page",
  uploader: "oyelabs__images-upload__uploader",
  content: "custom-section",
};
const { Option } = Select;

const downloadFile = (filename, link) => {
  const element = document.createElement("a");
  element.setAttribute(
    "href",
    link,
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

function ImagesUpload(props) {
  const intl = useIntl();

  const [pagination, updatePagination] = useState({
    pageSize: 10,
    skip: 0,
    current: 1,
    search: "",
  });

  const [stores, updateStores] = useState({
    selected: undefined,
    isLoading: true,
    data: [],
    notFound: false,
  });

  const [tableControls, updateTableControls] = useState({
    isTableLoading: true,
    isTableEmpty: false,
    data: [],
    count: 0,
  });

  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    data: {},
    isLoading: false,
  });
  const [search, updateSearch] = useState("");
  const [isExportLoading, toggleExport] = useState(false);

  const handlePaginationChange = ({ pageSize, current, total }) => {
    updatePagination({
      ...pagination,
      pageSize,
      current,
      total,
      skip: (current - 1) * pageSize,
    });
  };

  const handleRecordsPerPageChange = (val) => {
    updatePagination({
      ...pagination,
      pageSize: val,
    });
  };

  const handleStoreChange = (selected) => {
    updateStores({
      ...stores,
      selected,
    });
    updatePagination({
        ...pagination,
        skip: 0,
        current: 1,
    })
  };

  const handleSearchChange = (val) => {
    updateSearch(val);
  };

  const handleSearch = (val) => {
    updatePagination({
      ...pagination,
      current: 1,
      search: val,
      skip: 0,
    });
  };

  useEffect(() => {
    axios
      .get("/getBranches", {
        params: {
          onlyNames: 1,
        },
      })
      .then((res) => {
        if (res.data.data.branchesData.length) {
          updateStores({
            selected: res.data.data.branchesData[0].branchId,
            data: res.data.data.branchesData,
            isLoading: false,
            notFound: false,
          });
        } else {
          updateStores({
            ...stores,
            notFound: true,
            isLoading: false,
            data: [],
          });
        }
      })
      .catch((err) => {
        updateStores({
          ...stores,
          notFound: true,
          isLoading: false,
          data: [],
        });
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
      });
  }, []);

  const getImagesData = () => {
    updateTableControls({
      isTableLoading: true,
      isTableEmpty: false,
      data: [],
    });

    axios
      .get("/getImages", {
        params: removeEmptyKeys({
            branchId: stores.selected,
            limit: pagination.pageSize,
            skip: (pagination.current - 1) * pagination.pageSize,
            search: pagination.search,
          }),
      })
      .then((res) => {
        updateTableControls({
          isTableLoading: false,
          isTableEmpty: res.data.data.images.length === 0,
          data: res.data.data.images,
        });
        updatePagination({
          ...pagination,
          count: res.data.data.count,
          total: res.data.data.count,
        });
      })
      .catch((err) => {
        console.log(err);
        updateTableControls({
          isTableLoading: false,
          isTableEmpty: true,
          data: [],
        });
      });
  };



  useEffect(() => {
    if (stores.selected !== undefined) {
      getImagesData();
    }
  }, [
    pagination.pageSize,
    pagination.current,
    stores.selected,
    pagination.search,
  ]);

  const handleExport = () => {
    toggleExport(true);
    axios.get('/exportImages', {
      params: {
        branchId: stores.selected,
      }
    }).then((res) => {
      toggleExport(false);
      downloadFile('images.csv', res.data.data.file_link);
    }).catch((err) => {
      toggleExport(false);
    })
  }
  const handleUpload = () => {};

  const toggleUploadModal = () => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
    });
  };

  const handleModalClose = () => {
    updateModalControls({
      ...modalControls,
      isVisible: false,
    });
  };

  return (
    <div className={styles.wrapper}>
      {modalControls.isVisible && (
        <UploadImage
          isVisible={modalControls.isVisible}
          handleUpload={handleUpload}
          onCloseIconClick={handleModalClose}
          branchId={stores.selected}
          callback={getImagesData}
        />
      )}
      <Header title="app.sidebar.images" />
      <div className={styles.content}>
        {true && (
          <div className={styles.menu}>
            <header>
              <div className={styles.menuItem}>
                {/* <Label value="app.placeholder.store" horizontal /> */}
                <Select
                  style={{ width: 180 }}
                  placeholder={intl.formatMessage({
                    id: "app.placeholder.store",
                  })}
                  onChange={handleStoreChange}
                  value={stores.selected}
                >
                  {stores.data.map((store) => (
                    <Option key={store.branchId} value={store.branchId}>
                      {store.branchName}
                    </Option>
                  ))}
                </Select>
              </div>
            </header>
            <section>
              <DelayedInput
                delay={500}
                onChange={handleSearchChange}
                value={search}
                callback={handleSearch}
                minLength={3}
                size="medium"
                prefixIcon={<SearchOutlined />}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.search",
                })}
              />
              <Button
                loading={stores.isLoading || isExportLoading}
                onClick={handleExport}
                shape={undefined}
                size="medium"
                icon={<DownloadOutlined />}
              >
                {intl.formatMessage({
                  id: "app.field.exportCSV",
                })}
              </Button>
              <Button
                onClick={toggleUploadModal}
                type="primary"
                size="medium"
                shape={undefined}
                loading={stores.isLoading}
              >
                {intl.formatMessage({
                  id: "app.field.upload",
                })}
              </Button>
            </section>
          </div>
        )}
        <ImagesTable
          data={tableControls.data}
          notFound={tableControls.isTableEmpty}
          isLoading={tableControls.isTableLoading}
          pagination={pagination}
          handlePaginationChange={handlePaginationChange}
        />
      </div>
      {/* <div>
        {!tableControls.isTableEmpty && (
          <div className={styles.content}>
            {tableControls.isTableLoading &&
              !tableControls.isTableEmpty &&
              [1, 2, 3, 4, 5, 6, 7, 8].slice(0, pagination.pageSize).map((item) => (
                <ImageCardSkeleton key={item.id} data={item} />
              ))}
            {!tableControls.isTableLoading &&
              !tableControls.isTableEmpty &&
              tableControls.data.map((item) => (
                <ImageCard key={item.id} data={item} />
              ))}
          </div>
        )}

        {tableControls.isTableEmpty && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Empty />
          </div>
        )}
      </div> */}
      {/* <div className={styles.footer}>
        <PaginationInfo
          currentPage={pagination.current}
          recordsPerPage={pagination.pageSize}
          totalRecords={pagination.count}
          onChange={handlePaginationChange}
        />
      </div> */}
    </div>
  );
}

export default ImagesUpload;
