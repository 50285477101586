import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "../../shared/api/axiosConfig";
import { toastr } from "react-redux-toastr";
import { Header, Panel } from "../../components/common";
import { Menu, Editor } from "./components";
import "./content-management.scss";

const styles = {
  wrapper: "oyelabs-custom__content-management__wrapper",
  content: "oyelabs-custom__content-management__container",
  menu: "custom-menu single",
};

const MENU = {
  ABOUT_US: {
    key: "ABOUT_US",
    icon: "USERS",
    text: "app.field.aboutUs",
    dataKey: "aboutUs",
  },
  FAQ: {
    key: "FAQ",
    icon: "QUESTION_MARK",
    text: "app.field.faq",
    dataKey: "faq",
  },
  PRIVACY_POLICY: {
    key: "PRIVACY_POLICY",
    icon: "PRIVACY",
    text: "app.field.privacyPolicy",
    dataKey: "privacyPolicy",
  },
  TNC: {
    key: "TNC",
    icon: "USERS",
    text: "Terms and Conditions",
    dataKey: "termsAndConditions",
  },
  // TNC_STORE: {
  //   key: "TNC_STORE",
  //   icon: "STORE",
  //   text: "app.field.termsAndConditionsStore",
  //   dataKey: "termsAndConditionsForRestaurant",
  // },
  CONTACT_US: {
    key: "CONTACT_US",
    icon: "PHONE",
    text: "app.field.contactUsSubjects",
  },
};

function ContentManagement(props) {
  const [editorData, updateEditorData] = useState({
    cId: "",
    aboutUs: "",
    privacyPolicy: "",
    faq: "",
    termsAndConditions: "",
    termsAndConditionsForRestaurant: "",
  });

  const [selectedMenu, updateSelectedMenu] = useState(MENU.ABOUT_US.key);

  const [isLoading, changeIsLoading] = useState(true);

  const getAboutUs = () => {
    changeIsLoading(true);
    axios
      .get("/content/about", {
        params: {
          adminId: props.adminId
        }
      })
      .then((res) => {
        updateEditorData({
          ...editorData,
          aboutUs: res.data.data.html,
        });
        changeIsLoading(false);
      })
      .catch((err) => {
        changeIsLoading(false);
        console.log(err);
        toastr.error("Error", "Something Went Wrong");
      });
  };

  const getFaq = () => {
    changeIsLoading(true);
    axios
      .get("/content/faq", {
        params: {
          adminId: props.adminId
        }
      })
      .then((res) => {
        console.log("RES: ",res);
        updateEditorData({
          ...editorData,
          faq: res.data.data.html,
        });
        changeIsLoading(false);
      })
      .catch((err) => {
        changeIsLoading(false);
        console.log(err);
        toastr.error("Error", "Something Went Wrong");
      });
  };

  const getPrivacyPolicy = () => {
    changeIsLoading(true);
    axios
      .get("/content/privacy", {
        params: {
          adminId: props.adminId
        }
      })
      .then((res) => {
        console.log("RES: ",res);
        updateEditorData({
          ...editorData,
          privacyPolicy: res.data.data.html,
        });
        changeIsLoading(false);
      })
      .catch((err) => {
        changeIsLoading(false);
        console.log(err);
        toastr.error("Error", "Something Went Wrong");
      });
  };


  const getTerms = () => {
    changeIsLoading(true);
    axios
      .get("/content/terms", {
        params: {
          adminId: props.adminId
        }
      })
      .then((res) => {
        console.log("RES: ",res);
        updateEditorData({
          ...editorData,
          termsAndConditions: res.data.data.html,
        });
        changeIsLoading(false);
      })
      .catch((err) => {
        changeIsLoading(false);
        console.log(err);
        toastr.error("Error", "Something Went Wrong");
      });
  };

  const handleMenuChange = (key) => {
    updateSelectedMenu(key);
  };

  useEffect(() => {
    if(selectedMenu === MENU.ABOUT_US.key){
      getAboutUs();
    }else if( selectedMenu === MENU.FAQ.key){
      getFaq();
    }else if(selectedMenu === MENU.PRIVACY_POLICY.key){
      getPrivacyPolicy();
    }else if( selectedMenu === MENU.TNC.key){
      getTerms();
    }
  }, [selectedMenu]);

  const postTerms = (value) => {
    changeIsLoading(true);
    axios.post('content/terms', {
      html: value,
    }).then((res) => {
      if(res.data.statusCode === 200){
        toastr.success(res.data.message);
      }
      getTerms();
      changeIsLoading(false)
    }).catch((err) => {
      changeIsLoading(false)
      console.log(err)
    })
  };

  const postAboutUs = (value) => {
    changeIsLoading(true);
    axios.post('content/about', {
      html: value,
    }).then((res) => {
      if(res.data.statusCode === 200){
        toastr.success(res.data.message);
      }
      getAboutUs();
      changeIsLoading(false)
    }).catch((err) => {
      changeIsLoading(false)
      console.log(err)
    })
  };

  const postFaq = (value) => {
    changeIsLoading(true);
    axios.post('content/faq', {
      html: value,
    }).then((res) => {
      if(res.data.statusCode === 200){
        toastr.success(res.data.message);
      }
      getFaq();
      changeIsLoading(false)
    }).catch((err) => {
      changeIsLoading(false)
      console.log(err)
    })
  };

  const postPrivacyPolicy = (value) => {
    changeIsLoading(true);
    axios.post('content/privacy', {
      html: value,
    }).then((res) => {
      if(res.data.statusCode === 200){
        toastr.success(res.data.message);
      }
      getPrivacyPolicy();
      changeIsLoading(false)
    }).catch((err) => {
      changeIsLoading(false)
      console.log(err)
    })
  };
  const handleEditorSubmit = (key, value) => {
    if(selectedMenu === MENU.ABOUT_US.key){
      postAboutUs(value);
    }else if( selectedMenu === MENU.FAQ.key){
      postFaq(value);
    }else if(selectedMenu === MENU.PRIVACY_POLICY.key){
      postPrivacyPolicy(value);
    }else if( selectedMenu === MENU.TNC.key){
      postTerms(value);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Header title="app.pageTitle.contentManagement" />
      <div style={{marginBottom: '24px'}} className={styles.menu}>
        <Menu data={MENU} selected={selectedMenu} onChange={handleMenuChange} />
      </div>
    <Panel loaderStyle={{height: '64.5vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}} isLoading={isLoading} className="custom-section">
        <Editor
          menu={MENU}
          selected={selectedMenu}
          data={editorData}
          isLoading={isLoading}
          handleSubmit={handleEditorSubmit}
        />
      </Panel>
    </div>
  );
}
const mapStateToProps = (state) => ({
  adminId: state.user.userInfo.adminId,
})
export default connect(mapStateToProps)(ContentManagement);
