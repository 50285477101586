import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import io from "socket.io-client";
import Push from "push.js";
import { connect } from "react-redux";
import classNames from 'classnames'

import Sidebar from "../components/sidebar/sidebar";
import Navbar from "../components/navbar/navbar";
import "./home-container.scss";
import history from "../shared/utils/history";
import axiosClient from "../shared/api/axiosConfig";
import userActions from "../redux/user-info/user-actions";
import orderActions from "../redux/orders/orders-action";
import logo from "../assets/images/logo.png";

import Dashboard from "./dashboard/dashboard-container";
import Users from "./role-management/users";
import Categories from "./categories/categories";
import Products from "./products/products";
import Geofence from "./geofence/geofence";
import GeofenceEdit from "./geofence/geofence-edit";
import Advertisements from "./advertisements/advertisements";
import Orders from "./orders/orders";
import Lessons from "./lessons/lessons";
import ContentManagement from "./content-management/content-management";
import CancellationReasons from "./cancellation-reasons/cancellation-reasons";
import PushNotification from "./push-notification/push-notification";
import ActivePromo from "./promo/active-promo";
import Parts from "./parts/parts";
import ExpiredPromo from "./promo/expired-promo";
import Stores from "./stores/stores";
import Customers from "./customers/customers";
import CustomerDetails from "./customer-details/customer-details";
import Schedule from "./schedule/schedule";
import EditOrder from "./edit-order/edit-order";
import Courses from "./courses/courses";
import Subscriptions from "./subscriptions/subscriptions";
import Shelters from "./shelters/shelters";
import Pickers from "./pickers/pickers";
import AdminSettings from "./settings/admin-settings";
import Brands from "./brands/brands";
import OrderReports from "./order-reports/order-reports";
import StoreCategories from "./store-categories/store-categories";
import DriverContainer from "./drivers/driverContainer";
import Images from "./images-upload/images-upload";
import { checkRtl } from "../shared/utils/rtl";

const styles = {
  wrapper: "home__wrapper",
  contentContainer: "home__content-container",
  active: "home__content-container--active",
};
class Home extends React.Component {
  state = {
    isSidebarExpanded: true,
  };

  toggleSidebar = () => {
    this.setState((prev) => ({
      isSidebarExpanded: !prev.isSidebarExpanded,
    }));
  };
  socket = null;

  setUpSocket = () => {
    // return;
    // const url = "http://13.245.63.240:3000/";
    const url = "https://dev-api.pawminded.com";
    // const url = "https://api.goodgrocery.co/";
    this.socket = io(url);

    this.socket.on("connect", () => {
      console.log("connected");
      this.socket.emit("admin_login", {
        "access-token": localStorage.getItem("trollyAdmin_jwt"),
      });
    });

    this.socket.on("connectToRoom", (data) => {
      console.log("succc", data);
    });

    this.socket.on("create_grocery_booking", (data) => {
      console.log("much wOOOOOw");
      this.props.dispatch(orderActions.sendNewOrderNotification(data));
    });

    this.socket.on("cancel_grocery_booking", (data) => {
      console.log("much wOOOOOw");
      this.props.dispatch(orderActions.sendCancelOrderNotification(data));
    });
  };

  setUpPN = () => {
    console.log("permission", Push.Permission.has());
    if (!Push.Permission.has()) {
      Push.Permission.request(
        () => {
          console.log("accceppted");
        },
        () => {
          console.log("denied");
        }
      );
    }
  };

  componentDidMount() {
    if (!localStorage.getItem("trollyAdmin_jwt")) {
      history.replace("/auth/login");
    } else {
      this.props.dispatch(
        userActions.setUserInfo(
          JSON.parse(localStorage.getItem("trollyAdmin_userInfo"))
        )
      );

      // this.setUpSocket();
      // this.setUpPN();
    }
    // TODO: get user details here
    // axiosClient.get('googleSheets/getListOfDataSets').then((r) => {console.log('then')}).catch((err) =>{ console.log('catcg', err)});
  }

  componentWillUnmount() {
    // if (this.socket !== null) {
    //   this.socket.disconnect();
    // }
  }

  getLoader = () => <h1>Loading...</h1>;

  getRoutes = (location) => (
    <TransitionGroup appear={true} exit={true}>
      <CSSTransition
        key={location.key}
        timeout={400}
        unmountOnExit
        classNames="custom-oyelabs-anim"
      >
        <Switch location={location}>
          <Route path="/home/dashboard" component={Dashboard} />
          <Route path="/home/role-management" component={Users} />
          <Route path="/home/marketplace/products" component={Products} />
          <Route
            exact
            path="/home/subscriptions"
            component={Subscriptions}
          />
          <Route
            exact
            path="/home/organizations"
            component={Shelters}
          />
          <Route exact path="/home/courses" component={Courses} />
          <Route exact path="/home/marketplace/categories" component={Categories} />
          <Route exact path="/home/geofence" component={Geofence} />
          <Route exact path="/home/orders/:type" component={Orders} />
          <Route
            exact
            path="/home/orders/edit/:orderId"
            component={EditOrder}
          />
          <Route
            exact
            path="/home/geofence/edit/:storeId/:editId"
            component={GeofenceEdit}
          />
          <Route exact path="/home/banners" component={Advertisements} />
          <Route
            exact
            path="/home/content-management"
            component={ContentManagement}
          />
          <Route
            exact
            path="/home/cancellation-reasons"
            component={CancellationReasons}
          />
          <Route
            exact
            path="/home/push-notification"
            component={PushNotification}
          />
          <Route exact path="/home/promos/active" component={ActivePromo} />
          <Route exact path="/home/promos/in-active" component={ExpiredPromo} />
          <Route exact path="/home/stores" component={Stores} />
          <Route exact path="/home/customers" component={Customers} />
          <Route
            exact
            path="/home/customers/:customerId"
            component={CustomerDetails}
          />
          <Route
            exact
            path="/home/lessons"
            component={Lessons}
          />
          <Route
            exact
            path="/home/lessons/:lessonId/parts"
            component={Parts}
          />
          <Route exact path="/home/schedule" component={Schedule} />
          <Route exact path="/home/pickers" component={Pickers} />
          <Route exact path="/home/brands" component={Brands} />
          <Route exact path="/home/settings" component={AdminSettings} />
          <Route exact path="/home/reports" component={OrderReports} />
          <Route
            exact
            path="/home/store-categories"
            component={StoreCategories}
          />
          <Route exact path="/home/drivers" component={DriverContainer} />
          <Route exact path="/home/images" component={Images} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );

  render() {
    return (
      <Route
        render={({ location }) => (
          <div
            dir={checkRtl(this.props.locale) ? "rtl" : "ltr"}
            className={styles.wrapper}
          >
            <Sidebar
              toggleSidebar={this.toggleSidebar}
              isSidebarExpanded={this.state.isSidebarExpanded}
            />
            <div
              className="oyelabs__home-wrapper"
              dir={checkRtl(this.props.locale) ? "rtl" : "ltr"}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                overflow: "hidden",
                backgroundColor: "#f9f9f9",
              }}
            >
              <Navbar
                toggleSidebar={this.toggleSidebar}
                isSidebarExpanded={this.state.isSidebarExpanded}
              />
              <div
                dir={checkRtl(this.props.locale) ? "rtl" : "ltr"}
                className={classNames({
                  [styles.contentContainer]: true,
                  [styles.active]: this.state.isSidebarExpanded,
                })}
              >
                {this.props.isLoading
                  ? this.getLoader()
                  : this.getRoutes(location)}
              </div>
            </div>
          </div>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.user.isLoading,
});

export default connect(mapStateToProps)(Home);
