import React, { useState, useEffect } from "react";

import { Header, CustomTable, Label, Locked, CustomTag } from "../../components/common";
import moment from "moment";
import axios from "../../shared/api/axiosConfig";

import {
  removeEmptyKeys,
} from "../../shared/utils/object-manipulation";
import "./shelters.scss";
import { Popconfirm } from "antd";
import axiosClient from "../../shared/api/axiosConfig";
import { useIntl } from "react-intl";

const styles = {
  wrapper: "shelters__wrapper",
  menu: "shelters__menu",
  leftMenu: "shelters__menu--left",
  menuItem: "shelters__menu-item",
  table: "custom-table",
  menu: 'custom-menu',
  section: 'custom-section',
};


const Shelters = (props) => {
const intl = useIntl()
  const [searchDelayed, updateSearchDelayed] = useState("");

  const [pagination, updatePagination] = useState({
    pageSize: 10,
    skip: 0,
    current: 1,
    search: "",
    delayedcurrent: 1,
    delayedpageSize: 10,
  });

  const [tableControls, updateTableControls] = useState({
    isLoading: true,
    isEmpty: false,
    data: [],
    count: 0,
  });

  const handleTableChange = (pag, filt, sorter) => {
    // debugger;
    updatePagination({
      ...pag,
    });
  };
    function getShelters  () {
      updateTableControls({
        ...tableControls,
        isEmpty: false,
        isLoading: true,
      });
        axios
        .get("/nominations", {
          params: removeEmptyKeys({
            limit: pagination.pageSize,
            skip: (pagination.current - 1) * pagination.pageSize,
            search: searchDelayed,
          }),
        })
        .then((res) => {
          // const parsed = nestedParseJSON(res.data.customers);
          updateTableControls({
            isLoading: false,
            isEmpty: res.data.data.length === 0,
            data: res.data.data,
            count: res.data.data.length,
          });

          updatePagination({
            ...pagination,
            total: res.data.data.length,
            // delayedcurrent: pagination.current,
            // delayedpageSize: pagination.pageSize,
          });
        })
        .catch((err) => {
          console.log(err)
          updateTableControls({
            ...tableControls,
            isEmpty: true,
            isLoading: false,
            data: [],
          });
        });
    }
    function handleDeleteNomination(item) {
      const {nominationId} = item
      axiosClient.delete(`https://dev-api.pawminded.com/admin/nominations/delete/${nominationId}`).then(res=>{
        console.log(res)
        getShelters();
      }).catch(err=>{
        console.log(err.message)
      })
    }
    useEffect(() => {
        getShelters();
    },[pagination.current, pagination.pageSize, searchDelayed]);


  const columns = [
    {
      dataIndex: "",
      // sorter: true,
      title: "S No.",
      // fixed: 'left',
      render: (item, object, index) => {
        return (
          <span>
            {pagination.pageSize * (pagination.current - 1) + index + 1}
          </span>
        );
      },
    },
    {
      dataIndex: "",
      title: "Customer Name",
      render: (item) => `${item.customer?.name ? item.customer.name : "N/A"}`
    },
    {
      dataIndex: "",
      title: "Customer Email",
      render: (item) => `${item.customer?.email ? item.customer.email : "N/A"}`
    },
    {
      dataIndex: "createdAt",
      title: "Registration Date",
      render: (i,item) => `${moment.utc(item.createdAt).format("MMMM Do YYYY")}`,
    },
    {
      dataIndex: "shelterName",
      title: "Shelter Name",
    },
    {
      dataIndex: "shelterWebsite",
      title: "Shelter Website",
      render: (i) => {
        return (
            <div>
            <a href={i} target="_blank">{i}</a>
            </div>
        )
      },
    },
    {
      dataIndex: "",
      title: "Action",
      render: (item) => (
        <Popconfirm
          title={intl.formatMessage({
            id: "app.message.deleteWarning",
          })}
          onConfirm={() => {
            handleDeleteNomination(item);
          }}
          placement="topRight"
          okText={intl.formatMessage({ id: "app.field.yes" })}
          cancelText={intl.formatMessage({ id: "app.field.no" })}
        >
          <button
            style={{
              cursor: "pointer",
              background: "#CE0F0F",
              border: "none",
              color: "white",
              padding: "3px 10px",
              borderRadius: "5px",
              fontSize: "10px",
            }}
          >
            Delete
          </button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <div className={styles.wrapper}>
      <Header title="Organizations" />
      <div className="custom-section">
        <CustomTable
          isLoading={tableControls.isLoading}
          columns={columns}
          dataSource={tableControls.data}
          isEmpty={tableControls.isEmpty}
          pagination={pagination}
          onChange={handleTableChange}
          current={pagination.current}
          pageSize={pagination.pageSize}
        />
      </div>
    </div>
  );

}

export default Shelters;