const data = {
  "app.auth.welcomeTitle": "Let's Get Started Now!",
  "app.auth.welcomeSubTitle": "Welcome Back!",
  "app.field.affiliateLink": "Affiliate Link",
  "app.sidebar.dashboard": "Dashboard",
  "app.sidebar.roleManagement": "Role Management",
  "app.sidebar.schedule": "Schedule",
  "app.sidebar.customers": "Customers",
  "app.sidebar.reviews": "Reviews",
  "app.sidebar.subscriptions": "Subscriptions",
  "app.sidebar.courses": "Courses",
  "app.sidebar.categories": "Categories",
  "app.sidebar.globalCategories": "Global Categories",
  "app.sidebar.globalBrands": "Global Brands",
  "app.sidebar.products": "Products",
  "app.sidebar.stores": "Stores",
  "app.sidebar.pickers": "Pickers",
  "app.sidebar.orders": "Orders",
  "app.sidebar.allOrders": "All Orders",
  "app.sidebar.upcomingOrders": "Upcoming Orders",
  "app.sidebar.acceptedOrders": "Accepted Orders",
  "app.sidebar.deliveredOrders": "Delivered Orders",
  "app.sidebar.cancelledOrders": "Cancelled Orders",
  "app.sidebar.cities": "Cities",
  "app.sidebar.pickedOrders": "Ready For Delivery",
  "app.sidebar.beingPickedOrders": "Orders Being Picked",
  "app.sidebar.shippedOrders": "Orders On The Way",
  "app.sidebar.advertisements": "Advertisements",
  "app.sidebar.geofence": "Geofence",
  "app.sidebar.promoCodes": "Promo Codes",
  "app.sidebar.activePromo": "Active",
  "app.sidebar.inActivePromo": "InActive",
  "app.sidebar.pushNotification": "Push Notification",
  "app.sidebar.cancellationReasons": "Cancellation Reasons",
  "app.sidebar.contentManagement": "Content Management",
  "app.sidebar.settings": "Settings",
  "app.sidebar.brands": "Brands",
  "app.sidebar.reports": "Reports",
  "app.sidebar.storeCategories": "Store Categories",
  "app.sidebar.ordersReports": "Orders Reports",
  "app.sidebar.drivers": "Drivers",
  "app.sidebar.globalProducts": "Global Products",

  "app.pageTitle.ordersReports": "Orders Reports",
  "app.pageTitle.addCity": "Add City",
  "app.pageTitle.editCity": "Edit City",
  "app.pageTitle.dashboard": "Pawminded Dashboard",
  "app.pageTitle.storeCategories": "Store Categories",
  "app.pageTitle.addProduct": "Add Product",
  "app.pageTitle.viewSubAdmin": "View Sub-Admin",
  "app.pageTitle.editProduct": "Edit Product",
  "app.pageTitle.addGeofence": "Add Geofence",
  "app.pageTitle.editGeofence": "Edit Geofence",
  "app.pageTitle.addAdvertisement": "Add Advertisement",
  "app.pageTitle.editAdvertisement": "Edit Advertisement",
  "app.pageTitle.roleManagement": "User Roles",
  "app.pageTitle.addSubAdmin": "Add Sub-Admin",
  "app.pageTitle.editSubAdmin": "Edit Sub-Admin",
  "app.pageTitle.schedule": "Schedule",
  "app.pageTitle.customers": "Customers",
  "app.pageTitle.cities": "Cities",
  "app.pageTitle.categories": "Categories",
  "app.pageTitle.products": "Products",
  "app.pageTitle.stores": "Stores",
  "app.pageTitle.storeProducts": "Store Products",
  "app.pageTitle.pickers": "Pickers",
  "app.pageTitle.orders": "Orders",
  "app.pageTitle.allOrders": "All Orders",
  "app.pageTitle.upcomingOrders": "Upcoming Orders",
  "app.pageTitle.acceptedOrders": "Accepted Orders",
  "app.pageTitle.deliveredOrders": "Delivered Orders",
  "app.pageTitle.cancelledOrders": "Cancelled Orders",
  "app.pageTitle.pickedOrders": "Ready For Delivery",
  "app.pageTitle.beingPickedOrders": "Orders Being Picked",
  "app.pageTitle.shippedOrders": "Orders Shipped",
  "app.pageTitle.advertisements": "Advertisements",
  "app.pageTitle.geofence": "Geofence",
  "app.pageTitle.promoCodes": "Promo Codes",
  "app.pageTitle.activePromo": "Active Promo Codes",
  "app.pageTitle.inActivePromo": "InActive Promo Codes",
  "app.pageTitle.pushNotification": "Push Notification",
  "app.pageTitle.cancellationReasons": "Cancellation Reasons",
  "app.pageTitle.contentManagement": "Content Management",
  "app.pageTitle.settings": "Settings",
  "app.pageTitle.details": "Details",
  "app.pageTitle.assignPicker": "Assign Picker",
  "app.pageTitle.addCancellationReason": "Add Cancellation Reason",
  "app.pageTitle.editCancellationReason": "Edit Cancellation Reason",
  "app.pageTitle.notificationContent": "Notification Content",
  "app.pageTitle.addPromo": "Add Promo",
  "app.pageTitle.editPromo": "Edit Promo",
  "app.pageTitle.addStore": "Add Store",
  "app.pageTitle.editStore": "Edit Store",
  "app.pageTitle.addWallet": "Add Wallet",
  "app.pageTitle.customerDetails": "Customer Details",
  "app.pageTitle.editCustomerDetails": "Edit Customer Details",
  "app.pageTitle.addSlot": "Add Slot",
  "app.pageTitle.editSlot": "Edit Slot",
  "app.pageTitle.copySlot": "Copy Slot",
  "app.pageTitle.editOrder": "Edit Order",
  "app.pageTitle.addItem": "Add Item",
  "app.pageTitle.editItem": "Edit Item",
  "app.pageTitle.adminSettings": "Admin Settings",
  "app.pageTitle.brands": "Brands",
  "app.pageTitle.drivers": "Drivers",
  "app.pageTitle.editCostPrice": "Edit Cost Price",

  "app.pageSubtitle.dashboard": "Welcome to Pawminded",
  "app.pageSubtitle.roleManagement": "Role Management",
  "app.pageSubtitle.schedule": "Schedule",
  "app.pageSubtitle.customers": "Customers",
  "app.pageSubtitle.categories": "Categories",
  "app.pageSubtitle.products": "Products",
  "app.pageSubtitle.productDetails": "Product Details",
  "app.pageSubTitle.globalProductDetails": "Global Product Details",
  "app.pageSubtitle.stores": "Stores",
  "app.pageSubtitle.pickers": "Pickers",
  "app.pageSubtitle.orders": "Orders",
  "app.pageSubtitle.allOrders": "All Orders",
  "app.pageSubtitle.upcomingOrders": "Upcoming Orders",
  "app.pageSubtitle.acceptedOrders": "Accepted Orders",
  "app.pageSubtitle.deliveredOrders": "Delivered Orders",
  "app.pageSubtitle.cancelledOrders": "Cancelled Orders",
  "app.pageSubtitle.advertisements": "Advertisements",
  "app.pageSubtitle.geofence": "Geofence",
  "app.pageSubtitle.promoCodes": "Promo Codes",
  "app.pageSubtitle.activePromo": "Active",
  "app.pageSubtitle.inActivePromo": "InActive",
  "app.pageSubtitle.pushNotification": "Push Notification",
  "app.pageSubtitle.cancellationReasons": "Cancellation Reasons",
  "app.pageSubtitle.contentManagement": "Content Management",
  "app.pageSubtitle.settings": "Settings",
  "app.sidebar.images": "Images",
  "app.sidebar.storeProducts": "Store Products",
  "app.pageTitle.uploadImage": "Upload Image",

  "app.button.addSubAdmin": "Add Sub-Admin",
  "app.button.addStoreCategory": "Add Store Category",
  "app.button.back": "Back",
  "app.button.addCategory": "Add Category",
  "app.button.uploadImage": "Upload Image",
  "app.button.addAdvertisement": "Add Advertisement",
  "app.button.addReason": "Add Reason",
  "app.button.sendNotificationToSelected": "Send Notification To Selected",
  "app.button.sendToAll": "Send To All",
  "app.button.cancel": "Cancel",
  "app.button.logout": "Logout",
  "app.button.sendNotification": "Send Notification",
  "app.button.addPromo": "Add Promo",
  "app.button.addStore": "Add Store",
  "app.button.addSlot": "Add Slot",
  "app.button.copySlot": "Copy Slot",
  "app.button.cancelEditing": "Cancel Editing",
  "app.button.updateOrder": "Update Order",
  "app.button.addProduct": "Add Product",
  "app.button.submit": "Submit",
  "app.button.save": "Save",
  "app.button.addCity": "Add City",
  "app.button.signIn": "Sign In",
  "app.button.addGlobalProduct": "Add Global Product",
  "app.button.addTag": "Add Tag",
  "app.button.addTipOptions": "Add Tip Options",
  "app.button.addPicker": "Add Picker",
  "app.button.autoAssign": "Auto Assign",
  "app.button.cancelOrder": "Cancel Order",
  "app.button.addDriver": "Add Driver",
  "app.button.resetPassword": "Reset Password",
  "app.button.delete": "Delete",

  "app.placeholder.category": "Select Category",
  "app.placeholder.city": "Enter City",
  "app.placeholder.selectCity": "Select City",
  "app.placeholder.cities": "Select Cities",
  "app.placeholder.countries": "Select Countries",
  "app.placeholder.subCategory": "Select Sub-Category",
  "app.placeholder.name": "Enter Name",
  "app.placeholder.email": "Enter E-Mail",
  "app.placeholder.password": "Enter Password",
  "app.placeholder.phoneNumber": "Enter Phone Number",
  "app.placeholder.countryCode": "Country Code",
  "app.placeholder.country": "Select Country",
  "app.placeholder.userType": "Enter User Type",
  "app.placeholder.privilages": "Select Privileges",
  "app.placeholder.store": "Select Store",
  "app.placeholder.addCategory": "Add Category",
  "app.placeholder.editCategory": "Edit Category",
  "app.placeholder.addSubCategory": "Add Sub-Category",
  "app.placeholder.editSubCategory": "Edit Sub-Category",
  "app.placeholder.categoryName": "Enter Category Name",
  "app.placeholder.categoryDescription": "Enter Category Description",
  "app.placeholder.categoryRank": "Enter Sub-Category Rank",
  "app.placeholder.subCategoryName": "Enter Sub-Category Name",
  "app.placeholder.subCategoryDescription": "Enter Sub-Category Description",
  "app.placeholder.subCategoryRank": "Enter Sub-Category Rank",
  "app.placeholder.search": "Search",
  "app.placeholder.productName": "Enter Product Name",
  "app.placeholder.productBrand": "Enter Product Brand",
  "app.placeholder.productDescription": "Enter Product Description",
  "app.placeholder.productManufacturer": "Enter Product Manufacturer",
  "app.placeholder.skuId": "Enter Sku ID",
  "app.placeholder.rank": "Enter rank",
  "app.placeholder.barcode": "Enter Barcode",
  "app.placeholder.shelfPosition": "Enter Shelf Position",
  "app.placeholder.costPrice": "Enter Cost Price",
  "app.placeholder.margin": "Enter Margin",
  "app.placeholder.vat": "Enter VAT",
  "app.placeholder.quantity": "Enter Quantity",
  "app.placeholder.maxQuantity": "Enter Maximum Quantity",
  "app.placeholder.length": "Enter Length (in cm)",
  "app.placeholder.breadth": "Enter Breadth (in cm)",
  "app.placeholder.height": "Enter Height (in cm)",
  "app.placeholder.weight": "Enter Weight",
  "app.placeholder.loyalityPoints": "Enter Loyalty Points",
  "app.placeholder.unit": "Enter Unit",
  "app.placeholder.storeToAssign": "Select Store to Assign",
  "app.placeholder.areaName": "Enter Area Name",
  "app.placeholder.areaDescription": "Enter Area Description",
  "app.placeholder.deliveryChargeGeofence": "Enter Delivery Charges",
  "app.placeholder.minimumOrderValueGeofence": "Enter Minimum Order Value",
  "app.placeholder.advertisementType": "Select Advertisement Type",
  "app.placeholder.product": "Select Product",
  "app.placeholder.picker": "Select Picker",
  "app.placeholder.cancellationReason": "Enter Cancellation Reason",
  "app.placeholder.message": "Enter Message",
  "app.placeholder.promoCode": "Enter Promo Code",
  "app.placeholder.frequencyPerUser": "Enter Frequency Per User",
  "app.placeholder.typeOfPromo": "Select Type Of Promo",
  "app.placeholder.promoDiscount": "Enter Promo Discount",
  "app.placeholder.promoDescription": "Enter Promo Description",
  "app.placeholder.storeName": "Store Name",
  "app.placeholder.isFreeDeliveryEnabled": "Enter Free Delivery Enabled",
  "app.placeholder.limitForFreeDelivery": "Enter Limit For Free Delivery",
  "app.placeholder.storeDescription": "Enter Store Description",
  "app.placeholder.walletAmount": "Enter Wallet Amount",
  "app.placeholder.firstName": "Enter First Name",
  "app.placeholder.lastName": "Enter Last Name",
  "app.placeholder.day": "Select Day",
  "app.placeholder.deliveryMode": "Select Delivery Mode",
  "app.placeholder.startTime": "Enter Start Time",
  "app.placeholder.stock": "Select Stock",
  "app.placeholde.status": "Select Status",
  "app.placeholder.endTime": "Enter End Time",
  "app.placeholder.maxOrders": "Enter Max Orders",
  "app.placeholder.addPicker": "Add Picker",
  "app.placeholder.editPicker": "Edit Picker",
  "app.placeholder.description": "Enter Description",
  "app.placeholder.address": "Enter Address",
  "app.placeholder.transportType": "Transport Type",
  "app.placeholder.totalCount": "Total Count",
  "app.placeholder.brandName": "Enter Brand Name",
  "app.placeholder.brands": "Enter Brand",
  "app.placeholder.title": "Enter Title",
  "app.placeholder.searchLocation": "Search Location",
  "app.placeholder.tipType": "Select Tip Type",

  "app.field.tip": "Tip",
  "app.field.dailyReport": "Daily Report",
  "app.field.weeklyReport": "Weekly Report",
  "app.field.monthlyReport": "Monthly Report",
  "app.field.reportType": "Report Type",
  "app.field.schedulerSettings": "Scheduler",
  "app.field.dailySchedulerThreshold": "Daily Scheduler Threshold",
  "app.field.weeklySchedulerThreshold": "Weekly Scheduler Threshold",
  "app.field.monthlySchedulerThreshold": "Monthly Scheduler Threshold",
  "app.field.referralSettings": "Referral",
  "app.field.chartText1": "Total Orders in Given Period",
  "app.field.referredToPoints": "Referred To Amount",
  "app.field.referredByPoints": "Referred By Amount",
  "app.field.product": "Product",
  "app.field.deliveryTime": "Delivery Time",
  "app.field.brands": "Brand",
  "app.field.store": "Store",
  "app.field.stores": "Stores",
  "app.field.allStores": "All Stores",
  "app.field.stock": "Stock",
  "app.field.deliveryAddress": "Delivery Address",
  "app.field.firstName": "First Name",
  "app.field.id": "ID",
  "app.field.profile": "Profile",
  "app.field.lastName": "Last Name",
  "app.field.walletAmount": "Wallet Amount",
  "app.field.storeDescription": "Store Description",
  "app.field.message": "Message",
  "app.field.advertisementType": "Advertisement Type",
  "app.field.loyalityPoints": "Loyalty Points",
  "app.field.productBrand": "Product Brand",
  "app.field.isRedeemable": "Is Redeemable",
  "app.field.isFeatured": "Is Featured",
  "app.field.isTrending": "Is Trending",
  "app.field.shelfPosition": "Shelf Position",
  "app.field.productDescription": "Product Description",
  "app.field.categoryName": "Category Name",
  "app.field.productDetails": "Product Details",
  "app.field.categoryDescription": "Category Description",
  "app.field.rank": "Rank",
  "app.field.subCategoryName": "Sub-Category Name",
  "app.field.subCategoryDescription": "Sub-Category Description",
  "app.field.description": "Description",
  "app.field.categories": "Categories",
  "app.field.subCategories": "Sub-Categories",
  "app.field.privilages": "Privileges",
  "app.field.details": "Details",
  "app.field.isAlcoholic": "Alcoholic Category?",
  "app.field.password": "Password",
  "app.field.index": "No",
  "app.field.name": "Name",
  "app.field.email": "E-Mail",
  "app.field.contact": "Contact",
  "app.field.address": "Address",
  "app.field.userType": "User Type",
  "app.field.action": "Action",
  "app.field.day": "Day",
  "app.field.sourceDay": "Source Day",
  "app.field.destinationDay": "Destination Day",
  "app.field.deliveryMode": "Delivery Mode",
  "app.field.sourceDeliveryMode": "Source Delivery Mode",
  "app.field.age": "Age",
  "app.field.gender": "Gender",
  "app.field.geofences": "Geofences",
  "app.field.startTime": "Start Time",
  "app.field.endTime": "End Time",
  "app.field.startDate": "Start Date",
  "app.field.endDate": "End Date",
  "app.field.maxOrders": "Max Orders",
  "app.field.customerName": "Customer Name",
  "app.field.customers": "Customers",
  "app.field.addAgent": "Add Agent",
  "app.field.editAgent": "Edit Agent",
  "app.field.phoneNumber": "Phone Number",
  "app.field.registrationDate": "Registration Date",
  "app.field.walletMoney": "Wallet Money",
  "app.field.productImage": "Product Image",
  "app.field.productName": "Product Name",
  "app.field.skuId": "Sku ID",
  "app.field.title": "Title",
  "app.field.manufacturer": "Manufacturer",
  "app.field.brand": "Brand",
  "app.field.costPrice": "Cost Price",
  "app.field.actualPrice": "Actual Price",
  "app.field.vat": "VAT",
  "app.field.margin": "Margin",
  "app.field.quantity": "Quantity",
  "app.field.maxQuantity": "Maximum Quantity",
  "app.field.length": "Length",
  "app.field.breadth": "Breadth",
  "app.field.height": "Height",
  "app.field.weight": "Weight",
  "app.field.redeemable": "Redeemable",
  "app.field.redemptionPoints": "Redemption Points",
  "app.field.barcode": "Barcode",
  "app.field.featureFlag": "Feature Flag",
  "app.field.storeName": "Store Name",
  "app.field.country": "Country",
  "app.field.city": "City",
  "app.field.cities": "Cities",
  "app.field.ranking": "Ranking",
  "app.field.delivery": "Delivery",
  "app.field.transportType": "Transport Type",
  "app.field.vehicleNumber": "Vehicle Number",
  "app.placeholder.vehicleNumber": "Enter Vehicle Number",
  "app.field.imageURL": "Image URL",
  "app.field.iconURL": "Icon URL",
  "app.field.copyImage": "Copy Image",
  "app.field.copyIcon": "Copy Icon",
  "app.field.image": "Image",
  "app.field.bookingNumber": "Booking Number",
  "app.field.customerPhone": "Customer Phone",
  "app.field.branchName": "Branch Name",
  "app.field.bookingType": "Booking Type",
  "app.field.countdown": "Countdown",
  "app.field.totalAmount": "Total Amount",
  "app.field.totalOrders": "Total Orders",
  "app.field.totalValue": "Total Value (in LBP)",
  "app.field.avgOrderCart": "Average Order Cart",
  "app.field.avgOrderValue": "Average Order Value (in LBP)",
  "app.field.ordersPerDay": "Orders per Day",
  "app.field.bookingTime": "Booking Time",
  "app.field.acceptTime": "Accept Time",
  "app.field.createdAt": "Created At",
  "app.field.rating": "Rating",
  "app.field.status": "Status",
  "app.field.type": "Type",
  "app.field.areaName": "Area Name",
  "app.field.areaDescription": "Area Description",
  "app.field.deliveryCharge": "Delivery Charge",
  "app.field.isFreeDeliveryEnabled": "Free Delivery Enabled",
  "app.field.limitForFreeDelivery": "Limit For Free Delivery",
  "app.field.minimumOrderValue": "Minimum Order Value",
  "app.field.searchPlace": "Search Place",
  "app.field.promoCode": "Promo Code",
  "app.field.discount": "Discount",
  "app.field.applicableFor": "Applicable For",
  "app.field.frequencyPerUser": "Frequency Per User",
  "app.field.expiryDate": "Expiry Date",
  "app.field.cancellationReason": "Cancellation Reason",
  "app.field.subject": "Subject",
  "app.field.countryCode": "Country Code",
  "app.field.vatInPercentage": "VAT(in %)",
  "app.field.criteriaForSorting": "Criteria For Sorting",
  "app.field.radiusOfService": "Radius of Service(km)",
  "app.field.loyalityPointWalletMoney":
    "One Loyalty Point equals to Wallet Money(ex-1.5x)",
  "app.field.forgotPasswordLink": "Forgot Password Link",
  "app.field.supportEmail": "Support E-Mail",
  "app.field.automaticBookingCancellation": "Automatic Booking Cancellation",
  "app.field.timePeriodToAutomaticallyCancelOrder":
    "Time Period to Automatically Cancel Order (in Min)",
  "app.field.linkOfForgotPasswordBranch": "Link of Forgot Password For Branch",
  "app.field.linkOfForgotPasswordStore": "Link of Forgot Password For Store",
  "app.field.totalTransactionAmount": "Total Transaction Amount",
  "app.field.numberOfUsers": "No. of Users",
  "app.field.limitOfReferral": "Limit of Referral",
  "app.field.daysForExploreStores": "No. of Days For Explore Stores",
  "app.field.recordsPerPage": "Records per page",
  "app.field.yes": "Yes",
  "app.field.no": "No",
  "app.field.filterBy": "Filter By",
  "app.field.picker": "Picker",
  "app.field.paymentMode": "Payment Mode",
  "app.field.paymentMethods": "Payment Methods",
  "app.field.brandEnabled": "Brands Enabled",
  "app.field.slotTime": "Slot Time",
  "app.field.category": "Category",
  "app.field.item": "Item",
  "app.field.pickingStatus": "Picking Status",
  "app.field.unitPrice": "Unit Price",
  "app.field.unit": "Unit",
  "app.field.subTotal": "Sub-Total",
  "app.field.slotStartTime": "Slot Start Time",
  "app.field.slotEndTime": "Slot End Time",
  "app.field.orderStatus": "Order Status",
  "app.field.aboutUs": "About Us",
  "app.field.privacyPolicy": "Privacy Policy",
  "app.field.termsAndConditionsCustomer": "T&C Customer",
  "app.field.termsAndConditionsStore": "T&C Store",
  "app.field.faq": "FAQ",
  "app.field.contactUsSubjects": "Contact Us Subjects",
  "app.field.forCustomers": "For Customers",
  "app.field.forStores": "For Stores",
  "app.field.typeOfPromo": "Type Of Promo",
  "app.field.promoDescription": "Promo Description",
  "app.field.promoDiscount": "Promo Discount",
  "app.field.totalBookings": "Total Bookings",
  "app.field.originalAmount": "Original Amount",
  "app.field.newAmount": "New Amount",
  "app.field.newPassword": "New Password",
  "app.field.confirmPassword": "Confirm Password",
  "app.field.totalCount": "Total Count",
  "app.field.addBrand": "Add Brand",
  "app.field.editBrand": "Edit Brand",
  "app.field.selectBrand": "Select Brand",
  "app.fields.brands": "Brands",
  "app.field.brandName": "Enter Brand Name",
  "app.field.brandFilter": "Filter by Brand",
  "app.field.linkProducts": "Linked Products",
  "app.field.sortBy": "Sort By",
  "app.field.ascending": "Ascending",
  "app.field.ascendingSlotTime": "Ascending Slot Time",
  "app.field.descendingSlotTime": "Descending Slot Time",
  "app.field.descending": "Descending",
  "app.field.refundType": "Refund Type",
  "app.field.isSelfDelivery": "Is Self Delivery",
  "app.field.maxRadius": "Max Radius (in km)",
  "app.field.baseFare": "Base Fare",
  "app.field.timeFare": "Time Fare",
  "app.field.teamName": "Team Name",
  "app.field.storeComission": "Store Commission (in %)",
  "app.field.onlinePayComission": "Online Payment Commission (in %)",
  "app.field.distanceFare": "Distance Fare",
  "app.field.customerAddress": "Customer Address",
  "app.field.consideredQuantity": "Considered Quantity",
  "app.field.companyName": "Pawminded",
  "app.field.amountOfSales": "Amount of Sales",
  "app.field.sales": "Sales",
  "app.field.growthByYears": "Growth By Years",
  "app.field.growthByHours": "Growth By Hours",
  "app.field.android": "ANDROID",
  "app.field.ios": "IOS",
  "app.field.noData": "No Data",
  "app.field.totalCustomersRegistered": "Total Customers Registered",
  "app.field.totalPickersRegistered": "Total Pickers Registered",
  "app.field.totalStoresRegistered": "Total Stores Registered",
  "app.field.todaysTotalOrders": "Today's Total Orders",
  "app.field.todaysSales": "Today's Sales",
  "app.field.yesterDaysSales": "Yesterday's Sales",
  "app.field.todaysCompletedOrders": "Today's Completed Orders",
  "app.field.overAllCancelledOrders": "Overall Cancelled Orders",
  "app.field.totalOnlineCustomers": "Total Online Customers",
  "app.field.edit": "Edit",
  "app.field.disable": "Disable",
  "app.field.enable": "Enable",
  "app.field.delete": "Delete",
  "app.field.of": "of",
  "app.field.to": "to",
  "app.field.showing": "Showing",
  "app.field.walletPermission": "Wallet Permission",
  "app.field.countryAssigned": "Country Assigned",
  "app.field.citiesAssigned": "Cities Assigned",
  "app.field.unblock": "Unblock",
  "app.field.block": "Block",
  "app.field.appVersion": "App Version",
  "app.field.deviceType": "Device Type",
  "app.field.deviceToken": "Device Token",
  "app.field.deviceDetails": "Device Details",
  "app.field.addWallet": "Add Wallet",
  "app.message.noBrandsFound": "No Brands Found",
  "app.field.uploadImage": "Upload Image",
  "app.field.view": "View",
  "app.field.viewDetails": "View Details",
  "app.field.optional": "Optional",
  "app.field.outOfStock": "Out of Stock",
  "app.field.inStock": "In Stock",
  "app.field.disabledProducts": "Disabled Products",
  "app.field.enabledProducts": "Enabled Products",
  "app.field.allProducts": "All Products",
  "app.field.withinCategory": "Within Category",
  "app.field.withinSubCategory": "Within Sub-Category",
  "app.field.processing": "Processing",
  "app.field.uploadCSV": "Upload CSV",
  "app.field.sampleCSV": "Sample CSV",
  "app.field.addGlobalProduct": "Add Global Product",
  "app.field.editGlobalProduct": "Edit Global Product",
  "app.field.globalProductImage": "Global Product Image",
  "app.field.saveAsCSV": "Save as CSV",
  "app.field.filters": "Filters",
  "app.field.exportAsCSV": "Export as CSV",
  "app.field.bulkUpload": "Bulk Upload",
  "app.field.allBrands": "All Brands",
  "app.field.changeCostPrice": "Change Cost Price",
  "app.field.allStoreProducts": "All Store Products",
  "app.field.addStoreProduct": "Add Store Product",
  "app.field.editStoreProduct": "Edit Store Product",
  "app.field.storeProductDetails": "Store Product Details",
  "app.field.tag": "Tag",
  "app.field.saveAsGlobalCSV": "Save As Global CSV",
  "app.field.globalProducts": "Global Products",
  "app.field.addTip": "Add Tip",
  "app.field.editTip": "Edit Tip",
  "app.field.tipType": "Tip Type",
  "app.field.tipOptions": "Tip Options",
  "app.field.tipAmount": "Tip Amount",
  "app.field.lock": "Lock",
  "app.field.unlock": "Unlock",
  "app.field.deactivate": "De Activate",
  "app.field.inactive": "Inactive",
  "app.field.active": "Active",
  "app.field.activate": "Activate",
  "app.field.cancelledBy": "Cancelled By",
  "app.field.cancelledDays": "Cancelled Days",
  "app.field.pickedAt": "Picked At",
  "app.field.orderNotes": "Order Notes",
  "app.field.transactionId": "Transaction Id",
  "app.field.picked": "Picked",
  "app.field.notPicked": "Not Picked",
  "app.field.paidViaWallet": "Paid via wallet",
  "app.field.updatePassword": "Update Password",
  "app.field.beingPicked": "Being Picked",
  "app.field.readyForDelivery": "Ready for Delivery",
  "app.field.history": "History",
  "app.field.assignPicker": "Assign Picker",
  "app.field.cancelOrder": "Cancel Order",
  "app.field.sendForShipping": "Send For Shipping",
  "app.field.deliver": "Deliver",
  "app.field.markAsComplete": "Mark As Complete",
  "app.field.finish": "Finish",
  "app.field.landmark": "Landmark",
  "app.field.itemCount": "Item Count",
  "app.field.sendCriteria": "Send Criteria",
  "app.field.select": "Select",
  "app.field.chooseLanguage": "Choose Language",
  "app.field.asapOrder": "ASAP Order",
  "app.field.saveAsCsv": "Save As CSV",
  "app.field.addProduct": "Add Product",
  "app.field.subCategory": "Subcategory",
  "app.field.close": "Close",

  "app.placeholder.baseFare": "Enter Base Fare",
  "app.placeholder.paymentMethods": "Select Payment Methods",
  "app.placeholder.geofences": "Select Geofences",
  "app.placeholder.timeFare": "Enter Time Fare",
  "app.placeholder.distanceFare": "Enter Distance Fare",
  "app.placeholder.maxRadius": "Enter Max Radius (in km)",
  "app.placeholder.teamName": "Enter Team Name",
  "app.placeholder.storeComission": "Enter Store Commission (in %)",
  "app.placeholder.onlinePayComission":
    "Enter Online Payment Commission (in %)",
  "app.placeholder.deliveryTime": "Enter Delivery Time",
  "app.placeholder.enterTags": "Enter Tags",
  "app.placeholder.amount": "Enter Amount",

  "app.filter.acceptedOrders": "Accepted Orders",
  "app.filter.deliveredOrders": "Delivered Orders",
  "app.filter.cancelledOrders": "Cancelled Orders",
  "app.filter.ordersBeingPicked": "Orders Being Picked",
  "app.filter.readyForDelivery": "Ready For Delivery",
  "app.filter.ordersOnTheWay": "Orders On The Way",

  "app.message.deleteCategoryWarning":
    "All sub-categories and products present in the selected category would also be deleted !",
  "app.message.deleteSubCategoryWarning":
    "All products present in the selected sub-category would also be deleted",
  "app.message.deleteWarning": "Are you sure you want to delete?",
  "app.message.cancelOrder": "Do you really want to cancel this order?",
  "app.message.sendForShipping": "Ship this order !!",
  "app.message.markOrderComplete": "Mark this order as completed?",
  "app.message.noCustomersSelected": "No Customers Selected",
  "app.message.blockWarning": "Are you sure you want to block?",
  "app.message.unblockWarning": "Are you sure you want to unblock?",
  "app.message.lockWarning": "Are you sure you want to lock?",
  "app.message.unlockWarning": "Are you sure you want to unlock?",
  "app.message.logoutWarning": "Are you sure you want to logout?",
  "app.message.outOfStockWarning": "Item is out of stock",
  "app.message.error": "Something went wrong",
  "app.message.imageError": "Could not upload image",
  "app.message.formCheck":
    "Please check for errors in the fields filled for the form",
  "app.message.noCategoriesFound": "No Categories Found",
  "app.message.noSubCategoriesFound": "No Sub-Categories Found",
  "app.message.noCategorySelected": "No Category Selected",
  "app.message.selectCategory": "Please Select a Category",
  "app.message.noProductsFound": "No Products Found",
  "app.message.noStoresFound": "No Stores Found",
  "app.message.processingCSV": "Processing CSV",
  "app.message.tagAlreadyExists": "Tag Already Exists",
  "app.message.noPromoCodeFound": "No Promo Codes found",
  "app.message.reasonExists": "Reason already exists",
  "app.message.noPickersFound": "No Pickers Found",
  "app.message.driversNotFound": "Drivers not found",
  "app.message.teamNotFound": "Team not found",
  "app.message.noGeofenceSelected": "No Geofence Selected",
  "app.message.loading": "Loading",
  "app.message.success": "Success",
  "app.message.loggingIn": "Logging in",

  "app.warning.tagCannotBeEmpty": "Tag can not be empty",
  "app.warning.createGeofence": "Create Geofence",
  "app.warning.validateTime": "End time must be ahead of Start time",
  "app.warning.selectPicker": "Please select a Picker",
  "app.warning.duplicate": "Duplicates not allowed",
  "app.warning.noGeofenceSelected": "No Geofence Selected",
  "app.field.todayTotalOrders": "Today's Total Orders",
  "app.field.todayCompletedOrders": "Today's Completed Orders",
  "app.field.sNo": "No.",
  "app.field.markInStock": "Mark as available",
  "app.field.markOutOfStock": "Mark as unavailable",
  "app.field.lockStatus": "Lock Status",
  "app.field.loading": "Loading",
  "app.field.exportCSV": "Export CSV",
  "app.field.upload": "Upload",
  "app.field.lockedByAdmin": "Locked By Admin",

  "app.url.home": "Home",
  "app.url.Cities": "Cities",
  "app.url.Role-Management": "Role-Management",
  "app.url.Customers": "Customers",
  "app.url.Global-Brands": "Global Brands",
  "app.url.Global-Categories": "Global Categories",
  "app.url.Global-Products": "Global Products",
  "app.url.Store-Products": "Store Products",
  "app.url.Stores": "Stores",
  "app.url.Schedule": "Schedule",
  "app.url.Pickers": "Pickers",
  "app.url.Store-Categories": "Store Categories",
  "app.url.Orders": "Orders",
  "app.url.Advertisements": "Advertisements",
  "app.url.Promos": "Promos",
  "app.url.Active": "Active",
  "app.url.In-Active": "In Active",
  "app.url.Push-Notification": "Push Notification",
  "app.url.Cancellation-Reasons": "Cancellation Reasons",
  "app.url.Content-Management": "Content Management",
  "app.url.Reports": "Reports",
  "app.url.Settings": "Settings",
  "app.url.Drivers": "Drivers",
  "app.url.Images": "Images",
  "app.url.Edit": "Edit",
  "app.url.Details": "Details",
  "app.url.Geofence": "Geofence",

  "app.tag.locked": "LOCKED",
  "app.tag.open": "OPEN",
  "app.tag.blocked": "BLOCKED",
  "app.tag.active": "ACTIVE",
  "app.tag.disabled": "DISABLED",
  "app.tag.enabled": "ENABLED",
  "app.tag.unverified": "UNVERIFIED",
  "app.tag.verified": "VERIFIED",
  "app.tag.closed": "CLOSED",
  "app.tag.outOfStock": "OUT OF STOCK",
  "app.tag.inStock": "IN STOCK",
  "app.tag.no": "NO",
  "app.tag.yes": "YES",

  "app.auth.welcomeTitle": "Let's Get Started Now!",
  "app.auth.welcomeSubTitle": "Welcome Back!",

  "app.sidebar.dashboard": "Dashboard",
  "app.sidebar.roleManagement": "Role Management",
  "app.sidebar.schedule": "Schedule",
  "app.sidebar.customers": "Customers",
  "app.sidebar.categories": "Categories",
  "app.sidebar.products": "Products",
  "app.sidebar.stores": "Stores",
  "app.sidebar.pickers": "Pickers",
  "app.sidebar.orders": "Orders",
  "app.sidebar.allOrders": "All Orders",
  "app.sidebar.upcomingOrders": "Upcoming Orders",
  "app.sidebar.acceptedOrders": "Accepted Orders",
  "app.sidebar.deliveredOrders": "Delivered Orders",
  "app.sidebar.cancelledOrders": "Cancelled Orders",
  "app.sidebar.pickedOrders": "Ready For Delivery",
  "app.sidebar.beingPickedOrders": "Orders Being Picked",
  "app.sidebar.shippedOrders": "Orders On The Way",
  "app.sidebar.advertisements": "Banners",
  "app.sidebar.geofence": "Geofence",
  "app.sidebar.promoCodes": "Promo Codes",
  "app.sidebar.activePromo": "Active",
  "app.sidebar.inActivePromo": "InActive",
  "app.sidebar.pushNotification": "Push Notification",
  "app.sidebar.cancellationReasons": "Cancellation Reasons",
  "app.sidebar.contentManagement": "Content Management",
  "app.sidebar.settings": "Settings",
  "app.sidebar.brands": "Brands",
  "app.sidebar.reports": "Reports",
  "app.sidebar.storeCategories": "Store Categories",
  "app.sidebar.ordersReports": "Orders Reports",
  "app.sidebar.drivers": "Drivers",

  "app.pageTitle.ordersReports": "Orders Reports",
  "app.pageTitle.dashboard": "Pawminded Dashboard",
  "app.pageTitle.storeCategories": "Store Categories",
  "app.pageTitle.addProduct": "Add Product",
  "app.pageTitle.viewSubAdmin": "View Sub-Admin",
  "app.pageTitle.editProduct": "Edit Product",
  "app.pageTitle.addGeofence": "Add Geofence",
  "app.pageTitle.editGeofence": "Edit Geofence",
  "app.pageTitle.addAdvertisement": "Add Advertisement",
  "app.pageTitle.editAdvertisement": "Edit Advertisement",
  "app.pageTitle.roleManagement": "User Roles",
  "app.pageTitle.addSubAdmin": "Add Sub-Admin",
  "app.pageTitle.editSubAdmin": "Edit Sub-Admin",
  "app.pageTitle.schedule": "Schedule",
  "app.pageTitle.customers": "Customers",
  "app.pageTitle.categories": "Categories",
  "app.pageTitle.products": "Products",
  "app.pageTitle.stores": "Stores",
  "app.pageTitle.pickers": "Pickers",
  "app.pageTitle.orders": "Orders",
  "app.pageTitle.allOrders": "All Orders",
  "app.pageTitle.upcomingOrders": "Upcoming Orders",
  "app.pageTitle.acceptedOrders": "Accepted Orders",
  "app.pageTitle.deliveredOrders": "Delivered Orders",
  "app.pageTitle.cancelledOrders": "Cancelled Orders",
  "app.pageTitle.pickedOrders": "Ready For Delivery",
  "app.pageTitle.beingPickedOrders": "Orders Being Picked",
  "app.pageTitle.shippedOrders": "Orders Shipped",
  "app.pageTitle.advertisements": "Advertisements",
  "app.pageTitle.geofence": "Geofence",
  "app.pageTitle.promoCodes": "Promo Codes",
  "app.pageTitle.activePromo": "Active Promo Codes",
  "app.pageTitle.inActivePromo": "InActive Promo Codes",
  "app.pageTitle.pushNotification": "Push Notification",
  "app.pageTitle.cancellationReasons": "Cancellation Reasons",
  "app.pageTitle.contentManagement": "Content Management",
  "app.pageTitle.settings": "Settings",
  "app.pageTitle.details": "Details",
  "app.pageTitle.assignPicker": "Assign Picker",
  "app.pageTitle.addCancellationReason": "Add Cancellation Reason",
  "app.pageTitle.editCancellationReason": "Edit Cancellation Reason",
  "app.pageTitle.notificationContent": "Notification Content",
  "app.pageTitle.addPromo": "Add Promo",
  "app.pageTitle.editPromo": "Edit Promo",
  "app.pageTitle.addStore": "Add Store",
  "app.pageTitle.editStore": "Edit Store",
  "app.pageTitle.addWallet": "Add Wallet",
  "app.pageTitle.banners": "Banners",
  "app.pageTitle.customerDetails": "Customer Details",
  "app.pageTitle.editCustomerDetails": "Edit Customer Details",
  "app.pageTitle.addSlot": "Add Slot",
  "app.pageTitle.editSlot": "Edit Slot",
  "app.pageTitle.copySlot": "Copy Slot",
  "app.pageTitle.editOrder": "Edit Order",
  "app.sidebar.banners": "Banners",
  "app.sidebar.lessons": "Lessons",
  "app.pageTitle.addItem": "Add Item",
  "app.pageTitle.editItem": "Edit Item",
  "app.pageTitle.adminSettings": "Admin Settings",
  "app.pageTitle.brands": "Brands",
  "app.pageTitle.drivers": "Drivers",

  "app.pageSubtitle.dashboard": "Welcome to Pawminded",
  "app.pageSubtitle.roleManagement": "Role Management",
  "app.pageSubtitle.schedule": "Schedule",
  "app.pageSubtitle.customers": "Customers",
  "app.pageSubtitle.categories": "Categories",
  "app.pageSubtitle.products": "Products",
  "app.pageSubtitle.productDetails": "Product Details",
  "app.pageSubtitle.stores": "Stores",
  "app.pageSubtitle.pickers": "Pickers",
  "app.pageSubtitle.orders": "Orders",
  "app.pageSubtitle.allOrders": "All Orders",
  "app.pageSubtitle.upcomingOrders": "Upcoming Orders",
  "app.pageSubtitle.acceptedOrders": "Accepted Orders",
  "app.pageSubtitle.deliveredOrders": "Delivered Orders",
  "app.pageSubtitle.cancelledOrders": "Cancelled Orders",
  "app.pageSubtitle.advertisements": "Advertisements",
  "app.pageSubtitle.geofence": "Geofence",
  "app.pageSubtitle.promoCodes": "Promo Codes",
  "app.pageSubtitle.activePromo": "Active",
  "app.pageSubtitle.inActivePromo": "InActive",
  "app.pageSubtitle.pushNotification": "Push Notification",
  "app.pageSubtitle.cancellationReasons": "Cancellation Reasons",
  "app.pageSubtitle.contentManagement": "Content Management",
  "app.pageSubtitle.settings": "Settings",
  "app.sidebar.images": "Images",
  "app.pageTitle.uploadImage": "Upload Image",

  "app.button.addSubAdmin": "Add Sub-Admin",
  "app.button.addStoreCategory": "Add Store Category",
  "app.button.back": "Back",
  "app.button.addCategory": "Add Category",
  "app.button.uploadImage": "Upload Image",
  "app.button.addAdvertisement": "Add Advertisement",
  "app.button.addReason": "Add Reason",
  "app.button.sendNotificationToSelected": "Send Notification To Selected",
  "app.button.sendToAll": "Send To All",
  "app.button.cancel": "Cancel",
  "app.button.sendNotification": "Send Notification",
  "app.button.addPromo": "Add Promo",
  "app.button.addStore": "Add Store",
  "app.button.addSlot": "Add Slot",
  "app.button.copySlot": "Copy Slot",
  "app.button.cancelEditing": "Cancel Editing",
  "app.button.updateOrder": "Update Order",
  "app.button.addProduct": "Add Product",
  "app.button.submit": "Submit",
  "app.button.save": "Save",

  "app.placeholder.category": "Select Category",
  "app.placeholder.subCategory": "Select Sub-Category",
  "app.placeholder.name": "Enter Name",
  "app.placeholder.email": "Enter E-Mail",
  "app.placeholder.password": "Enter Password",
  "app.placeholder.phoneNumber": "Enter Phone Number",
  "app.placeholder.countryCode": "Country Code",
  "app.placeholder.userType": "Enter User Type",
  "app.placeholder.privilages": "Select Privileges",
  "app.placeholder.store": "Select Store",
  "app.placeholder.addCategory": "Add Category",
  "app.placeholder.editCategory": "Edit Category",
  "app.placeholder.addSubCategory": "Add Sub-Category",
  "app.placeholder.editSubCategory": "Edit Sub-Category",
  "app.placeholder.categoryName": "Enter Category Name",
  "app.placeholder.categoryDescription": "Enter Category Description",
  "app.placeholder.categoryRank": "Enter Sub-Category Rank",
  "app.placeholder.subCategoryName": "Enter Sub-Category Name",
  "app.placeholder.subCategoryDescription": "Enter Sub-Category Description",
  "app.placeholder.subCategoryRank": "Enter Sub-Category Rank",
  "app.placeholder.search": "Search",
  "app.placeholder.productName": "Enter Product Name",
  "app.placeholder.productBrand": "Enter Product Brand",
  "app.placeholder.productDescription": "Enter Product Description",
  "app.placeholder.productManufacturer": "Enter Product Manufacturer",
  "app.placeholder.skuId": "Enter Sku ID",
  "app.placeholder.rank": "Enter rank",
  "app.placeholder.barcode": "Enter Barcode",
  "app.placeholder.costPrice": "Enter Cost Price",
  "app.placeholder.margin": "Enter Margin",
  "app.placeholder.vat": "Enter VAT",
  "app.placeholder.quantity": "Enter Quantity",
  "app.placeholder.length": "Enter Length (in cm)",
  "app.placeholder.breadth": "Enter Breadth (in cm)",
  "app.placeholder.height": "Enter Height (in cm)",
  "app.placeholder.weight": "Enter Weight",
  "app.placeholder.loyalityPoints": "Enter Loyalty Points",
  "app.placeholder.unit": "Enter Unit",
  "app.placeholder.storeToAssign": "Select Store to Assign",
  "app.placeholder.areaName": "Enter Area Name",
  "app.placeholder.areaDescription": "Enter Area Description",
  "app.placeholder.deliveryChargeGeofence": "Enter Delivery Charges",
  "app.placeholder.minimumOrderValueGeofence": "Enter Minimum Order Value",
  "app.placeholder.advertisementType": "Select Advertisement Type",
  "app.placeholder.product": "Select Product",
  "app.placeholder.picker": "Select Picker",
  "app.placeholder.cancellationReason": "Enter Cancellation Reason",
  "app.placeholder.message": "Enter Message",
  "app.placeholder.promoCode": "Enter Promo Code",
  "app.placeholder.frequencyPerUser": "Enter Frequency Per User",
  "app.placeholder.typeOfPromo": "Select Type Of Promo",
  "app.placeholder.promoDiscount": "Enter Promo Discount",
  "app.placeholder.promoDescription": "Enter Promo Description",
  "app.placeholder.storeName": "Store Name",
  "app.placeholder.isFreeDeliveryEnabled": "Enter Free Delivery Enabled",
  "app.placeholder.limitForFreeDelivery": "Enter Limit For Free Delivery",
  "app.placeholder.storeDescription": "Enter Store Description",
  "app.placeholder.walletAmount": "Enter Wallet Amount",
  "app.placeholder.firstName": "Enter First Name",
  "app.placeholder.lastName": "Enter Last Name",
  "app.placeholder.day": "Select Day",
  "app.placeholder.deliveryMode": "Select Delivery Mode",
  "app.placeholder.startTime": "Enter Start Time",
  "app.placeholder.endTime": "Enter End Time",
  "app.placeholder.maxOrders": "Enter Max Orders",
  "app.placeholder.addPicker": "Add Picker",
  "app.placeholder.editPicker": "Edit Picker",
  "app.placeholder.description": "Enter Description",
  "app.placeholder.address": "Enter Address",
  "app.placeholder.transportType": "Transport Type",
  "app.placeholder.totalCount": "Total Count",
  "app.placeholder.brandName": "Enter Brand Name",
  "app.placeholder.brands": "Enter Brand",
  "app.placeholder.title": "Enter Title",

  "app.field.dailyReport": "Daily Report",
  "app.field.weeklyReport": "Weekly Report",
  "app.field.monthlyReport": "Monthly Report",
  "app.field.reportType": "Report Type",
  "app.field.schedulerSettings": "Scheduler",
  "app.field.customerNotes": "Customer Notes",
  "app.field.dailySchedulerThreshhold": "Daily Scheduler Threshhold",
  "app.field.weeklySchedulerThreshhold": "Weekly Scheduler Threshhold",
  "app.field.monthlySchedulerThreshhold": "Monthly Scheduler Threshhold",
  "app.field.referralSettings": "Referral",
  "app.field.referredToPoints": "Referred To Amount",
  "app.field.referredByPoints": "Referred By Amount",
  "app.field.product": "Select Product",
  "app.field.brands": "Brand",
  "app.field.store": "Store",
  "app.field.deliveryAddress": "Delivery Address",
  "app.field.firstName": "First Name",
  "app.field.lastName": "Last Name",
  "app.field.walletAmount": "Wallet Amount",
  "app.field.storeDescription": "Store Description",
  "app.field.message": "Message",
  "app.field.advertisementType": "Advertisement Type",
  "app.field.loyalityPoints": "Loyalty Points",
  "app.field.productBrand": "Product Brand",
  "app.field.isRedeemable": "Is Redeemable",
  "app.field.isFeatured": "Is Featured",
  "app.field.productDescription": "Product Description",
  "app.field.categoryName": "Category Name",
  "app.field.productDetails": "Product Details",
  "app.field.categoryDescription": "Category Description",
  "app.field.rank": "Rank",
  "app.field.subCategoryName": "Sub-Category Name",
  "app.field.subCategoryDescription": "Sub-Category Description",
  "app.field.description": "Description",
  "app.field.categories": "Categories",
  "app.field.subCategories": "Sub-Categories",
  "app.field.privilages": "Privileges",
  "app.field.details": "Details",
  "app.field.password": "Password",
  "app.field.index": "No",
  "app.field.name": "Name",
  "app.field.email": "E-Mail",
  "app.field.contact": "Contact",
  "app.field.address": "Address",
  "app.field.userType": "User Type",
  "app.field.action": "Action",
  "app.field.day": "Day",
  "app.field.sourceDay": "Source Day",
  "app.field.destinationDay": "Destination Day",
  "app.field.deliveryMode": "Delivery Mode",
  "app.field.sourceDeliveryMode": "Source Delivery Mode",
  "app.field.age": "Age",
  "app.field.gender": "Gender",
  "app.field.startTime": "Start Time",
  "app.sidebar.organizations": "Organizations",
  "app.field.endTime": "End Time",
  "app.field.startDate": "Start Date",
  "app.field.endDate": "End Date",
  "app.field.maxOrders": "Max Orders",
  "app.field.customerName": "Customer Name",
  "app.field.phoneNumber": "Phone Number",
  "app.field.registrationDate": "Registration Date",
  "app.field.walletMoney": "Wallet Money",
  "app.field.productImage": "Product Image",
  "app.field.productName": "Product Name",
  "app.field.skuId": "Sku ID",
  "app.field.manufacturer": "Manufacturer",
  "app.field.brand": "Brand",
  "app.field.costPrice": "Cost Price",
  "app.field.actualPrice": "Actual Price",
  "app.field.vat": "VAT",
  "app.field.margin": "Margin",
  "app.field.quantity": "Quantity",
  "app.field.length": "Length",
  "app.field.breadth": "Breadth",
  "app.field.height": "Height",
  "app.field.weight": "Weight",
  "app.field.redeemable": "Redeemable",
  "app.field.redemptionPoints": "Redemption Points",
  "app.field.barcode": "Barcode",
  "app.field.featureFlag": "Feature Flag",
  "app.field.storeName": "Store Name",
  "app.field.city": "City",
  "app.field.ranking": "Ranking",
  "app.field.delivery": "Delivery",
  "app.field.transportType": "Transport Type",
  "app.field.image": "Image",
  "app.field.bookingNumber": "Booking Number",
  "app.field.customerPhone": "Customer Phone",
  "app.field.branchName": "Branch Name",
  "app.field.bookingType": "Booking Type",
  "app.field.countdown": "Countdown",
  "app.field.totalAmount": "Total Amount",
  "app.field.bookingTime": "Booking Time",
  "app.field.createdAt": "Created At",
  "app.field.rating": "Rating",
  "app.field.status": "Status",
  "app.field.type": "Type",
  "app.field.areaName": "Area Name",
  "app.field.areaDescription": "Area Description",
  "app.field.deliveryCharge": "Delivery Charge",
  "app.field.isFreeDeliveryEnabled": "Free Delivery Enabled",
  "app.field.limitForFreeDelivery": "Limit For Free Delivery",
  "app.field.minimumOrderValue": "Minimum Order Value",
  "app.field.searchPlace": "Search Place",
  "app.field.promoCode": "Promo Code",
  "app.field.discount": "Discount",
  "app.field.applicableFor": "Applicable For",
  "app.field.frequencyPerUser": "Frequency Per User",
  "app.field.expiryDate": "Expiry Date",
  "app.field.cancellationReason": "Cancellation Reason",
  "app.field.subject": "Subject",
  "app.field.countryCode": "Country Code",
  "app.field.vatInPercentage": "VAT(in %)",
  "app.field.criteriaForSorting": "Criteria For Sorting",
  "app.field.radiusOfService": "Radius of Service(km)",
  "app.field.loyalityPointWalletMoney":
    "One Loyalty Point equals to Wallet Money(ex-1.5x)",
  "app.field.forgotPasswordLink": "Forgot Password Link",
  "app.field.supportEmail": "Support E-Mail",
  "app.field.automaticBookingCancellation": "Automatic Booking Cancellation",
  "app.field.timePeriodToAutomaticallyCancelOrder":
    "Time Period to Automatically Cancel Order (in Min)",
  "app.field.linkOfForgotPasswordBranch": "Link of Forgot Password For Branch",
  "app.field.linkOfForgotPasswordStore": "Link of Forgot Password For Store",
  "app.field.totalTransactionAmount": "Total Transaction Amount",
  "app.field.numberOfUsers": "No. of Users",
  "app.field.limitOfReferral": "Limit of Referral",
  "app.field.daysForExploreStores": "No. of Days For Explore Stores",
  "app.field.recordsPerPage": "Records per page",
  "app.field.yes": "Yes",
  "app.field.no": "No",
  "app.field.filterBy": "Filter By",
  "app.field.picker": "Picker",
  "app.field.paymentMode": "Payment Mode",
  "app.field.slotTime": "Slot Time",
  "app.field.category": "Category",
  "app.field.item": "Item",
  "app.field.pickingStatus": "Picking Status",
  "app.field.unitPrice": "Unit Price",
  "app.field.unit": "Unit",
  "app.field.subTotal": "Sub-Total",
  "app.field.slotStartTime": "Slot Start Time",
  "app.field.slotEndTime": "Slot End Time",
  "app.field.orderStatus": "Order Status",
  "app.field.aboutUs": "About Us",
  "app.field.privacyPolicy": "Privacy Policy",
  "app.field.termsAndConditionsCustomer": "T&C Customer",
  "app.field.termsAndConditionsStore": "T&C Store",
  "app.field.faq": "FAQ",
  "app.field.contactUsSubjects": "Contact Us Subjects",
  "app.field.forCustomers": "For Customers",
  "app.field.forStores": "For Stores",
  "app.field.typeOfPromo": "Type Of Promo",
  "app.field.promoDescription": "Promo Description",
  "app.field.promoDiscount": "Promo Discount",
  "app.field.totalBookings": "Total Bookings",
  "app.field.originalAmount": "Original Amount",
  "app.field.newAmount": "New Amount",
  "app.field.totalCount": "Total Count",
  "app.field.addBrand": "Add Brand",
  "app.field.editBrand": "Edit Brand",
  "app.field.selectBrand": "Select Brand",
  "app.fields.brands": "Brands",
  "app.field.brandName": "Enter Brand Name",
  "app.field.brandFilter": "Filter by Brand",
  "app.field.linkProducts": "Linked Products",
  "app.field.sortBy": "Sort By",
  "app.field.ascending": "Ascending",
  "app.field.descending": "Descending",
  "app.field.refundType": "Refund Type",
  "app.field.isSelfDelivery": "Is Self Delivery",
  "app.field.maxRadius": "Max Radius (in km)",
  "app.field.baseFare": "Base Fare",
  "app.field.timeFare": "Time Fare",
  "app.field.teamName": "Team Name",
  "app.field.storeComission": "Store Commission (in %)",
  "app.field.onlinePayComission": "Online Payment Commission (in %)",
  "app.field.distanceFare": "Distance Fare",
  "app.field.customerAddress": "Customer Address",

  "app.placeholder.baseFare": "Enter Base Fare",
  "app.placeholder.timeFare": "Enter Time Fare",
  "app.placeholder.distanceFare": "Enter Distance Fare",
  "app.placeholder.maxRadius": "Enter Max Radius (in km)",
  "app.placeholder.teamName": "Enter Team Name",
  "app.placeholder.storeComission": "Enter Store Commission (in %)",
  "app.placeholder.onlinePayComission":
    "Enter Online Payment Commission (in %)",

  "app.message.deleteCategoryWarning":
    "All sub-categories and products present in the selected category would also be deleted !",
  "app.message.deleteSubCategoryWarning":
    "All products present in the selected sub-category would also be deleted",
  "app.message.deleteWarning": "Are you sure you want to delete?",
  "app.message.cancelOrder": "Do you really want to cancel this order?",
  "app.message.sendForShipping": "Ship this order !!",
  "app.message.markOrderComplete": "Mark this order as completed?",
  "app.message.noCustomersSelected": "No Customers Selected",
  "app.message.blockWarning": "Are you sure you want to block?",
  "app.message.unblockWarning": "Are you sure you want to unblock?",
  "app.message.lockWarning": "Are you sure you want to lock?",
  "app.message.unlockWarning": "Are you sure you want to unlock?",
  "app.message.outOfStockWarning": "Item is out of stock",
  "app.message.error": "Something went wrong",
  "app.message.imageError": "Could not upload image",
  "app.message.formCheck":
    "Please check for errors in the fields filled for the form",
  "app.field.totalOrdersInGivenPeriod": "Total Orders in given period",
  "app.placeholder.date": "Select date",
  "app.field.exportAsCsv": "Export As CSV"
};
export default data;
