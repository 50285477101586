import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Select, Button } from "antd";
import { toastr } from "react-redux-toastr";

import axios from "../../../../shared/api/axiosConfig";
import { Panel, CustomModal, CustomTable, CustomImage } from "../../../../components/common";
import { numberWithCommas } from "../../../../shared/utils/object-manipulation";
import {
  PAYMENT_METHODS_MAPPING,
  PICKER_STATUS,
} from "../../../../shared/constants/orders";
import "./order-details-modal.scss";

const styles = {
  table: "custom-table",
  tableWrapper: "order-details-modal__table-wrapper",
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  wrapper: "order-details-modal__wrapper",
  bookingInfo: {
    container: "order-details-modal__booking-info__container",
    item: "order-details-modal__booking-info__item",
  },

  billing: {
    container: "order-details-modal__billing__container",
    item: "order-details-modal__billing__item",
    footer: "order-details-modal__billing__footer",
  },
};

function OrderDetailsModal(props) {
  const intl = useIntl();

  const [tableControls, updateTableControls] = useState({
    isLoading: false,
    data: [],
    isEmpty: false,
  });
  const getProducts = (bookingId) => {
    updateTableControls({
      data: [],
      isLoading: true,
      isEmpty: false,
    });
    axios
      .get("/getGroceryOrderDetails", {
        params: {
          bookingId,
        },
      })
      .then((res) => {
        if (!res.data.data.booking.length) {
          toastr.error("Error", "Something Went Wrong");
        } else {
          const products = JSON.parse(res.data.data.booking[0].products);
          if (products.length) {
            updateTableControls({
              data: products,
              isLoading: false,
              isEmpty: false,
            });
          } else {
            updateTableControls({
              data: [],
              isLoading: false,
              isEmpty: true,
            });
          }
        }
      })
      .catch((err) => {
        // toastr.error("Error", "Something Went Wrong");
        updateTableControls({
          data: [],
          isLoading: false,
          isEmpty: true,
        });
      });
  };
  // console.log("props", props);
  console.log("detailsRender");
  useEffect(() => {
    if (props.isVisible) {
      getProducts(props.data.bookingId);
    }
  }, [props.isVisible]);

  const columns = [
    {
      dataIndex: "image",
      title: intl.formatMessage({ id: "app.field.image" }),
      render: (item) => <CustomImage isTable src={item} style={{ height:"120px",width:"120px" }} />
    },
    {
      dataIndex: "name",
      title: intl.formatMessage({ id: "app.field.item" }),
    },
    {
      dataIndex: "description",
      title: intl.formatMessage({ id: "app.field.description" }),
    },
    {
      dataIndex: "pickerJobStatus",
      title: intl.formatMessage({ id: "app.field.status" }),
      render: (item) =>
        item === PICKER_STATUS.PICKED ? "Picked" : "Not Picked",
      filterOut: !props.pickerFlow,
    },
    {
      dataIndex: "",
      title: intl.formatMessage({ id: "app.field.quantity" }),
      render: (item) => <div>{item.baseValue?item.quantityBooked*item.baseValue: item.quantityBooked} {item.sellingUnit ? item.sellingUnit : ""}</div>
    },
    {
      dataIndex: "actualPrice",
      title: intl.formatMessage({ id: "app.field.unitPrice" }),
      render: (item) => numberWithCommas(item),
    },
  ].filter((i) => (i.filterOut ? false : true));

  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={props.onCloseIconClick}
      useRawHeader
      header={`${intl.formatMessage({ id: "app.field.bookingNumber" })} #${
        props.data.bookingId
      }`}
      isVisible={props.isVisible}
    >
      <div className={styles.wrapper}>
        <div className={styles.bookingInfo.container}>
          <div className={styles.bookingInfo.item}>
            <label>
              {intl.formatMessage({ id: "app.field.customerName" })}
            </label>
            <div>{`${props.data.customer?.customerName}`}</div>
          </div>
          <div className={styles.bookingInfo.item}>
            <label>
              {intl.formatMessage({ id: "app.field.customerPhone" })}
            </label>
            <div>{props.data.customer?.phoneNo}</div>
          </div>
          {props.data.trannsactionId !== "" &&
            props.data.trannsactionId !== null &&
            props.data.trannsactionId !== undefined && (
              <div className={styles.bookingInfo.item}>
                <label>
                  TransactionId
                  {/* {intl.formatMessage({ id: "app.field.customerPhone" })} */}
                </label>
                <div>{props.data.transactionId}</div>
              </div>
            )}
          {/* <div className={styles.bookingInfo.item}>
            <label>{intl.formatMessage({ id: "app.field.bookingType" })}</label>
            <div>{props.data.bookingType}</div>
          </div> */}
          <div className={styles.bookingInfo.item}>
            <label>{intl.formatMessage({ id: "app.field.paymentMode" })}</label>
            <div>{PAYMENT_METHODS_MAPPING[props.data.modeOfPaymentStatus]}</div>
          </div>
          <div className={styles.bookingInfo.item}>
            <label>{intl.formatMessage({ id: "app.field.customerNotes" })}</label>
            <div>{props.data.description ? props.data.description : "N/A"}</div>
          </div>
        </div>

        <CustomTable
          dataSource={tableControls.data}
          isLoading={tableControls.isLoading}
          pagination={undefined}
          size="small"
          columns={columns}
        />

        <div className={styles.billing.container}>
          <div className={styles.billing.item}>
            <label>{intl.formatMessage({ id: "app.field.subTotal" })}</label>
            <div>{numberWithCommas(props.data.totalAmount)}</div>
          </div>
          <div className={styles.billing.item}>
            <label>{intl.formatMessage({ id: "app.field.discount" })}*</label>
            <div style={{ color: "red" }}>
              -{numberWithCommas(props.data.discount)}
            </div>
          </div>
          {props.data.creditsUsed > 0 && (
            <div className={styles.billing.item}>
              <label>
                {intl.formatMessage({ id: "app.field.creditsUsed" })}
              </label>
              <div style={{ color: "red" }}>
                -{numberWithCommas(props.data.creditsUsed)}
              </div>
            </div>
          )}
          <div className={styles.billing.item}>
            <label>
              {intl.formatMessage({ id: "app.field.deliveryCharge" })}
            </label>
            <div>+{numberWithCommas(props.data.deliveryCharges)}</div>
          </div>
        </div>
        <div className={styles.billing.footer}>
          <label>{intl.formatMessage({ id: "app.field.totalAmount" })}</label>
          <div>{numberWithCommas(props.data.overAllTotal)}</div>
        </div>
      </div>
    </CustomModal>
  );
}

export default OrderDetailsModal;
