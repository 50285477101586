import React from "react";
import { Table } from "antd";
import "./custom-table.scss";

import { connect } from "react-redux";
import { checkRtl } from "../../../shared/utils/rtl";
/*
    pageSize: 10,
    skip: 0,
    current: 1,
    search: "",
    delayedcurrent: 1,
    delayedpageSize: 10,
 */
const getViewportHeight = (offset) => {
  offset = offset || 0;
  return window.innerHeight - 380 - offset;
};
function CustomTable(props) {
  const pagination = props.pagination ? {
    total: props.totalRecords,
    pageSize: props.pagination.recordsPerPage,
    current: props.pagination.currentPage,
    recordsPerPage: props.pagination.recordsPerPage,
    currentPage: props.pagination.currentPage,
    direction: "rtl",
    // ...props.pagination,
  } : undefined

  const handleChange = (nextProps) => {
    props.onChange({
      ...nextProps,
      recordsPerPage: nextProps.pageSize,
      currentPage: nextProps.current,
      skip: (nextProps.current - 1) * nextProps.pageSize,
    })
  }
  return (
    <Table
      className="custom-table"
      dataSource={props.dataSource}
      size={props.size || "small"}
      rowKey={props.rowKey}
      columns={props.columns.map((i) => ({ ...i, width: props.columnWidth }))}
      loading={props.isLoading}
      direction={props.isRTL ? "rtl" : "ltr"}
      onChange={handleChange}
      pagination={pagination}
      rowSelection={props.rowSelection}
      expandable={props.expandable}
      scroll={
        props.noScroll
          ? undefined
          : {
              x: props.columns.length * props.columnWidth,
              y: getViewportHeight(props.offset),
            }
      }
      row
    ></Table>
  );
}

CustomTable.defaultProps = {
  rowKey: (temp, index) => index,
  onChange: (pagination, filters, sorter) => {
    console.log({ pagination, filters, sorter });
  },
  columnWidth: 110,
  // pagination: {
  //     current: 1,
  //     pageSize: 10,
  //   },
};

const mapState = (state) => ({
  isRTL: checkRtl(state.user.locale),
});
export default connect(mapState)(CustomTable);
