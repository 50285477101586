import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Select, Dropdown, Button, Menu,Popconfirm } from "antd";
import { CaretDownOutlined, MoreOutlined, SearchOutlined } from "@ant-design/icons";
import { connect } from 'react-redux';
import moment from "moment";
import userActions from '../../redux/user-info/user-actions';
import { RECORDS_PER_PAGE } from "../../shared/constants/dropdown";
import {
  Header,
  PaginationInfo,
  ConfirmModal,
  Label,
  DelayedInput,
  CustomTable,
  CustomImage,
  CustomTag,
} from "../../components/common";
import {
  getBaseURL,
  textTrimmer,
  TABLE_TEXT_LIMIT,
} from "../../shared/constants/dropdown";
import { toastr } from "react-redux-toastr";
import { removeEmptyKeys } from "../../shared/utils/object-manipulation";
import axios from "../../shared/api/axiosConfig";
import AddEditLesson from "./components/add-edit-lesson/add-edit-lesson";
import { checkRtl } from "../../shared/utils/rtl";
import Validations from "../../shared/validations/Validations";

const styles = {
  wrapper: "lessons__wrapper",
  upperMenu: "lessons__upper-menu",
  upperMenuLeft: "lessons__upper-menu__left",
  upperMenuRight: "lessons__upper-menu__right",
  upperMenuDropdown: "lessons__upper-menu__dropdown",
  recordsPerPage: "products__upper-menu__records-per-page",
};

const courseType = [
  {
    key: 1,
    value: "Engage",
  },
  {
    key: 2,
    value: "Adopt",
  },
  // {
  //   key: 3,
  //   value: "Save",
  // },
  {
    key: 3,
    value: "Train",
  }
];

const lessonType = [
  {
    key: "video",
    value: "Video",
  },
  {
    key: "reading",
    value: "Reading",
  }
];
const { Option } = Select;

function Lessons(props) {
  const intl = useIntl();
  const [lessons, updateLessons] = useState({
    data: [],
    notFound: false,
    isLoading: true,
    count: null,
  });

  const [courses, updateCourses] = useState({
    data: [],
    selected: undefined,
    notFound: false,
    isLoading: true,
    count: null,
  });


  const getCourses = () => {
    updateCourses({
      ...courses,
      isLoading: true,
      notFound: false,
    });
    axios
      .get(`/courses`)
      .then((res) => {
        if (res.data.data) {
          updateCourses({
            ...courses,
            data: res.data.data,
            isLoading: false,
            notFound: false,
            count: res.data.data.length,
          });
          if(props.courseInfo === undefined){
            changeCourse(Validations.validateNumericValue(res.data.data[0].courseId));
          }
        } else {
          toastr.error("Error", "No courses found");
          updateCourses({
            ...courses,
            data: [],
            isLoading: false,
            notFound: true,
            count: 0,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        updateCourses({
          ...courses,
          isLoading: false,
        });
      });
  };

  const [search, updateSearch] = useState("");

  const handleSearchChange = (val) => {
    updateSearch(val);
  };

  const handleSearch = (val) => {
    updatePagination({
      ...pagination,
      currentPage: 1,
      search: val,
      skip: 0,
    });
  };
  const [course,changeCourse] = useState(Validations.validateNumericValue(props.courseInfo));
  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    isDeleteModalVisible: false,
    isDisableModalVisible: false,
    data: {},
    type: "",
  });

  const [pagination, updatePagination] = useState({
    recordsPerPage: 10,
    skip: 0,
    currentPage: 1,
    search: "",
  });

  const [tableControls, updateTableControls] = useState({
    isTableLoading: true,
    isTableEmpty: false,
  });

  const openAddLessonModal = () => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      type: "ADD_LESSON",
    });
  };

  const openEditLessonModal = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      data,
      type: "EDIT_LESSON",
    });
  };

  const openPartModal = (data) => {
    // return;
    props.history.push(getBaseURL(`/lessons/${data.lessonId}/parts`));
  };

  const openDeleteLessonModal = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: false,
      isDeleteModalVisible: true,
      data,
      type: "",
    });
  };

  const handleModalClose = () => {
    updateModalControls({
      isVisible: false,
      isDeleteModalVisible: false,
      data: {},
    });
  };
  const handleCourseChange = (course) => {
    props.dispatch(userActions.changeCourse(course));
    changeCourse(Validations.validateNumericValue(course));
    updatePagination({
      ...pagination,
      currentPage: 1,
      skip: 0,
    });
  };
  const handlePaginationChange = (page) => {
    updatePagination({
      ...pagination,
      currentPage: page,
      skip: (page - 1) * pagination.recordsPerPage,
    });
  };

  const handleRecordsPerPageChange = (val) => {
    updatePagination({
      ...pagination,
      recordsPerPage: val,
    });
  };


  const getReviews = () => {
    // updateLessons({
    //   ...lessons,
    //   isLoading: true,
    //   notFound: false,
    // });
    axios
      .get(`/reviews/course/${course.value}`, {
        params: removeEmptyKeys({
          courseId: course.value,
          // limit: pagination.recordsPerPage,
          // skip: (pagination.currentPage - 1) * pagination.recordsPerPage,
          // search: pagination.search,
        }),
      })
      .then((res) => {
        if (res.data.data) {
          // updateLessons({
          //   ...lessons,
          //   data: res.data.data,
          //   isLoading: false,
          //   notFound: false,
          //   count: res.data.data.length,
          // });

          updateTableControls({
            isTableEmpty: false,
            isTableLoading: false,
          });
        } else {
          toastr.error("Error", "No Review found");
          updateTableControls({
            isTableEmpty: true,
            isTableLoading: false,
          });
          // updateLessons({
          //   ...lessons,
          //   data: [],
          //   isLoading: false,
          //   notFound: true,
          //   count: 0,
          // });
        }
      })
      .catch((err) => {
        // console.log(err);
        updateLessons({
          ...lessons,
          isLoading: false,
        });
      });
  };

  const getLessons = () => {
    updateLessons({
      ...lessons,
      isLoading: true,
      notFound: false,
    });
    axios
      .get(`/courses/${course.value}/lessons`, {
        params: removeEmptyKeys({
          limit: pagination.recordsPerPage,
          skip: (pagination.currentPage - 1) * pagination.recordsPerPage,
          search: pagination.search,
        }),
      })
      .then((res) => {
        if (res.data.data) {
          updateLessons({
            ...lessons,
            data: res.data.data,
            isLoading: false,
            notFound: false,
            count: res.data.data.length,
          });

          updateTableControls({
            isTableEmpty: false,
            isTableLoading: false,
          });
        } else {
          toastr.error("Error", "No Lessons found");
          updateTableControls({
            isTableEmpty: true,
            isTableLoading: false,
          });
          updateLessons({
            ...lessons,
            data: [],
            isLoading: false,
            notFound: true,
            count: 0,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        updateLessons({
          ...lessons,
          isLoading: false,
        });
      });
  };

  useEffect(() => {
    getCourses();
  },[])

  useEffect(() => {
    if(course.value){
      getLessons();
      // getReviews(); 
    }
  }, [
    pagination.recordsPerPage,
    pagination.currentPage,
    pagination.search,
    course.value,
  ]);

  const handleLessonAdd = (data) => {
    console.log("add called");
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });

    axios
      .post(`/courses/${course.value}/lessons`, removeEmptyKeys(data))
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isVisible: false,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getLessons();
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateModalControls({
          ...modalControls,
          isLoading: false,
        });
      });
  };

  const handleLessonEdit = (data) => {
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });
    // data.courseId = modalControls.data.courseId;
    axios
      .put(`/courses/lessons/${modalControls.data.lessonId}`, removeEmptyKeys(data))
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isVisible: res.data.statusCode !== 200,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getLessons();
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateModalControls({
          ...modalControls,
          isLoading: false,
        });
      });
  };

  const handleLessonDelete = (item) => {
    const data = {
      lessonId: item.lessonId,
    //   isDeleted: 1,
    };
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });

    axios
      .delete(`/courses/lessons/${item.lessonId}`, removeEmptyKeys(data))
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isDeleteModalVisible: res.data.statusCode !== 200,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getLessons();
        } else {
          toastr.error("Error", res.data.message);
          updateModalControls({
            ...modalControls,
            isDeleteModalVisible: false,
          });
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateModalControls({
          ...modalControls,
          isDeleteModalVisible: false,
        });
      });
  };

  const getMenu = (item) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            openEditLessonModal(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.edit" })}
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            openPartModal(item);
          }}
        >Parts
        </Menu.Item>
        <Menu.Item
        >
          <Popconfirm
            title={intl.formatMessage({
              id: "app.message.deleteWarning",
            })}
            onConfirm={() => {
              handleLessonDelete(item);
            }}
            placement="topRight"
            okText={intl.formatMessage({ id: "app.field.yes" })}
            cancelText={intl.formatMessage({ id: "app.field.no" })}
          >
            {intl.formatMessage({ id: "app.field.delete" })}
          </Popconfirm>
        </Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      dataIndex: "",
      // sorter: true,
      title: intl.formatMessage({ id: "app.field.sNo" }),
      // fixed: 'left',
      render: (e, item, index) => {
        return (
          <span>
            {pagination.recordsPerPage * (pagination.currentPage - 1) +
              index +
              1}
          </span>
        );
      },
    },
    {
      dataIndex: "lessonIndex",
      // sorter: true,
      title: "Rank",
      // fixed: 'left',
      // render: (e, item, index) => {
      //   return (
      //     <span>
      //       {pagination.recordsPerPage * (pagination.currentPage - 1) +
      //         index +
      //         1}
      //     </span>
      //   );
      // },
    },
    {
      dataIndex: "cover",
      title: intl.formatMessage({ id: "app.field.image" }),
      render: (i) => {
        return <CustomImage isTable src={i} />;
      },
    },
    {
      dataIndex: "title",
      title: intl.formatMessage({ id: "app.field.title" }),
    },

    {
        dataIndex: "lead",
        title: "Introduction Text",
        // title: intl.formatMessage({ id: "app.field.title" }),
    },
    {
        dataIndex: "createdAt",
        title: "Uploaded At",
        render: (item) => moment.utc(item).format("MMMM Do YYYY"),

    },

    // {
    //     dataIndex: "introduction",
    //     title: "Introduction",
    //     render: (i) => {
    //         // return (<iframe width="420" height="345" src={i}>
    //         // </iframe>)
    //         return (
    //             <div>
    //             <a href={i} target="_blank">{i}</a>
    //             {/* <iframe width="50" height="50" src={i} frameborder="0" allowfullscreen ng-show="showvideo"></iframe> */}
    //             </div>
    //         )
    //     }
    //     // title: intl.formatMessage({ id: "app.field.title" }),
    // },
    // {
    //   dataIndex: "lessonType",
    //   title: "Lesson Type",
    //   render: (item) => (
    //     lessonType.map((bt) => {
    //       if(item == bt.key){
    //        return <span>{bt.value}</span>
    //       }
    //     })
    //   )
    // },
    {
      dataIndex: "",
      title: intl.formatMessage({ id: "app.field.action" }),
      fixed: "right",
      render: (item) => {
        return (
          <Dropdown overlay={getMenu(item)}>
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  return (
    <div className={styles.wrapper}>
      <AddEditLesson
        isVisible={modalControls.isVisible}
        isLoading={modalControls.isLoading}
        onClose={handleModalClose}
        onCloseIconClick={handleModalClose}
        addLesson={handleLessonAdd}
        editLesson={handleLessonEdit}
        modalToShow={modalControls.type}
        data={modalControls.data}
      />
      <ConfirmModal
        header={`Delete ${modalControls?.data?.name}?`}
        message={intl.formatMessage({
          id: "app.message.deleteWarning",
        })}
        onCloseIconClick={handleModalClose}
        onAction={handleLessonDelete}
        isVisible={modalControls.isDeleteModalVisible}
        actionText="Delete"
        isLoading={modalControls.isLoading}
      />
      <Header title="Lessons" />

      <div className="custom-section">
        <div className="custom-menu">
          <header>
            <Label value="Select Course" />
            <Select
              style={{ width: 150, marginLeft: 20 }}
                placeholder="Select Course"
                onChange={handleCourseChange}
                value={course.value}
              >
                {/* {courses.data.filter((item) => item.section !== "save").map((course) => ( */}
                  {courses.data.map((course) => (
                  <Option key={course.courseId} value={course.courseId}>
                    {course.section}
                  </Option>
                ))}
              </Select>
          </header>
          <section>
            <DelayedInput
              onChange={handleSearchChange}
              value={search}
              callback={handleSearch}
              delay={500}
              minLength={3}
              size="medium"
              prefixIcon={<SearchOutlined />}
              placeholder={intl.formatMessage({ id: "app.placeholder.search" })}
            />
            <Button
              onClick={openAddLessonModal}
              shape=""
              size="medium"
              disabled={props.isAddDisabled}
              type="primary"
            >
                Add Lesson
            </Button>
          </section>
        </div>
        <CustomTable
          isLoading={lessons.isLoading}
          dataSource={lessons.data}
          columns={columns}
          pagination={pagination}
          totalRecords={lessons.count}
          onChange={updatePagination}
        />
      </div>
    </div>
  );
}


const mapStateToProps = (state) => ({
  userInfo: state.user.userInfo,
  courseInfo: state.user.course,
  isRTL: checkRtl(state.user.locale),
});
export default connect(mapStateToProps)(Lessons);