import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Header, Icon, Placeholder } from "../../components/common";
import { YearlyChart, HourlyChart, OverviewCard } from "./components";
// import axios from "../../shared/api/axiosConfig";
import axios from "../../shared/api/branchConfig";
import { useIntl } from "react-intl";
import "./dashboard-container.scss";
import {
  removeEmptyKeys,
  numberWithCommas,
  abbreviateNumber,
  nestedParseJSON,
} from "../../shared/utils/object-manipulation";

import Validations from "../../shared/validations/Validations";
// import res from '../../shared/api/fake-db/dashboardChart.json';
const styles = {
  wrapper: "dashboard__wrapper",
  chartsContainer: "dashboard__charts-container",
  overviewContainer: "dashboard__overview-container",
  content: "dashboard__content",
  card: {
    countHeader: "dashboard__overview-card__count-header",
    header: "dashboard__overview-card__header",
    headerItem: "dashboard__overview-card__icon",
    footer: "dashboard__overview-card__footer",
    wrapper: "dashboard__overview-card__wrapper",
    content: "dashboard__overview-card__content",
  },
};
function Dashboard(props) {
  const intl = useIntl();

  const [dashboardData, updateDashboardData] = useState({
    isLoading: true,
    androidSubscribers: "~",
    iosSubscribers: "~",
    totalSubscribers: "~",
    totalPicker: "~",
    totalRevenue: "~",
    activeSubscriptions: "~",
    subscriptionsToday: "~",
    subscribersLast7days: "~",
    certificatesMonthly: "~",
    certificatesWeekly: "~",
    totalStores: "~",
    todayOrders: "~",
    overAllTotalOrders: "~",
    overAllFailedOrders: "~",
    onlineSubscribers: "~",
    todayCompletedOrders: "~",
    weeklySubscribers: [],
    hourlySubscribers: [],
  });

  const formatNullOrEmpty = (field) => {
    if (field === null || field === undefined) {
      return 0;
    } else return field;
  };

  const getDashboardData = () => {
    updateDashboardData({
      ...dashboardData,
      isLoading: true,
    });
    axios
      .get("/dashboard", {
        params: removeEmptyKeys({
          // countryId: props.selectedCountry,
          // cityIds: props.selectedCities,
        }),
      })
      .then((res) => {
        updateDashboardData({
          ...res.data.data,
          isLoading: false,
        });
      })
      .catch((err) => {
        updateDashboardData({
          ...dashboardData,
          isLoading: false,
        });
      });
  };

  useEffect(() => {
    // Validations.validateStartEndDate("2020-06-18T18:36:23.177Z", "2020-06-18T18:36:23.179Z")
    if (true) {
      getDashboardData();
      // const interval = setInterval(getDashboardData, 30000);
      // return () => {
      //   clearInterval(interval);
      // };
    }
  }, []);

  if (dashboardData.isLoading) {
    return <Placeholder full type="loading" />;
  }
  return (
    <div className={styles.wrapper}>
      {/* <Header
        rawTitle={`${props.enterprice.name || "Your"} Dashboard`}
        subTitle={`Welcome to ${props.enterprice.name || "your Dashboard"}`}
      /> */}
      <div className={styles.content}>
        <div className={styles.overviewContainer}>
          {true && (
            <div className={styles.card.wrapper}>
              {/* <div className={styles.card.headerItem}>
                <Icon name="USERS" size="medium" />
              </div> */}
              <div
                style={{ paddingLeft: "1rem" }}
                dir={props.isRTL ? "rtl" : "ltr"}
                className={styles.card.content}
              >
                <div className={styles.card.header}>
                  {" "}
                  Total Users Registered
                  {/* {intl.formatMessage({
                    id: "app.field.totalUsersRegistered",
                  })} */}
                </div>
                <div id="oof" className={styles.card.footer}>
                  <span title={dashboardData.totalSubscribers}>{`${abbreviateNumber(
                    // dashboardData.totalSubscribers
                    dashboardData.androidSubscribers+dashboardData.iosSubscribers
                  )}`}</span>
                  <div className="offset">
                    <section title={dashboardData.androidSubtotalSubscribers}>
                      <Icon name="ANDROID" size="small" />
                      {`${abbreviateNumber(dashboardData.androidSubscribers)}`}
                    </section>
                    <section title={dashboardData.iosSubscribers}>
                      <Icon name="IOS" size="small" />
                      {`${abbreviateNumber(dashboardData.iosSubscribers)}`}
                    </section>
                    {/* <section title={           dashboardData.totalCustomer -
                          dashboardData.androidCustomers -
                          dashboardData.iosCustomers}>
                      <Icon name="WEB" size="small" />
                      {`${abbreviateNumber(
                        dashboardData.totalCustomer -
                          dashboardData.androidCustomers -
                          dashboardData.iosCustomers
                      )}`}
                    </section> */}
                  </div>
                </div>
              </div>
            </div>
          )}
          {true && (
            <div className={styles.card.wrapper}>
              <div className={styles.card.headerItem}>
                <Icon name="USER" size="medium" />
              </div>
              <div className={styles.card.content}>
                <div className={styles.card.header}>
                  Total Subscribers
                </div>
                <div className={styles.card.footer}>
                  {formatNullOrEmpty(dashboardData.totalSubscribers)}
                </div>
              </div>
            </div>
          )}
          {true && (
            <div className={styles.card.wrapper}>
              <div className={styles.card.headerItem}>
                <Icon name="CERTIFICATE" size="medium" />
              </div>
              <div className={styles.card.content}>
                <div className={styles.card.header}>
                  Total Certifications in this month
                  {/* {intl.formatMessage({
                    id: "app.field.totalPickersRegistered",
                  })} */}
                </div>
                <div className={styles.card.footer}>
                  {formatNullOrEmpty(dashboardData.certificatesMonthly)}
                </div>
              </div>
            </div>
          )}
          {false && (
            <div className={styles.card.wrapper}>
              <div className={styles.card.headerItem}>
                <Icon name="MONEY" size="medium" />
              </div>
              <div className={styles.card.content}>
                <div className={styles.card.header}>
                  Total Revenue Generated
                </div>
                <div className={styles.card.footer}>
                  {/* {"$ 10,000"} */}
                  $ {dashboardData.totalRevenue || "~"} 
                </div>
              </div>
            </div>
          )}
          {/* {props.panelType == 0 && (
            <div className={styles.card.wrapper}>
              <div className={styles.card.headerItem}>
                <Icon name="STORE" size="medium" />
              </div>
              <div className={styles.card.content}>
                <div className={styles.card.header}>
                  Total Certifications
                </div>
                <div className={styles.card.footer}>
                  {formatNullOrEmpty(dashboardData.totalStores)}
                </div>
              </div>
            </div>
          )}
          <div className={styles.card.wrapper}>
            <div className={styles.card.headerItem}>
              <Icon name="ALL_ORDERS" size="medium" />
            </div>
            <div className={styles.card.content}>
              <div className={styles.card.header}>
                {intl.formatMessage({ id: "app.field.todayTotalOrders" })}
              </div>
              <div className={styles.card.footer}>
                {formatNullOrEmpty(dashboardData.todayOrders)}
              </div>
            </div>
          </div>
          <div className={styles.card.wrapper}>
            <div className={styles.card.headerItem}>
              <Icon name="ACCEPTED_ORDERS" size="medium" />
            </div>
            <div className={styles.card.content}>
              <div className={styles.card.header}>
                {intl.formatMessage({ id: "app.field.todayCompletedOrders" })}
              </div>
              <div className={styles.card.footer}>
                {formatNullOrEmpty(dashboardData.todayCompletedOrders)}
              </div>
            </div>
          </div>
          <div className={styles.card.wrapper}>
            <div className={styles.card.headerItem}>
              <Icon name="CANCELLED_ORDERS" size="medium" />
            </div>
            <div className={styles.card.content}>
              <div className={styles.card.header}>
                {intl.formatMessage({ id: "app.field.overAllCancelledOrders" })}
              </div>
              <div className={styles.card.footer}>
                {formatNullOrEmpty(dashboardData.overAllFailedOrders)}
              </div>
            </div>
          </div> */}
          {/* {props.panelType == 0 && (
            <div className={styles.card.wrapper}>
              <div className={styles.card.headerItem}>
                <Icon name="USERS" size="medium" />
              </div>
              <div className={styles.card.content}>
                <div className={styles.card.header}>
                  {intl.formatMessage({ id: "app.field.totalOnlineCustomers" })}
                </div>
                <div className={styles.card.footer}>
                  {formatNullOrEmpty(dashboardData.onlineCustomers)}
                </div>
              </div>
            </div>
          )} */}
        </div>
        <div className={styles.chartsContainer}>
          <YearlyChart
            name={props.enterprice.name}
            color={props.enterprice.color}
            yearData={dashboardData.weeklySubscribers}
            isLoading={dashboardData.isLoading}
          />
          <HourlyChart
            name={props.enterprice.name}
            color={props.enterprice.color}
            colorLight={props.enterprice.colorLight}
            hourData={dashboardData.hourlySubscribers}
            isLoading={dashboardData.isLoading}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user.userInfo,
  enterprice: state.user.enterprice,
  panelType: state.user.enterprice.panelType,
  pickerFlow: state.user.enterprice.pickerFlow,
  color: state.user.enterprice.color,
});
export default connect(mapStateToProps)(Dashboard);
