import React, { useState } from "react";
import { Popconfirm, Tooltip, Switch } from "antd";
import { useIntl } from "react-intl";
import classNames from "classnames";
import moment from "moment";

import { Panel, Icon } from "../../../../components/common";
import "./edit-order-table.scss";
import { numberWithCommas } from "../../../../shared/utils/object-manipulation";

const styles = {
  table: "custom-table",
  tableActions: "custom-table__table-actions",
  actionIcon: "custom-table__action-icon",
  panelContainer: "edit-order-table__panel-container",
};

function EditOrderTable(props) {
  const intl = useIntl();
  return (
    <Panel
      isLoading={props.isLoading}
      isEmpty={props.isEmpty}
      emptyText="No Data"
      containerClass={styles.panelContainer}
    >
      <table className={styles.table}>
        <tbody>
          <tr>
            <th>No.</th>
            <th>{intl.formatMessage({ id: "app.field.productImage" })}</th>
            <th>{intl.formatMessage({ id: "app.field.name" })}</th>
            <th>{intl.formatMessage({ id: "app.field.quantity" })}</th>
            <th>{intl.formatMessage({ id: "app.field.actualPrice" })}</th>
            <th>{intl.formatMessage({ id: "app.field.action" })}</th>
          </tr>
          {props.data.map((item, index) => (
            <tr style={item.isOutOfStock ? {outlineColor: 'red'} : null} key={item.bookedProductId}>
              <td>{index + 1}</td>
              <td>
                <img src={item.image} width="50" height="50" alt="item" />
              </td>
              <td>{item.name}</td>
              <td>{item.quantityBooked}</td>
              <td>{numberWithCommas(item.actualPrice)}</td>
              <td>
                <div className={styles.tableActions}>
                  <Tooltip title="Edit" placement="top">
                    <span
                      onClick={() => {
                        props.editItem(item);
                      }}
                      className={styles.actionIcon}
                    >
                      <Icon name="PEN" size="small" />
                    </span>
                  </Tooltip>
                  <Tooltip title="Delete" placement="top">
                    <Popconfirm
                      title={intl.formatMessage({
                        id: "app.message.deleteWarning",
                      })}
                      onConfirm={() => {
                        props.removeItem(item);
                      }}
                      placement="topRight"
                      okText="Yes"
                      cancelText="No"
                    >
                      <span className={styles.actionIcon}>
                        <Icon name="TRASH" size="small" />
                      </span>
                    </Popconfirm>
                  </Tooltip>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Panel>
  );
}

export default EditOrderTable;
