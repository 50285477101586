import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import Pagination from "react-js-pagination";

import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Tag } from "antd";
import {
  CaretDownOutlined,
  MoreOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import classNames from "classnames";
import {
  Header,
  Panel,
  PaginationInfo,
  Icon,
  DelayedInput,
  CustomTable,
  CustomTag,
  ConfirmModal,
} from "../../components/common";
import { RECORDS_PER_PAGE } from "../../shared/constants/dropdown";
import { removeEmptyKeys } from "../../shared/utils/object-manipulation";
import axios from "../../shared/api/axiosConfig";
import { Button, Select, Tooltip, Popconfirm, Dropdown, Menu } from "antd";
import AddEditUser from "./add-edit-user/add-edit-user";
import ViewUser from "./view-user/view-user";
import "./users.scss";
import { toastr } from "react-redux-toastr";
import { checkRtl } from "../../shared/utils/rtl";

const styles = {
  wrapper: "role-management__users__wrapper",
  menuContainer: "custom-menu",
  content: "custom-section",
  leftMenuItem: "role-management__users__left-menu-item",
  rightMenuItem: "role-management__users__right-menu-item",
  tableActions: "role-management__users__table-actions",
  actionIcon: "role-management__users__action-icon",
  panelContainer: "custom-table-panel-container",
  actionIconHidden: "role-management__users__action-icon--hidden",
  table: "custom-table",
};

const { Option } = Select;

const Users = (props) => {
  const intl = useIntl();

  const [search, updateSearch] = useState("");
  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    isViewModalVisible: false,
    isDeleteModalVisible: false,
    data: {},
    type: "",
  });

  const [pagination, updatePagination] = useState({
    recordsPerPage: 10,
    skip: 0,
    currentPage: 1,
    search: "",
    delayedCurrentPage: 1,
    delayedRecordsPerPage: 10,
  });

  const [tableControls, updateTableControls] = useState({
    isLoading: true,
    isEmpty: false,
    data: [],
    count: 0,
  });

  const closeModal = () => {
    updateModalControls({
      isVisible: false,
      isViewModalVisible: false,
      isDeleteModalVisible: false,
      data: {},
    });
  };
  // MountConfirmModal
  const handlePaginationChange = (page) => {
    updatePagination({
      ...pagination,
      currentPage: page,
      skip: (page - 1) * pagination.recordsPerPage,
    });
  };

  const handleRecordsPerPageChange = (val) => {
    updatePagination({
      ...pagination,
      recordsPerPage: val,
    });
  };

  const handleSearchChange = (val) => {
    updateSearch(val);
  };

  const handleSearch = (val) => {
    updatePagination({
      ...pagination,
      currentPage: 1,
      search: val,
      skip: 0,
    });
  };

  const removeItem = (item) => {
    const data = {
      // adminId: item.adminId,
      // masterAdminId: item.masterAdminId,
      // countryCode: item.countryCode,
      // isDeleted: 1,
    };
    axios
      .delete(`subadmins/delete/${item.adminId}`)
      .then((res) => {
        toastr.success(res.data.message);
        getPageData();
      })
      .catch((err) => {
        const message = err?.data?.message;
        toastr.error(
          "Error",
          message ? message : intl.formatMessage({ id: "app.message.error" })
        );
      });
  };

  const toggleBlockAdmin = (item) => {
    const data = {
      // adminId: item.adminId,
      // masterAdminId: item.masterAdminId,
      // countryCode: item.countryCode,
      isBlocked: !item.isBlocked,
    };
    axios
      .put("updateSubAdminDetails", data)
      .then((res) => {
        toastr.success(res.data.message);
        getPageData();
      })
      .catch((err) => {
        const message = err?.data?.message;
        toastr.error(
          "Error",
          message ? message : intl.formatMessage({ id: "app.message.error" })
        );
      });
  };

  const toggleAddModal = () => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      type: "ADD",
    });
  };

  const toggleEditModal = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      data: data,
      type: "EDIT",
    });
  };

  const toggleViewModal = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: false,
      isViewModalVisible: true,
      data: data,
    });
  };

  const toggleDeleteModal = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: false,
      isViewModalVisible: false,
      isDeleteModalVisible: true,
      data: data,
    });
  };
  const handleModalClose = () => {
    updateModalControls({
      ...modalControls,
      isVisible: false,
      isLoading: false,
      isDeleteModalVisible: false,
      isViewModalVisible: false,
    });
  };

  const addUser = (data) => {
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });
    axios
      .post("subadmins/create", data)
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isVisible: res.data.statusCode !== 200,
          isLoading: false,
        });
        getPageData();
        toastr.success(res.data.message);
      })
      .catch((err) => {
        const message = err?.data?.message;
        // toastr.error(
        //   "Error",
        //   message ? message : intl.formatMessage({ id: "app.message.error" })
        // );
        updateModalControls({
          ...modalControls,
          isLoading: false,
        });
      });
  };

  const editUser = (data) => {
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });

    data.adminId = modalControls.data.adminId;
    data.masterAdminId = modalControls.data.masterAdminId;
    if (modalControls.data.email === data.email) {
      delete data.email;
    }
    if (modalControls.data.phoneNo === data.phoneNo) {
      delete data.phoneNo;
    }
    axios
      .put(`subadmins/restore/${modalControls.data.adminId}`, data)
      .then((res) => {
        toastr.success(res.data.message);
        getPageData();
        handleModalClose();
      })
      .catch((err) => {
        const message = err?.data?.message;
        updateModalControls({
          ...modalControls,
          isLoading: false,
        });
        toastr.error(
          "Error",
          message ? message : intl.formatMessage({ id: "app.message.error" })
        );
      });
  };

  const getPageData = () => {
    updateTableControls({
      ...tableControls,
      isLoading: true,
    });
    const params = removeEmptyKeys({
      limit: pagination.recordsPerPage,
      skip: (pagination.currentPage - 1) * pagination.recordsPerPage,
      search: pagination.search,
    });
    axios
      .get("/subadmins", {
        params: params,
      })
      .then((res) => {
        if (res.data.data.length !== 0) {
          updateTableControls({
            ...tableControls,
            data: res.data.data.map((i) => {
              return i;
            }),
            isLoading: false,
            isEmpty: false,
            count: res.data.data.count,
          });
        } else {
          updateTableControls({
            ...tableControls,
            isLoading: false,
            data: [],
            isEmpty: true,
          });
        }
      })
      .catch((err) => {
        updateTableControls({
          ...tableControls,
          isLoading: false,
          data: [],
          isEmpty: true,
        });
      });
  };

  const getMenu = (item) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            toggleViewModal(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.view" })}
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            toggleEditModal(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.edit" })}
        </Menu.Item>
        {/* {item.isBlocked == 1 && (
          <Menu.Item
            onClick={() => {
              toggleBlockAdmin(item);
            }}
          >
            {intl.formatMessage({ id: "app.field.unblock" })}
          </Menu.Item>
        )}
        {item.isBlocked == 0 && (
          <Menu.Item
            onClick={() => {
              toggleBlockAdmin(item);
            }}
          >
            {intl.formatMessage({ id: "app.field.block" })}
          </Menu.Item>
        )} */}

        <Menu.Item
        >
          <Popconfirm
            title={intl.formatMessage({
              id: "app.message.deleteWarning",
            })}
            onConfirm={() => {
              removeItem(item);
            }}
            placement="topRight"
            okText={intl.formatMessage({ id: "app.field.yes" })}
            cancelText={intl.formatMessage({ id: "app.field.no" })}
          >
            {intl.formatMessage({ id: "app.field.delete" })}
          </Popconfirm>
        </Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      dataIndex: "",
      // sorter: true,
      title: intl.formatMessage({ id: "app.field.sNo" }),
      // fixed: 'left',
      render: (e, item, index) => {
        return (
          <span>
            {pagination.recordsPerPage * (pagination.currentPage - 1) +
              index +
              1}
          </span>
        );
      },
    },
    {
      dataIndex: "name",
      title: intl.formatMessage({ id: "app.field.name" }),
    },
    {
      dataIndex: "email",
      title: intl.formatMessage({ id: "app.field.email" }),
    },
    {
      dataIndex: "isSuperAdmin",
      // dataIndex: "userType",
      title: "User Type",
      render: (item) => {
        return <Tag style={{ margin: 0 }} color="green">
          {item === 1 ? "Super Admin" : "Sub Admin"}
        </Tag>
      }
      // render: (item) => {
      //     return <CustomTag isSuperAdmin={item.isSuperAdmin} />;
      // }
    },
    // {
    //   dataIndex: "phoneNo",
    //   title: intl.formatMessage({ id: "app.field.contact" }),
    // },
    // {
    //   dataIndex: "",
    //   title: intl.formatMessage({ id: "app.field.status" }),
    //   render: (item) => {
    //     return <CustomTag isBlocked={item.isBlocked} />;
    //   },
    // },
    {
      title: intl.formatMessage({ id: "app.field.action" }),
      dataIndex: "",
      render: (item) => {
        // debugger;
        return (
          <Dropdown overlay={getMenu(item)}>
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  useEffect(() => {
    getPageData();
    console.log("userINfo", props.userInfo);
  }, [
    pagination.search,
    pagination.recordsPerPage,
    pagination.currentPage,
  ]);

  return (
    <div className={styles.wrapper}>
      <AddEditUser
        isVisible={modalControls.isVisible}
        onClose={handleModalClose}
        onCloseIconClick={handleModalClose}
        modalToShow={modalControls.type}
        roles={props.userInfo.userRoles}
        isWalletVisible={props.userInfo.isWalletVisible}
        data={modalControls.data}
        addAdmin={addUser}
        editAdmin={editUser}
        masterAdminId={props.userInfo.masterAdminId}
        countries={props.userInfo.enabledCountries}
        selectedCountry={props.selectedCountry}
        citiesAssigned={props.citiesAssigned}
        isLoading={modalControls.isLoading}
      />
      <ViewUser
        isVisible={modalControls.isViewModalVisible}
        onCloseIconClick={handleModalClose}
        modalToShow={modalControls.type}
        countries={props.userInfo.enabledCountries}
        roles={props.userInfo.userRoles}
        data={modalControls.data}
      />
      <Header title="app.pageTitle.roleManagement" />
      <div className={styles.content}>
        <div className={styles.menuContainer}>
          <header></header>
          <section>
            <DelayedInput
              onChange={handleSearchChange}
              value={search}
              callback={handleSearch}
              delay={500}
              minLength={3}
              size="medium"
              prefixIcon={<SearchOutlined />}
              placeholder={intl.formatMessage({ id: "app.placeholder.search" })}
            />
            <Button
              disabled={props.isAddDisabled}
              onClick={toggleAddModal}
              type="primary"
              shape=""
            >
              Add Admin
            </Button>
          </section>
        </div>
        <CustomTable
          columns={columns}
          dataSource={tableControls.data}
          pagination={pagination}
          onChange={updatePagination}
          isLoading={tableControls.isLoading}
          totalRecords={tableControls.count}
          isEmpty={tableControls.isEmpty}
        />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  userInfo: state.user.userInfo,
  isAddDisabled: state.user.isAddDisabled,
  isRTL: checkRtl(state.user.locale),
});
export default connect(mapStateToProps)(Users);
