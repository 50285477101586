import React, { useState, useEffect, useRef } from "react";
import { Input, Button, Select, TimePicker, Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import moment from "moment";

import {
  CustomModal,
  Panel,
  ErrorMessage,
} from "../../../../components/common";
import Validations from "../../../../shared/validations/Validations";
import "./upload-image.scss";
import { DAYS } from "../../../../shared/constants/calendar";
import axiosClient from "../../../../shared/api/axiosConfig";
const { Option } = Select;

const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  formGroup: "custom-form-group",
};

const { Dragger } = Upload;

function UploadImage(props) {
  const intl = useIntl();
  const timeoutRef = useRef(null);
  const actionHandler = (options) => {
    // debugger;
    const data = new FormData();
    data.append("file", options.file);
    if(description !== undefined && description !== null && description !== '') {
        data.append("description", description);
    }
    data.append("branchId", props.branchId);

    return axiosClient
      .post("/saveImages", data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        options.onSuccess(res.data, options.file);
        console.log(options);
      })
      .catch((err) => {
        console.log(err);
        options.onError(options.file, err?.response);
      });
  };
  const [isPristine, togglePristine] = useState(true);
  const [description, updateDescription] = useState(undefined);
  const [payload, updatePayload] = useState([]);

  useEffect(() => {
    if (props.isVisible) {
    }
  }, [props.isVisible]);

  const closeModal = () => {
    props.onCloseIconClick();
  };

  const handleFieldChange = (e) => {
    updateDescription(e.target.value);
  };
  const formAction = () => {};

  const handleCallback = () => {
    toastr.success("Success", "Images Saved");
    props.callback();
  };

  const handleChange = (info) => {
    const { status } = info.file;
    // debugger;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      // console.log(info.file.response.data)
      updatePayload([...payload, info.file.response.data]);
      message.success(`${info.file.name} file uploaded successfully.`);
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        handleCallback();
      }, 2000);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={closeModal}
      header="app.pageTitle.uploadImage"
      isVisible={props.isVisible}
    >
      <Panel isLoading={props.isLoading} className={styles.formModal}>
        <div className={styles.formItem}>
          <label htmlFor="">Description</label>
          <Input
            onChange={handleFieldChange}
            placeholder="Image Description (optional)"
            value={description}
          />
        </div>
        <Dragger
          onPreview={() => {}}
          name="file"
          multiple={true}
          customRequest={actionHandler}
          showUploadList={{ showRemoveIcon: false, showPreviewIcon: false }}
          listType="picture"
          onChange={handleChange}
          accept="image/*"
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag image to this area to upload
          </p>
          <p className="ant-upload-hint">
            Bulk upload supported. Drag or select multiple images to bulk upload
          </p>
          <p className="ant-upload-hint">
            Note: Description will be appied per batch/image upload
          </p>
        </Dragger>
      </Panel>
    </CustomModal>
  );
}

export default UploadImage;
