import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Button, Input } from "antd";

import {
  Panel,
  CustomModal,
  ErrorMessage,
} from "../../../../components/common";
import Validations from "../../../../shared/validations/Validations";
import { toastr } from "react-redux-toastr";

const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
};
function CancellationReasonsModal(props) {
  const intl = useIntl();
  const closeModal = () => {
    props.onCloseIconClick();
  };

  const [reason, changeReason] = useState(Validations.validateTextField(""));
  const [isPristine, changeIsPristine] = useState(true);

  const handleReasonChange = (e) => {
    changeReason(Validations.validateTextField(e.target.value));
  };

  const handleSubmit = () => {
    changeIsPristine(false);
    if (reason.isValid) {
      if (props.modalType === "ADD") {
        let check = false;
        props.tableData.map((data,index) => {
          if(reason.value == data.cancellationReason){
            toastr.error('Error', `${intl.formatMessage({ id: "app.message.reasonExists" })}`);
            check = true;
          }
        })
        if(check == false){
          props.addReason(reason.value);
        }
      }

      if (props.modalType === "EDIT") {
        let check = false;
        props.tableData.map((data,index) => {
          if(reason.value == data.cancellationReason){
            toastr.error('Error', `${intl.formatMessage({ id: "app.message.reasonExists" })}`);
            check = true;
          }
        })
        if(check == false){
          props.editReason(reason.value)
        }
      }
    }
  };

  const resetForm = () => {
    changeReason(Validations.validateTextField(""));
  };

  const setForm = () => {
    changeReason(Validations.validateTextField(props.data.cancellationReason));
  };

  useEffect(() => {
    if (props.isVisible) {
      changeIsPristine(true);

      if (props.modalType === "EDIT") {
        setForm();
      } else {
        resetForm();
      }
    }
  }, [props.isVisible]);

  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={closeModal}
      header={
        props.modalType === "ADD"
          ? "app.pageTitle.addCancellationReason"
          : "app.pageTitle.editCancellationReason"
      }
      isVisible={props.isVisible}
    >
      <Panel isLoading={props.isLoading} className={styles.formModal}>
        <div className={styles.formItem}>
          <label>
            {intl.formatMessage({ id: "app.field.cancellationReason" })}
          </label>
          <Input
            value={reason.value}
            onChange={handleReasonChange}
            placeholder={intl.formatMessage({
              id: "app.placeholder.cancellationReason",
            })}
          />
          {!isPristine && <ErrorMessage field={reason} />}
        </div>
        <div className={styles.formAction}>
          <Button onClick={props.onCloseIconClick} shape="">
          {intl.formatMessage({ id: "app.button.cancel" })}
          </Button>
          <Button onClick={handleSubmit} type="primary" shape="">
          {intl.formatMessage({ id: "app.button.submit" })}
          </Button>
        </div>
      </Panel>
    </CustomModal>
  );
}

export default CancellationReasonsModal;
