import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Button, Select } from "antd";

import {
  Panel,
  CustomModal,
  ErrorMessage,
  Label
} from "../../../../components/common";
import Validations from "../../../../shared/validations/Validations";

import { DAYS } from "../../../../shared/constants/calendar";
import "./copy-slots-modal.scss";
const { Option } = Select;

const styles = {
  formGroup: "custom-form-group",
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  spanImp: "span-imp",
};
const errText = "Field cannot be empty";
const deliveryModeData = [
  { value: 0, text: "Bike" },
  { value: 1, text: "Truck" },
];

function CopySlotsModal(props) {
  const intl = useIntl();
  const closeModal = () => {
    props.onCloseIconClick();
  };

  const [sourceDay, changeSourceDay] = useState(
    Validations.validateTextField(undefined)
  );
  const [destinationDay, changeDestinationDay] = useState(
    Validations.validateTextField(undefined)
  );
  const [deliveryMode, changeDeliveryMode] = useState(undefined);
  const [isPristine, changeIsPristine] = useState(true);

  const handleSourceChange = (day) => {
    const days = Validations.validateSourceDestDay(day, destinationDay.value);
    console.log("days", days);
    changeSourceDay(days.sourceDay);
    changeDestinationDay(days.destDay);
  };

  const handleDestChange = (day) => {
    const days = Validations.validateSourceDestDay(sourceDay.value, day);
    changeSourceDay(days.sourceDay);
    changeDestinationDay(days.destDay);
  };

  const handleSubmit = () => {
    changeIsPristine(false);
    console.log("in valid");
    if (!isFormValid()) {
      return;
    }
    console.log("valid");
    props.copySlot(getFormData());
  };

  const isFormValid = () => {
    return (
      sourceDay.isValid && destinationDay.isValid && deliveryMode !== undefined
    );
  };

  const getFormData = () => ({
    sourceDay: sourceDay.value,
    destDay: destinationDay.value,
    vehicleType: deliveryMode * 1,
  });

  const setForm = () => {
    // const days = Validations.validateSourceDestDay(props.day, props.day + 1);
    // changeSourceDay(days.sourceDay);
    // changeDestinationDay(days.destDay);
    // changeSourceDay(props.day);
    // changeDeliveryMode(undefined);
    // changeDestinationDay(props.day + 1);
  };

  useEffect(() => {
    if (props.isVisible) {
      changeIsPristine(true);

      if (props.modalType === "COPY") {
        // setForm();
      }
    }
  }, [props.isVisible]);

  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={closeModal}
      header="app.pageTitle.copySlot"
      isVisible={props.isVisible}
      action={<>
          <Button onClick={props.onCloseIconClick} shape={undefined}>
            {intl.formatMessage({ id: "app.button.cancel" })}
          </Button>
          <Button onClick={handleSubmit} loading={props.isLoading} type="primary" shape={undefined}>
            {intl.formatMessage({ id: "app.button.submit" })}
          </Button>
      </>}
    >
      <Panel isModal isLoading={props.isLoading} className={styles.formModal}>
        <div className={styles.formGroup}>
          <div className={styles.formItem}>
          <Label value="app.field.sourceDeliveryMode">
              </Label>

            <Select
              placeholder={intl.formatMessage({
                id: "app.placeholder.deliveryMode",
              })}
              value={deliveryMode}
              onChange={changeDeliveryMode}
            >
              {deliveryModeData.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.text}
                </Option>
              ))}
            </Select>
            {!isPristine && deliveryMode === undefined && (
              <ErrorMessage text={errText} />
            )}
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.formItem}>
          <Label value="app.field.sourceDay">
              </Label>
            <Select
              placeholder={intl.formatMessage({
                id: "app.placeholder.day",
              })}
              onChange={handleSourceChange}
              value={sourceDay.value}
            >
              {DAYS.map((item) => (
                <Option key={item.value} value={item.value}>
                  {intl.formatMessage({ id: item.text })}
                </Option>
              ))}
            </Select>
            {!isPristine && <ErrorMessage field={sourceDay} />}
          </div>
          <div className={styles.formItem}>
          <Label value="app.field.destinationDay">
              </Label>
            <Select
              placeholder={intl.formatMessage({
                id: "app.placeholder.day",
              })}
              onChange={handleDestChange}
              value={destinationDay.value}
            >
              {DAYS.filter((day) => day.value !== sourceDay.value).map(
                (item) => (
                  <Option key={item.value} value={item.value}>
                    {intl.formatMessage({ id: item.text })}
                  </Option>
                )
              )}
            </Select>
            {!isPristine && <ErrorMessage field={destinationDay} />}
          </div>
        </div>
      </Panel>
    </CustomModal>
  );
}

export default CopySlotsModal;
