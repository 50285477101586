import React, { useState } from "react";
import { Popconfirm, Tooltip, Menu, Dropdown } from "antd";
import {MoreOutlined} from '@ant-design/icons';
import { useIntl } from "react-intl";

import { Panel, Icon, CustomTable, CustomTag, Label } from "../../../../components/common";
import "./cancellation-reasons-table.scss";

const styles = {
  table: "custom-table",
  tableActions: "custom-table__table-actions",
  actionIcon: "custom-table__action-icon",
  panelContainer: "cancellation-reasons-table__panel-container",
};

function CancellationReasonsTable(props) {
  const intl = useIntl();


  const getMenu = (item) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            props.handleEdit(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.edit" })}
        </Menu.Item>
        <Menu.Item
          // onClick={() => {
          //   props.removeItem(item);
          // }}
        >
        <Popconfirm
          title={intl.formatMessage({
            id: "app.message.deleteWarning",
          })}
          onConfirm={() => {
            props.removeItem(item);
          }}
          placement="topRight"
          okText={intl.formatMessage({ id: "app.field.yes" })}
          cancelText={intl.formatMessage({ id: "app.field.no" })}
        >
          {intl.formatMessage({ id: "app.field.delete" })}
        </Popconfirm>
        </Menu.Item>
      </Menu>
    );
  };
  const columns = [
    {
      dataIndex: "",
      title: intl.formatMessage({ id: "app.field.id" }),
      render: (item,object,index) => {
        return (<span>{props.pagination.pageSize * (props.pagination.current - 1) + index + 1}</span>)
      }
    },
    {
      dataIndex: "cancellationReason",
      title: intl.formatMessage({ id: "app.field.cancellationReason" }),
      render: (item) => {
        return item || "---"
      },
    },
    {
      title: intl.formatMessage({ id: "app.field.action" }),
      dataIndex: "",
      fixed: "right",
      render: (item) => {
        return (
          <Dropdown overlay={getMenu(item)}>
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];


  return (
    <CustomTable dataSource={props.data} pagination={false} columns={columns} isLoading={props.isLoading} />
  );
}

export default CancellationReasonsTable;
