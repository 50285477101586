import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useIntl, FormattedMessage, injectIntl } from "react-intl";
import {
  Select,
  Dropdown,
  Button,
  Menu,
  Popconfirm,
  Tooltip,
  Switch,
} from "antd";
import classNames from "classnames";
import {
  CaretDownOutlined,
  SearchOutlined,
  MoreOutlined,
} from "@ant-design/icons";

import { textTrimmer, TABLE_TEXT_LIMIT } from "../../shared/constants/dropdown";
import {
  Header,
  Panel,
  Icon,
  PaginationInfo,
  ConfirmModal,
  DelayedInput,
  CustomTable,
  CustomTag,
  CustomImage,
  Label,
} from "../../components/common";
import placeholderImg from "../../assets/images/picker-placeholder.png";
import { toastr } from "react-redux-toastr";
import { removeEmptyKeys } from "../../shared/utils/object-manipulation";
import axios from "../../shared/api/axiosConfig";
import AddEditPicker from "./add-edit-picker/add-edit-picker";
import "./pickers.scss";
import { checkRtl } from "../../shared/utils/rtl";
import { useBranch } from "../../hooks";

const TRANSPORT_TYPES = [
  { type: 0, name: "Bike" },
  { type: 1, name: "Car" },
];

const styles = {
  wrapper: "pickers__wrapper",
  stickyRight: "pickers__sticky-right",
  stickyAction: "sticky-action",
  upperMenu: "pickers__upper-menu",
  upperMenuLeft: "pickers__upper-menu__left",
  upperMenuRight: "pickers__upper-menu__right",
  upperMenuDropdown: "pickers__upper-menu__dropdown",
  pageSize: "products__upper-menu__records-per-page",
  image: "pickers__image",
  tableActions: "pickers__table-actions",
  actionIcon: "pickers__action-icon",
  actionIconHidden: "pickers__action-icon--hidden",
  table: "custom-table",
  content: "custom-section",
  menu: "custom-menu horizontal",
  tableContainer: "pickers__table-container",
};

const { Option } = Select;

function Pickers(props) {
  const intl = useIntl();
  const [pickers, updatePickers] = useState({
    selected: undefined,
    data: [],
    notFound: false,
    isLoading: true,
    count: null,
  });
  const [search, updateSearch] = useState("");

  const handleSearchChange = (val) => {
    updateSearch(val);
  };

  const handleSearch = (val) => {
    updatePagination({
      ...pagination,
      currentPage: 1,
      search: val,
      skip: 0,
    });
  };


  const emptyStoreCallback = () => {
    updatePickers({
      ...pickers,
      data: [],
    });
    updateTableControls({
      isTableEmpty: true,
      isTableLoading: false,
    });
  };

  const {stores, updateStores} = useBranch(emptyStoreCallback)

  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    isLoading: false,
    isDisableModalVisible: false,
    data: {},
    type: "",
  });

  const [pagination, updatePagination] = useState({
    recordsPerPage: 10,
    skip: 0,
    currentPage: 1,
    search: "",
  });

  const [tableControls, updateTableControls] = useState({
    isTableLoading: true,
    isTableEmpty: false,
  });

  const openAddPickerModal = () => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      type: "ADD_PICKER",
    });
  };

  const openEditPickerModal = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      data,
      type: "EDIT_PICKER",
    });
  };

  const openTogglePickerModal = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: false,
      isDisableModalVisible: true,
      data,
      type: "",
    });
  };

  const handleModalClose = () => {
    updateModalControls({
      isVisible: false,
      isDisableModalVisible: false,
      data: {},
    });
  };

  const handlePaginationChange = (page) => {
    updatePagination({
      ...pagination,
      currentPage: page,
      skip: (page - 1) * pagination.recordsPerPage,
    });
  };

  const handleRecordsPerPageChange = (val) => {
    updatePagination({
      ...pagination,
      recordsPerPage: val,
    });
  };

  const handleStoreChange = (selected) => {
    updateStores(selected);
    updateTableControls({
      ...tableControls,
      isTableLoading: true,
      isTableEmpty: false,
    });
    updatePagination({
      ...pagination,
      currentPage: 1,
      skip: 0,
    });
  };

  const handleImageError = (e) => {
    e.target.src = placeholderImg;
  };

  const getPickersList = () => {
    updateTableControls({
      isTableEmpty: false,
      isTableLoading: true,
    });
    axios
      .get(`/listPickers`, {
        params: removeEmptyKeys({
          limit: pagination.recordsPerPage,
          skip: (pagination.currentPage - 1) * pagination.recordsPerPage,
          branchId: stores.selected,
          search: pagination.search,
        }),
      })
      .then((res) => {
        if (res.data.data.count) {
          updatePickers({
            ...pickers,
            selected: res.data.data.pickers[0].pickerId,
            data: res.data.data.pickers,
            isLoading: false,
            notFound: false,
            count: res.data.data.count,
          });

          updateTableControls({
            isTableEmpty: false,
            isTableLoading: false,
          });
        } else {
          toastr.error("Error", `${intl.formatMessage({ id: "app.message.noPickersFound" })}`);
          updateTableControls({
            isTableEmpty: true,
            isTableLoading: false,
          });
          updatePickers({
            ...pickers,
            selected: "",
            data: [],
            isLoading: false,
            notFound: true,
            count: 0,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  // mount and page change
  useEffect(() => {
    if(stores.selected){
      getPickersList();
    }
  }, [pagination.recordsPerPage, pagination.currentPage, stores.selected, pagination.search]);

  const handlePickerAdd = (data) => {
    console.log("add called");
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });

    axios
      .post("/registerPicker", removeEmptyKeys(data))
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isVisible: false,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getPickersList(stores.selected);
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateModalControls({
          ...modalControls,
          isLoading: false,
        });
      });
  };

  const handlePickerEdit = (data) => {
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });

    data.pickerId = modalControls.data.pickerId;
    axios
      .put("/updatePicker", removeEmptyKeys(data))
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isVisible: res.data.statusCode !== 200,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getPickersList(stores.selected);
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateModalControls({
          ...modalControls,
          isLoading: false,
        });
      });
  };

  const deletePicker = (item) => {
    const data = {
      pickerId: item.pickerId,
      // email: item.email,
      // countryCode: item.countryCode,
      isDelete: 1,
    };
    axios
      .put("/updatePicker", removeEmptyKeys(data))
      .then((res) => {
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getPickersList(stores.selected);
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
      });
  };

  const getTransportType = (type) => {
    if(type === null || type === undefined || type > 1) {
      return 'Invalid';
    }
    return TRANSPORT_TYPES[type].name;
  }

  const handlePickerToggle = (item) => {
    const data = {
      pickerId: item.pickerId,
      // email: modalControls.data.email,
      // countryCode: modalControls.data.countryCode,
      isEnable: item.isEnable ? 0 : 1,
    };
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });

    axios
      .put("/updatePicker", removeEmptyKeys(data))
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isDisableModalVisible: res.data.statusCode !== 200,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getPickersList(stores.selected);
        } else {
          toastr.error("Error", res.data.message);
          updateModalControls({
            ...modalControls,
            isDisableModalVisible: false,
          });
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateModalControls({
          ...modalControls,
          isDisableModalVisible: false,
        });
      });
  };


  const columns = [
    {
      fixed: "left",
      dataIndex: "pickerId",
      title: intl.formatMessage({ id: "app.field.id" }),
    },
    {
      fixed: "left",
      dataIndex: "name",
      title: intl.formatMessage({ id: "app.field.name" }),
    },

    {
      dataIndex: "profileImage",
      title: intl.formatMessage({ id: "app.field.image" }),
      render: (item) => <CustomImage src={item} isTable />,
    },
    {
      dataIndex: "phoneNo",
      title: intl.formatMessage({ id: "app.field.contact" }),
    },

    {
      dataIndex: "email",
      title: intl.formatMessage({ id: "app.field.email" }),
    },
    {
      dataIndex: "address",
      title: intl.formatMessage({ id: "app.field.address" }),
      render: (i) =>
        i.length > TABLE_TEXT_LIMIT.ADDRESS ? (
          <Tooltip title={i}>
            <span style={{ cursor: "pointer" }}>
              {textTrimmer(i, TABLE_TEXT_LIMIT.ADDRESS)}
            </span>
          </Tooltip>
        ) : (
          i
        ),
    },

    {
      dataIndex: "transportType",
      title: intl.formatMessage({ id: "app.field.transportType" }),
      render: (item) => getTransportType(item) || "---",
    },
    {
      dataIndex: "isEnable",
      title: intl.formatMessage({ id: "app.field.status" }),
      render: (i) => {
        return <CustomTag isBlocked={!i} />;
      },
      filterOut: props.panelType != 0,
    },
    {
      title: intl.formatMessage({ id: "app.field.action" }),
      fixed: "right",
      render: (item) => {
        return (
          <Dropdown overlay={getMenu(item)}>
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  const getMenu = (item) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            openEditPickerModal(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.edit" })}
        </Menu.Item>
        {item.isEnable == 1 && (
          <Menu.Item
            onClick={() => {
              handlePickerToggle(item);
            }}
          >
            {intl.formatMessage({ id: "app.field.block" })}
          </Menu.Item>
        )}
        {item.isEnable == 0 && (
          <Menu.Item
            onClick={() => {
              handlePickerToggle(item);
            }}
          >
            {intl.formatMessage({ id: "app.field.unblock" })}
          </Menu.Item>
        )}
        <Menu.Item
          // onClick={() => {
          //   deletePicker(item);
          // }}
        >
        <Popconfirm
          title={intl.formatMessage({
            id: "app.message.deleteWarning",
          })}
          onConfirm={() => {
            deletePicker(item);
          }}
          placement="topRight"
          okText={intl.formatMessage({ id: "app.field.yes" })}
          cancelText={intl.formatMessage({ id: "app.field.no" })}
        >
          {intl.formatMessage({ id: "app.field.delete" })}
        </Popconfirm>
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <div className={styles.wrapper}>
      <AddEditPicker
        isVisible={modalControls.isVisible}
        onClose={handleModalClose}
        onCloseIconClick={handleModalClose}
        addPicker={handlePickerAdd}
        stores={stores.data}
        storesVisible={stores.isVisible}
        editPicker={handlePickerEdit}
        modalToShow={modalControls.type}
        data={modalControls.data}
        isLoading={modalControls.isLoading}
      />
      <ConfirmModal
        header={
          modalControls.data.isEnable
            ? `${intl.formatMessage({ id: "app.field.block" })} ${modalControls?.data?.name}?`
            : `${intl.formatMessage({ id: "app.field.unblock" })} ${modalControls?.data?.name}?`
        }
        message={
          modalControls.data.isEnable
            ? intl.formatMessage({
                id: "app.message.blockWarning",
              })
            : intl.formatMessage({
                id: "app.message.unblockWarning",
              })
        }
        onCloseIconClick={handleModalClose}
        onAction={handlePickerToggle}
        isVisible={modalControls.isDisableModalVisible}
        actionText={modalControls.data.isEnable ? `${intl.formatMessage({ id: "app.field.block" })}` : `${intl.formatMessage({ id: "app.field.unblock" })}`}
        isLoading={modalControls.isLoading}
      />
      <Header title="app.pageTitle.pickers" />
      <div className={styles.content}>
        <div className={styles.menu}>
          <header>
            {props.panelType == 0 && (
              <div className={styles.upperMenuDropdown}>
            <Label value="app.field.filterBy" horizontal />
                
                <Select
                  showSearch
                  placeholder={intl.formatMessage({
                    id: "app.placeholder.store",
                  })}
                  onChange={handleStoreChange}
                  value={stores.selected}
                  style={{ width: "180px" }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {stores.data.map((store) => (
                    <Option key={store.branchId} value={store.branchId}>
                      {store.branchName}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
          </header>
          <section>
            <div>
              <DelayedInput
                onChange={handleSearchChange}
                value={search}
                width={180}
                callback={handleSearch}
                delay={500}
                minLength={3}
                size="medium"
                prefixIcon={<SearchOutlined />}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.search",
                })}
              />
            </div>

            <Button
              onClick={openAddPickerModal}
              shape={undefined}
              size="medium"
              type="primary"
              disabled={props.isAddDisabled}
            >
              {intl.formatMessage({
                id: "app.button.addPicker",
              })}
            </Button>
          </section>
        </div>
        <CustomTable
          columns={columns}
          isLoading={stores.isLoading || tableControls.isTableLoading}
          dataSource={pickers.data}
          pagination={pagination}
          totalRecords={pickers.count}
          onChange={updatePagination}
        />
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  selectedCountry: state.user.selectedCountry,
  selectedCities: state.user.selectedCities,
  storeId: state.user.enterprice.storeId,
  branchId: state.user.userInfo.branchId,
  panelType: state.user.enterprice.panelType,
  isAddDisabled: state.user.isAddDisabled,
  isRTL: checkRtl(state.user.locale),
});
export default connect(mapStateToProps)(Pickers);