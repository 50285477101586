import React from "react";
import {Modal} from 'antd';
function SessionExpired(props) {
    // return null;
    return (
        <Modal
        title="Session Expired"
        style={{ top: 20 }}
        closable={false}
        visible={props.isVisible}
        footer={false}
      >
          Your session has expired. Please login again.
          </Modal>
    )
}
export default SessionExpired;