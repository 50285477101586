import React, { useState } from "react";
import { Input, Button } from "antd";
import { useIntl } from "react-intl";

import { ErrorMessage, Panel, Label } from "../../../../components/common";
import Validations from "../../../../shared/validations/Validations";
import "./geofence-edit-form.scss";
import { useEffect } from "react";

const styles = {
  formItem: "custom-form-item",
  formGroup: "custom-form-group",
  formAction: "custom-form-action",
  spanImp: "span-img",
};
function GeofenceEditForm(props) {
  const intl = useIntl();
  // const [store, updateStore] = useState(Validations.validateTextField(undefined));
  const [isPristine, changeIsPristine] = useState(true);
  const [areaName, changeAreaName] = useState(
    Validations.validateTextField(null)
  );
  const [areaDescription, changeAreaDescription] = useState(
    Validations.validateTextField(null)
  );
  const [deliveryCharge, changeDeliveryCharge] = useState(
    Validations.validateNumericValue(null)
  );
  const [minOrder, changeMinOrder] = useState(
    Validations.validateNumericValue(null)
  );

  const isFormValid = () => {
    return areaName.isValid && areaDescription.isValid;
  };

  const handleSubmit = () => {
    changeIsPristine(false);
    if (isFormValid()) {
      const data = {
        name: areaName.value,
        tags: areaDescription.value,
        // "deliveryCharge": deliveryCharge.value*1,
        // "minimumOrderValue": minOrder.value*1,
        branchId: props.data[0].branchId * 1,
      };
      props.handleSubmit(data);
    }
  };

  const setForm = () => {
    if (!props.data.length) {
      return;
    }
    const [data] = props.data;
    changeAreaName(Validations.validateTextField(data.name));
    changeAreaDescription(Validations.validateTextField(data.tags));
    changeDeliveryCharge(Validations.validateNumericValue(data.deliveryCharge));
    changeMinOrder(Validations.validateNumericValue(data.minimumOrderValue));
  };

  useEffect(() => {
    setForm();
  }, [props.data]);

  const handleFieldChange = (e) => {
    const { value, name } = e.target;
    switch (name) {
      case "areaName":
        changeAreaName(Validations.validateTextField(value));
        break;
      case "areaDescription":
        changeAreaDescription(Validations.validateTextField(value));
        break;
      case "deliveryCharge":
        changeDeliveryCharge(Validations.validateNumericValue(value));
        break;
      case "minOrder":
        changeMinOrder(Validations.validateNumericValue(value));
        break;
      default:
        return;
    }
  };

  return (
    <div className="custom-menu">
      <div style={{ flex: 2 }} className={styles.formGroup}>
        <div className={styles.formItem}>
          <Label value="app.field.areaName" />

          <Input
            name="areaName"
            value={areaName.value}
            onChange={handleFieldChange}
            placeholder={intl.formatMessage({
              id: "app.placeholder.areaName",
            })}
          />
          {!isPristine && <ErrorMessage float field={areaName} />}
        </div>

        <div className={styles.formItem}>
          <Label value="app.field.areaDescription" />
          <Input
            name="areaDescription"
            value={areaDescription.value}
            onChange={handleFieldChange}
            placeholder={intl.formatMessage({
              id: "app.placeholder.areaDescription",
            })}
          />
          {!isPristine && <ErrorMessage float field={areaDescription} />}
        </div>
      </div>
      {/* <div className={styles.formGroup}>
        <div className={styles.formItem}>
          <label>
            {intl.formatMessage({ id: "app.field.deliveryCharge" })}
          </label>
          <Input
            name="deliveryCharge"
            value={deliveryCharge.value}
            onChange={handleFieldChange}
            placeholder={intl.formatMessage({
              id: "app.placeholder.deliveryChargeGeofence",
            })}
          />
          {!isPristine && <ErrorMessage field={deliveryCharge} />}
        </div>
        <div className={styles.formItem}>
          <label>
            {intl.formatMessage({ id: "app.field.minimumOrderValue" })}
          </label>
          <Input
            name="minOrder"
            value={minOrder.value}
            onChange={handleFieldChange}
            placeholder={intl.formatMessage({
              id: "app.placeholder.minimumOrderValueGeofence",
            })}
          />
          {!isPristine && <ErrorMessage field={minOrder} />}
        </div>
      </div> */}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          flex: 1,
        }}
      >
        <Button
          loading={props.isLoading}
          onClick={handleSubmit}
          type="primary"
          shape={undefined}
        >
          {intl.formatMessage({ id: "app.pageTitle.editGeofence" })}
        </Button>
      </div>
    </div>
  );
}

export default GeofenceEditForm;
