import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Select, Dropdown, Button, Menu, Tag, Tooltip,Popconfirm } from "antd";
import { SearchOutlined, MoreOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import moment from "moment";

import { CustomersTable, AddWalletModal } from "./components";
import { RECORDS_PER_PAGE } from "../../shared/constants/dropdown";
import {
  Header,
  PaginationInfo,
  DelayedInput,
  ConfirmModal,
  CustomTable,
  CustomTag,
} from "../../components/common";
import { toastr } from "react-redux-toastr";
import {
  nestedParseJSON,
  removeEmptyKeys,
} from "../../shared/utils/object-manipulation";
import axios from "../../shared/api/axiosConfig";
import {
  getBaseURL,
  textTrimmer,
  TABLE_TEXT_LIMIT,
} from "../../shared/constants/dropdown";
import "./customers.scss";

const styles = {
  wrapper: "customers__wrapper",
  menu: "custom-menu",
  section: "custom-section",
  pageSize: "customers__menu__records-per-page",
};

const { Option } = Select;

function Customers(props) {
  const intl = useIntl();

  const [search, updateSearch] = useState("");
  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    isDeleteModalVisible: false,
    data: {},
    type: "",
  });

  const [searchDelayed, updateSearchDelayed] = useState("");

  const [pagination, updatePagination] = useState({
    pageSize: 10,
    skip: 0,
    current: 1,
    search: "",
    delayedcurrent: 1,
    delayedpageSize: 10,
  });

  const [tableControls, updateTableControls] = useState({
    isLoading: true,
    isEmpty: false,
    data: [],
    count: 0,
  });

  const openAddWalletModal = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      data,
    });
  };

  const addWallet = (value) => {
    if (modalControls.data.walletAmount) {
      value += modalControls.data.walletAmount;
    }
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });
    axios
      .put(`customers/restore/${modalControls.data.customerId}`, {
        customerId: modalControls.data.customerId,
        walletAmount: value,
      })
      .then((res) => {
        if (res.data.statusCode !== 200) {
          toastr.error("Error", res.data.message);
          updateModalControls({
            ...modalControls,
            isLoading: false,
          });
          return;
        }
        toastr.success(res.data.message);
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isVisible: false,
        });
        getCustomersData();
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
      });
  };

  const closeModal = () => {
    updateModalControls({
      isVisible: false,
      isDeleteModalVisible: false,
      data: {},
    });
  };
  // MountConfirmModal
  const handlePaginationChange = ({ current, pageSize, total }) => {
    updatePagination({
      ...pagination,
      current,
      total,
      skip: (current - 1) * pagination.pageSize,
    });
  };

  const handleRecordsPerPageChange = (val) => {
    updatePagination({
      ...pagination,
      pageSize: val,
    });
  };

  const handleSearchChange = (val) => {
    updateSearch(val);
  };

  const handleSearch = (val) => {
    updatePagination({
      ...pagination,
      current: 1,
      skip: 0,
    });

    updateSearchDelayed(val);
  };

  const getCustomersData = () => {
    updateTableControls({
      ...tableControls,
      isEmpty: false,
      isLoading: true,
    });
    axios
      .get("/customers", {
        params: removeEmptyKeys({
          limit: pagination.pageSize,
          skip: (pagination.current - 1) * pagination.pageSize,
          search: searchDelayed,
        }),
      })
      .then((res) => {
        // const parsed = nestedParseJSON(res.data.customers);
        updateTableControls({
          isLoading: false,
          isEmpty: res.data.data.length === 0,
          data: res.data.data,
          count: res.data.data.length,
        });

        updatePagination({
          ...pagination,
          total: res.data.data.length,
          // delayedcurrent: pagination.current,
          // delayedpageSize: pagination.pageSize,
        });
      })
      .catch((err) => {
        // toastr.error('Error', intl.formatMessage({id: 'app.message.error'}))
        updateTableControls({
          ...tableControls,
          isEmpty: true,
          isLoading: false,
          data: [],
        });
      });
  };

  const handleCustomerToggle = (data) => {
    updateTableControls({
      ...tableControls,
      isLoading: true,
    });
    const url = `/${
      data.isBlocked ? `customers/unblock/${data.customerId}` : `customers/block/${data.customerId}`
    }`;
    axios
      .put(url)
      .then((res) => {
        if (res.data.statusCode !== 200) {
          toastr.error("Error", res.data.message);
          return;
        }
        toastr.success(res.data.message);
        updateTableControls({
          ...tableControls,
          data: tableControls.data.map((item) => {
            if (item.customerId === data.customerId) {
              item.isBlocked = item.isBlocked ? 0 : 1;
            }
            return item;
          }),
          isLoading: false,
        });
        getCustomersData();
      })
      .catch((err) => {
        // toastr.error('Error', intl.formatMessage({id: 'app.message.error'}))
      });
  };

  const removeCustomer = (data) => {
    console.log(data);
    updateModalControls({
      ...modalControls,
      isVisible: false,
      isDeleteModalVisible: true,
      data,
      type: "",
    });
  };

  const handleDeleteCustomer = (item) => {
    console.log("deleted customer",item);
    // const data = {
    //   customerId: item.customerId,
    //   IsDeleted: 1,
    // };

    updateModalControls({
      ...modalControls,
      isLoading: true,
    });

    axios
      .delete(`/customers/delete/${item.customerId}`)
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isDeleteModalVisible: res.data.statusCode !== 200,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getCustomersData();
        } else {
          toastr.error("Error", res.data.message);
          updateModalControls({
            ...modalControls,
            isDeleteModalVisible: false,
          });
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateModalControls({
          ...modalControls,
          isDeleteModalVisible: false,
        });
      });
  };

  const handleCustomerView = (data) => {
    props.history.push(getBaseURL(`/customers/${data.customerId}`));
  };

  const getMenu = (item) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            handleCustomerView(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.view" })}
        </Menu.Item>
        {/* <Menu.Item
          onClick={() => {
            openAddWalletModal(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.addWallet" })}
        </Menu.Item> */}
        {item.isBlocked == 1 && (
          <Menu.Item
            onClick={() => {
              handleCustomerToggle(item);
            }}
          >
            {intl.formatMessage({ id: "app.field.unblock" })}
          </Menu.Item>
        )}
        {!item.isBlocked == 1 && (
          <Menu.Item
            onClick={() => {
              handleCustomerToggle(item);
            }}
          >
            {intl.formatMessage({ id: "app.field.block" })}
          </Menu.Item>
        )}
        <Menu.Item
        >
          <Popconfirm
            title={intl.formatMessage({
              id: "app.message.deleteWarning",
            })}
            onConfirm={() => {
              handleDeleteCustomer(item);
            }}
            placement="topRight"
            okText={intl.formatMessage({ id: "app.field.yes" })}
            cancelText={intl.formatMessage({ id: "app.field.no" })}
          >
            {intl.formatMessage({ id: "app.field.delete" })}
          </Popconfirm>
        </Menu.Item>
      </Menu>
    );
  };

  useEffect(() => {
    getCustomersData();
  }, [pagination.current, pagination.pageSize, searchDelayed]);

  const handleTableChange = (pag, filt, sorter) => {
    // debugger;
    updatePagination({
      ...pag,
    });
  };
  const columns = [
    {
      dataIndex: "",
      // sorter: true,
      title: intl.formatMessage({ id: "app.field.sNo" }),
      // fixed: 'left',
      render: (item, object, index) => {
        return (
          <span>
            {pagination.pageSize * (pagination.current - 1) + index + 1}
          </span>
        );
      },
    },
    {
      dataIndex: "name",
      title: intl.formatMessage({ id: "app.field.name" }),
      // render: (a, b) => `${b.firstName || "N/A"} ${b.lastName || ""}`,
      // fixed: 'left',
    },
    {
      dataIndex: "email",
      title: intl.formatMessage({ id: "app.field.email" }),
      render:(item) => `${item ? item : "N/A"}`
    },
    {
      dataIndex: "phoneNo",
      title: intl.formatMessage({ id: "app.field.phoneNumber" }),
      render: (i, p) => `${p.countryCode || ""} ${p.phoneNo? p.phoneNo : "N/A"}`,
    },
    {
      dataIndex: "createdAt",
      title: intl.formatMessage({ id: "app.field.registrationDate" }),
      render: (i, item) => `${moment.utc(item.createdAt).format("MMMM Do YYYY")}`,
    },
   
    // {
    //   dataIndex: "address",
    //   title: intl.formatMessage({ id: "app.field.address" }),
    //   render: (add) => {
    //     let i = add?.address || "---";
    //     // debugger;
    //     if (typeof i === "string") {
    //       // add = JSON.parse(add);
    //     }
    //     return i.length > TABLE_TEXT_LIMIT.ADDRESS ? (
    //       <Tooltip title={i}>
    //         <span style={{ cursor: "pointer" }}>
    //           {textTrimmer(i, TABLE_TEXT_LIMIT.ADDRESS)}
    //         </span>
    //       </Tooltip>
    //     ) : (
    //       i
    //     );
    //   },
    // },
    // {
    //   dataIndex: "dateOfBirth",
    //   title: intl.formatMessage({ id: "app.field.age" }),
    //   render: (dateOfBirth) =>
    //     dateOfBirth ? moment().diff(dateOfBirth, "years") : "---",
    // },
    // {
    //   dataIndex: "gender",
    //   title: intl.formatMessage({ id: "app.field.gender" }),
    // },
    // {
    //   dataIndex: "walletAmount",
    //   title: intl.formatMessage({ id: "app.field.walletMoney" }),
    // },
    
    {
      dataIndex: "isBlocked",
      title: intl.formatMessage({ id: "app.field.status" }),
      render: (item) => {
        // return item.isBlocked ? <Tag color="volcano">BLOCKED</Tag> : <Tag color="green">ACTIVE</Tag>
        return <CustomTag isBlocked={item} />;
      },
    },
    // {
    //   dataIndex: "",
    //   title: intl.formatMessage({ id: "app.field.deviceDetails"}),
    //   render: (item) => { return( <span>{item.deviceType} ({item.appVersion})</span>) }
    // },
    // {
    //   dataIndex: "appVersion",
    //   title: intl.formatMessage({ id: "app.field.appVersion" }),
    // },
    {
      dataIndex: "deviceType",
      title: intl.formatMessage({ id: "app.field.deviceType" }),
    },
    {
      title: intl.formatMessage({ id: "app.field.action" }),
      dataIndex: "",
      fixed: "right",
      width: 100,
      render: (item) => {
        // debugger;
        return (
          <center>
            <Dropdown overlay={getMenu(item)}>
              <MoreOutlined />
            </Dropdown>
          </center>
        );
      },
    },
  ];

  return (
    <div className={styles.wrapper}>
      {/* <AddWalletModal
        isVisible={modalControls.isVisible}
        // data={modalControls.data}
        onCloseIconClick={closeModal}
        addWallet={addWallet}
        isLoading={modalControls.isLoading}
        // modalType={modalControls.type}
      /> */}
      <ConfirmModal
        header={`${intl.formatMessage({ id:"app.field.delete" })} ${modalControls?.data?.name}?`}
        message={intl.formatMessage({
          id: "app.message.deleteWarning",
        })}
        onCloseIconClick={closeModal}
        onAction={handleDeleteCustomer}
        isVisible={modalControls.isDeleteModalVisible}
        actionText={intl.formatMessage({ id: "app.button.delete" })}
        isLoading={modalControls.isLoading}
      />
      <Header title="app.pageTitle.customers" />
      <div className="custom-section">
        <div className={styles.menu}>
          {/* <div className={styles.pageSize}>
          <label>{intl.formatMessage({ id: "app.field.recordsPerPage" })}</label>
          <Select onChange={handlepageSizeChange} value={pagination.pageSize}>
            {RECORDS_PER_PAGE.map((item) => (
              <Option key={item.key} value={item.value}>
                {item.text}
              </Option>
            ))}
          </Select>
        </div> */}
          <div></div>
          <div>
            <DelayedInput
              onChange={handleSearchChange}
              value={search}
              callback={handleSearch}
              delay={500}
              minLength={3}
              size="medium"
              prefixIcon={<SearchOutlined />}
              placeholder={intl.formatMessage({ id: "app.placeholder.search" })}
            />
          </div>
        </div>
        <CustomTable
          isLoading={tableControls.isLoading}
          columns={columns}
          dataSource={tableControls.data}
          isEmpty={tableControls.isEmpty}
          toggleItemBlock={handleCustomerToggle}
          viewItem={handleCustomerView}
          addWallet={openAddWalletModal}
          removeItem={removeCustomer}
          pagination={pagination}
          onChange={handleTableChange}
          current={pagination.current}
          pageSize={pagination.pageSize}
        />
      </div>
      {/* <div className={styles.footer}>
        <div></div>
        <div>
          <PaginationInfo
            currentPage={pagination.current}
            recordsPerPage={pagination.pageSize}
            totalRecords={tableControls.count}
            onChange={handlePaginationChange}
          />
        </div>
      </div> */}
    </div>
  );
}
const mapStateToProps = (state) => ({
  selectedPanel: state.user.panelMode,
  selectedCities: state.user.selectedCities,
  selectedCountry: state.user.selectedCountry,
});

export default connect(mapStateToProps)(Customers);
