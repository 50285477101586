import React, { useState, useEffect } from "react";
import { Input, Button, Select, TimePicker } from "antd";
import { useIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import moment from "moment";

import {
  CustomModal,
  Panel,
  ErrorMessage,
  Label,
} from "../../../../components/common";
import Validations from "../../../../shared/validations/Validations";
import "./add-edit-schedule-modal.scss";
import { DAYS } from "../../../../shared/constants/calendar";
const { Option } = Select;

const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  formGroup: "custom-form-group",
  spanImp: "span-imp",
};

// const deliveryModeData = [
//   { value: 1, text: "Car" },
//   { value: 2, text: "Motor Cycle" },
//   { value: 3, text: "Bicycle" },
//   { value: 4, text: "Scooter" },
//   { value: 5, text: "Foot" },
//   { value: 6, text: "Truck" },
// ];

function timeFix(time) {
  let [hours, minutes] = time.split(":");
  if (hours.length === 1) {
    hours = `0${hours}`;
  }
  if (minutes.length === 1) {
    minutes = `0${minutes}`;
  }
  return `${hours}:${minutes}`;
}

const errText = "Field cannot be empty";
const deliveryModeData = [
  { value: 0, text: "Bike" },
  { value: 1, text: "Truck" },
];
function AddEditScheduleModal(props) {
  const intl = useIntl();
  const [day, changeDay] = useState(undefined);
  const [deliveryMode, changeDeliveryMode] = useState(undefined);
  const [startTime, changeStartTime] = useState(undefined);
  const [endTime, changeEndTime] = useState(undefined);
  const [maxOrders, changeMaxOrders] = useState(
    Validations.validateNumericValue("")
  );
  const [isPristine, togglePristine] = useState(true);

  const setForm = () => {
    togglePristine(true);
    changeDay(props.data.day);
    changeDeliveryMode(props.data.vehicleType);
    changeStartTime(moment(timeFix(props.data.startTime), "HH:mm"));
    changeEndTime(moment(timeFix(props.data.endTime), "HH:mm"));
    changeMaxOrders(Validations.validateNumericValue(props.data.maxOrders));
  };

  const resetForm = () => {
    togglePristine(true);
    changeDay(undefined);
    changeDeliveryMode(undefined);
    changeStartTime(undefined);
    changeEndTime(undefined);
    changeMaxOrders(Validations.validateNumericValue(""));
  };
  useEffect(() => {
    if (props.isVisible) {
      if (props.modalType === "ADD") {
        resetForm();
      }
      if (props.modalType === "EDIT") {
        setForm();
      }
    }
  }, [props.isVisible]);

  const handleEdit = () => {
    // TODO
  };

  const isFormValid = () => {
    return (
      day !== undefined &&
      startTime !== undefined &&
      startTime !== null &&
      endTime !== undefined &&
      endTime !== null &&
      deliveryMode !== undefined &&
      maxOrders.isValid
    );
  };

  const closeModal = () => {
    props.onCloseIconClick();
  };

  const handleMaxOrdersChange = (e) => {
    changeMaxOrders(Validations.validateTextField(e.target.value));
  };

  const getFormData = () => ({
    startTime: moment(startTime).format("HH:mm"),
    endTime: moment(endTime).format("HH:mm"),
    maxOrders: maxOrders.value * 1,
    dayId: day,
    vehicleType: deliveryMode * 1,
  });

  const checkTime = () => {};

  const formAction = () => {
    togglePristine(false);
    if (!isFormValid()) {
      return;
    }

    if (moment(endTime).diff(startTime, "minute") <= 0) {
      toastr.error("Error", "End Time must be ahead of Start Time");
      return;
    }
    const data = getFormData();
    if (props.modalType === "ADD") {
      props.addSlot(data);
    }

    if (props.modalType === "EDIT") {
      props.editSlot(data);
    }
  };

  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={closeModal}
      header={
        props.modalType === "ADD"
          ? "app.pageTitle.addSlot"
          : "app.pageTitle.editSlot"
      }
      isVisible={props.isVisible}
      action={
        <>
          <Button type="default" shape={undefined} onClick={closeModal}>
            {intl.formatMessage({ id: "app.button.cancel" })}
          </Button>
          <Button type="primary" loading={props.isLoading} shape={undefined} onClick={formAction}>
            {intl.formatMessage({ id: "app.button.submit" })}
          </Button>
        </>
      }
    >
      <Panel isModal isLoading={props.isLoading} className={styles.formModal}>
        <div>
          <section className={styles.formGroup}>
            <div className={styles.formItem}>
              <Label value="app.field.day">
              </Label>
              <Select
                placeholder={intl.formatMessage({ id: "app.placeholder.day" })}
                value={day}
                onChange={changeDay}
              >
                {DAYS.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {intl.formatMessage({ id: item.text })}
                  </Option>
                ))}
              </Select>
              {!isPristine && day === undefined && (
                <ErrorMessage text={errText} />
              )}
            </div>
            <div className={styles.formItem}>
            <Label value="app.field.deliveryMode">
              </Label>
              <Select
                placeholder={intl.formatMessage({
                  id: "app.placeholder.deliveryMode",
                })}
                value={deliveryMode}
                onChange={changeDeliveryMode}
              >
                {deliveryModeData.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.text}
                  </Option>
                ))}
              </Select>
              {!isPristine && deliveryMode === undefined && (
                <ErrorMessage text={errText} />
              )}
            </div>
          </section>
          <section className={styles.formGroup}>
            <div className={styles.formItem}>
            <Label value="app.field.startTime">
              </Label>
              <TimePicker
                placeholder={intl.formatMessage({
                  id: "app.placeholder.startTime",
                })}
                value={startTime}
                onChange={changeStartTime}
                format={"HH:mm"}
              />
              {!isPristine && startTime === undefined && (
                <ErrorMessage text={errText} />
              )}
              {isPristine && startTime === null && (
                <ErrorMessage text={errText} />
              )}
            </div>
            <div className={styles.formItem}>
            <Label value="app.field.endTime">
              </Label>
              <TimePicker
                placeholder={intl.formatMessage({
                  id: "app.placeholder.endTime",
                })}
                value={endTime}
                onChange={changeEndTime}
                format={"HH:mm"}
              />
              {!isPristine && endTime === undefined && (
                <ErrorMessage text={errText} />
              )}
              {isPristine && endTime === null && (
                <ErrorMessage text={errText} />
              )}
            </div>
          </section>
          <div className={styles.formItem}>
          <Label value="app.field.maxOrders">
              </Label>
            <Input
              placeholder={intl.formatMessage({
                id: "app.placeholder.maxOrders",
              })}
              value={maxOrders.value}
              onChange={handleMaxOrdersChange}
            />
            {!isPristine && <ErrorMessage field={maxOrders} />}
          </div>
        </div>

        {/* <div className={styles.formAction}>

        </div> */}
      </Panel>
    </CustomModal>
  );
}

export default AddEditScheduleModal;
