import React from "react";
import { useIntl } from "react-intl";
import { Modal, Button } from "antd";
import "./logout.scss";
const styles = {
  buttonContainer: "custom-logout-modal__button-container",
};
function Logout(props) {
  const intl = useIntl();
  //   return null;
  return (
    <Modal
      title={intl.formatMessage({ id: "app.button.logout" })}
      style={{ top: 20 }}
      closable={false}
      visible={props.isVisible}
      footer={[
        <Button onClick={props.cancel}>{intl.formatMessage({id: 'app.button.cancel'})}</Button>,
        <Button type="primary" onClick={props.logout}>
        {intl.formatMessage({id: 'app.button.logout'})}
      </Button>
      ]}
    >
      <span>{intl.formatMessage({ id: "app.message.logoutWarning"})}</span>
    </Modal>
  );
}
export default Logout;
