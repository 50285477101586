import React, { useState, useEffect, useRef } from "react";
import GoogleMap from "../../../../components/google-map/google-map";
import { Marker, StandaloneSearchBox } from "@react-google-maps/api";
import StoresCategoryModal from "../store-categories-modal/store-categories-modal";
import { useIntl } from "react-intl";
import Validations from "../../../../shared/validations/Validations";
import { Input, Button, Select, Radio } from "antd";
import placeholderImg from "../../../../assets/images/item-placeholder.png";
import { toastr } from "react-redux-toastr";
import {
  FullPageDrawer,
  Panel,
  ErrorMessage,
  UploadButton,
  CustomModal,
  Icon,
} from "../../../../components/common";
import countryCodes from "../../../../shared/constants/countries.json";
import "./stores-modal.scss";
import axiosClient from "../../../../shared/api/axiosConfig";

const styles = {
  formItem: "custom-form-item",
  formGroup: "custom-form-group",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  formHeader: "custom-form-title",
  formContainer: "stores-modal__form-container",
  formContent: "stores-modal__form-content",
  imageContainer: "stores-modal__image-container",
  formLeft: "stores-modal__form-container__left",
  formRight: "stores-modal__form-container__right",
  wrapper: "stores-modal__wrapper",
  googleMap: "stores-modal__google-map",
  imageContainerItem: "stores-modal__image-container__item",
  categoryContainer: "stores-modal__category-container",
  categoryTag: {
    wrapper: "stores-modal__category-tag__wrapper",
    text: "stores-modal__category-tag__text",
    rank: "stores-modal__category-tag__rank",
    name: "stores-modal__category-tag__name",
    iconContainer: "stores-modal__category-tag__icon-container",
  },
};

function CategoryTag(props) {
  const handleEdit = () => {
    props.onEdit(props.data);
  };

  const handleDelete = () => {
    props.onDelete(props.data);
  };

  return (
    <div className={styles.categoryTag.wrapper}>
      <div className={styles.categoryTag.text}>
        <span className={styles.categoryTag.rank}>#{props.data.priority}</span>
        <span className={styles.categoryTag.name}>
          {props.data.categoryName}
        </span>
      </div>
      <div className={styles.categoryTag.iconContainer}>
        <Icon
          onClick={handleEdit}
          size="tiny"
          onClick={handleEdit}
          name="PEN"
        />
        <Icon
          onClick={handleDelete}
          size="tiny"
          onClick={handleDelete}
          name="TRASH"
        />
      </div>
    </div>
  );
}

const { Option } = Select;

const filterOption = (input, option) => {
  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

const { TextArea } = Input;

function StoresModal(props) {
  const [storeData, updateStoreData] = useState({
    data: {},
    isLoading: false,
  });
  const intl = useIntl();
  const locationSearchRef = useRef(null);
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const [isPristine, changeIsPristine] = useState(true);
  const [address, changeAddress] = useState(Validations.validateTextField(""));
  const [name, changeName] = useState(Validations.validateTextField(""));
  const [imageURL, changeImageURL] = useState(
    Validations.validateTextField("")
  );
  const [consideredQuantity, toggleConsideredQuantity] = useState(true);
  const [maxQuantity, changeMaxQuantity] = useState(
    Validations.validateNumericValue(5)
  );
  const [deliveryCharge, changeDeliveryCharge] = useState(
    Validations.validateNumericValue(null)
  );
  const [deliveryTime, changeDeliveryTime] = useState(
    Validations.validateTextField(null)
  );
  const [minOrder, changeMinOrder] = useState(
    Validations.validateNumericValue(null)
  );
  const [phoneNumber, changePhoneNumber] = useState(
    Validations.validatePhoneNumber("")
  );
  const [email, changeEmail] = useState(Validations.validateEmail(""));
  const [countryCode, changeCountryCode] = useState(
    Validations.validateTextField(undefined)
  );

  const [maxRadius, updateMaxRadius] = useState(
    Validations.validateNumericValue("")
  );
  const [baseFare, updateBaseFare] = useState(
    Validations.validateNumericValue("")
  );
  const [storeComission, updateStoreComission] = useState(
    Validations.validateNumericValue("")
  );
  const [onlinePayComission, updateOnlinePayComission] = useState(
    Validations.validateNumericValue("")
  );
  const [timeFare, updateTimeFare] = useState(
    Validations.validateNumericValue("")
  );
  const [distanceFare, updateDistanceFare] = useState(
    Validations.validateNumericValue("")
  );
  const [teamName, updateTeamName] = useState(
    Validations.validateTextField("")
  );
  const [password, changePassword] = useState(Validations.validatePassword(""));
  const [isFreeDeliveryEnabled, updateIsFreeDeliveryEnabled] = useState(
    Validations.validateTextField(0)
  );

  const [isSelfDelivery, updateSelfDelivery] = useState(
    Validations.validateTextField(0)
  );
  const [limitForFreeDelivery, updateLimitForFreeDelivery] = useState(
    Validations.validateNumericValue(undefined)
  );
  const [description, changeDescription] = useState(
    Validations.validateTextField("")
  );

  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    data: {},
    type: "",
    isLoading: false,
  });

  const handleModalClose = () => {
    updateModalControls({
      ...modalControls,
      isVisible: false,
    });
  };

  const [selectedCategories, updateSelectedCategories] = useState(
    Validations.validateMultiSelect([])
  );

  //   const [address, changeAddress] = useState(Validations.validateTextField(""));
  const [latLng, updateLatLng] = useState({
    lat: null,
    lng: null,
  });
  const [isAddressLoading, changeIsAddressLoading] = useState(false);
  const isFormValid = () => {
    let isValid = true;

    if (isFreeDeliveryEnabled.value) {
      isValid = isValid && limitForFreeDelivery.isValid;
    }
    if(!consideredQuantity){
      isValid = isValid && maxQuantity.isValid;
    }

    if (isSelfDelivery.value) {
      isValid =
        isValid &&
        baseFare.isValid &&
        timeFare.isValid &&
        distanceFare.isValid &&
        // maxRadius.isValid &&
        teamName.isValid;
    }
    isValid =
      isValid &&
      name.isValid &&
      phoneNumber.isValid &&
      // maxRadius.isValid &&
      // countryCode.isValid &&
      address.isValid &&
      latLng.lat !== null &&
      email.isValid &&
      onlinePayComission.isValid &&
      storeComission.isValid &&
      deliveryTime.isValid &&
      // deliveryCharge.isValid &&
      minOrder.isValid &&
      imageURL.isValid &&
      selectedCategories.isValid &&
      description.isValid;

    if (props.modalType === "ADD") {
      isValid = isValid && password.isValid;
    } else {
      if (
        password.value !== "" &&
        password.value !== null &&
        password.value !== undefined
      ) {
        isValid = isValid && password.isValid;
      }
    }

    return isValid;
  };

  const handleFieldChange = (e) => {
    const { value, name } = e.target;
    // debugger;
    switch (name) {
      case "address":
        changeAddress(Validations.validateTextField(value));
        break;
      default:
      case "storeName":
        changeName(Validations.validateTextField(value));
        break;
      case "phoneNumber":
        changePhoneNumber(Validations.validatePhoneNumber(value));
        break;
      case "maxQuantity":
        changeMaxQuantity(Validations.validateNumericValue(value));
        break;
      case "email":
        changeEmail(Validations.validateEmail(value));
        break;
      case "password":
        changePassword(Validations.validatePassword(value));
        break;
      case "description":
        changeDescription(Validations.validateTextField(value));
        break;
      case "deliveryCharge":
        changeDeliveryCharge(Validations.validateNumericValue(value));
        break;
      case "deliveryTime":
        changeDeliveryTime(Validations.validateTextField(value));
        break;
      case "minOrder":
        changeMinOrder(Validations.validateNumericValue(value));
        break;
      case "isFreeDeliveryEnabled":
        updateIsFreeDeliveryEnabled(Validations.validateTextField(value));
        break;
      case "limitForFreeDelivery":
        updateLimitForFreeDelivery(Validations.validateNumericValue(value));
        break;
      case "isSelfDelivery":
        updateSelfDelivery(Validations.validateTextField(value));
        break;
      case "timeFare":
        updateTimeFare(Validations.validateNumericValue(value));
        break;
      case "teamName":
        updateTeamName(Validations.validateTextField(value));
        break;
      case "distanceFare":
        updateDistanceFare(Validations.validateNumericValue(value));
        break;
      case "onlinePayComission":
        updateOnlinePayComission(Validations.validateNumericValue(value));
        break;
      case "storeComission":
        updateStoreComission(Validations.validateNumericValue(value));
        break;
      case "baseFare":
        updateBaseFare(Validations.validateNumericValue(value));
        break;
      case "maxRadius":
        updateMaxRadius(Validations.validateNumericValue(value));
        break;
    }
  };

  const handleImageChange = (image) => {
    changeImageURL(Validations.validateTextField(image));
  };

  const resetForm = () => {
    changeIsPristine(true);
    changeAddress(Validations.validateTextField(""));
    changeName(Validations.validateTextField(""));
    changePhoneNumber(Validations.validatePhoneNumber(""));
    changeEmail(Validations.validateEmail(""));
    changePassword(Validations.validatePassword(""));
    changeDescription(Validations.validateTextField(""));
    updateSelectedCategories(Validations.validateMultiSelect([]));
    changeMinOrder(Validations.validateNumericValue(undefined));
    updateIsFreeDeliveryEnabled(Validations.validateTextField(0));
    updateLimitForFreeDelivery(Validations.validateNumericValue(undefined));
    changeDeliveryCharge(Validations.validateNumericValue(undefined));
    changeDeliveryTime(Validations.validateTextField(""));
    updateBaseFare(Validations.validateNumericValue(null));
    updateTimeFare(Validations.validateNumericValue(null));
    updateDistanceFare(Validations.validateNumericValue(null));
    updateStoreComission(Validations.validateNumericValue(null));
    updateOnlinePayComission(Validations.validateNumericValue(null));
    updateMaxRadius(Validations.validateNumericValue(null));
    updateSelfDelivery(Validations.validateTextField(0));
    toggleConsideredQuantity(true);
    changeMaxQuantity(Validations.validateNumericValue(5));
    // changeCountryCode(Validations.validateTextField(undefined));
    changeImageURL(Validations.validateTextField(""));
    updateLatLng({
      lat: null,
      lng: null,
    });

    if (mapRef.current !== null && markerRef.current !== null) {
      mapRef.current.setCenter(
        // new window.google.maps.LatLngBounds({
        {
          lat: 0,
          lng: 0,
        }
        // })
      );

      mapRef.current.setZoom(2);
    }
  };

  const getStoreData = () => {
    updateStoreData({
      isLoading: true,
      data: {},
    });

    axiosClient
      .get("/getBranchDetails", {
        params: {
          branchId: props.data.branchId,
        },
      })
      .then((res) => {
        updateStoreData({
          isLoading: false,
          data: res.data.data,
        });
        setForm(res.data.data);
      })
      .catch((err) => {
        updateStoreData({
          isLoading: false,
          data: {},
        });

        console.log(err);
      });
  };

  const setForm = (data) => {
    changeIsPristine(true);
    // changeName(Validations.validateTextField(props.data.name.en));
    changeAddress(Validations.validateTextField(props.data.address));
    changeName(Validations.validateTextField(props.data.branchName));
    changePhoneNumber(Validations.validatePhoneNumber(props.data.phoneNo));
    changeEmail(Validations.validateEmail(props.data.email));
    changePassword(Validations.validatePassword(""));
    changeDescription(Validations.validateTextField(props.data.description));
    updateIsFreeDeliveryEnabled(
      Validations.validateTextField(props.data.isFreeDeliveryEnabled)
    );
    toggleConsideredQuantity(!!props.data.consideredQuantity);
    changeMaxQuantity(
      Validations.validateNumericValue(props.data.maxQuantity)
    );
    updateLimitForFreeDelivery(
      Validations.validateNumericValue(props.data.limitForFreeDelivery)
    );
    // changeCountryCode(Validations.validateTextField(undefined));
    changeImageURL(Validations.validateTextField(props.data.imageURLOriginal));
    // changeDeliveryCharge(
    //   Validations.validateNumericValue(props.data.deliveryCharge)
    // );
    changeDeliveryTime(
      Validations.validateTextField(props.data.deliveryTime)
    );
    changeMinOrder(
      Validations.validateNumericValue(props.data.minimumOrderValue)
    );
    const superCatMap = props.storeCategories.reduce((acc, val) => {
      acc[val.superCatId] = val.categoryName;
      return acc;
    }, {});
    updateSelectedCategories(
      Validations.validateMultiSelect(
        props.data.superCatIds
          .filter(
            (i) =>
              i.superCatId !== null && superCatMap[i.superCatId] !== undefined
          )
          .map((i) => {
            return {
              ...i,
              categoryName: superCatMap[i.superCatId] || "~Removed~",
            };
          })
      )
    );
    updateLatLng({
      lat: parseFloat(props.data.latitude),
      lng: parseFloat(props.data.longitude),
    });
    if (mapRef.current !== null && markerRef.current !== null) {
      mapRef.current.setCenter(
        // new window.google.maps.LatLngBounds({
        {
          lat: parseFloat(props.data.latitude),
          lng: parseFloat(props.data.longitude),
        }
        // })
      );

      mapRef.current.setZoom(18);
    }

    updateSelfDelivery(
      Validations.validateTextField(data.isDeliveryService ? 1 : 0)
    );
    updateBaseFare(Validations.validateNumericValue(data.teamDetails.baseFare));
    updateTimeFare(Validations.validateNumericValue(data.teamDetails.timeFare));
    updateDistanceFare(
      Validations.validateNumericValue(data.teamDetails.distanceFare)
    );
    updateTeamName(
      Validations.validateTextField(data.teamDetails?.teamName || "")
    );
    updateOnlinePayComission(
      Validations.validateNumericValue(data.onlinePaymentCommision)
    );
    updateStoreComission(Validations.validateNumericValue(data.storeCommision));
  };

  useEffect(() => {
    if (props.isVisible) {
      if (props.modalType === "ADD") {
        resetForm();
      } else if (props.modalType === "EDIT") {
        getStoreData();
      }
    }
  }, [props.isVisible]);

  const getFormData = () => {
    const data = {
      branchName: name.value,
      latitude: `${latLng.lat}`,
      longitude: `${latLng.lng}`,
      address: address.value,
      phoneNo: phoneNumber.value,
      email: email.value,
      description: description.value,
      password: password.value,
      imageURLOriginal: imageURL.value,
      // deliveryCharge: deliveryCharge.value * 1,
      deliveryTime: deliveryTime.value,
      minimumOrderValue: minOrder.value * 1,
      isDeliveryService: isSelfDelivery.value ? true : false,
      isFreeDeliveryEnabled: isFreeDeliveryEnabled.value,
      consideredQuantity: consideredQuantity ? 1 : 0,
      limitForFreeDelivery: limitForFreeDelivery.value,
      storeCommision: storeComission.value * 1,
      onlinePaymentCommision: onlinePayComission.value * 1,
      superCatIds: selectedCategories.value.map((i) => {
        return {
          superCatId: i.superCatId * 1,
          priority: i.priority * 1,
        };
      }),
    };
    if(!consideredQuantity){
      data.maxQuantity = maxQuantity.value * 1;
    }
    if (isSelfDelivery.value) {
      data.teamDetails = {};
      data.teamDetails.baseFare = baseFare.value * 1;
      data.teamDetails.timeFare = timeFare.value * 1;
      data.teamDetails.teamName = teamName.value;
      data.teamDetails.distanceFare = distanceFare.value;
    }

    return data;
  };

  const handleSubmit = () => {
    changeIsPristine(false);
    if (!isFormValid()) {
      toastr.warning(
        "Invalid Form",
        intl.formatMessage({ id: "app.message.formCheck" })
      );
      return;
    }
    if (props.modalType === "ADD") {
      props.addStore(getFormData());
    } else if (props.modalType === "EDIT") {
      props.editStore(getFormData());
    }
  };

  const handleImageError = (e) => {
    e.target.src = placeholderImg;
  };

  const handleCountryCodeChange = (value) => {
    changeCountryCode(Validations.validateTextField(value));
  };

  const handleSearchPlaceChange = (data) => {
    const places = locationSearchRef.current.getPlaces();
    console.log(places);
    if (places.length) {
      const bounds = new window.google.maps.LatLngBounds();
      changeAddress(Validations.validateTextField(places[0].formatted_address));
      updateLatLng({
        lat: places[0].geometry.location.lat(),
        lng: places[0].geometry.location.lng(),
      });
      places.forEach((place) => {
        if (!place.geometry) {
          console.log("No geometry");
          return;
        }
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      mapRef.current.fitBounds(bounds);
    }
  };

  const handleMarkerDrag = (marker) => {
    const lat = marker.latLng.lat();
    const lng = marker.latLng.lng();
    mapRef.current.setCenter(marker.latLng);
    changeIsAddressLoading(true);
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCSHHKKFCGHd8jd0_jlhytWadlpFLX6h9k`
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.results.length) {
          changeAddress(
            Validations.validateTextField(res.results[0].formatted_address)
          );
        }
        changeIsAddressLoading(false);
      })
      .catch((err) => {
        changeIsAddressLoading(false);
        // toastr.error("Error", "Something Went Wrong");
      });
    updateLatLng({
      lat,
      lng,
    });
  };

  const handleSearchOnLoad = (ref) => {
    locationSearchRef.current = ref;
  };

  const handleMarkerLoad = (ref) => {
    markerRef.current = ref;
    mapRef.current.setCenter(ref.position);
    mapRef.current.setZoom(18);
  };

  const handleMapLoad = (ref) => {
    console.log(ref);
    mapRef.current = ref;
  };

  const openAddCategoryModal = () => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      type: "ADD_CATEGORY",
    });
  };

  const openEditCategoryModal = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      data,
      type: "EDIT_CATEGORY",
    });
  };

  const addCategory = (data) => {
    for (let i = 0; i < selectedCategories.value.length; i++) {
      if (selectedCategories.value[i].superCatId === data.superCatId) {
        toastr.warning("Duplicates not allowed");
        return;
      }
    }
    updateSelectedCategories(
      Validations.validateMultiSelect([...selectedCategories.value, data])
    );

    handleModalClose();
  };

  const editCategory = (data) => {
    for (let i = 0; i < selectedCategories.value.length; i++) {
      if (
        selectedCategories.value[i].superCatId === data.superCatId &&
        data.superCatId !== modalControls.data.superCatId
      ) {
        toastr.warning("Duplicates not allowed");
        return;
      }
    }
    updateSelectedCategories(
      Validations.validateMultiSelect(
        selectedCategories.value.map((i) => {
          if (i.superCatId === modalControls.data.superCatId) {
            return data;
          }
          return i;
        })
      )
    );

    handleModalClose();
  };

  const deleteCategory = (data) => {
    updateSelectedCategories(
      Validations.validateMultiSelect(
        selectedCategories.value.filter((i) => i.superCatId !== data.superCatId)
      )
    );
  };

  return (
    <CustomModal
      width={750}
      isVisible={props.isVisible}
      onCloseIconClick={props.onCloseIconClick}
      action={
        <div className={styles.formAction}>
          <Button
            shape={undefined}
            onClick={props.onCloseIconClick}
            size="medium"
          >
            {intl.formatMessage({ id: "app.button.cancel" })}
          </Button>
          <Button
            shape={undefined}
            type="primary"
            onClick={handleSubmit}
            size="medium"
          >
            {intl.formatMessage({ id: "app.button.submit" })}
          </Button>
        </div>
      }
      header={
        props.modalType === "ADD"
          ? "app.pageTitle.addStore"
          : "app.pageTitle.editStore"
      }
      // title={
      //
      //     ? intl.formatMessage({ id: "app.pageTitle.addStore" })
      //     : intl.formatMessage({ id: "app.pageTitle.editStore" })
      // }
    >
      <StoresCategoryModal
        isVisible={modalControls.isVisible}
        modalToShow={modalControls.type}
        onCloseIconClick={handleModalClose}
        categories={props.storeCategories}
        addCategory={addCategory}
        data={modalControls.data}
        editCategory={editCategory}
      />
      <Panel
        className={styles.formModal}
        // containerClass={styles.wrapper}
        isLoading={props.isLoading || storeData.isLoading}
      >
        <div className={styles.wrapper}>
          <div className={styles.formContainer}>
            <div className={styles.imageContainer}>
              <div className={styles.imageContainerItem}>
                <div className={styles.formItem}>
                  <img
                    src={imageURL.value ? imageURL.value : placeholderImg}
                    alt=""
                  />
                  <UploadButton
                    accept="image/*"
                    resultType="url"
                    onChange={handleImageChange}
                  />
                  {!isPristine && <ErrorMessage field={imageURL} />}
                </div>
              </div>
              <div className={styles.imageContainerItem}>
                <div className={styles.formItem}>
                  <label>
                    {intl.formatMessage({ id: "app.field.storeName" })}
                  </label>
                  <Input
                    name="storeName"
                    value={name.value}
                    onChange={handleFieldChange}
                    placeholder={intl.formatMessage({
                      id: "app.placeholder.storeName",
                    })}
                  />
                  {!isPristine && <ErrorMessage field={name} />}
                </div>
                <div className={styles.formItem}>
                  <label>
                    {intl.formatMessage({ id: "app.field.storeDescription" })}
                  </label>
                  <Input
                    // rows={3}
                    name="description"
                    value={description.value}
                    onChange={handleFieldChange}
                    placeholder={intl.formatMessage({
                      id: "app.placeholder.storeDescription",
                    })}
                  />
                  {!isPristine && <ErrorMessage field={description} />}
                </div>
              </div>
            </div>
            <div className={styles.formGroup}>
              {/* <div className={styles.formItem}>
                <label>
                  {intl.formatMessage({ id: "app.field.countryCode" })}
                </label>
                <Select
                  showSearch
                  placeholder={intl.formatMessage({
                    id: "app.placeholder.countryCode",
                  })}
                  onChange={handleCountryCodeChange}
                  value={countryCode.value}
                  optionFilterProp="children"
                  filterOption={filterOption}
                >
                  {countryCodes.map((item, index) => (
                    <Option
                      key={index}
                      value={item.dial_code}
                    >{`${item.dial_code}(${item.name})`}</Option>
                  ))}
                </Select>
                {!isPristine && <ErrorMessage field={countryCode} />}
              </div> */}
              <div className={styles.formItem}>
                <label>
                  {intl.formatMessage({ id: "app.field.phoneNumber" })}
                </label>
                <Input
                  name="phoneNumber"
                  value={phoneNumber.value}
                  onChange={handleFieldChange}
                  placeholder={intl.formatMessage({
                    id: "app.placeholder.phoneNumber",
                  })}
                />
                {!isPristine && <ErrorMessage field={phoneNumber} />}
              </div>
            </div>
            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <label>{intl.formatMessage({ id: "app.field.email" })}</label>
                <Input
                  name="email"
                  value={email.value}
                  onChange={handleFieldChange}
                  placeholder={intl.formatMessage({
                    id: "app.placeholder.email",
                  })}
                />
                {!isPristine && <ErrorMessage field={email} />}
              </div>
              <div className={styles.formItem}>
                <label>
                  {intl.formatMessage({ id: "app.field.password" })}
                </label>
                <Input
                  name="password"
                  value={password.value}
                  type="password"
                  onChange={handleFieldChange}
                  placeholder={intl.formatMessage({
                    id: "app.placeholder.password",
                  })}
                />
                {!isPristine && props.modalType === "ADD" && (
                  <ErrorMessage field={password} />
                )}
                {!isPristine &&
                  props.modalType === "EDIT" &&
                  password.value !== "" &&
                  password.value !== null &&
                  password.value !== undefined && (
                    <ErrorMessage field={password} />
                  )}
              </div>
            </div>
            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <label>
                  {intl.formatMessage({
                    id: "app.field.isFreeDeliveryEnabled",
                  })}
                </label>
                <Radio.Group
                  value={isFreeDeliveryEnabled.value}
                  name="isFreeDeliveryEnabled"
                  s
                  onChange={handleFieldChange}
                >
                  <Radio value={1}>
                    {intl.formatMessage({ id: "app.field.yes" })}
                  </Radio>
                  <Radio value={0}>
                    {intl.formatMessage({ id: "app.field.no" })}
                  </Radio>
                </Radio.Group>
              </div>
              <div
                style={{
                  display: isFreeDeliveryEnabled.value === 1 ? "flex" : "none",
                }}
                className={styles.formItem}
              >
                <label>
                  {intl.formatMessage({ id: "app.field.limitForFreeDelivery" })}
                </label>
                <Input
                  name="limitForFreeDelivery"
                  value={limitForFreeDelivery.value}
                  onChange={handleFieldChange}
                  placeholder={intl.formatMessage({
                    id: "app.placeholder.limitForFreeDelivery",
                  })}
                />
                {!isPristine && <ErrorMessage field={limitForFreeDelivery} />}
              </div>
            </div>

            <div className={styles.formItem}>
              <label>{intl.formatMessage({ id: "app.field.category" })}</label>
              <div className={styles.categoryContainer}>
                {selectedCategories.value.map((item) => (
                  <CategoryTag
                    data={item}
                    onEdit={openEditCategoryModal}
                    onDelete={deleteCategory}
                  />
                ))}
                <Button
                  style={{ margin: "0.25rem" }}
                  shape={undefined}
                  type="primary"
                  onClick={openAddCategoryModal}
                >
                  {intl.formatMessage({ id: "app.button.addCategory" })}
                </Button>
              </div>
              {/* <Select
                mode="multiple"
                placeholder={intl.formatMessage({
                  id: "app.placeholder.category",
                })}
                value={selectedCategories.value}
                onChange={handleCategoryChange}
              >
                {props.storeCategories.map((item) => (
                  <Option key={item.superCatId} value={item.superCatId}>
                    {item.categoryName}
                  </Option>
                ))}
              </Select> */}
              {!isPristine && <ErrorMessage field={selectedCategories} />}
            </div>
            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <label>
                  {intl.formatMessage({
                    id: "app.field.isSelfDelivery",
                  })}
                </label>
                <Radio.Group
                  value={isSelfDelivery.value}
                  name="isSelfDelivery"
                  s
                  onChange={handleFieldChange}
                >
                  <Radio value={1}>
                    {intl.formatMessage({ id: "app.field.yes" })}
                  </Radio>
                  <Radio value={0}>
                    {intl.formatMessage({ id: "app.field.no" })}
                  </Radio>
                </Radio.Group>
              </div>
              {/* <div className={styles.formItem}>
                <label>
                  {intl.formatMessage({ id: "app.field.maxRadius" })}
                </label>
                <Input
                  name="maxRadius"
                  value={maxRadius.value}
                  onChange={handleFieldChange}
                  placeholder={intl.formatMessage({
                    id: "app.placeholder.maxRadius",
                  })}
                />
                {!isPristine && <ErrorMessage field={maxRadius} />}
              </div> */}
            </div>
            {isSelfDelivery.value == 1 && (
              <>
                <div className={styles.formGroup}>
                  <div className={styles.formItem}>
                    <label>
                      {intl.formatMessage({ id: "app.field.baseFare" })}
                    </label>
                    <Input
                      name="baseFare"
                      value={baseFare.value}
                      onChange={handleFieldChange}
                      placeholder={intl.formatMessage({
                        id: "app.placeholder.baseFare",
                      })}
                    />
                    {!isPristine && <ErrorMessage field={baseFare} />}
                  </div>
                  <div className={styles.formItem}>
                    <label>
                      {intl.formatMessage({ id: "app.field.distanceFare" })}
                    </label>
                    <Input
                      name="distanceFare"
                      value={distanceFare.value}
                      onChange={handleFieldChange}
                      placeholder={intl.formatMessage({
                        id: "app.placeholder.distanceFare",
                      })}
                    />
                    {!isPristine && <ErrorMessage field={distanceFare} />}
                  </div>
                </div>
                <div className={styles.formGroup}>
                  <div className={styles.formItem}>
                    <label>
                      {intl.formatMessage({ id: "app.field.teamName" })}
                    </label>
                    <Input
                      name="teamName"
                      value={teamName.value}
                      onChange={handleFieldChange}
                      placeholder={intl.formatMessage({
                        id: "app.placeholder.teamName",
                      })}
                    />
                    {!isPristine && <ErrorMessage field={teamName} />}
                  </div>
                  <div className={styles.formItem}>
                    <label>
                      {intl.formatMessage({ id: "app.field.timeFare" })}
                    </label>
                    <Input
                      name="timeFare"
                      value={timeFare.value}
                      onChange={handleFieldChange}
                      placeholder={intl.formatMessage({
                        id: "app.placeholder.timeFare",
                      })}
                    />
                    {!isPristine && <ErrorMessage field={timeFare} />}
                  </div>
                </div>
              </>
            )}

            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <label>
                  {intl.formatMessage({ id: "app.field.storeComission" })}
                </label>
                <Input
                  name="storeComission"
                  value={storeComission.value}
                  onChange={handleFieldChange}
                  placeholder={intl.formatMessage({
                    id: "app.placeholder.storeComission",
                  })}
                />
                {!isPristine && <ErrorMessage field={storeComission} />}
              </div>
              <div className={styles.formItem}>
                <label>
                  {intl.formatMessage({
                    id: "app.field.onlinePayComission",
                  })}
                </label>
                <Input
                  name="onlinePayComission"
                  value={onlinePayComission.value}
                  onChange={handleFieldChange}
                  placeholder={intl.formatMessage({
                    id: "app.placeholder.onlinePayComission",
                  })}
                />
                {!isPristine && <ErrorMessage field={onlinePayComission} />}
              </div>
            </div>
            {/* <div className={styles.formItem}>
              <label>
                {intl.formatMessage({ id: "app.field.deliveryCharge" })}
              </label>
              <Input
                name="deliveryCharge"
                value={deliveryCharge.value}
                onChange={handleFieldChange}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.deliveryChargeGeofence",
                })}
              />
              {!isPristine && <ErrorMessage field={deliveryCharge} />}
            </div> */}
            <div className={styles.formItem}>
              <label>
                {intl.formatMessage({ id: "app.field.deliveryTime" })}
              </label>
              <Input
                name="deliveryTime"
                value={deliveryTime.value}
                onChange={handleFieldChange}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.deliveryTime",
                })}
              />
              {!isPristine && <ErrorMessage field={deliveryTime} />}
            </div>
            <div className={styles.formItem}>
              <label>
                {intl.formatMessage({ id: "app.field.minimumOrderValue" })}
              </label>
              <Input
                name="minOrder"
                value={minOrder.value}
                onChange={handleFieldChange}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.minimumOrderValueGeofence",
                })}
              />
              {!isPristine && <ErrorMessage field={minOrder} />}
            </div>
            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <label>Consider Inventory Quantity?</label>
                <Radio.Group
                  value={consideredQuantity ? 1 : 0}
                  name="consideredQuantity"
                  s
                  onChange={(e) => toggleConsideredQuantity(!!e.target.value)}
                >
                  <Radio value={1}>
                    {intl.formatMessage({ id: "app.field.yes" })}
                  </Radio>
                  <Radio value={0}>
                    {intl.formatMessage({ id: "app.field.no" })}
                  </Radio>
                </Radio.Group>
              </div>
              {!consideredQuantity && (
                <div className={styles.formItem}>
                  <label>Max Quantity</label>
                  <Input
                    name="maxQuantity"
                    type="number"
                    min={1}
                    step={1}
                    defaultValue={5}
                    value={maxQuantity.value}
                    onChange={handleFieldChange}
                    placeholder="Enter Max Quantity"
                  />
                  {!isPristine && <ErrorMessage field={maxQuantity} />}
                </div>
              )}
            </div>

            <div className={styles.mapForm}>
              <div className={styles.mapFormItem}>
                <div className={styles.formItem}>
                  <label>
                    {intl.formatMessage({ id: "app.field.address" })}
                  </label>
                  <StandaloneSearchBox
                    onLoad={handleSearchOnLoad}
                    onPlacesChanged={handleSearchPlaceChange}
                  >
                    <Input
                      name="address"
                      placeholder="Enter Address"
                      value={address.value}
                      disabled={isAddressLoading}
                      onChange={handleFieldChange}
                    />
                  </StandaloneSearchBox>
                  {!isPristine && <ErrorMessage field={address} />}
                </div>
              </div>
              <div className={styles.mapFormItem}>
                <div className={styles.googleMap}>
                  <GoogleMap drawingControl={false} onLoad={handleMapLoad}>
                    {latLng.lat !== null && (
                      <Marker
                        draggable={true}
                        onDragEnd={handleMarkerDrag}
                        position={latLng}
                        onLoad={handleMarkerLoad}
                      />
                    )}
                  </GoogleMap>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Panel>
    </CustomModal>
  );
}

export default StoresModal;
