import React, { useState, useEffect } from "react";
import "./driver.scss";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import {
  Header,
  CustomImage,
  CustomTable,
  CustomTag,
  Label,
} from "../../components/common";
import {
  Select,
  Avatar,
  Table,
  Menu,
  Tooltip,
  Popconfirm,
  Button,
  Dropdown,
} from "antd";
import axios from "../../shared/api/axiosConfig";
// import foodAxios from "../../shared/api/foodConfig";
import {
  UserOutlined,
  FormOutlined,
  DeleteOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { toastr } from "react-redux-toastr";
import { Panel, Icon } from "../../components/common";
import { checkRtl } from "../../shared/utils/rtl";
import {
  // handleImageError,
  removeEmptyKeys,
} from "../../shared/utils/object-manipulation";
import AddAgent from "./components/addAgent";
import { useBranch } from "../../hooks";
// import { verticles, getField } from "../../shared/constants/dropdown";

const styles = {
  wrapper: "driver__wrapper",
  menu: "custom-menu",
  leftMenu: "driver__menu--left",
  menuItem: "driver__menu-item",
  rightMenu: "driver__menu--right",
  content: "custom-section",
  tableActions: "custom-table__table-actions",
  actionIcon: "custom-table__action-icon",
};
const TRANSPORT_TYPES_MAPPING = {
  0: "Bike",
  1: "Truck",
};

const { Option } = Select;

function DriverContainer(props) {
  const intl = useIntl();
  const [tableControls, updateTableControls] = useState({
    isTableLoading: false,
    isTableEmpty: true,
  });
  const [drivers, updateDrivers] = useState({
    selected: "",
    data: [],
    isLoading: false,
    notFound: false,
  });
  const { stores, updateStores } = useBranch();
  const [modalControls, updateModalControls] = useState({
    data: {},
    type: "",
    isVisible: false,
    isDisableModalVisible: false,
    isDeleteModalVisible: false,
  });
  const handleDriverDelete = (item) => {
    console.log(item.driverId);
    const data = {
      driverId: item.driverId,
      isDeleted: 1,
    };

    axios
      .put("/updateDriversToGoodFleetsTeam", removeEmptyKeys(data))
      .then((res) => {
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getDriversList();
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        // updateModalControls({
        //   ...modalControls,
        //   isDeleteModalVisible: false,
        // });
      });
  };

  const getDriversList = () => {
    console.log(stores.selected, "fwefwfwe");
    updateTableControls({
      isTableEmpty: true,
      isTableLoading: true,
    });
    axios
      .get(`/getDrivers`, {
        params: removeEmptyKeys({
          branchId: props.panelType == 0 ? stores.selected : "",
        }),
      })
      .then((res) => {
        if (res.data.data.data.driverData) {
          updateDrivers({
            ...drivers,
            selected: "",
            data: res.data.data.data.driverData,
            isLoading: false,
            notFound: false,
            count: res.data.data.data.allDrivers,
          });
          updateTableControls({
            isTableEmpty: false,
            isTableLoading: false,
          });
        } else {
          toastr.error(
            "Error",
            `${intl.formatMessage({ id: "app.message.driversNotFound" })}`
          );
          updateDrivers({
            ...drivers,
            selected: "",
            data: [],
            isLoading: false,
            notFound: true,
            count: 0,
          });
          updateTableControls({
            isTableEmpty: true,
            isTableLoading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        updateTableControls({
          isTableEmpty: true,
          isTableLoading: false,
        });
        //   toastr.error("Error", "Drivers not found");
        updateDrivers({
          ...drivers,
          selected: "",
          data: [],
          isLoading: false,
          notFound: true,
          count: 0,
        });
      });
  };
  useEffect(() => {
    if (stores.selected) {
      getDriversList();
    }
  }, [stores.selected]);
  const handleStoreChange = (store) => {
    updateStores(store);
  };
  const handleModalClose = () => {
    updateModalControls({
      ...modalControls,
      isVisible: false,
      isDeleteModalVisible: false,
    });
  };
  const handleDriverAdd = (data) => {
    data.deviceType = "WEB";
    // data.appVersion="1.0.0";
    data.enterpriceReferenceId = "c0367056fc679a370b5b453128c09f20";
    if(props.panelType == 0){
      data.branchId = stores.selected;
    }
    console.log("add called", data);
    updateTableControls({
      isTableEmpty: true,
      isTableLoading: false,
    });
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });

    axios
      .post("/addDriversToGoodFleetsTeam", removeEmptyKeys(data))
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isVisible: false,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getDriversList();
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateModalControls({
          ...modalControls,
          isLoading: false,
        });
      });
  };
  const openAddDriverModal = () => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      type: "ADD",
    });
  };
  const openEditListModal = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      data,
      type: "EDIT",
    });
  };
  const handleDriverEdit = (data) => {
    updateTableControls({
      isTableEmpty: true,
      isTableLoading: false,
    });
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });

    data.driverId = modalControls.data.driverId;
    // delete data.password;
    axios
      .put("/updateDriversToGoodFleetsTeam", removeEmptyKeys(data))
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isVisible: res.data.statusCode !== 200,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getDriversList();
        } else {
          toastr.error("Error", res.data.message);
          updateModalControls({
            ...modalControls,
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateModalControls({
          ...modalControls,
          isLoading: false,
        });
      });
  };

  const getMenu = (item) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            openEditListModal(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.edit" })}
        </Menu.Item>

        <Menu.Item
          // onClick={() => {
          //   handleDriverDelete(item, "delete");
          // }}
        >
        <Popconfirm
          title={intl.formatMessage({
            id: "app.message.deleteWarning",
          })}
          onConfirm={() => {
            handleDriverDelete(item, "delete");
          }}
          placement="topRight"
          okText={intl.formatMessage({ id: "app.field.yes" })}
          cancelText={intl.formatMessage({ id: "app.field.no" })}
        >
          {intl.formatMessage({ id: "app.field.delete" })}
        </Popconfirm>
        </Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      title: `${intl.formatMessage({ id: "app.field.id" })}`,
      dataIndex: "id",
      render: (text) => <a>#{text}</a>,
    },
    {
      title: `${intl.formatMessage({ id: "app.field.profile" })}`,
      dataIndex: "image",
      render: (item) => (
        <div>
          {item.profilePicURL ? (
            <img
              style={{ width: "80px", height: "70px" }}
              src={item.profilePicURL}
              alt="profile_image"
            />
          ) : (
            <Avatar
              size={48}
              style={{
                backgroundColor: "#f4f8fd",
                color: "#5d3df0",
                marginLeft: "5px",
              }}
              icon={<UserOutlined />}
            />
          )}
        </div>
      ),
    },
    {
      title: `${intl.formatMessage({ id: "app.field.name" })}`,
      dataIndex: "name",
    },
    {
      title: `${intl.formatMessage({ id: "app.field.email" })}`,
      dataIndex: "email",
    },
    {
      title: `${intl.formatMessage({ id: "app.field.phoneNumber" })}`,
      dataIndex: "phone",
    },
    {
      title: `${intl.formatMessage({ id: "app.field.address" })}`,
      dataIndex: "address",
    },
    {
      title: `${intl.formatMessage({ id: "app.field.transportType" })}`,
      dataIndex: "transportType",
    },
    {
      title: intl.formatMessage({ id: "app.field.action" }),
      dataIndex: "",
      fixed: "right",
      render: (item) => {
        // debugger;
        return (
          <Dropdown overlay={getMenu(item.action)}>
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];
  const dataSource = () => {
    let driversList = [];
    drivers &&
      drivers.data.forEach((el, index) => {
        driversList.push({
          key: index,
          id: index + 1,
          image: el,
          name: el.firstName + " " + el.lastName,
          email: el.email,
          address: el.address,
          phone: el.phoneNo,
          transportType:
            el.transportType >= 0
              ? TRANSPORT_TYPES_MAPPING[el.transportType]
              : "None",
          action: el,
        });
      });
    return driversList;
  };
  return (
    <div className={styles.wrapper}>
      <AddAgent
        isVisible={modalControls.isVisible}
        onClose={handleModalClose}
        isLoading={modalControls.isLoading}
        onCloseIconClick={handleModalClose}
        addDriver={handleDriverAdd}
        selectedCountry={props.selectedCountry}
        selectedCities={props.selectedCities}
        editDriver={handleDriverEdit}
        modalToShow={modalControls.type}
        data={modalControls.data}
      />
      <Header title="app.pageTitle.drivers" />
      <div className={styles.content}>
        {stores.isVisible && (
          <div className={styles.menu}>
            <header>
              <div className={styles.menuItem}>
                <Label value="app.placeholder.store" horizontal />
                <Select
                  style={{ width: 180 }}
                  placeholder={intl.formatMessage({
                    id: "app.placeholder.store",
                  })}
                  onChange={handleStoreChange}
                  value={stores.selected}
                >
                  {stores.data.map((store) => (
                    <Option key={store.branchId} value={store.branchId}>
                      {store.branchName}
                    </Option>
                  ))}
                </Select>
              </div>
            </header>
            <section>
              {" "}
              <Button
                onClick={() => openAddDriverModal()}
                shape={undefined}
                disabled={props.isAddDisabled}
                size="medium"
                type="primary"
              >
                {intl.formatMessage({ id: "app.button.addDriver" })}
              </Button>
            </section>
          </div>
        )}
        <CustomTable
          columns={columns}
          isLoading={tableControls.isTableLoading}
          isEmpty={tableControls.isTableEmpty}
          dataSource={dataSource()}
          // pagination={{ position: ["bottomCenter"] }}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  selectedCountry: state.user.selectedCountry,
  selectedCities: state.user.selectedCities,
  branchId: state.user.userInfo.branchId,
  panelType: state.user.enterprice.panelType,
  isAddDisabled: state.user.isAddDisabled,
  isRTL: checkRtl(state.user.locale),
});
export default connect(mapStateToProps)(DriverContainer);
