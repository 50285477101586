import React, { useEffect, useState } from "react";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import {  removeEmptyKeys } from "../../../shared/utils/object-manipulation";
import axios from "../../../shared/api/axiosConfig";
import { CustomModal, Panel, ErrorMessage } from "../../../components/common";
import {Tag} from 'antd';
import "./view-user.scss";

import sidebarData from "../../../shared/constants/sidebar-data.json";

const styles = {
  wrapper: "view-user__wrapper",
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  label: "view-user__label",
  value: "view-user__value",
  row: "view-user__row",
};
function ViewUser(props) {
  const intl = useIntl();

  const addStatusToSidebarRoles = (lsRoles) => {
    lsRoles = lsRoles.reduce((acc, val) => {
      if (val.status) {
        acc.push(val.menuId);
      }
      return acc;
    }, []);
    return sidebarData.map((i) => {
      if (lsRoles.indexOf(i.menuId) !== -1) {
        i.status = 1;
      } else {
        i.status = 0;
      }
      return i;
    });
  };

  const getRoles = () => {
      if(!props.data.adminroles) {
          return [];
      }
    const allRoles = props.data.adminroles.reduce((acc, val) => {
        if(val.status === 1) {
          acc.push(val.menuId);
        }
        return acc;
    }, []);

    return sidebarData.filter(i => allRoles.indexOf(i.menuId) !== -1)
  };
  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={props.onCloseIconClick}
      header="app.pageTitle.viewSubAdmin"
      isVisible={props.isVisible}
    >
      <Panel className={styles.formModal}>
        <div className={styles.wrapper}>
          <div className={styles.row}>
            <div className={styles.label}>
              {intl.formatMessage({ id: "app.field.name" })}
            </div>
            <div className={styles.value}>{props.data.name}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>
              {intl.formatMessage({ id: "app.field.email" })}
            </div>
            <div className={styles.value}>{props.data.email}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>
              {intl.formatMessage({ id: "app.field.userType" })}
            </div>
            <div className={styles.value}>{props.data.isSuperAdmin === 1 ? "Super Admin" : "Sub Admin"}</div>
          </div>
 
          <div className={styles.row}>
            <div className={styles.label}>
              {intl.formatMessage({ id: "app.field.countryCode" })}
            </div>
            <div className={styles.value}>{props.data.countryCode}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>
              {intl.formatMessage({ id: "app.field.phoneNumber" })}
            </div>
            <div className={styles.value}>{props.data.phoneNo}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>
              {intl.formatMessage({ id: "app.field.privilages" })}
            </div>
            {console.log(getRoles())}
          </div>
          <div>{getRoles().map(i => (
      <Tag>
          {intl.formatMessage({id: i.name})}
          
          </Tag>
  ))}</div>
        </div>
      </Panel>
    </CustomModal>
  );
}

export default ViewUser;
