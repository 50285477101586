import React, { useState } from "react";
import { Menu, Dropdown, Card, Button, Tooltip } from "antd";
import { useIntl } from "react-intl";
import {
  EditOutlined,
  EllipsisOutlined,
  DeleteOutlined,
  FolderOpenOutlined,
} from "@ant-design/icons";

import "./category-card.scss";
import placeholderImg from "../../../../assets/images/item-placeholder.png";
import { Icon, CustomImage } from "../../../../components/common";
const styles = {
  wrapper: "categories__category-list__card__wrapper",
  imageContainer: "categories__category-list__card__image-container",
  footer: "categories__category-list__card__footer",
  footerLeft: "categories__category-list__card__footer__left",
  footerRight: "categories__category-list__card__footer__right",
  shadow: "categories__category-list__card__shadow",
};
const { Meta } = Card;

function CategoryCard(props) {
  // const {intl} = useIntl();
  const handleImageError = (e) => {
    e.target.src = placeholderImg;
  };

  const [isExpanded, expandCard] = useState(false);
  const toggleExpand = () => {
    expandCard(!isExpanded);
  };
  const handleMenuItemClick = (action, a, b) => {
    // debugger;
    switch (action) {
      case "view":
        props.viewItem(props.data);
        return;
      case "edit":
        props.editItem(props.data);
        return;
      case "delete":
        props.deleteItem(props.data);
        return;
      default:
        return;
    }
  };
  const menu = (
    <Menu onClick={handleMenuItemClick}>
      <Menu.Item key="view">View</Menu.Item>
      <Menu.Item key="edit">Edit</Menu.Item>
      <Menu.Item key="delete">Delete</Menu.Item>
    </Menu>
  );
  if (props.isAdd) {
    return (
      <Card
        hoverable
        style={{ width: 250, height: "100%", margin: "0.5rem" }}
        className={`${styles.shadow} ${props.disabled ? "disabled" : ""}`}
        cover={
          <CustomImage
            onClick={props.onClick}
            className={`cat-card-custom-add ${
              props.disabled ? "disabled" : ""
            }`}
            icon="ADD_CAT"
            // alt={props.data.categoryName}
            src={null}
          />
        }
      >
        <Meta
          style={{ textAlign: "center" }}
          // avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
          title={props.title}
          // description={props.data.description}
        />
      </Card>
    );
  }

  const getDesc = () => {
    if (!props.data.description) {
      return "---";
    }

    if (props.data.description.length <= 40) {
      return props.data.description;
    } else if (isExpanded) {
      return props.data.description;
    } else {
      return (
        <>
          {`${props.data.description.slice(0, 37)}...`}
          <code>See More</code>
        </>
      );
    }
  };
  const getTitle = () => {
    if (props.data.categoryName.length <= 25) {
      return props.data.categoryName;
    } else {
      return (
        <Tooltip title={props.data.categoryName}>
          {props.data.categoryName}
        </Tooltip>
      );
    }
  };

  return (
    <Card
      hoverable
      style={{ width: 250, margin: "0.5rem" }}
      className={styles.shadow}
      cover={
        <CustomImage
          onClick={() => {
            handleMenuItemClick("view");
          }}
          alt={props.data.categoryName}
          src={props.data.categoryImage}
        />
      }
      actions={[
        <Tooltip title="View">
          <FolderOpenOutlined
            onClick={() => {
              handleMenuItemClick("view");
            }}
            key="view"
            value="view"
          />
        </Tooltip>,
        <Tooltip title="Edit">
          <EditOutlined
            onClick={() => {
              handleMenuItemClick("edit");
            }}
            key="edit"
            value="edit"
          />
        </Tooltip>,
        <Tooltip title="Delete">
          <DeleteOutlined
            onClick={() => {
              handleMenuItemClick("delete");
            }}
            key="delete"
            value="delete"
          />
        </Tooltip>,
      ]}
    >
      <Meta
        // avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
        title={getTitle()}
        description={getDesc()}
        onClick={toggleExpand}
      />
    </Card>
  );
  return (
    <div className={styles.wrapper}>
      <div className={styles.imageContainer}>
        <img
          src={
            props.data.categoryImage ? props.data.categoryImage : placeholderImg
          }
          alt={props.data.categoryName}
          onError={handleImageError}
        />
      </div>
      <div className={styles.footer}>
        <div className={styles.footerLeft}>{props.data.categoryName}</div>
        <Dropdown overlay={menu}>
          <div className={styles.footerRight}>
            <Icon name="MENU" size="small" />
          </div>
        </Dropdown>
      </div>
    </div>
  );
}
export default CategoryCard;
