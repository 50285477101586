import React, { useState } from "react";
import { Popconfirm, Tooltip, Switch } from "antd";
import { useIntl } from "react-intl";
import classNames from "classnames";
import moment from "moment";

import { Panel, Icon } from "../../../../components/common";
import "./store-categories-table.scss";
import placeholderImg from "../../../../assets/images/item-placeholder.png";

const styles = {
  table: "custom-table",
  tableActions: "custom-table__table-actions",
  actionIcon: "custom-table__action-icon",
  panelContainer: "store-categories-table__panel-container",
  image: "store-categories-table__image",
};

const handleImageError = (e) => {
  e.target.src = placeholderImg;
};

function StoreCategoriesTable(props) {
  const intl = useIntl();

  return (
    <Panel
      isLoading={props.isLoading}
      isEmpty={props.isEmpty}
      emptyText={intl.formatMessage({ id: "app.field.noData" })}
      containerClass={styles.panelContainer}
    >
      <table className={styles.table}>
        <tbody>
          <tr>
            <th>{intl.formatMessage({ id: "app.field.index" })}</th>
            <th>{intl.formatMessage({ id: "app.field.image" })}</th>
            <th>{intl.formatMessage({ id: "app.field.categoryName" })}</th>
            <th>{intl.formatMessage({ id: "app.field.rank" })}</th>
            <th>{intl.formatMessage({ id: "app.field.action" })}</th>
          </tr>
          {props.data.map((item, index) => (
            <tr key={item.superCatId}>
              <td>{index + 1 + props.recordsPerPage * (props.currentPage - 1)}</td>
              <td>
                {" "}
                <img
                  src={item.categoryImage ? item.categoryImage : placeholderImg}
                  onError={handleImageError}
                  className={styles.image}
                />
              </td>
              <td>{item.categoryName}</td>
              <td>{item.priority}</td>
              <td>
                <div className={styles.tableActions}>
                  <Tooltip title={intl.formatMessage({ id: "app.field.edit" })} placement="top">
                    <span
                      onClick={() => {
                        props.editItem(item);
                      }}
                      className={styles.actionIcon}
                    >
                      <Icon name="PEN" size="small" />
                    </span>
                  </Tooltip>
                  <Tooltip title={intl.formatMessage({ id: "app.field.delete" })} placement="top">
                    <Popconfirm
                      title={intl.formatMessage({
                        id: "app.message.deleteWarning",
                      })}
                      onConfirm={() => {
                        props.deleteItem(item, true);
                      }}
                      placement="topRight"
                      okText={intl.formatMessage({ id: "app.field.yes" })}
                      cancelText={intl.formatMessage({ id: "app.field.no" })}
                    >
                      <span className={styles.actionIcon}>
                        <Icon name="TRASH" size="small" />
                      </span>
                    </Popconfirm>
                  </Tooltip>
                  <Tooltip
                    title={item.isEnabled ? `${intl.formatMessage({ id: "app.field.disable" })}` : `${intl.formatMessage({ id: "app.field.enable" })}`}
                    placement="top"
                  >
                    <span>
                      <Switch
                        checked={item.isEnabled}
                        onChange={() => {
                          props.toggleItem(item);
                        }}
                      />
                    </span>
                  </Tooltip>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Panel>
  );
}

export default StoreCategoriesTable;
