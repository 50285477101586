import React from "react";
import {useIntl} from "react-intl";
import Pagination from 'react-js-pagination';
import "./pagination-info.scss";

const styles = {
  wrapper: "pagination-info__wrapper",
  info: "pagination-info__info",
};

function PaginationInfo(props) {
  const intl = useIntl();
  if(!props.totalRecords) {
    return null;
  }
  return (
    <div className={styles.wrapper}>
      <div>
      <Pagination
            activePage={props.currentPage}
            itemsCountPerPage={props.recordsPerPage}
            totalItemsCount={props.totalRecords}
            pageRangeDisplayed={5}
            onChange={props.onChange}
            prevPageText="‹"
            nextPageText="›"
          />
      </div>
      <div className={styles.info}>
      {intl.formatMessage({ id: "app.field.showing" })} {(props.currentPage - 1) * props.recordsPerPage + 1} {intl.formatMessage({ id: "app.field.to" })} {props.currentPage * props.recordsPerPage > props.totalRecords
        ? props.totalRecords
        : props.currentPage * props.recordsPerPage}<span> </span>
      {intl.formatMessage({ id: "app.field.of" })} {props.totalRecords}
      </div>
    </div>
  );
}

export default PaginationInfo;
