import React, { useState, useEffect } from "react";
import { toastr } from "react-redux-toastr";
import { useIntl } from "react-intl";

import { GeofenceMap, GeofenceEditForm } from "./components";
import { BackButton, Panel, Label } from "../../components/common";
import "./geofence-edit.scss";
import { Header } from "../../components/common";
import axios from "../../shared/api/axiosConfig";
import { getBaseURL } from "../../shared/constants/dropdown";

const styles = {
  wrapper: "geofence-edit__wrapper",
  mapContainer: "geofence-edit__map-container",
  formContainer: "geofence-edit__form-container",
  content: "custom-section",
};

const connectPolygon = (paths) => {
  const [firstLng, firstLat] = paths[0];
  const [lastLng, lastLat] = paths[paths.length - 1];
  if (firstLat !== lastLat || firstLng !== lastLng) {
    paths.push([firstLng, firstLat]);
  }
};

function GeofenceEdit(props) {
  const intl = useIntl();
  const [stores, updateStores] = useState({
    data: [],
    isLoading: true,
    notFound: false,
  });

  const [isFormLoading, changeIsFormLoading] = useState(false);

  const [geofences, updateGeofences] = useState({
    isLoading: true,
    notFound: false,
    data: [],
  });
  const [newGeofenceRef, updateNewGeofenceRef] = useState(null);

  const handleGeofenceChange = (geoFence) => {
    updateNewGeofenceRef(geoFence);
  };

  const handleSubmit = (data) => {
    if (newGeofenceRef) {
      const polyArray = newGeofenceRef.getPath().getArray();
      let paths = [];
      polyArray.forEach(function (path) {
        paths.push([path.lng(), path.lat()]);
      });
      connectPolygon(paths);
      data.geofenceId = props.match.params.editId * 1;
      data.geoLongLat = paths;
      changeIsFormLoading(true);

      axios
        .put("/editGeoFenceOfBranch", data)
        .then((res) => {
          changeIsFormLoading(false);
          if (res.data.statusCode === 200) {
            toastr.success(res.data.message);
            props.history.push(getBaseURL("/geofence"));
          } else {
            toastr.error("Error", res.data.message);
          }
        })
        .catch((err) => {
          changeIsFormLoading(false);
          // toastr.error(
          //   "Error",
          //   intl.formatMessage({ id: "app.message.error" })
          // );
        });
    } else {
      toastr.warning("Please create a geofence");
    }
  };

  const getBranches = () => {
    updateStores({
      ...stores,
      isLoading: true,
      data: [],
    })
    axios
      .get("/getBranches", {
        params: {
          onlyNames: 1,
        },
      })
      .then((res) => {
        if (res.data.data.branchesData.length) {
          updateStores({
            ...stores,
            data: res.data.data.branchesData,
            isLoading: false,
            notFound: false,
          });
        } else {
          updateStores({
            ...stores,
            data: [],
            isLoading: false,
            notFound: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        updateStores({
          ...stores,
          data: [],
          isLoading: false,
          notFound: true,
        });
      });
  };

  const getGeofences = () => {
    updateGeofences({
      isLoading: true,
      data: [],
    })
    axios
      .get("/getAllGeoFenceOfBranch")
      .then((res) => {
        if (res.data.data.length) {
          const data = res.data.data.reduce((acc, val) => {
            val.latLngArray = val.geoLongLat[0].reduce((coords, pair) => {
              coords.push({
                lat: pair.y,
                lng: pair.x,
              });
              return coords;
            }, []);
            acc.push(val);
            return acc;
          }, []);
          console.log(data);
          updateGeofences({
            data,
            isLoading: false,
            notFound: false,
          });
        } else {
          updateStores({
            data: [],
            isLoading: false,
            notFound: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        updateStores({
          data: [],
          isLoading: false,
          notFound: true,
        });
      });
  };
  useEffect(() => {
    // console.log([undefined,getBaseURL("/geofence").split('/').slice(0, -2).join('/')])
    getBranches();
    getGeofences();
  }, []);
  return (
    <div className={styles.wrapper}>
      <Header  key="geofenceEdiegn" crumbs={[undefined,getBaseURL("/geofence")]} title="app.pageTitle.editGeofence" />

      <Panel isLoading={stores.isLoading || geofences.isLoading} loaderStyle={{height: '65vh'}} className={styles.content}>
        <div className={styles.mapContainer}>
          <GeofenceMap
            handleGeofenceChange={handleGeofenceChange}
            stores={stores}
            geofences={geofences}
            selectedStore={props.match.params.storeId}
            editId={props.match.params.editId}
          />
        </div>

        <div className={styles.formContainer}>
          <GeofenceEditForm
            isLoading={geofences.isLoading || isFormLoading}
            data={geofences.data.filter(
              (data) => data.geofenceId == props.match.params.editId
            )}
            handleSubmit={handleSubmit}
          />
        </div>
      </Panel>
    </div>
  );
}

export default GeofenceEdit;
