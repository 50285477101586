import React, { useState, useEffect } from "react";
import { Select, Button } from "antd";
import { useIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import { CaretDownOutlined, SearchOutlined } from "@ant-design/icons";

import { DelayedInput } from "../../components/common";
import axios from "../../shared/api/axiosConfig";
import {
  Icon,
  Placeholder,
  ConfirmModal,
  CustomImage,
} from "../../components/common";
import "./category-details.scss";
import {
  SubCategoryCard,
  AddEditSubCategory,
  CategoryCard,
} from "./components";
import { removeEmptyKeys } from "../../shared/utils/object-manipulation";
import placeholderImg from "../../assets/images/item-placeholder.png";

const styles = {
  wrapper: "category-details__wrapper",
  content: "category-details__content",
  count: "category-details__count",
  menu: "category-details__menu",
  ppwrapper: "category-details__pp-wrapper",
  section: "custom-section",
  categoryInfo: {
    wrapper: "category-details__category-info__wrapper",
    image: "category-details__category-info__image",
    name: "category-details__category-info__name",
    description: "category-details__category-info__description",
    content: "category-details__category-info__content",
  },
  subCategories: {
    wrapper: "category-details__sub-categories__wrapper",
    menu: "category-details__sub-categories__menu",
    menuLeft: "category-details__sub-categories__menu--left",
    menuRight: "category-details__sub-categories__menu--right",
    content: "category-details__sub-categories__content",
  },
};

const { Option } = Select;

function CategoryDetails(props) {
  const intl = useIntl();

  const [search, updateSearch] = useState("");
  const handleSearchChange = (val) => {
    updateSearch(val);
  };

  const handleImageError = (e) => {
    e.target.src = placeholderImg;
  };

  const [subCategories, updateSubCategories] = useState({
    selected: "",
    selectedData: {},
    isLoading: false,
    data: [],
    notFound: false,
    count: 0,
  });

  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    isDeleteModalVisible: false,
    data: {},
    type: "",
  });

  const handleCategoryChange = (categoryId) => {
    const [category] = props.allCategories.filter(
      (cat) => cat.categoryId === categoryId
    );
    props.changeCategory(category);
  };

  const getSubCategories = () => {
    updateSubCategories({
      ...subCategories,
      isLoading: true,
      notFound: false,
    });
    axios
      .get("/listCatOrSubCategories", {
        params: {
          branchId: props.storeId,
          parentCategoryId: props.selectedCategory,
        },
      })
      .then((res) => {
        if (res.data.data.count) {
          updateSubCategories({
            ...subCategories,
            data: res.data.data.subcategories,
            count: res.data.data.count,
            selected: "",
            selectedData: {},
            isLoading: false,
            notFound: false,
          });
        } else {
          updateSubCategories({
            ...subCategories,
            data: res.data.data.subcategories,
            count: res.data.data.count,
            selected: "",
            selectedData: {},
            isLoading: false,
            notFound: true,
          });
        }
      })
      .catch((err) => {
        // console.log("fucc", err);
      });
  };

  const handleSubCategoryAdd = (data) => {
    data.branchId = props.storeId;
    data.parentCategoryId = props.selectedCategory;
    // data.adminId = 1;
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });

    axios
      .post("/addCatorSubCategory", data)
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isVisible: res.data.statusCode !== 200,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getSubCategories();
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateModalControls({
          ...modalControls,
          isLoading: false,
        });
      });
  };

  const handleSubCategoryEdit = (data) => {
    data.categoryId = modalControls.data.categoryId;
    data.branchId = props.storeId;
    data.parentCategoryId = props.selectedCategory;
    // data.adminId = 1;
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });

    axios
      .post("/updateCatorSubCategory", data)
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isVisible: res.data.statusCode !== 200,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getSubCategories();
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateModalControls({
          ...modalControls,
          isLoading: false,
        });
      });
  };

  const handleSubCategoryDelete = () => {
    // console.log(modalControls.data._id);
    const data = {
      branchId: props.storeId,
      parentCategoryId: props.selectedCategory,
      categoryId: modalControls.data.categoryId,
      isDeleted: 1,
    };
    // data.adminId = 1;
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });

    axios
      .post("/updateCatorSubCategory", data)
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isDeleteModalVisible: res.data.statusCode !== 200,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getSubCategories();
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateModalControls({
          ...modalControls,
          isDeleteModalVisible: false,
        });
      });
  };

  const openEditSubCategoryModal = (subCategoryData) => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      type: "EDIT_SUBCATEGORY",
      data: subCategoryData,
    });
  };

  const openAddSubCategoryModal = () => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      type: "ADD_SUBCATEGORY",
      data: {},
    });
  };

  const openDeleteSubCategoryModal = (subCategory) => {
    updateModalControls({
      ...modalControls,
      isVisible: false,
      isDeleteModalVisible: true,
      type: "",
      data: subCategory,
    });
  };

  const handleModalClose = () => {
    updateModalControls({
      isVisible: false,
      data: {},
      type: "",
    });
  };

  const filteredData = () => {
    return subCategories.data.filter((i) => {
      if (search === "") {
        return true;
      } else {
        return i.categoryName.toLowerCase().includes(search.toLowerCase());
      }
    });
  };

  const handleSearch = () => {};

  useEffect(() => {
    getSubCategories();
  }, [props.selectedCategory, props.selectedCategory]);

  return (
    <div className={styles.wrapper}>
      {true && (
        <AddEditSubCategory
          isVisible={modalControls.isVisible}
          onClose={handleModalClose}
          onCloseIconClick={handleModalClose}
          modalToShow={modalControls.type}
          isRTL={props.isRTL}
          data={modalControls.data}
          addSubCategory={handleSubCategoryAdd}
          editSubCategory={handleSubCategoryEdit}
          isLoading={modalControls.isLoading}
        />
      )}
      <ConfirmModal
        header={`Delete ${modalControls?.data?.categoryName}?`}
        message={intl.formatMessage({
          id: "app.message.deleteSubCategoryWarning",
        })}
        onCloseIconClick={handleModalClose}
        onAction={handleSubCategoryDelete}
        isVisible={modalControls.isDeleteModalVisible}
        actionText="Delete"
        isLoading={modalControls.isLoading}
      />
      <div className={styles.content}>
        <div className={styles.ppwrapper}>
          <div className={styles.menu}>
            <Icon onClick={props.handleBack} name="LEFT_ARROW" size="small" />
            <span className={styles.count}>{`${intl.formatMessage({
              id: "app.field.categories",
            })} (${props.count}):`}</span>
            <Select
              bordered={false}
              onChange={handleCategoryChange}
              value={props.selectedCategory}
            >
              {props.allCategories.map((cat) => (
                <Option key={cat.categoryId} value={cat.categoryId}>
                  {cat.categoryName}
                </Option>
              ))}
            </Select>
          </div>
          <div className={`${styles.categoryInfo.wrapper}`}>
            <CustomImage
              style={{ width: "200px" }}
              src={props.selectedCategoryData.categoryImage}
              alt={props.selectedCategoryData.categoryName}
            />

            <div
              dir={props.isRTL ? "rtl" : "ltr"}
              className={styles.categoryInfo.content}
            >
              {/* <div className={styles.subCategories.menuRight}>
              <Button
                onClick={openAddSubCategoryModal}
                type="primary"
                disabled={props.isAddDisabled}
                shape={undefined}
              >
                {intl.formatMessage({ id: "app.placeholder.addSubCategory" })}
              </Button>
            </div> */}
              <div className={styles.categoryInfo.name}>
                {props.selectedCategoryData.categoryName}
              </div>
              <div className={styles.categoryInfo.description}>
                <p>
                  <b>{intl.formatMessage({ id: "app.field.description" })}</b>
                </p>
                <p>{props.selectedCategoryData.description}</p>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.subCategories.wrapper}>
          {!subCategories.isLoading && (
            <div className={styles.subCategories.content}>
              <CategoryCard
                color={props.color}
                isAdd={true}
                disabled={props.isAddDisabled}
                onClick={openAddSubCategoryModal}
                title={intl.formatMessage({
                  id: "app.placeholder.addSubCategory",
                })}
              />
              {filteredData().map((item) => (
                <SubCategoryCard
                  editItem={openEditSubCategoryModal}
                  deleteItem={openDeleteSubCategoryModal}
                  data={item}
                  key={item.categoryId}
                />
              ))}
            </div>
          )}
          {subCategories.isLoading && (
            <Placeholder type="loading" text="Loading" />
          )}
          {/* {subCategories.notFound && (
            <Placeholder type="empty" text="No Sub-Categories Found" />
          )} */}
        </div>
      </div>
    </div>
  );
}
export default CategoryDetails;
