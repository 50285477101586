import React, { useState } from "react";
import { Popconfirm, Tooltip, Switch, Menu, Dropdown } from "antd";
import {MoreOutlined} from '@ant-design/icons';
import { useIntl } from "react-intl";
import classNames from "classnames";
import moment from "moment";

import { Panel, Icon, CustomTable, CustomTag, CustomImage, Label } from "../../../../components/common";


const styles = {
  table: "custom-table",
  tableActions: "custom-table__table-actions",
  stickyAction: "sticky-action",
  actionIcon: "custom-table__action-icon",
  panelContainer: "active-promo-table__panel-container",
};

function ExpiredPromoTable(props) {
  const intl = useIntl();


  const columns = [
    {
      dataIndex: "",
      // sorter: true,
      title: intl.formatMessage({ id: "app.field.sNo" }),
      fixed: 'left',
      render: (e, item, index) => {
        return (
          <span>
            {props.pagination.recordsPerPage * (props.pagination.currentPage - 1) + index + 1}
          </span>
        );
      },
    },
    {
      fixed: "left",
      dataIndex: "code",
      title: intl.formatMessage({ id: "app.field.promoCode" }),
    },
    {
      dataIndex: "imageUrl",
      title: intl.formatMessage({ id: "app.field.image" }),
      render: (item) => <CustomImage src={item} isTable />
    },
    {
      
      title: intl.formatMessage({ id: "app.field.discount" }),
      render: (item) => item.promoType == 2 ? `${item.credits} (% off)` : item.credits
    },
    {
      dataIndex: "branchName",
      title: intl.formatMessage({ id: "app.field.applicableFor" }),
      render: (item) => item ? item : intl.formatMessage({id: "app.field.allStores"})
    },
  
    {
      dataIndex: "perUser",
      title: intl.formatMessage({ id: "app.field.frequencyPerUser" }),
    },
 
    {
      dataIndex: "startDate",
      title: intl.formatMessage({ id: "app.field.startDate" }),
      render: (item) => moment(item).format("MMMM Do YYYY"),
    },

    {
      dataIndex: "endDate",
      title: intl.formatMessage({ id: "app.field.expiryDate" }),
      render: (item) => moment(item).format("MMMM Do YYYY"),
    },
  ];

  return (
      <CustomTable
      dataSource={props.data}
      pagination={props.pagination}
      onChange={props.onChange}
      columns={columns}
      totalRecords={props.totalRecords}
      isLoading={props.isLoading}
       />
  );
}

export default ExpiredPromoTable;
