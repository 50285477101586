import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Button, Input,Popconfirm } from "antd";

import {
  Panel,
  CustomModal,
  ErrorMessage,
} from "../../../../components/common";
import Validations from "../../../../shared/validations/Validations";
const {TextArea}  = Input;
const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
};
function PushNotificationModal(props) {
  const intl = useIntl();
  const closeModal = () => {
    props.onCloseIconClick();
  };

  const [message, changeMessage] = useState(Validations.validateTextField(""));
  const [title, changeTitle] = useState(Validations.validateTextField(''));
  const [isPristine, changeIsPristine] = useState(true);

  const handleMessageChange = (e) => {
    changeMessage(Validations.validateTextField(e.target.value));
  };

  const handleTitleChange = (e) => {
    changeTitle(Validations.validateTextField(e.target.value));
  };

  const handleSubmit = () => {
      changeIsPristine(false)
    if (message.isValid && title.isValid) {
      props.sendNotification({
        "message": message.value,
        "title": title.value,
      });
    }
  };

  const resetForm = () => {
    changeMessage(Validations.validateTextField(""));
    changeTitle(Validations.validateTextField(''));
  };

  const setForm = () => {
    changeMessage(Validations.validateTextField(props.data));
  };

  useEffect(() => {
    if (props.isVisible) {
      changeIsPristine(true);

      if (props.modalType === "EDIT") {
        setForm();
      } else {
        resetForm();
      }
    }
  }, [props.isVisible]);

  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={closeModal}
      header="app.pageTitle.notificationContent"
      isVisible={props.isVisible}
    >
      <Panel isLoading={props.isLoading} className={styles.formModal}>
      <div className={styles.formItem}>
          <label>
            {intl.formatMessage({ id: "app.placeholder.title" })}
          </label>
          <Input
            value={title.value}
            onChange={handleTitleChange}
            placeholder={intl.formatMessage({
              id: "app.placeholder.title",
            })}
          />
          {!isPristine && <ErrorMessage field={title} />}
        </div>
        <div className={styles.formItem}>
          <label>
            {intl.formatMessage({ id: "app.placeholder.message" })}
          </label>
          <TextArea
          rows={5}
            value={message.value}
            onChange={handleMessageChange}
            placeholder={intl.formatMessage({
              id: "app.placeholder.message",
            })}
          />
          {!isPristine && <ErrorMessage field={message} />}
        </div>
        <div className={styles.formAction}>
          <Button onClick={props.onCloseIconClick} shape="">
            {intl.formatMessage({id: 'app.button.cancel'})}
          </Button>
          <Button type="primary" shape="">

          <Popconfirm
                title="Are you sure you want to send notification?"
                onConfirm={() => {
                  handleSubmit();
                }}
                placement="topRight"
                okText={intl.formatMessage({ id: "app.field.yes" })}
                cancelText={intl.formatMessage({ id: "app.field.no" })}
              >
                {intl.formatMessage({id: 'app.button.sendNotification'})}
              </Popconfirm>
          </Button>
        </div>
      </Panel>
    </CustomModal>
  );
}

export default PushNotificationModal;
