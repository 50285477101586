import React, { useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { Select, Dropdown, Button, Menu, Tooltip, Checkbox,Popconfirm } from "antd";
import {
  CaretDownOutlined,
  SearchOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import userActions from "../../redux/user-info/user-actions";
import {
  nestedParseJSON,
  removeEmptyKeys,
  multiSort,
} from "../../shared/utils/object-manipulation";
import { ProductsTable, UpdateStock } from "./components";
import {
  RECORDS_PER_PAGE,
  TABLE_TEXT_LIMIT,
  textTrimmer,
} from "../../shared/constants/dropdown";
import {
  Header,
  Panel,
  Icon,
  PaginationInfo,
  DelayedInput,
  FullPageDrawer,
  CustomImage,
  CustomTable,
  CustomTag,
  Label,
} from "../../components/common";
import { toastr } from "react-redux-toastr";
import axios from "../../shared/api/axiosConfig";
import "./products.scss";
import AddEditProduct from "./add-edit-product/add-edit-product";

const styles = {
  wrapper: "products__wrapper",
  menu: "custom-menu",
  section: "custom-section",
};

const { Option } = Select;

const downloadFile = (filename, text) => {
  const element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

function downloadFileFromLink(filename, link) {
  const element = document.createElement("a");
  element.setAttribute("href", link);
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

function Products(props) {
  const intl = useIntl();
  const [stores, updateStores] = useState({
    selected: undefined,
    data: [],
    notFound: false,
    isLoading: true,
  });

  const csvUploadRef = useRef(null);

  const [search, updateSearch] = useState("");
  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    isDeleteModalVisible: false,
    data: {},
    type: "",
  });

  const [isCSVUploading, changeIsCSVUploading] = useState(false);

  const [categories, updateCategories] = useState({
    selected: undefined,
    data: [],
    notFound: false,
    isLoading: false,
  });

  const [subCategories, updateSubCategories] = useState({
    selected: undefined,
    data: [],
    notFound: false,
    isLoading: false,
  });

  const [brands, updateBrands] = useState({
    selected: "",
    data: [],
    notFound: false,
    isLoading: true,
    count: null,
  });

  const [products, updateProducts] = useState({
    selected: undefined,
    data: [],
    notFound: false,
    isLoading: false,
    count: null,
  });

  const [pagination, updatePagination] = useState({
    recordsPerPage: 10,
    skip: 0,
    currentPage: 1,
    search: "",
  });

  const [tableControls, updateTableControls] = useState({
    isTableLoading: true,
    isTableEmpty: false,
  });

  const openAddProductModal = () => {
    // if (!categories.selected) {
    //   toastr.warning("No category selected", "Please select a category");
    //   return;
    // }
    updateModalControls({
      ...modalControls,
      isVisible: true,
      type: "ADD_PRODUCT",
    });
  };

  const openEditProductModal = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      data,
      type: "EDIT_PRODUCT",
    });
  };

  const handleProductToggle = (product) => {
    const data = {
      branchId: stores.selected,
      productId: product.productId,
      isEnabled: product.isEnabled ? 0 : 1,
      subCategoryIds: product.subCategoryIds.split(","),
    };
    updateProducts({
      ...products,
      data: products.data.map((i) => {
        if (i.productId === product.productId) {
          i.isEnabled = i.isEnabled ? 0 : 1;
        }
        return i;
      }),
    });
    axios
      .put("/updateProduct", data)
      .then((res) => {
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
        } else {
          toastr.error(res.data.message);
          updateProducts({
            ...products,
            data: products.data.map((i) => {
              if (i.productId === product.productId) {
                i.isEnabled = i.isEnabled ? 0 : 1;
              }
              return i;
            }),
          });
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateProducts({
          ...products,
          data: products.data.map((i) => {
            if (i.productId === product.productId) {
              i.isEnabled = i.isEnabled ? 0 : 1;
            }
            return i;
          }),
        });
      });
  };

  const closeModal = () => {
    updateModalControls({
      isVisible: false,
      isDeleteModalVisible: false,
      data: {},
    });
  };

  const handleStoreChange = (storeId) => {
    updateStores({
      ...stores,
      selected: storeId,
    });

    updateCategories({
      ...categories,
      selected: undefined,
      data: [],
    });
    updateSubCategories({
      ...subCategories,
      selected: undefined,
      data: [],
    });
    updateBrands({
      ...brands,
      selected: undefined,
    });

    // updateProducts({
    //   ...products,
    //   data: [],
    // });

    updateTableControls({
      ...tableControls,
      isTableLoading: true,
      isTableEmpty: false,
    });

    updatePagination({
      ...pagination,
      currentPage: 1,
      skip: 0,
    });
  };

  const handleCategoryChange = (categoryId) => {
    updateCategories({
      ...categories,
      selected: categoryId,
    });
    updateSubCategories({
      ...subCategories,
      selected: undefined,
      data: [],
      isLoading: true,
      notFound: false,
    });
    updateBrands({
      ...brands,
      selected: "",
    });
    // updateProducts({
    //   ...products,
    //   data: [],
    // });
    updateTableControls({
      ...tableControls,
      isTableLoading: true,
      isTableEmpty: false,
    });
    updatePagination({
      ...pagination,
      currentPage: 1,
      skip: 0,
    });
  };

  const handleSubCategoryChange = (subCategoryId) => {
    updateSubCategories({
      ...subCategories,
      selected: subCategoryId,
    });
    updateProducts({
      ...products,
      // data: [],
      isLoading: true,
      notFound: false,
    });
    updateBrands({
      ...brands,
      selected: "",
    });
    updateTableControls({
      ...tableControls,
      isTableLoading: true,
      isTableEmpty: false,
    });
    updatePagination({
      ...pagination,
      currentPage: 1,
      skip: 0,
    });
  };

  const handleBrandChange = (brandId) => {
    updateBrands({
      ...brands,
      selected: brandId,
    });
    updateProducts({
      ...products,
      // data: [],
      isLoading: true,
      notFound: false,
    });
    updateTableControls({
      ...tableControls,
      isTableLoading: true,
      isTableEmpty: false,
    });
    updatePagination({
      ...pagination,
      currentPage: 1,
      skip: 0,
    });
  };

  const storeChangeFunction = () => {
      // debugger;
    axios
      .get("/marketplace/categories", {
        params: {
          // branchId: stores.selected,
          // onlyNames: 1,
        },
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          updateCategories({
            ...categories,
            // selected: res.data.data.categories[0].categoryId,
            data: res.data.data,
            isLoading: false,
            notFound: false,
          });
        } else {
          toastr.error("Error", "No categories found");
          updateCategories({
            ...categories,
            selected: undefined,
            data: [],
            isLoading: false,
            notFound: true,
          });
          updateSubCategories({
            ...subCategories,
            selected: undefined,
            data: [],
          });
          updateProducts({
            ...products,
            data: [],
          });
          updateBrands({
            ...brands,
            data: [],
          });
          updateTableControls({
            isTableEmpty: true,
            isTableLoading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err)
        updateCategories({
          ...categories,
          selected: undefined,
          data: [],
          isLoading: false,
          notFound: true,
        });
        updateSubCategories({
          ...subCategories,
          selected: undefined,
          data: [],
        });
        updateProducts({
          ...products,
          data: [],
        });
        updateTableControls({
          isTableEmpty: true,
          isTableLoading: false,
        });
        // toastr.error(
        //   "Error",
        //   intl.formatMessage({ id: "app.message.error" })
        // );
      });
  };

  // BRAND
  // Pagination
  const getProducts = () => {
    updateTableControls({
      isTableEmpty: false,
      isTableLoading: true,
    });
    // console.log("brandids", brandIds);
    const params = removeEmptyKeys({
      limit: pagination.recordsPerPage,
      skip: (pagination.currentPage - 1) * pagination.recordsPerPage,
      search: pagination.search,
      categoryId: categories.selected,
    });
    axios
      .get("/marketplace/products", {
        params: params,
      })
      .then((res) => {
        if (res.data.statusCode === 200 ) {
          updateProducts({
            ...products,
            data: res.data.data,
            // data: nestedParseJSON(res.data.data.products),
            count: res.data.data.count,
            isLoading: false,
            notFound: false,
          });
          updateTableControls({
            isTableEmpty: false,
            isTableLoading: false,
          });
        } else {
          toastr.error("Error", "No Products found");
          updateProducts({
            ...products,
            data: [],
            count: 0,
            isLoading: false,
            notFound: true,
          });
          updateTableControls({
            isTableEmpty: true,
            isTableLoading: false,
          });
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    storeChangeFunction();
  },[])
  useEffect(() => {
    // if (categories.selected || brands.selected) {
      getProducts();
    // }
  }, [
    pagination.recordsPerPage,
    pagination.currentPage,
    pagination.search,
    categories.selected,
  ]);

  const handleCSVMenuClick = (option) => {
    changeIsCSVUploading(true);
    let params = {
      branchId: stores.selected,
    };
    if (option.key === "category") {
      params.categoryId = categories.selected;
    }
    if (option.key === "subcategory") {
      params.subCategoryId = subCategories.selected;
    }

    axios
      .get("/exportProductsAsCsv", {
        params,
      })
      .then((res) => {
        changeIsCSVUploading(false);
        if (res.data.statusCode === 200) {
          toastr.success("Success", res.data.message);
          downloadFileFromLink(`${option.key}.csv`, res.data.data.file_link);
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        changeIsCSVUploading(false);
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
      });
  };

  const handleBulkUploadMenuClick = (option) => {
    if (option.key === "upload" && csvUploadRef.current !== null) {
      if (stores.selected === undefined || stores.selected === null) {
        toastr.warning("Error", "Please select a store");
      }
      csvUploadRef.current.click();
    } else if (option.key === "sample") {
      axios
        .get("/downloadSampleReporte")
        .then((res) => {
          toastr.success("Success", res.data.message);
          downloadFile("sample.csv", res.data);
        })
        .catch((err) => {
          // toastr.error(
          //   "Error",
          //   intl.formatMessage({ id: "app.message.error" })
          // );
        });
    }
  };

  const uploadCSV = (ev) => {
    if (ev.target.files.length) {
      changeIsCSVUploading(true);
      const data = new FormData();
      data.append("file", ev.target.files[0]);
      data.append("branchId", stores.selected);
      axios
        .post("/bulkImportProducts", data, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          // csvUploadRef.current.value = null;
          changeIsCSVUploading(false);
          if (res.data.statusCode === 200) {
            toastr.success(res.data.message);
            handleStoreChange(stores.selected);
            storeChangeFunction();
          } else {
            toastr.error("Error", res.data.message);
          }
        })
        .catch((err) => {
          changeIsCSVUploading(false);
          // csvUploadRef.current.value = null;
          // toastr.error(
          //   "Error",
          //   err?.data?.message ||
          //     intl.formatMessage({ id: "app.message.error" })
          // );
        });
    }
  };

  const handlePaginationChange = (page) => {
    updatePagination({
      ...pagination,
      currentPage: page,
      skip: (page - 1) * pagination.recordsPerPage,
    });
  };

  const handleRecordsPerPageChange = (val) => {
    updatePagination({
      ...pagination,
      recordsPerPage: val,
    });
  };

  const handleSearchChange = (val) => {
    updateSearch(val);
  };

  const handleProductAdd = (data) => {
    // data.branchId = stores.selected;
    // data.subCategoryIds.push(subCategories.selected);
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });
    axios
      .post("/marketplace/products", data)
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isVisible: res.data.statusCode !== 200,
          isLoading: false,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getProducts();
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((err) => {
        updateModalControls({
          ...modalControls,
          // isVisible: false,
          isLoading: false,
        });
      });
  };

  const setCSVRef = (ref) => {
    csvUploadRef.current = ref;
  };

  const handleProductEdit = (data) => {
    // data.branchId = stores.selected;
    // data.productId = modalControls.data.productId;

    updateModalControls({
      ...modalControls,
      isLoading: true,
    });
    axios
      .put(`/marketplace/products/${modalControls.data.productId}`, data)
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isVisible: res.data.statusCode !== 200,
          isLoading: false,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getProducts();
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateModalControls({
          ...modalControls,
          isVisible: false,
          isLoading: false,
        });
      });
  };

  const handleProductDelete = (data) => {
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });
    axios
      .delete(`/marketplace/products/${data.productId}`, data)
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isVisible: res.data.statusCode !== 200,
          isLoading: false,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getProducts();
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateModalControls({
          ...modalControls,
          isVisible: false,
          isLoading: false,
        });
      });
  };

  const csvMenu = (
    <Menu onClick={handleCSVMenuClick}>
      <Menu.Item key="all">All Products</Menu.Item>
      <Menu.Item key="category">Within Category</Menu.Item>
      <Menu.Item key="subcategory">Within Sub-Category</Menu.Item>
    </Menu>
  );

  const bulkUploadMenu = (
    <Menu onClick={handleBulkUploadMenuClick}>
      <Menu.Item key="upload">
        <input
          ref={setCSVRef}
          onChange={uploadCSV}
          id="csvupload"
          accept=".csv"
          type="file"
          style={{ visibility: "hidden", width: 0, height: 0 }}
        />
        {isCSVUploading ? "Processing..." : "Upload CSV"}
      </Menu.Item>
      <Menu.Item key="sample">Sample CSV</Menu.Item>
    </Menu>
  );

  const handleSearch = (val) => {
    updatePagination({
      ...pagination,
      currentPage: 1,
      search: val,
      skip: 0,
    });
  };

  const getMenu = (item) => {
    return (
      <Menu>

        {props.panelType == 0 && (<Menu.Item
          onClick={() => {
            openEditProductModal(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.edit" })}
        </Menu.Item>)}

        {/* {item.isEnabled == 0 && (
          <Menu.Item
            onClick={() => {
              handleProductToggle(item);
            }}
          >
            {intl.formatMessage({ id: "app.field.enable" })}
          </Menu.Item>
        )}
        {item.isEnabled == 1 && (
          <Menu.Item
            onClick={() => {
              handleProductToggle(item);
            }}
          >
            {intl.formatMessage({ id: "app.field.disable" })}
          </Menu.Item>
        )} */}
        <Menu.Item>
          <Popconfirm
              title={intl.formatMessage({
                id: "app.message.deleteWarning",
              })}
              onConfirm={() => {
                handleProductDelete(item);
              }}
              placement="topRight"
              okText={intl.formatMessage({ id: "app.field.yes" })}
              cancelText={intl.formatMessage({ id: "app.field.no" })}
            >
            {intl.formatMessage({ id: "app.field.delete" })}
          </Popconfirm>
        </Menu.Item>
      
        {/* {props.allProductsFlow && ( */}

        {/* )} */}
      </Menu>
    );
  };

  const columns = [
    {
      dataIndex: "",
      // sorter: true,
      title: intl.formatMessage({ id: "app.field.sNo" }),
      fixed: "left",
      render: (e, item, index) => {
        return (
          <span>
            {pagination.recordsPerPage * (pagination.currentPage - 1) +
              index +
              1}
          </span>
        );
      },
    },
    {
      dataIndex: "title",
      title: intl.formatMessage({ id: "app.field.name" }),

      // fixed: "left",
      render: (i) => {
        return i || "---";
        // return ()
      },
    },
    {
      dataIndex: "cover",
      title: intl.formatMessage({ id: "app.field.image" }),
      fixed: "left",
      render: (i) => {
        return <CustomImage isTable src={i.split(",")[0]} />;
        // return ()
      },
    },
    {
      dataIndex: "description",
      title: intl.formatMessage({ id: "app.field.description" }),

      render: (i) => {
        if (!i) {
          return "---";
        }
        return i.length > TABLE_TEXT_LIMIT.DESCRIPTION ? (
          <Tooltip title={i}>
            <span style={{ cursor: "pointer" }}>
              {textTrimmer(i, TABLE_TEXT_LIMIT.DESCRIPTION)}
            </span>
          </Tooltip>
        ) : (
          i
        );
      },
    },
    {
      dataIndex: "affiliateLink",
      title: "Link of Product",
      render: (i) => {
        return(<a href={i} target="_blank">{i}</a>)
      }
    },
    {
      dataIndex: "",
      title: intl.formatMessage({ id: "app.field.action" }),

      fixed: "right",
      render: (item) => {
        return (
          <Dropdown overlay={getMenu(item)}>
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  const showMenu = !pagination.search && pagination.search == 0;
  return (
    <div>
      <Header title="app.pageTitle.products" />
      <AddEditProduct
        isVisible={modalControls.isVisible}
        data={modalControls.data}
        onCloseIconClick={closeModal}
        modalType={modalControls.type}
        addProduct={handleProductAdd}
        editProduct={handleProductEdit}
        categories={categories.data}
        isLoading={modalControls.isLoading}
      />
      <Panel isLoading={isCSVUploading} className={styles.section}>
        <div className={styles.menu}>
          <header>

          <div className={styles.lowerMenuDropdown}>
              <Label value="app.field.category" horizontal />
              <Select
                style={{ width: "150px" }}
                showSearch
                placeholder={intl.formatMessage({
                  id: "app.placeholder.category",
                })}
                onChange={handleCategoryChange}
                value={categories.selected}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {categories.data.map((category) => (
                  <Option key={category.categoryId} value={category.categoryId}>
                    {category.title}
                  </Option>
                ))}
              </Select>
            </div>
          </header>
          <section>
            <div>
              <DelayedInput
                onChange={handleSearchChange}
                value={search}
                callback={handleSearch}
                delay={500}
                minLength={3}
                size="medium"
                prefixIcon={<SearchOutlined />}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.search",
                })}
              />
            </div>
            {props.panelType == 0 && (<Button
              onClick={openAddProductModal}
              shape={undefined}
              size="medium"
              disabled={props.isAddDisabled}
              type="primary"
            >
              {intl.formatMessage({ id: "app.field.addProduct" })}
            </Button>)}
          </section>
        </div>

        <CustomTable
         isLoading={tableControls.isTableLoading}
         isEmpty={tableControls.isTableEmpty}
         columns={columns}
         dataSource={products.data}
        //  editCostPrice={openCostPriceModal}
         toggleProduct={handleProductToggle}
         pagination={pagination}
         handleEdit={openEditProductModal}
         totalRecords={products.count}
         onChange={updatePagination}
        />
        {/* <ProductsTable
      isLoading={tableControls.isTableLoading}
      isEmpty={tableControls.isTableEmpty}
      data={products.data}
      updateStock={openUpdateStockModal}
      productMapping={props.productMapping}
      toggleProduct={handleProductToggle}
      handleEdit={openEditProductModal}
    /> */}
        {/* <div className={styles.footer}>
      <div></div>
      <div>
        <PaginationInfo
          currentPage={pagination.current}
          recordsPerPage={pagination.pageSize}
          totalRecords={products.count}
          onChange={handlePaginationChange}
        />
      </div>
    </div> */}
      </Panel>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user.userInfo,
  selectedCountry: state.user.selectedCountry,
  selectedCities: state.user.selectedCities,
  storeId: state.user.enterprice.storeId,
  isAddDisabled: state.user.isAddDisabled,
  branchId: state.user.userInfo.branchId,
  panelType: state.user.enterprice.panelType,
  isAddDisabled: state.user.isAddDisabled,
  isRTL: false,
});
export default connect(mapStateToProps)(Products);
