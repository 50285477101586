import React, { useState, useEffect } from "react";
import { Button, Select } from "antd";
import {connect} from 'react-redux';
import { useIntl } from "react-intl";
import { toastr } from "react-redux-toastr";

import { removeEmptyKeys } from "../../shared/utils/object-manipulation";
import { Header, MenuItem } from "../../components/common";
import axios from "../../shared/api/axiosConfig";
import {
  CancellationReasonsTable,
  CancellationReasonsModal,
} from "./components";

import "./cancellation-reasons.scss";
import { checkRtl } from "../../shared/utils/rtl";

const styles = {
  content: "custom-section",
  menu: "custom-menu",
 
};

const MENU_DATA = {
  CUSTOMERS: {
    key: "CUSTOMERS",
    text: "app.field.forCustomers",
    icon: "USER",
  },
  STORES: {
    key: "STORES",
    text: "app.field.forStores",
    icon: "STORE",
  },
};

const userTypes = {
  BRANCH: 1,
  CUSTOMER: 2,
};

function CancellationReasons(props) {
  const intl = useIntl();

  const [activeMenu, changeActiveMenu] = useState(MENU_DATA.CUSTOMERS.key);
  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    data: "",
    type: "",
  });

  const [tableControls, updateTableControls] = useState({
    isLoading: false,
    data: [],
    userType: "",
    isEmpty: false,
  });

  const [pagination, updatePagination] = useState({
    pageSize: 10,
    skip: 0,
    current: 1,
  });
  
  const handleMenuChange = (menu) => {
    changeActiveMenu(menu);
  };

  const openAddReasonModal = () => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      type: "ADD",
    });
  };

  const handleModalClose = () => {
    updateModalControls({
      ...modalControls,
      isVisible: false,
    });
  };

  const openEditReasonModal = (data) => {
    data.userType = tableControls.userType;
    updateModalControls({
      ...modalControls,
      isVisible: true,
      type: "EDIT",
      data,
    });
  };

  const handleReasonAdd = (reason) => {
    console.log(reason);
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });

    const data = {
      userType: tableControls.userType,
      cancellationReason: reason,
    };

    axios
      .post("/addUpdateCancellationReason", removeEmptyKeys(data))
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isVisible: false,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          if (activeMenu === MENU_DATA.CUSTOMERS.key) {
            getCancellationReasons(userTypes.CUSTOMER);
          }
          if (activeMenu === MENU_DATA.STORES.key) {
            getCancellationReasons(userTypes.BRANCH);
          }
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isVisible: false,
        });
      });
  };

  const handleReasonEdit = (reason) => {
    console.log(reason);
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });

    const data = {
      userType: tableControls.userType,
      reasonId: modalControls.data.reasonId,
      cancellationReason: reason,
    };

    axios
      .post("/addUpdateCancellationReason", removeEmptyKeys(data))
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isVisible: false,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          if (activeMenu === MENU_DATA.CUSTOMERS.key) {
            getCancellationReasons(userTypes.CUSTOMER);
          }
          if (activeMenu === MENU_DATA.STORES.key) {
            getCancellationReasons(userTypes.BRANCH);
          }
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isVisible: false,
        });
      });
  };

  const handleReasonDelete = (reasonData) => {
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });
    const obj = { reasonId: reasonData.reasonId };
    console.log("obj to send", obj);
    axios
      .delete("/deleteCancellationReason", removeEmptyKeys({ data: obj }))
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isVisible: false,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          if (activeMenu === MENU_DATA.CUSTOMERS.key) {
            getCancellationReasons(userTypes.CUSTOMER);
          }
          if (activeMenu === MENU_DATA.STORES.key) {
            getCancellationReasons(userTypes.BRANCH);
          }
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isVisible: false,
        });
      });
  };

  const getCancellationReasons = (userType) => {
    updateTableControls({
      ...tableControls,
      isLoading: true,
    });
    axios
      .get("/getCancellationReasons", {
        params: { userType: userType },
      })
      .then((res) => {
        if (res.data.data.cancellationReasons.length) {
          updateTableControls({
            isLoading: false,
            isEmpty: false,
            userType: userType,
            data: res.data.data.cancellationReasons,
          });
        } else {
          updateTableControls({
            isLoading: false,
            isEmpty: true,
            data: [],
            userType: userType,
          });
        }
      })
      .catch((err) => {
        updateTableControls({
          isLoading: false,
          isEmpty: true,
          data: [],
          userType: userType,
        });
      });
  };

  useEffect(() => {
    updateTableControls({
      isLoading: true,
      isEmpty: false,
      data: [],
    });
    if (activeMenu === MENU_DATA.CUSTOMERS.key) {
      getCancellationReasons(userTypes.CUSTOMER);
    }

    if (activeMenu === MENU_DATA.STORES.key) {
      getCancellationReasons(userTypes.BRANCH);
    }
  }, [activeMenu]);

  return (
    <div className={styles.wrapper}>
      <CancellationReasonsModal
        isVisible={modalControls.isVisible}
        modalType={modalControls.type}
        isLoading={modalControls.isLoading}
        data={modalControls.data}
        tableData={tableControls.data}
        onCloseIconClick={handleModalClose}
        editReason={handleReasonEdit}
        addReason={handleReasonAdd}
      />
      <Header title="app.pageTitle.cancellationReasons" />
    <div className={`${styles.content}`}>
      <div className={styles.menu}>
        <header>
          <Select value={activeMenu} onChange={handleMenuChange} style={{width: '200px'}}>
            {Object.keys(MENU_DATA).map((key) => (
              <Select.Option key={key} value={key}>{intl.formatMessage({ id: MENU_DATA[key].text })}</Select.Option>
          ))}
          </Select>
          </header>
        <section>
        <Button
            type="primary"
            shape={undefined}
            disabled={props.isAddDisabled}
            size="medium"
            onClick={openAddReasonModal}
          >
            {intl.formatMessage({ id: "app.button.addReason" })}
          </Button>
        </section>
      </div>
        <CancellationReasonsTable
          data={tableControls.data}
          isLoading={tableControls.isLoading}
          isEmpty={tableControls.isEmpty}
          removeItem={handleReasonDelete}
          pagination={pagination}
          handleEdit={openEditReasonModal}
        />
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  storeId: state.user.enterprice.storeId,
  isAddDisabled: state.user.isAddDisabled,
  isRTL: checkRtl(state.user.locale),
})

export default connect(mapStateToProps)(CancellationReasons);
