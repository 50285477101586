import React from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { connect } from "react-redux";
import { Radio, Menu, Tooltip } from "antd";
import { FormattedMessage } from "react-intl";

import { Icon } from "../common";
import "./sidebar.scss";
// import LeftLogo from "../../assets/images/sidebar-logo.png";
import LeftLogo from "../../assets/images/logo.png";
import RightLogo from "../../assets/images/logo.png"
import {
  // getPanelType,
  getRolesFromStorage,
} from "../../shared/utils/local-storage";
// import {
//   verticles,
//   verticleType,
// } from "../../shared/constants/dropdown";
import sidebarItems from "../../shared/constants/sidebar-data.json";
import { checkRtl } from "../../shared/utils/rtl";
const foodSidebarItems = [];

const styles = {
  wrapper: "custom-sidebar__wrapper",
  contentContainer: "custom-sidebar__content-container",
  sidebarActive: "custom-sidebar__wrapper--sidebar-active",
  listItem: "custom-sidebar__list-item",
  listItemClosed: "custom-sidebar__list-item--closed",
  listItemActive: "custom-sidebar__list-item--active",
  logoContainer: "custom-sidebar__logo-container",
  leftLogo: "custom-sidebar__logo-container__left-logo",
  rightLogo: "custom-sidebar__logo-container__right-logo",
  footer: "custom-sidebar__footer",
  alternateLogo: "custom-sidebar__alternate-logo",
  alternateH: "custom-sidebar__alternate-H",
  footerActive: "custom-sidebar__footer--active",
  icon: "custom-sidebar__list-item__icon",
  subIcon: "custom-sidebar__list-item__icon--submenu",
  text: "custom-sidebar__list-item__text",
  subMenuContainer: "custom-sidebar__sub-menu-container",
  subMenuContainerToggled: "custom-sidebar__sub-menu-container--toggled",
  subMenuItem: "custom-sidebar__sub-menu-item",
  subMenuItemActive: "custom-sidebar__sub-menu-item--active",
  dropIcon: "custom-sidebar__drop-icon",
  dropIconRotated: "custom-sidebar__drop-icon--rotated",
  toggledListItem: "custom-sidebar__list-item--toggled",
  shadowContainer: "custom-sidebar__shadow-container",
};

const pickerOptions = [

];

const nonPickerOptions = [

];

class Sidebar extends React.Component {
  state = {
    activeSubMenu: "",
    toggledSubMenu: "",
  };

  scrollTo = (offset, callback) => {
    const fixedOffset = offset.toFixed(),
      onScroll = function () {
        if (window.pageYOffset.toFixed() === fixedOffset) {
          window.removeEventListener("scroll", onScroll);
          callback();
        }
      };

    window.addEventListener("scroll", onScroll);
    onScroll();
    window.scrollTo({
      top: offset,
      behavior: "smooth",
    });
  };

  setMenuActive = (menu) => {
    // window.scrollTo({top: 0, behavior: 'smooth'});
    this.setState({
      activeSubMenu: menu,
      toggledSubMenu: menu,
    });
  };

  resetSubMenu = () => {
    this.setState({
      activeSubMenu: "",
      toggledSubMenu: "",
    });
  };

  toggleSubMenu = (menu) => {
    // window.scrollTo({top: 0, behavior: 'smooth'});
    if (menu === this.state.toggledSubMenu) {
      this.setState({
        toggledSubMenu: "",
      });
    } else {
      this.setState({
        toggledSubMenu: menu,
      });
      if (!this.props.isSidebarExpanded) {
        // this.props.toggleSidebar();
      }
    }
  };

  toggleHover = () => {
    this.setState({ isSidebarHover: !this.state.isSidebarHover });
  };
  toggleHoverOn = (e) => {
    e.preventDefault();
    this.setState({ isSidebarHover: true });
  };

  toggleHoverOff = (e) => {
    e.preventDefault();
    this.setState({ isSidebarHover: false });
  };

  getRoles = () => {
    if(this.props.panelType === 1) {
      return sidebarItems.filter(i => i.onStore ? true : false)
    }
    let roles = getRolesFromStorage();
    if (roles === undefined) return [];
    const vert = 0;
    // const invType = verticleType[vert.type];
    const mode = 0;
    // debugger;
    // goodFleetEnabled
    roles = roles.reduce((acc, val) => {
      if (val.status == 1) {
        // if(invType)
        if (
          (val.menuId == 115 || val.menuId == 15) &&
          !this.props.goodFleetEnabled
        ) {
          return acc;
        }
        if (mode == 0 && val.menuId == 8 && !this.props.pickerFlow) {
          return acc;
        }
        acc.push(val.menuId);
      }
      return acc;
    }, []);
    console.log("#1", roles);
    // if(mode == 1) {
    //   return foodSidebarItems
    // }

    let routes = sidebarItems;
    if (mode == 1) {
      routes = foodSidebarItems;
    }
    console.log("#2", routes);
    // debugger;
    return routes
      .filter((item) => roles.indexOf(item.menuId) !== -1)
      .map((item) => {
        let result = item;
        if (item.subMenu?.length) {
          result = {
            ...item,
            subMenu: item.subMenu.map((i) => ({
              ...i,
              url: i.url,
            })),
          };
        }


        return {
          ...result,
          url: item.url,
        };
      });
  };

  verticalPlacer = (arr) => {
    return arr;
    // const vert = verticles[this.props.userInfo.selectedVerticalType];
    // return arr.map((i) => ({
    //   ...i,
    //   url: i.url.replace("{}", vert.text.toLowerCase()),
    // }));
  };
  getSubMenu = (item, index) => {
    let menu = [...item.subMenu];
    if (item.menuId == 9) {
      // ORDERS
      if (this.props.pickerFlow) {
        menu = [...menu, ...this.verticalPlacer(pickerOptions)];
      } else {
        menu = [...menu, ...this.verticalPlacer(nonPickerOptions)];
      }

      menu = menu.sort((a, b) => a.order - b.order);
    }
    // debugger;
    return menu.map((subItem, i) => (
      <NavLink
        dir={this.props.isRTL ? "rtl" : "ltr"}
        className={styles.subMenuItem}
        activeClassName={styles.subMenuItemActive}
        key={`item${index}subItem${i}`}
        to={subItem.url}
        onClick={() => {
          this.setMenuActive(item.name);
        }}
      >
        <div dir={this.props.isRTL ? "rtl" : "ltr"} className={styles.subIcon}>
          <Icon name={subItem.icon} size="sidebar" />
        </div>
        <div dir={this.props.isRTL ? "rtl" : "ltr"} className={styles.text}>
          <FormattedMessage id={subItem.name} />
        </div>
      </NavLink>
    ));
  };
  getOpenSidebarView = () => {
    return this.getRoles().map((item, index) => {
      if (Array.isArray(item.subMenu)) {
        if (
          true || this.props.isSidebarExpanded
            ? false
            : !this.state.isSidebarHover
        ) {
          const { location } = this.props;
          // debugger;
          return (
            <Menu
              defaultSelectedKeys={["/"]}
              selectedKeys={[location.pathname]}
              onClick={this.handleClick}
              expandIcon={() => <></>}
              mode="vertical"
            >
              <Menu.SubMenu
                icon={<Icon name={item.icon} size="sidebar" />}
                expandIcon={() => <></>}
                key="sub4"
              >
                {this.getSubMenu(item, index)}
              </Menu.SubMenu>
            </Menu>
          );
        }
        return (
          <React.Fragment key={`item${index}`}>
            <NavLink
              className={classNames({
                [styles.listItem]: true,
                [styles.listItemClosed]: this.props.isSidebarExpanded
                  ? false
                  : !this.state.isSidebarHover,
              })}
              dir={this.props.isRTL ? "rtl" : "ltr"}
              activeClassName={classNames([
                styles.listItemActive,
                styles.toggledListItem,
              ])}
              to={item.url}
              style={
                item.name == this.state.toggledSubMenu
                  ? { background: "#f5f5f5" }
                  : {}
              }
              onClick={(e) => {
                e.preventDefault();
                this.toggleSubMenu(item.name);
              }}
            >
              <div
                dir={this.props.isRTL ? "rtl" : "ltr"}
                className={styles.icon}
              >
                <Icon name={item.icon} size="sidebar" />
              </div>
              <div
                dir={this.props.isRTL ? "rtl" : "ltr"}
                className={styles.text}
              >
                <FormattedMessage id={item.name} />
                <div
                  dir={this.props.isRTL ? "rtl" : "ltr"}
                  className={classNames({
                    [styles.dropIcon]: true,
                    [styles.dropIconRotated]:
                      this.state.toggledSubMenu === item.name,
                  })}
                >
                  <Icon name="CARET_DOWN" size="tiny" />
                </div>
              </div>
            </NavLink>
            <div
              dir={this.props.isRTL ? "rtl" : "ltr"}
              className={classNames({
                [styles.subMenuContainer]: true,
                [styles.subMenuContainerToggled]:
                  this.state.toggledSubMenu === item.name &&
                  (this.state.isSidebarHover || this.props.isSidebarExpanded),
              })}
            >
              {this.getSubMenu(item, index)}
            </div>
          </React.Fragment>
        );
      } else {
        return (
          <NavLink
            dir={this.props.isRTL ? "rtl" : "ltr"}
            className={classNames({
              [styles.listItem]: true,
              [styles.listItemClosed]: false,
            })}
            key={`item${index}`}
            activeClassName={styles.listItemActive}
            to={item.url}
            onClick={this.resetSubMenu}
          >
            <div dir={this.props.isRTL ? "rtl" : "ltr"} className={styles.icon}>
              <Icon name={item.icon} size="sidebar" />
            </div>

            <div dir={this.props.isRTL ? "rtl" : "ltr"} className={styles.text}>
              <FormattedMessage id={item.name} />
            </div>
          </NavLink>
        );
      }
    });
  };
  render() {
    return (
      <div
        dir={this.props.isRTL ? "rtl" : "ltr"}
        className={classNames({
          [styles.wrapper]: true,
          [styles.sidebarActive]:
            this.state.isSidebarHover || this.props.isSidebarExpanded,
        })}
        onMouseEnter={this.toggleHoverOn}
        onMouseLeave={this.toggleHoverOff}
      >
        {this.props.alternateSidebarView !== 1 && (
          <>
            <div
              dir={this.props.isRTL ? "rtl" : "ltr"}
              className={styles.logoContainer}
            >
              <img
                // style={{ width: "150px" }}
                src={LeftLogo}
                className={styles.leftLogo}
                alt=""
              />
                <h1>Teddy</h1>{" "}
              <h1>
              {/* <img src={RightLogo} className={styles.rightLogo} alt="" /> */}
                {/* <span>Pawminded</span>{" "} */}
                <Radio
                  onClick={this.props.toggleSidebar}
                  checked={this.props.isSidebarExpanded}
                />
              </h1>
            </div>
          </>
        )}

        {this.props.alternateSidebarView === 1 && (
          <>
            <div
              dir={this.props.isRTL ? "rtl" : "ltr"}
              className={`${styles.logoContainer} ${styles.alternateLogo}`}
            >
              <img
                src={
                  this.props.enterprice.logoImage ||
                  this.props.enterprice.branchLogo
                }
                alt=""
              />
              <h1 className={styles.alternateH}>
                {" "}
                <Radio
                  onClick={this.props.toggleSidebar}
                  checked={this.props.isSidebarExpanded}
                />
              </h1>
            </div>
          </>
        )}

        {/* <div style={{height: '64px'}}></div> */}
        <div
          dir={this.props.isRTL ? "rtl" : "ltr"}
          className={styles.contentContainer}
        >
          <section>{this.getOpenSidebarView()}</section>
          {/* <div
            onClick={this.props.toggleSidebar}
            className={classNames({
              [styles.footer]: true,
              [styles.footerActive]: this.state.isSidebarHover,
            })}
          >
            <span>
              {this.state.isSidebarHover && (
                <Icon name="SIDEBAR_CLOSE" size="sidebar" />
              )}
              {!this.state.isSidebarHover && (
                <Icon name="SIDEBAR_OPEN" size="sidebar" />
              )}
            </span>
          </div> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // enterprice: state.user.enterprice,
  panelType: state.user.enterprice.panelType,
  userInfo: state.user.userInfo,
  isRTL: checkRtl(state.user.locale),
  goodFleetEnabled: true,
  pickerFlow: true,
  alternateSidebarView: false,
});

export default connect(mapStateToProps)(Sidebar);
