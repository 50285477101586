import React, { useState, useEffect } from "react";
import { Input, Button, Select } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import {
  CustomModal,
  Panel,
  ErrorMessage,
  UploadButton,
} from "../../../components/common";
import axios from "../../../shared/api/axiosConfig";
import Validations from "../../../shared/validations/Validations";
import placeholderImg from "../../../assets/images/item-placeholder.png";
import "./add-edit-product.scss";
const {TextArea}  = Input;
const styles = {
    formItem: "custom-form-item",
    formModal: "custom-form-modal",
    formAction: "custom-form-action",
    imageContainer: "products__add-edit-product-modal__image-container",
    imageContainerImage: "products__add-edit-product-modal__image-container__image",
    imageContainerForm: "products__add-edit-product-modal__image-container__form",
};

const { Option } = Select;
const errorText = "Field cannot be empty";

function AddEditProduct(props) {
  const intl = useIntl();

  const [categories,updateCategories] = useState({
    selected: props.data.categoryId,
    data: props.categories,
    // selected: props.categories.selected,
    // data: props.categories,
  });
  const [isPristine, togglePristine] = useState(true);
  const [name, changeName] = useState(Validations.validateTextField(""));
  const [description, changeDescription] = useState(Validations.validateTextField(""));
  const [affiliateLink, changeAffiliateLink] = useState(Validations.validateTextField(""));
  const [imageURL, changeImageURL] = useState(
    Validations.validateTextField("")
  );

  const handleCategoryChange = (sec) => {
    updateCategories({
      ...categories,
      selected: sec,
    });
  };

  const resetForm = () => {
    togglePristine(true);
    updateCategories({
      ...categories,
      data: props.data,
    })
    changeName(Validations.validateTextField(null));
    changeDescription(Validations.validateTextField(null));
    changeAffiliateLink(Validations.validateTextField(null));
    changeImageURL(Validations.validateTextField(null));
  };

  const setForm = () => {
    console.log("PROPS: ",props);
    togglePristine(true);
    updateCategories({
      ...categories,
      data: props.data,
      selected: props.data.categoryId,
    })
    changeName(Validations.validateTextField(props.data.title));
    changeDescription(Validations.validateTextField(props.data.description));
    changeAffiliateLink(Validations.validateTextField(props.data.affiliateLink));
    changeImageURL(Validations.validateTextField(props.data.cover));
  };
  useEffect(() => {
    if(props.isVisible) {
      if (props.modalType === "EDIT_PRODUCT") {
        setForm();
        //   setForm();
      } else if (props.modalType === "ADD_PRODUCT") {
        resetForm();
      }
    }
  }, [props.isVisible, props.data]);


  const handleFieldChange = (e) => {
    const { value } = e.target;

    switch (e.target.name) {
      case "name":
        changeName(Validations.validateTextField(value));
        break;
      case "description":
        changeDescription(Validations.validateTextField(value));
        break;
      case "affiliateLink":
        changeAffiliateLink(Validations.validateTextField(value));
        break;
      // case "section":
      //   changeSection(Validations.validateTextField(value));
      //   break;
      case "imageUrl":
        changeImageURL(Validations.validateTextField(value));
        break;
      // case "rank":
      //   changeRank(Validations.validateNumericValue(value));
      //   break;
      default:
        break;
    }
  };

  const getData = () => ({
    title: name.value,
    cover: imageURL.value,
    categoryId: categories.selected,
    // introduction: intro.value,
    affiliateLink: affiliateLink.value,
    description: description.value,
    // overview: overview.value, 
    // lessonIndex: rank.value,
  });

  const handleAdd = () => {
    // TODO
    props.addProduct(getData());
  };

  const handleEdit = () => {
    // TODO
    props.editProduct(getData());
  };

  const isFormValid = () => {
    let isValid = name.isValid && imageURL.isValid && affiliateLink.isValid && description.isValid && categories.selected ;
    // if(props.modalToShow === 'ADD_COURSE') {
    //   isValid = isValid && store.isValid;
    // }
    return isValid;
  };

  const closeModal = () => {
    props.onCloseIconClick();
  };

  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const formAction = () => {
    togglePristine(false);
    if (!isFormValid()) {
      return;
    }
    if (props.modalType === "ADD_PRODUCT") {
      handleAdd();
    } else if (props.modalType === "EDIT_PRODUCT") {
      handleEdit();
    }
  };

  const handleImageChange = (image) => {
    changeImageURL(Validations.validateTextField(image));
  };

  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={closeModal}
      header={
        props.modalType === "ADD_PRODUCT"
          ? "Add Product"
          : "Edit Product"
      }
      isVisible={props.isVisible}
    >
      <Panel isLoading={props.isLoading} className={styles.formModal}>
        <div className={styles.imageContainer}>
          <div className={styles.imageContainerImage}>
            <img
              src={imageURL.value ? imageURL.value : placeholderImg}
              alt=""
            />
            <UploadButton
              text="Upload Image"
              resultType="url"
              accept="image/*"
              onChange={handleImageChange}
            />
            {!isPristine && <ErrorMessage field={imageURL} />}
          </div>

          <div className={styles.imageContainerForm}>
            <div className={styles.formItem}>
              <label>
                {intl.formatMessage({
                  id: "app.field.name",
                })}
              </label>
              <Input
                name="name"
                onChange={handleFieldChange}
                value={name.value}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.name",
                })}
              />
              {!isPristine && <ErrorMessage field={name} />}
            </div>
            <div className={styles.formItem}>
              <label>
                  Desription
              </label>
              <Input
                name="description"
                onChange={handleFieldChange}
                value={description.value}
                placeholder="Enter Desription"
              />
              {!isPristine && <ErrorMessage field={description} />}
            </div>
            <div className={styles.formItem}>
              <label>
                  Affiliate Link
              </label>
              <Input
                name="affiliateLink"
                onChange={handleFieldChange}
                value={affiliateLink.value}
                placeholder="Enter Affiliate Link "
              />
              {!isPristine && <ErrorMessage field={affiliateLink} />}
            </div>
            <div className={styles.formItem}>
              <label>
                  Select Category
              </label>
              <Select
              placeholder="Select Category"
                // placeholder={intl.formatMessage({
                //   id: "app.placeholder.advertisementType",
                // })}
                onChange={handleCategoryChange}
                value={categories.selected}
              >
                {props.categories.map((category) => (
                  <Option key={category.categoryId} value={category.categoryId}>
                    {category.title}
                  </Option>
                ))}
              </Select>
              {!isPristine && categories.selected === undefined && (
                <ErrorMessage text={errorText} />
              )}
              {/* {!isPristine && <ErrorMessage field={section} />} */}
            </div>
            {/* <div className={styles.formItem}>
              <label>
                  Introduction Video
              </label>
              <Input
                name="intro"
                onChange={handleFieldChange}
                value={intro.value}
                placeholder="Enter Introduction Video Link "
                
              />
            </div> */}
          </div>
        </div>
        <div className={styles.formAction}>
          <Button type="default" shape={undefined} onClick={closeModal}>
          {intl.formatMessage({id: 'app.button.cancel'})}
          </Button>
          <Button type="primary" shape={undefined} onClick={formAction}>
          {intl.formatMessage({id: 'app.button.submit'})}
          </Button>
        </div>
      </Panel>
    </CustomModal>
  );
}

export default AddEditProduct;