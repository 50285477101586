import React, { useState, useEffect, useRef } from "react";
import { Input, Button } from "antd";
import { useIntl } from "react-intl";

import {
  CustomModal,
  Panel,
  ErrorMessage,
  UploadButton,
  Label,
  CustomImage,
} from "../../../../components/common";
import Validations from "../../../../shared/validations/Validations";
import placeholderImg from "../../../../assets/images/item-placeholder.png";
import "./add-edit-category.scss";

const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  imageContainer: "categories__add-edit-category-modal__image-container",
  imageContainerImage:
    "categories__add-edit-category-modal__image-container__image",
  imageContainerForm:
    "categories__add-edit-category-modal__image-container__form",
  spanImp: "categories__add-edit-category-modal__span-imp",
};

function AddEditCategory(props) {
  const intl = useIntl();
  const imageContainerRef = useRef(null);
  const [isPristine, togglePristine] = useState(true);
  const [name, changeName] = useState(Validations.validateTextField(""));
  const [description, changeDescription] = useState(
    Validations.validateTextField("")
  );
  const [rank, changeRank] = useState(Validations.validateNumericValue(null));
  const [imageURL, changeImageURL] = useState(
    Validations.validateTextField("")
  );

  const resetForm = () => {
    togglePristine(true);
    changeName(Validations.validateTextField(null));
    changeDescription(Validations.validateTextField(null));
    changeRank(Validations.validateNumericValue(null));
    changeImageURL(Validations.validateTextField(null));
  };

  const setForm = () => {
    togglePristine(true);
    changeName(Validations.validateTextField(props.data.title));
    // changeDescription(Validations.validateTextField(props.data.description));
    // changeRank(Validations.validateNumericValue(props.data.priority));
    // changeImageURL(Validations.validateTextField(props.data.categoryImage));
  };
  useEffect(() => {
    if (props.modalToShow === "EDIT_CATEGORY") {
      setForm();
    } else if (props.modalToShow === "ADD_CATEGORY") {
      resetForm();
    }
  }, [props.modalToShow, props.data, props.isVisible]);

  const handleFieldChange = (e) => {
    const { value } = e.target;

    switch (e.target.name) {
      case "name":
        changeName(Validations.validateTextField(value));
        break;
      case "description":
        changeDescription(Validations.validateTextField(value));
        break;
      case "rank":
        changeRank(Validations.validateNumericValue(value));
        break;
      default:
        break;
    }
  };

  const getData = () => {
    const data = {
      title: name.value,
      // categoryImage: imageURL.value,
      // categoryIcon: imageURL.value,
      // priority: rank.value,
    };
    // if (description.value && description.isValid) {
    //   data.description = description.value;
    // }
    // if (description.value === null || description.value === "") {
    //   data.description = "---";
    // }

    return data;
  };

  const handleAdd = () => {
    // TODO
    props.addCategory(getData());
  };

  const handleEdit = () => {
    // TODO
    props.editCategory(getData());
  };

  const isFormValid = () => {
    let isValid = true;
    isValid = name.isValid;
    // if (description.value) {
    //   isValid = isValid && description.isValid;
    // }
    return isValid;
  };

  const closeModal = () => {
    props.onCloseIconClick();
  };

  const formAction = () => {
    togglePristine(false);
    if (!isFormValid()) {
      return;
    }
    if (props.modalToShow === "ADD_CATEGORY") {
      handleAdd();
    } else if (props.modalToShow === "EDIT_CATEGORY") {
      handleEdit();
    }
  };

  const handleImageChange = (image) => {
    changeImageURL(Validations.validateTextField(image));
  };

  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={closeModal}
      header={
        props.modalToShow === "ADD_CATEGORY"
          ? "app.placeholder.addCategory"
          : "app.placeholder.editCategory"
      }
      action={
        <>
          <Button type="default" shape={undefined} onClick={closeModal}>
            {intl.formatMessage({ id: "app.button.cancel" })}
          </Button>
          <Button
            loading={props.isLoading}
            type="primary"
            shape={undefined}
            onClick={formAction}
          >
            {intl.formatMessage({ id: "app.button.submit" })}
          </Button>
        </>
      }
      isVisible={props.isVisible}
    >
      <Panel isModal isLoading={props.isLoading} className={styles.formModal}>
        <div
          dir={props.isRTL ? "rtl" : "ltr"}
          className={styles.imageContainer}
        >
          {/* <div
            dir={props.isRTL ? "rtl" : "ltr"}
            className={styles.imageContainerImage}
          >
            <CustomImage src={imageURL.value} />
            <UploadButton
              parentRef={imageContainerRef}
              text={intl.formatMessage({ id: "app.field.uploadImage" })}
              resultType="url"
              id="qopekijfwgruy"
              accept="image/*"
              text={
                intl.formatMessage({ id: "app.field.uploadImage" }) +
                "(300x300)"
              }
              onChange={handleImageChange}
            />
            {!isPristine && <ErrorMessage field={imageURL} />}
          </div> */}

          <div className={styles.imageContainerForm}>
            {/* <div className={styles.formItem}>
              <Label value="app.field.rank" />
              <Input
                name="rank"
                type="number"
                onChange={handleFieldChange}
                value={rank.value}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.rank",
                })}
              />
              {!isPristine && <ErrorMessage field={rank} />}
            </div> */}
            <div className={styles.formItem}>
              <Label value="app.field.categoryName" />
              <Input
                name="name"
                onChange={handleFieldChange}
                value={name.value}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.categoryName",
                })}
              />
              {!isPristine && <ErrorMessage field={name} />}
            </div>
          </div>
        </div>
        {/* <div className={styles.formItem}>
          <Label value="app.field.categoryDescription" optional />
          <Input
            name="description"
            onChange={handleFieldChange}
            value={description.value}
            placeholder={intl.formatMessage({
              id: "app.placeholder.categoryDescription",
            })}
          />
          {!isPristine && description.value && (
            <ErrorMessage field={description} />
          )}
        </div> */}
      </Panel>
    </CustomModal>
  );
}

export default AddEditCategory;
