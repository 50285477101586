import React, { useState, useEffect } from "react";
import "./overview-card.scss";
import {Icon} from '../../../../components/common';

const styles = {
  wrapper: "dashboard__overview-card__wrapper",
  header: "dashboard__overview-card__header",
  iconWrapper: 'dashboard__overview-card__icon-wrapper',
};

function OverviewCard(props) {
    return (
        <div className={styles.wrapper}>
            <div>
                {props.children}
            </div>
            <div className={styles.iconWrapper}>
                <Icon name={props.icon} />
            </div>
        </div>
    )
}

export default OverviewCard;