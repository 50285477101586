import React from "react";
import { Button } from "antd";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

import { Icon } from "../";
import history from "../../../shared/utils/history";
import styles from "./back-button.module.scss";

function BackButton({open}) {
  return (
    <div>
      <Button
      type="link"
      shape={undefined}
      className={classNames({
        [styles.wrapper]: true,
        [styles.open]: !!open,
      })}
      onClick={() => {
        history.goBack();
      }}
    >
      <Icon name="LEFT_ARROW" pointer={1} />
      <FormattedMessage id="app.button.back" />
    </Button>
      </div>
  );
}

export default BackButton;
