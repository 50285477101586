import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  AreaChart,
  Area,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer,
} from "recharts";
import numeral from 'numeral';

import { Panel } from "../../../../components/common";
import {
  abbreviateNumber,
  numberWithCommas,
} from "../../../../shared/utils/object-manipulation";
import "./hourly-chart.scss";

const styles = {
  wrapper: "dashboard__hourly-chart__wrapper",
  header: "dashboard__hourly-chart__header",
  containerClass: 'dashboard__hourly-chart__container-class',
};

function Hourly(props) {
  const intl = useIntl();
  const [chartData, updateChartData] = useState([]);
  const formatData = (data) => {
    const formattedData = data.map((item) => {
      return {
        hour: item.hour,
        "Today's Subscribers": item.todaySubscribers,
        "Yesterday's Subscribers": item.yesterDaySubscribers,
      }
    })
    console.log(formattedData)
    updateChartData(formattedData);
  };

  const formatNums = (num) => {
    return numeral(num).format('0 a');
  }

  useEffect(() => {
    // console.log("HOURPROP: ",props);
    formatData(props.hourData);
  }, [props.hourData]);
  return (
    <div className={styles.containerClass}>
      <div className={styles.header}>Subscribers {intl.formatMessage({ id: "app.field.growthByHours" })}</div>
      <ResponsiveContainer
    width="100%"
    height="100%"
    aspect={3}
    className={styles.transFix}
  >
    <AreaChart data={chartData}>
      <CartesianGrid vertical={false} strokeDasharray="3 0" />
      <XAxis height={20} dataKey="hour"></XAxis>
      <YAxis type="number" tickFormatter={formatNums}>
        <Label
          angle={-90}
          fill={props.color}
          value="No. of Subscribers"
          // value={intl.formatMessage({ id: "app.field.amountOfSales"})}
          position="insideLeft"
          style={{ textAnchor: "middle" }}
        />
      </YAxis>
      <Tooltip formatter={numberWithCommas} />
      <Legend />
      <Area
        type="monotone"
        dataKey="Today's Subscribers"
        // dataKey={intl.formatMessage({ id: "app.field.todaysSales"})}
        stroke={props.color}
        fill={props.color}
      />
            <Area
        type="monotone"
        dataKey="Yesterday's Subscribers"
        // dataKey={intl.formatMessage({ id: "app.field.yesterDaysSales"})}
        stroke={props.colorLight}
        fill={props.colorLight}
      />
    </AreaChart>
  </ResponsiveContainer>
    </div>
  );
}

export default Hourly;
