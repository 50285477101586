import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Select, Button,Menu,Popconfirm,Dropdown } from "antd";

import { CaretDownOutlined, MoreOutlined, SearchOutlined } from "@ant-design/icons";

import {
  Header,
  Placeholder,
  ConfirmModal,
  Label,
  CustomTable
} from "../../components/common";
import {
  CategoriesList,
  AddEditCategory,
  AddEditSubCategory,
  CategoryCard,
} from "./components";
import { connect } from "react-redux";
import CategoryDetails from "./category-details";
import userActions from "../../redux/user-info/user-actions";
import {
  removeEmptyKeys,
  // multiSort,
} from "../../shared/utils/object-manipulation";
import axios from "../../shared/api/axiosConfig";
import "./categories.scss";
import { toastr } from "react-redux-toastr";
import { checkRtl } from "../../shared/utils/rtl";

const styles = {
  wrapper: "categories__wrapper",
  menu: "custom-menu",
  singleSection: "custom-section single",
  leftMenuItem: "categories__menu__left-menu-item",
};
const { Option } = Select;
function Categories(props) {
  const [stores, updateStores] = useState({
    selected: undefined,
    isLoading: true,
    data: [],
    notFound: false,
  });

  const [categories, updateCategories] = useState({
    selected: "",
    selectedData: {},
    isLoading: false,
    data: [],
    notFound: false,
    count: 0,
  });

  const [pagination, updatePagination] = useState({
    recordsPerPage: 10,
    skip: 0,
    currentPage: 1,
    search: "",
  });
  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    isDeleteModalVisible: false,
    data: {},
    type: "",
  });

  const intl = useIntl();

  const getCategories = () => {
    updateCategories({
      ...categories,
      isLoading: true,
      selected: undefined,
      selectedData: {},
    });
    axios
      .get("/marketplace/categories")
      .then((res) => {
        if (res.data.statusCode === 200) {
          updateCategories({
            selected: "",
            isLoading: false,
            data: res.data.data,
            notFound: false,
            count: res.data.data.length,
          });
        } else {
          updateCategories({
            selected: "",
            isLoading: false,
            data: [],
            notFound: true,
            count: 0,
          });
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateCategories({
          selected: "",
          isLoading: false,
          data: [],
          notFound: true,
          count: 0,
        });
      });
  };

  useEffect(() => {
    getCategories();
  }, []);

  const handleStoreChange = (selected) => {
    updateStores({
      ...stores,
      selected,
    });
    // props.dispatch(userActions.setStore(selected));
    getCategories(selected);
  };

  const handleCategoryAdd = (data) => {
    console.log("add called");
    // data.branchId = stores.selected;
    // data.adminId = 1;
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });

    axios
      .post("/marketplace/categories", data)
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isVisible: false,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getCategories();
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateModalControls({
          ...modalControls,
          isLoading: false,
        });
      });
  };
  const handleCategoryEdit = (data) => {
    // data.categoryId = modalControls.data.categoryId;
    // data.branchId = stores.selected;
    // data.adminId = 1;
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });
    axios
      .put(`marketplace/categories/${modalControls.data.categoryId}`, data)
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isVisible: res.data.statusCode !== 200,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getCategories();
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateModalControls({
          ...modalControls,
          isLoading: false,
        });
      });
  };

  const handleCategoryDelete = (item) => {
    const data = {
      // branchId: stores.selected,
      categoryId: item.categoryId,
      // isDeleted: 1,
    };
    // data.adminId = 1;
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });

    axios
      .delete(`marketplace/categories/${item.categoryId}`, data)
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isDeleteModalVisible: res.data.statusCode !== 200,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getCategories();
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateModalControls({
          ...modalControls,
          isDeleteModalVisible: false,
        });
      });
  };

  const openDeleteModal = (category) => {
    updateModalControls({
      ...modalControls,
      isVisible: false,
      isDeleteModalVisible: true,
      data: category,
      type: "",
    });
  };

  const handleCategoryView = (category) => {
    updateCategories({
      ...categories,
      selected: category.categoryId,
      selectedData: category,
    });
  };

  const handleModalClose = () => {
    // debugger;
    updateModalControls({
      isVisible: false,
      data: {},
      type: "",
    });
  };

  const openAddCategoryModal = () => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      isDeleteModalVisible: false,
      type: "ADD_CATEGORY",
    });
  };

  const openEditCategoryModal = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      isDeleteModalVisible: false,
      data: data,
      type: "EDIT_CATEGORY",
    });
  };

  const closeDetails = () => {
    updateCategories({
      ...categories,
      selected: "",
      selectedData: {},
    });
  };
  const getMenu = (item) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            openEditCategoryModal(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.edit" })}
        </Menu.Item>
        {/* {item.isEnabled == 0 && (
          <Menu.Item
            onClick={() => {
              handleBrandToggle(item);
            }}
          >
            {intl.formatMessage({ id: "app.field.enable" })}
          </Menu.Item>
        )}
        {item.isEnabled == 1 && (
          <Menu.Item
            onClick={() => {
              handleBrandToggle(item);
            }}
          >
            {intl.formatMessage({ id: "app.field.disable" })}
          </Menu.Item>
        )} */}

        <Menu.Item
        // onClick={() => {
        //   openDeleteBrandModal(item)
        // }}
        >
          <Popconfirm
            title={intl.formatMessage({
              id: "app.message.deleteWarning",
            })}
            onConfirm={() => {
              handleCategoryDelete(item);
            }}
            placement="topRight"
            okText={intl.formatMessage({ id: "app.field.yes" })}
            cancelText={intl.formatMessage({ id: "app.field.no" })}
          >
            {intl.formatMessage({ id: "app.field.delete" })}
          </Popconfirm>
        </Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      dataIndex: "",
      // sorter: true,
      title: intl.formatMessage({ id: "app.field.sNo" }),
      // fixed: 'left',
      render: (e, item, index) => {
        return (
          <span>
            {pagination.recordsPerPage * (pagination.currentPage - 1) +
              index +
              1}
          </span>
        );
      },
    },
    {
      dataIndex: "title",
      // title: intl.formatMessage({ id: "app.field.title" }),
      title: "Name",
    },

    // {
    //     dataIndex: "lead",
    //     title: "Lead",
    // },
    // {
    //     dataIndex: "createdAt",
    //     title: "Uploaded At",
    //     render: (item) => moment.utc(item).format("MMMM Do YYYY"),

    // },
    {
      dataIndex: "",
      title: intl.formatMessage({ id: "app.field.action" }),
      fixed: "right",
      render: (item) => {
        return (
          <Dropdown overlay={getMenu(item)}>
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  return (
    <div className={styles.wrapper}>
      {true && (
        <AddEditCategory
          isVisible={modalControls.isVisible}
          onClose={handleModalClose}
          onCloseIconClick={handleModalClose}
          modalToShow={modalControls.type}
          data={modalControls.data}
          isRTL={props.isRTL}
          addCategory={handleCategoryAdd}
          editCategory={handleCategoryEdit}
          isLoading={modalControls.isLoading}
        />
      )}
      {/* <ConfirmModal
        header={`Delete ${modalControls?.data?.categoryName}?`}
        message={intl.formatMessage({
          id: "app.message.deleteCategoryWarning",
        })}
        onCloseIconClick={handleModalClose}
        onAction={handleCategoryDelete}
        isVisible={modalControls.isDeleteModalVisible}
        actionText="Delete"
        isLoading={modalControls.isLoading}
      /> */}
      <Header title="app.pageTitle.categories" />
      <div className={styles.singleSection}>
        {props.panelType == 0 && (
          <div className={styles.menu}>
            {/* <header>
              <div>
                <Select<CustomTable
          isLoading={lessons.isLoading}
          dataSource={lessons.data}
          columns={columns}
          pagination={pagination}
          totalRecords={lessons.count}
          onChange={updatePagination}
        />
                  showSearch
                  style={{ width: 250 }}
                  placeholder={intl.formatMessage({
                    id: "app.placeholder.store",
                  })}
                  onChange={handleStoreChange}
                  value={stores.selected}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {stores.data.map((store) => (
                    <Option key={store.branchId} value={store.branchId}>
                      {store.branchName}
                    </Option>
                  ))}
                </Select>
              </div>
            </header> */}
            <header></header>
            <section>
              {true && (
                <Button
                  type="primary"
                  shape={undefined}
                  size="medium"
                  disabled={props.isAddDisabled}
                  onClick={openAddCategoryModal}
                >
                  {intl.formatMessage({ id: "app.button.addCategory" })}
                </Button>
              )}
            </section>
          </div>
        )}
        {/* {stores.notFound && <Placeholder type="empty" text="No stores found" />} */}
        {/* {(categories.isLoading) && (
          <Placeholder type="loading" text="Loading" />
        )} */}
        <CustomTable
          isLoading={categories.isLoading}
          dataSource={categories.data}
          columns={columns}
          pagination={pagination}
          totalRecords={categories.count}
          onChange={updatePagination}
        />
        {/* { !categories.isLoading && !categories.selected && (
          <>
            <CategoriesList
              addCard={
                <CategoryCard
                  color={props.color}
                  isAdd={true}
                  disabled={props.isAddDisabled}
                  onClick={openAddCategoryModal}
                  title={intl.formatMessage({ id: "app.button.addCategory" })}
                />
              }
              data={categories.data}
              viewItem={handleCategoryView}
              editItem={openEditCategoryModal}
              deleteItem={openDeleteModal}
              count={categories.count}
            />
          </>
        )} */}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  color: state.user.enterprice.color,
  panelType: state.user.enterprice.panelType,
  isAddDisabled: state.user.locale !== "en",
  isRTL: checkRtl(state.user.locale),
});

export default connect(mapStateToProps)(Categories);
