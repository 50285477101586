import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Button, Input } from "antd";

import {
  Panel,
  CustomModal,
  ErrorMessage,
  Label,
} from "../../../../components/common";
import Validations from "../../../../shared/validations/Validations";
import { toastr } from "react-redux-toastr";

const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
};
function AddWalletModal(props) {
  const intl = useIntl();
  const closeModal = () => {
    props.onCloseIconClick();
  };

  const [wallet, changeWallet] = useState(Validations.validateNumericValue(""));
  const [isPristine, changeIsPristine] = useState(true);

  const handleWalletChange = (e) => {
    changeWallet(Validations.validateTextField(e.target.value));
  };

  const handleSubmit = () => {
    changeIsPristine(false);
    if (!wallet.value) {
      return toastr.warning(
        "Invalid Form",
        intl.formatMessage({ id: "app.message.formCheck" })
      );
    } else {
      props.addWallet(wallet.value * 1);
    }
  };

  const resetForm = () => {
    changeWallet(Validations.validateNumericValue(""));
  };

  useEffect(() => {
    if (props.isVisible) {
      changeIsPristine(true);

      resetForm();
    }
  }, [props.isVisible]);

  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={closeModal}
      header="app.pageTitle.addWallet"
      isVisible={props.isVisible}
      action={
        <div>
          <Button onClick={props.onCloseIconClick} shape={undefined}>
            {intl.formatMessage({ id: "app.button.cancel" })}
          </Button>
          <Button
            loading={props.isLoading}
            onClick={handleSubmit}
            type="primary"
            shape={undefined}
          >
            {intl.formatMessage({ id: "app.button.submit" })}
          </Button>
        </div>
      }
    >
      <Panel isModal isLoading={props.isLoading} className={styles.formModal}>
        <div className={styles.formItem}>
          <label>{intl.formatMessage({ id: "app.field.walletAmount" })}</label>
          <Input
            value={wallet.value}
            onChange={handleWalletChange}
            placeholder={intl.formatMessage({
              id: "app.placeholder.walletAmount",
            })}
          />
          {!isPristine && <ErrorMessage field={wallet} />}
        </div>
      </Panel>
    </CustomModal>
  );
}

export default AddWalletModal;
