import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer,
} from "recharts";
import numeral from "numeral";
import { Panel } from "../../../../components/common";
import {
  abbreviateNumber,
  numberWithCommas,
} from "../../../../shared/utils/object-manipulation";
import "./yearly-chart.scss";

const styles = {
  wrapper: "dashboard__yearly-chart__wrapper",
  header: "dashboard__yearly-chart__header",
  containerClass: "dashboard__yearly-chart__container-class",
  transFix: 'custom-trans-fix',
};

function YearlyChart(props) {
  const intl = useIntl();
  const [chartData, updateChartData] = useState([]);
  const formatData = (yearChart) => {
    const formattedData = yearChart.map((item) => ({
      ...item,
      subscribers: item.subscribers,
    }))
    updateChartData(formattedData);
  };

  const formatNums = (num) => {
    return numeral(num).format("0 a");
  };

  useEffect(() => {
    console.log("PROPS: ",props);
    // return;
    formatData(props.yearData);
  }, [props.yearData]);
  return (
    <div className={styles.containerClass}>
      <div className={styles.header}>
        Subscribers Growth by Weeks
        {/* Subscribers {intl.formatMessage({ id: "app.field.growthByYears" })},{" "} */}
        {`${props.yearData?.[0]?.week}-${
          props.yearData?.[props.yearData?.length - 1]?.["week"]
        }`}
      </div>
      <ResponsiveContainer
        width="100%"
        height="100%"
        aspect={3}
        className={styles.transFix}
      >
        <AreaChart data={chartData}>
          <CartesianGrid vertical={false} strokeDasharray="3 0" />
          <XAxis height={20} dataKey="weekLabel"></XAxis>
          <YAxis type="number" tickFormatter={formatNums}>
            <Label
              angle={-90}
              fill={props.color}
              value="No. of Subscribers"
              // value={intl.formatMessage({ id: "app.field.amountOfSales"})}
              position="insideLeft"
              style={{ textAnchor: "middle" }}
            />
          </YAxis>
          <Tooltip formatter={numberWithCommas} />
          <Legend />
          <Area
            type="monotone"
            dataKey="subscribers"
            // dataKey={intl.formatMessage({ id: "app.field.sales"})}
            stroke={props.color}
            fill={props.color}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default YearlyChart;
