import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import {connect} from 'react-redux';
import { CaretDownOutlined, SearchOutlined } from "@ant-design/icons";

import axios from "../../shared/api/axiosConfig";
// import { multiSort } from "../../shared/utils/object-manipulation";
import { getBaseURL } from "../../shared/constants/dropdown";
import {
  Panel,
  Placeholder,
  DelayedInput,
  Header,
} from "../../components/common";
import "./geofence.scss";
import { GeofenceMap, GeofenceAddForm, GeofenceTable } from "./components";

const styles = {
  wrapper: "geofence__wrapper",
  content: "custom-section",
  formContainer: "geofence__form-container",
  mapContainer: "geofence__map-container",
  addContainer: "geofence__add-container",
  tableContainer: "geofence__table-container",
};

const connectPolygon = (paths) => {
  const [firstLng, firstLat] = paths[0];
  const [lastLng, lastLat] = paths[paths.length - 1];
  if (firstLat !== lastLat || firstLng !== lastLng) {
    paths.push([firstLng, firstLat]);
  }
};

function Geofence(props) {
  const intl = useIntl();
  const [stores, updateStores] = useState({
    data: [],
    isLoading: true,
    notFound: false,
  });
  const [pagination, updatePagination] = useState({
    pageType: undefined,
    pageSize: 10,
    skip: 0,
    current: 1,
    search: "",
  });
  const [search, updateSearch] = useState("");
  const [selectedStore, updateSelectedStore] = useState(null);
  const filteredData = () => {
    return geofences.data.filter((i) => {
      if (search === "") {
        return true;
      } else {
        return (
          i.branchName.toLowerCase().includes(search.toLowerCase()) ||
          i.name.toLowerCase().includes(search.toLowerCase()) ||
          i.tags.toLowerCase().includes(search.toLowerCase())
        );
      }
    });
  };

  const handleSearchChange = (val) => {
    updateSearch(val);
  };

  const handleStoreChange = (store) => {
    updateSelectedStore(store);
  };

  const [isFormLoading, changeIsFormLoading] = useState(false);

  const [newGeofenceRef, updateNewGeofenceRef] = useState(null);
  const [geofences, updateGeofences] = useState({
    isLoading: true,
    notFound: false,
    data: [],
  });

  // Child component listen to this value, and trigger a function when this changes
  const [hack, updateHack] = useState(undefined);

  const handleGeofenceChange = (geoFence) => {
    updateNewGeofenceRef(geoFence);
  };

  const getBranches = () => {
    axios
      .get("/getBranches", {
        params: {
          onlyNames: 1,
        },
      })
      .then((res) => {
        if (res.data.data.branchesData.length) {
          // res.data.data.branchesData = multiSort(res.data.data.branchesData, {
          //   branchName: "asc",
          // });
          updateStores({
            ...stores,
            data: res.data.data.branchesData,
            isLoading: false,
            notFound: false,
          });
        } else {
          updateStores({
            ...stores,
            data: [],
            isLoading: false,
            notFound: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (data) => {
    if (newGeofenceRef) {
      const polyArray = newGeofenceRef.getPath().getArray();
      let paths = [];

      polyArray.forEach(function (path) {
        paths.push([path.lng(), path.lat()]);
      });
      connectPolygon(paths);
      data.geoLongLat = paths;
      changeIsFormLoading(true);

      axios
        .post("/addGeoFenceOfBranch", data)
        .then((res) => {
          changeIsFormLoading(false);
          if (res.data.statusCode === 200) {
            updateHack(`${new Date().getTime()}`);
            toastr.success(res.data.message);
            getGeofences();
          } else {
            toastr.error("Error", res.data.message);
          }
        })
        .catch((err) => {
          changeIsFormLoading(false);
          // toastr.error('Error', intl.formatMessage({id: 'app.message.error'}))
        });
    } else {
      toastr.warning("Please create a geofence");
    }
  };

  const handleDelete = (gData) => {
    const data = {
      geofenceId: gData.geofenceId,
      isDeleted: 1,
      branchId: gData.branchId,
    };
    axios
      .put("/editGeoFenceOfBranch", data)
      .then((res) => {
        changeIsFormLoading(false);
        if (res.data.statusCode === 200) {
          getGeofences();
          toastr.success(res.data.message);
        } else {
          getGeofences();
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        changeIsFormLoading(false);
        // toastr.error(
        //   "Error",
        //   intl.formatMessage({ id: "app.message.error" })
        // );
      });
  };

  const toggleGeofence = (geoData) => {
    const data = {
      geofenceId: geoData.geofenceId,
      branchId: geoData.branchId,
      isBlocked: geoData.isBlocked ? 0 : 1,
    };
    updateGeofences({
      ...geofences,
      data: geofences.data.filter((i) => {
        if (i.geofenceId === geoData.geofenceId) {
          i.isBlocked = i.isBlocked ? 0 : 1;
        }
        return i;
      }),
    });
    axios
      .put("/editGeoFenceOfBranch", data)
      .then((res) => {
        changeIsFormLoading(false);
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
        } else {
          updateGeofences({
            ...geofences,
            data: geofences.data.filter((i) => {
              if (i.geofenceId === geoData.geofenceId) {
                i.isBlocked = i.isBlocked ? 0 : 1;
              }
              return i;
            }),
          });

          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        updateGeofences({
          ...geofences,
          data: geofences.data.filter((i) => {
            if (i.geofenceId === geoData.geofenceId) {
              i.isBlocked = i.isBlocked ? 1 : 0;
            }
            return i;
          }),
        });

        changeIsFormLoading(false);
        toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
      });
  };

  const handleEdit = (geoId, branchId) => {
    console.log(
      props.history.push(getBaseURL(`/geofence/edit/${branchId}/${geoId}`))
    );
  };

  const getGeofences = () => {
    axios
      .get("/getAllGeoFenceOfBranch")
      .then((res) => {
        if (res.data.data.length) {
          const data = res.data.data.reduce((acc, val) => {
            val.latLngArray = val.geoLongLat[0].reduce((coords, pair) => {
              coords.push({
                lat: pair.y,
                lng: pair.x,
              });
              return coords;
            }, []);
            acc.push(val);
            return acc;
          }, []);
          console.log(data);
          updateGeofences({
            data,
            isLoading: false,
            notFound: false,
          });
        } else {
          updateGeofences({
            data: [],
            isLoading: false,
            notFound: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        // toastr.error('Error', intl.formatMessage({id: 'app.message.error'}));
        updateGeofences({
          data: [],
          isLoading: false,
          notFound: true,
        });
      });
  };

  useEffect(() => {
    getBranches();
    getGeofences();
  }, []);

  return (
    <div className={styles.wrapper}>
      <Header title="app.pageTitle.geofence" />
      <Panel
        panelStyle={{ marginBottom: "32px" }}
        loaderStyle={{
          height: "70vh",
          marginBottom: "32px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        isLoading={geofences.isLoading}
        className={styles.content}
      >
        <div className={styles.addContainer}>
          <div className={styles.mapContainer}>
            <GeofenceMap
              handleGeofenceChange={handleGeofenceChange}
              stores={stores}
              selectedStore={selectedStore}
              geofences={geofences}
              hack={hack}
            />
          </div>
          <GeofenceAddForm
            hack={hack}
            handleStoreChange={handleStoreChange}
            isLoading={isFormLoading}
            stores={stores.data}
            handleSubmit={handleSubmit}
            isAddDisabled={props.isAddDisabled}
          />
        </div>
      </Panel>
      <div className="custom-section">
        <div className={styles.tableContainer}>
          <div
          className="custom-menu"
          >
            <header></header>
            <section>
            <DelayedInput
                onChange={handleSearchChange}
                value={search}
                delay={500}
                width={200}
                size="medium"
                prefixIcon={<SearchOutlined />}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.search",
                })}
              />
            </section>
          </div>
          <GeofenceTable
            isLoading={geofences.isLoading}
            data={filteredData()}
            pagination={pagination}
            notFound={geofences.notFound}
            toggleGeofence={toggleGeofence}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  storeId: state.user.enterprice.storeId,
  isAddDisabled: state.user.isAddDisabled,
});

export default connect(mapStateToProps)(Geofence);
