import { PANEL_MODE, devSettings } from "../constants/dropdown";

const PANEL_KEY = "trollyAdmin_panel_mode";
const USER_KEY = "trollyAdmin_userInfo";
const ROLES_KEY = "trollyAdmin_roles";
const JWT=  "trollyAdmin_jwt"

const userKeys = [PANEL_KEY, USER_KEY, ROLES_KEY, JWT];
const DEV_RANDOM_STRING = "0kf92h4931firwcj901jef";

const DEV_DATA = `${DEV_RANDOM_STRING}_dev`;
const panelData = {
  clientIndex: 0,
  panelIndex: 0,
  endpoint: process.env.REACT_APP_API_ENDPOINT,
  apiKey: null,
  domainName: null,
};

export const clearUserData = () => {
  userKeys.forEach((i) => {
    localStorage.removeItem(i);
  });
};

export class DevStorage {
  static allKeys = [
    PANEL_KEY,
    USER_KEY,
    JWT,
    ROLES_KEY,
    DEV_DATA,
  ];

  static getData = () => {
    let data = localStorage.getItem(DEV_DATA);
    if(!data) {
        data = {}
    } else {
        data = JSON.parse(data);
    }
    const clientIndex = data.clientIndex || 0;
    const panelIndex = data.panelIndex || 0;
    const endpoint = data.endpoint || process.env.REACT_APP_API_ENDPOINT;
    const domainName = data.domainName || devSettings.clients[clientIndex].panel[panelIndex].url;
    const apiKey = data.apiKey || null;

    return {
      clientIndex,
      panelIndex,
      endpoint,
      domainName,
      apiKey,
    };
  };

  static setData = (data) => {
      clearUserData();
      
      const prevData = DevStorage.getData();
      const newData = {
        ...prevData,
        ...data,
    };
    newData.domainName = devSettings.clients[newData.clientIndex].panel[newData.panelIndex].url;
      localStorage.setItem(DEV_DATA, JSON.stringify(newData));
    //   debugger;
  };

  static reset = () => {
    DevStorage.allKeys.forEach((key) => {
      localStorage.removeItem(key);
    });
    DevStorage.setData({})
  };
}

export const getPanelType = () => {
  // return 0;
  const mode = localStorage.getItem(PANEL_KEY);
  if (!mode) {
    return 0;
  }
  return mode;
};

export const getUserInfo = () => {
  const info = localStorage.getItem(USER_KEY);
  try {
    if (!info) {
      return {};
    }
    return JSON.parse(info);
  } catch (e) {
    return {};
  }
};

export const getRolesFromStorage = () => {
  const roles = localStorage.getItem(ROLES_KEY);
  // debugger;
  if (!roles) {
    return [];
  } else {
    return JSON.parse(roles);
  }
};
export const setPanelType = (mode) => {
  localStorage.setItem(PANEL_KEY, mode);
};
