import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Select, Button } from "antd";
import { toastr } from "react-redux-toastr";

import axios from "../../../../shared/api/axiosConfig";
import {
  Panel,
  CustomModal,
  ErrorMessage,
  Label,
} from "../../../../components/common";
import "./assign-picker-modal.scss";

const { Option } = Select;

const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
};
function AssignPickerModal(props) {
  const intl = useIntl();
  const closeModal = () => {
    props.onCloseIconClick();
  };

  const [pickers, updatePickers] = useState({
    selected: undefined,
    data: [],
    isLoading: false,
  });

  const [isPristine, changeIsPristine] = useState(true);

  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const handlePickerChange = (picker) => {
    updatePickers({
      ...pickers,
      selected: picker,
    });
  };

  const handlePickerAssign = () => {
    changeIsPristine(false);
    if (pickers.selected) {
      props.assignPicker(pickers.selected);
    }
  };

  const getPickers = (id) => {
    console.log('callllled')
    updatePickers({
      ...pickers,
      selected: undefined,
      data: [],
      isLoading: true,
    });
    axios
      .get("/listPickers", {
        params: {
          onlyNames: 1,
          branchId: id,
        },
      })
      .then((res) => {
        if (res.data.data.pickers.length) {
          updatePickers({
            ...pickers,
            data: res.data.data.pickers,
            isLoading: false,
          });
        } else {
          toastr.error("Error", "No pickers found");
          updatePickers({
            ...pickers,
            data: [],
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        // toastr.error("Error", "Something went wrong");
        updatePickers({
          ...pickers,
          data: [],
          isLoading: false,
        });
      });
  };

  useEffect(() => {
    if (props.isVisible) {
      changeIsPristine(true);
      getPickers(props.data.branchId);
    }
  }, [props.isVisible]);
  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={closeModal}
      header={intl.formatMessage({ id: "app.pageTitle.assignPicker" })}
      isVisible={props.isVisible}
      action={<>  <Button onClick={props.onCloseIconClick} shape={undefined}>
      {intl.formatMessage({id: 'app.button.cancel'})}
      </Button>
      <Button onClick={handlePickerAssign} loading={props.isLoading} type="primary" shape={undefined}>
      {intl.formatMessage({id: 'app.pageTitle.assignPicker'})}
      </Button></>}
    >
      <Panel
        isModal
        isLoading={props.isLoading || pickers.isLoading}
        className={styles.formModal}
      >
        <div className={styles.formItem}>
          <Select
            showSearch
            placeholder={intl.formatMessage({
              id: "app.placeholder.picker",
            })}
            onChange={handlePickerChange}
            value={pickers.selected}
            optionFilterProp="children"
            filterOption={filterOption}
          >
            {pickers.data.map((picker) => (
              <Option key={picker.pickerId} value={picker.pickerId}>
                {picker.name}
              </Option>
            ))}
          </Select>
          {!isPristine && !pickers.selected && (
            <ErrorMessage text="Please select a picker" />
          )}
        </div>
      </Panel>
    </CustomModal>
  );
}

export default AssignPickerModal;
