import React, { useState } from "react";
import { Popconfirm, Tooltip, Switch, Dropdown, Menu } from "antd";
import {MoreOutlined} from '@ant-design/icons';
import { useIntl } from "react-intl";
import classNames from "classnames";

import { Panel, Icon, CustomTable, CustomTag } from "../../../../components/common";
import "./geofence-table.scss";
const styles = {
  tableActions: "geofence-table__table-actions",
  actionIcon: "geofence-table__action-icon",
  table: 'custom-table',
};
function GeofenceTable(props) {
  const intl = useIntl();
  const getMenu = (item) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            props.handleEdit(item.geofenceId, item.branchId);
          }}
        >
          {intl.formatMessage({id: 'app.field.edit'})}
        </Menu.Item>
        { item.isBlocked == 0  && (
          <Menu.Item
            onClick={() => {
              props.toggleGeofence(item);
            }}
          >
            {intl.formatMessage({id: 'app.field.block'})}
          </Menu.Item>
        )}
        { item.isBlocked == 1 && (
          <Menu.Item
            onClick={() => {
              props.toggleGeofence(item);
            }}
          >
            {intl.formatMessage({id: 'app.field.unblock'})}
          </Menu.Item>
        )}

        <Menu.Item
          onClick={() => {
            props.handleDelete(item);
          }}
        >
          {intl.formatMessage({id: 'app.field.delete'})}
        </Menu.Item>
      </Menu>
    );
  };
  const columns = [
    {
      dataIndex: '',
      title: intl.formatMessage({id: 'app.field.sNo'}),
      render: (item,object,index) => {
        return (<span>{props.pagination.pageSize * (props.pagination.current - 1) + index + 1}</span>)
      }
    },
    {
      dataIndex: 'branchName',
      title: intl.formatMessage({id: 'app.field.storeName'}),
    },
    {
      dataIndex: 'name',
      title: intl.formatMessage({id: 'app.field.areaName'}),
    },
    {
      dataIndex: 'tags',
      title: intl.formatMessage({id: 'app.field.areaDescription'}),
    },
    {
      dataIndex: 'isBlocked',
      title: intl.formatMessage({id: 'app.field.status'}),
      render: (i) => <CustomTag isBlocked={i} />
    },
    {
      dataIndex: "",
      fixed: "right",
      title: intl.formatMessage({id: 'app.field.action'}),
      render: (item) => {
        // debugger;
        return (
          <Dropdown overlay={getMenu(item)}>
            <MoreOutlined />
          </Dropdown>
        );
      },
    }
  ]
  return (
    <CustomTable
    dataSource={props.data}
    columns={columns}
    pagination={false}
     />
  );
}

export default GeofenceTable;
