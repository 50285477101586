import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Select, Dropdown, Button, Menu,Popconfirm } from "antd";
import { CaretDownOutlined, MoreOutlined, SearchOutlined } from "@ant-design/icons";
import { connect } from 'react-redux';

import { RECORDS_PER_PAGE } from "../../shared/constants/dropdown";
import {
  Header,
  PaginationInfo,
  ConfirmModal,
  Label,
  DelayedInput,
  CustomTable,
  CustomImage,
  CustomTag,
  BackButton
} from "../../components/common";
import {
  getBaseURL,
  textTrimmer,
  TABLE_TEXT_LIMIT,
} from "../../shared/constants/dropdown";
import { toastr } from "react-redux-toastr";
import { removeEmptyKeys } from "../../shared/utils/object-manipulation";
import axios from "../../shared/api/axiosConfig";
import AddEditPart from "./components/add-edit-part/add-edit-part";
import { checkRtl } from "../../shared/utils/rtl";

const styles = {
  wrapper: "parts__wrapper",
  upperMenu: "parts__upper-menu",
  upperMenuLeft: "parts__upper-menu__left",
  upperMenuRight: "parts__upper-menu__right",
  upperMenuDropdown: "parts__upper-menu__dropdown",
  recordsPerPage: "products__upper-menu__records-per-page",
};

const lessonType = [
  {
    key: "video",
    value: "Video",
  },
  {
    key: "reading",
    value: "Reading",
  }
];
const { Option } = Select;

function Parts(props) {
  const intl = useIntl();
  const [parts, updateParts] = useState({
    data: [],
    notFound: false,
    isLoading: true,
    count: null,
  });

  const [search, updateSearch] = useState("");

  const handleSearchChange = (val) => {
    updateSearch(val);
  };

  const handleSearch = (val) => {
    updatePagination({
      ...pagination,
      currentPage: 1,
      search: val,
      skip: 0,
    });
  };

  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    isDeleteModalVisible: false,
    isDisableModalVisible: false,
    data: {},
    type: "",
  });

  const [pagination, updatePagination] = useState({
    recordsPerPage: 10,
    skip: 0,
    currentPage: 1,
    search: "",
  });

  const [tableControls, updateTableControls] = useState({
    isTableLoading: true,
    isTableEmpty: false,
  });

  const openAddPartModal = () => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      type: "ADD_PART",
    });
  };

  const openEditPartModal = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      data,
      type: "EDIT_PART",
    });
  };

  const openDeletePartModal = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: false,
      isDeleteModalVisible: true,
      data,
      type: "",
    });
  };

  const handleModalClose = () => {
    updateModalControls({
      isVisible: false,
      isDeleteModalVisible: false,
      data: {},
    });
  };

  const handlePaginationChange = (page) => {
    updatePagination({
      ...pagination,
      currentPage: page,
      skip: (page - 1) * pagination.recordsPerPage,
    });
  };

  const handleRecordsPerPageChange = (val) => {
    updatePagination({
      ...pagination,
      recordsPerPage: val,
    });
  };

  const getParts = () => {
    updateParts({
      ...parts,
      isLoading: true,
      notFound: false,
    });
    axios
      .get(`/courses/lessons/${props.match.params.lessonId}`, {
        params: removeEmptyKeys({
          limit: pagination.recordsPerPage,
          skip: (pagination.currentPage - 1) * pagination.recordsPerPage,
          search: pagination.search,
        }),
      })
      .then((res) => {
        if (res.data.data) {
          updateParts({
            ...parts,
            data: res.data.data.parts.map((i) => {
              return i;
            }),
            isLoading: false,
            notFound: false,
            count: res.data.data.parts.length,
          });

          updateTableControls({
            isTableEmpty: false,
            isTableLoading: false,
          });
        } else {
          toastr.error("Error", "No Parts found");
          updateTableControls({
            isTableEmpty: true,
            isTableLoading: false,
          });
          updateParts({
            ...parts,
            data: [],
            isLoading: false,
            notFound: true,
            count: 0,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        updateParts({
          ...parts,
          isLoading: false,
        });
      });
  };

  useEffect(() => {
    getParts();
  }, [
    pagination.recordsPerPage,
    pagination.currentPage,
    pagination.search,
  ]);

  const handlePartAdd = (data) => {
    console.log("add called");
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });

    axios
      .post(`/courses/lessons/${props.match.params.lessonId}/parts`, removeEmptyKeys(data))
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isVisible: false,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getParts();
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateModalControls({
          ...modalControls,
          isLoading: false,
        });
      });
  };

  const handlePartEdit = (data) => {
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });
    // data.courseId = modalControls.data.courseId;
    axios
      .put(`/courses/lessons/parts/${modalControls.data.lessonMetaId}`, removeEmptyKeys(data))
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isVisible: res.data.statusCode !== 200,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getParts();
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateModalControls({
          ...modalControls,
          isLoading: false,
        });
      });
  };

  const handlePartDelete = (item) => {
    const data = {
      metaId: item.metaId,
    //   isDeleted: 1,
    };
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });

    axios
      .delete(`/courses/lessons/parts/${item.lessonMetaId}`, removeEmptyKeys(data))
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isDeleteModalVisible: res.data.statusCode !== 200,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getParts();
        } else {
          toastr.error("Error", res.data.message);
          updateModalControls({
            ...modalControls,
            isDeleteModalVisible: false,
          });
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateModalControls({
          ...modalControls,
          isDeleteModalVisible: false,
        });
      });
  };

  const handlePartToggle = (data) => {
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });
    const url = `/${
      data.isActive ? `parts/inactive/${data.lessonMetaId}` : `parts/active/${data.lessonMetaId}`
    }`;
    axios
      .put(url)
      .then((res) => {
        if (res.data.statusCode !== 200) {
          toastr.error("Error", res.data.message);
          return;
        }
        toastr.success(res.data.message);
        updateParts({
          ...parts,
          data: parts.data.map((item) => {
            if (item.partId === data.partId) {
              item.isActive = item.isActive ? 0 : 1;
            }
            return item;
          }),
        });
        getParts();
        updateModalControls({
          ...modalControls,
          isLoading: false,
        });
      })
      .catch((err) => {
        // toastr.error("Error", err.data.message);
          updateModalControls({
            ...modalControls,
            isLoading: false,
          });
        // toastr.error('Error', intl.formatMessage({id: 'app.message.error'}))
      });
  };

  const getMenu = (item) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            openEditPartModal(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.edit" })}
        </Menu.Item>
        {item.isActive == 1 && (
          <Menu.Item
            onClick={() => {
              handlePartToggle(item);
            }}
          >
            {intl.formatMessage({ id: "app.field.inactive" })}
          </Menu.Item>
        )}
        {!item.isActive == 1 && (
          <Menu.Item
            onClick={() => {
              handlePartToggle(item);
            }}
          >
            {intl.formatMessage({ id: "app.field.active" })}
          </Menu.Item>
        )}
        <Menu.Item
        >
          <Popconfirm
            title={intl.formatMessage({
              id: "app.message.deleteWarning",
            })}
            onConfirm={() => {
              handlePartDelete(item);
            }}
            placement="topRight"
            okText={intl.formatMessage({ id: "app.field.yes" })}
            cancelText={intl.formatMessage({ id: "app.field.no" })}
          >
            {intl.formatMessage({ id: "app.field.delete" })}
          </Popconfirm>
        </Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      dataIndex: "",
      // sorter: true,
      title: intl.formatMessage({ id: "app.field.sNo" }),
      // fixed: 'left',
      render: (e, item, index) => {
        return (
          <span>
            {pagination.recordsPerPage * (pagination.currentPage - 1) +
              index +
              1}
          </span>
        );
      },
    },
    // {
    //   dataIndex: "cover",
    //   title: intl.formatMessage({ id: "app.field.image" }),
    //   render: (i) => {
    //     return <CustomImage isTable src={i} />;
    //   },
    // },
    {
      dataIndex: "title",
      title: intl.formatMessage({ id: "app.field.title" }),
    },

    {
        dataIndex: "lead",
        title: "Introduction Text",
    },

    {
        dataIndex: "",
        title: "Introduction Video",
        render: (i) => {
            // return (<iframe width="420" height="345" src={i}>
            // </iframe>)
            return (
                <div>
                {i.lessonType === "video" ? <a href={i.videoLink} target="_blank">{i.videoLink}</a> : <span>N/A</span>}
                {/* <iframe width="50" height="50" src={i} frameborder="0" allowfullscreen ng-show="showvideo"></iframe> */}
                </div>
            )
        }
    },
    {
      dataIndex: "lessonType",
      title: "Lesson Type",
      render: (item) => (
        lessonType.map((bt) => {
          if(item == bt.key){
           return <span>{bt.value}</span>
          }
        })
      )
    },
    {
      dataIndex: "isActive",
      title: intl.formatMessage({ id: "app.field.status" }),
      render: (item) => {
        return <CustomTag isActive={item} />;
      },
    },
    {
      dataIndex: "",
      title: intl.formatMessage({ id: "app.field.action" }),
      fixed: "right",
      render: (item) => {
        return (
          <Dropdown overlay={getMenu(item)}>
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  return (
    <div className={styles.wrapper}>
      <BackButton open />
      <AddEditPart
        isVisible={modalControls.isVisible}
        isLoading={modalControls.isLoading}
        onClose={handleModalClose}
        onCloseIconClick={handleModalClose}
        addPart={handlePartAdd}
        editPart={handlePartEdit}
        modalToShow={modalControls.type}
        data={modalControls.data}
      />
      <ConfirmModal
        header={`Delete ${modalControls?.data?.name}?`}
        message={intl.formatMessage({
          id: "app.message.deleteWarning",
        })}
        onCloseIconClick={handleModalClose}
        onAction={handlePartDelete}
        isVisible={modalControls.isDeleteModalVisible}
        actionText="Delete"
        isLoading={modalControls.isLoading}
      />
      <Header title="Parts" />

      <div className="custom-section">
        <div className="custom-menu">
          <header>
          </header>
          <section>
            <DelayedInput
              onChange={handleSearchChange}
              value={search}
              callback={handleSearch}
              delay={500}
              minLength={3}
              size="medium"
              prefixIcon={<SearchOutlined />}
              placeholder={intl.formatMessage({ id: "app.placeholder.search" })}
            />
            <Button
              onClick={openAddPartModal}
              shape=""
              size="medium"
              disabled={props.isAddDisabled}
              type="primary"
            >
                Add Part
            </Button>
          </section>
        </div>
        <CustomTable
          isLoading={parts.isLoading}
          dataSource={parts.data}
          columns={columns}
          pagination={pagination}
          totalRecords={parts.count}
          onChange={updatePagination}
        />
      </div>
    </div>
  );
}


const mapStateToProps = (state) => ({
  userInfo: state.user.userInfo,
  isRTL: checkRtl(state.user.locale),
});
export default connect(mapStateToProps)(Parts);