import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Select, Menu, Tooltip, Rate } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import moment from 'moment';
import {PAYMENT_METHODS_MAPPING, ORDER_STATUS_MAPPING} from '../../shared/constants/orders';
import { CustomerDetailsTable, CustomerDetailsFormModal } from "./components";
import { OrderDetailsModal } from "../../containers/orders/components";
import { RECORDS_PER_PAGE, getBaseURL } from "../../shared/constants/dropdown";
import {nestedParseJSON} from '../../shared/utils/object-manipulation';
import {
  Header,
  Panel,
  Icon,
  PaginationInfo,
  DelayedInput,
  BackButton,
  CustomTable,
} from "../../components/common";
import { toastr } from "react-redux-toastr";
import {
  removeEmptyKeys,
  numberWithCommas,
} from "../../shared/utils/object-manipulation";
import axios from "../../shared/api/axiosConfig";
import placeholderImg from "../../assets/images/profile-placeholder.png";
import "./customer-details.scss";

const styles = {
  wrapper: "customer-details__wrapper",
  menu: "customer-details__menu",
  recordsPerPage: "customer-details__menu__records-per-page",
  image: "customer-details__image",
  form: "customer-details__form",
  formItem: "custom-form-item",
  formGroup: "custom-form-group",
  formWrapper: "customer-details__form-wrapper",
  editIcon: "customer-details__edit-icon",
  backButton: "customer-details__back-button",
};

const { Option } = Select;

function CustomerDetails(props) {
  const intl = useIntl();

  const [search, updateSearch] = useState("");

  const [pagination, updatePagination] = useState({
    recordsPerPage: 10,
    skip: 0,
    currentPage: 1,
    search: "",
    delayedCurrentPage: 1,
    delayedRecordsPerPage: 10,
  });

  const [tableControls, updateTableControls] = useState({
    isLoading: true,
    isEmpty: false,
    data: [],
    count: "",
  });

  const [customerData, updateCustomerData] = useState({
    isLoading: true,
    customerId: "",
    availableCredits: "",
    walletAmount: "",
    isBlocked: "",
    profilePicURL: "",
    phoneNo: "",
    email: "",
    name: "",
    dateOfBirth: "",
    pet: {},
  });

  const [modalControls, updateModalControls] = useState({
    formModalVisible: false,
    orderDetailsModalVisible: false,
    data: {},
    isLoading: false,
  });

  const openOrderDetailsModal = (data) => {
    updateModalControls({
      ...modalControls,
      orderDetailsModalVisible: true,
      data,
    });
  };

  const openFormModal = () => {
    updateModalControls({
      ...modalControls,
      formModalVisible: true,
      data: customerData,
    });
  };

  const closeModal = () => {
    updateModalControls({
      ...modalControls,
      formModalVisible: false,
      orderDetailsModalVisible: false,
    });
  };

  const addWallet = (value) => {
    console.log(value, modalControls.data);
  };

  // Mount
  const handlePaginationChange = (page) => {
    updatePagination({
      ...pagination,
      currentPage: page,
      skip: (page - 1) * pagination.recordsPerPage,
    });
  };
  const handleTableChange = (pag, filt, sorter) => {
    // debugger;
    updatePagination({
      ...pagination,
      recordsPerPage: pag.recordsPerPage,
      currentPage: pag.current,
      skip: pag.skip,
      delayedCurrentPage: pag.delayedCurrentPage,
      delayedRecordsPerPage: pag.delayedRecordsPerPage,
    });
  };

  const handleRecordsPerPageChange = (val) => {
    updatePagination({
      ...pagination,
      recordsPerPage: val,
    });
  };

  const handleSearchChange = (val) => {
    updateSearch(val);
  };

  const handleSearch = (val) => {
    updatePagination({
      ...pagination,
      currentPage: 1,
      search: val,
      skip: 0,
    });
  };


  // const getOrdersData = (branchIds, filterValue) => {
  //   // if(branchIds === undefined) {
  //   //   branchIds = Object.keys(activeTags.mapping);
  //   // }
  //   // if(filterValue === undefined){
  //   //   filterValue = filter;
  //   // }
  //   updateTableControls({
  //     ...tableControls,
  //     isLoading: true,
  //     isEmpty: false,
  //     data: [],
  //   });
  //   // const sortCriterias = JSON.stringify([
  //   //   {
  //   //     criteria: 0,
  //   //     order: sortBy,
  //   //   },
  //   // ]);
  //   axios
  //     .get("/getAllOrders", {
  //       params: removeEmptyKeys({
  //         // branchId: stores.selected,
  //         limit: pagination.recordsPerPage,
  //         skip: (pagination.currentPage - 1) * pagination.recordsPerPage,
  //         customerId: props.match.params.customerId
  //         // status: filterValue,
  //         // search: pagination.search,
  //         // branchIds,
  //         // startDate: new Date(startDate.toISOString()).toUTCString(),
  //         // endDate: new Date(endDate.toISOString()).toUTCString(),
  //         // sortCriterias,
  //         // countryId: props.selectedCountry,
  //         // cityIds: props.selectedCities,
  //       }),
  //     })
  //     .then((res) => {
  //       // storesWOrderCount
     
  //       if (res.data.data.bookingData.length) {
  //         updateTableControls({
  //           ...tableControls,
  //           data: res.data.data.bookingData.map((i) => {
  //             i.customer = JSON.parse(i.customer);
  //             i.branch = JSON.parse(i.branch);
  //             i.picker = JSON.parse(i.picker);
  //             i.address = JSON.parse(i.address);
  //             return i;
  //           }),
  //           totalRecords: res.data.data.totalBooking,
  //           isLoading: false,
  //           isEmpty: false,
  //         });
  //       } else {
  //         updateTableControls({
  //           ...tableControls,
  //           data: [],
  //           totalRecords: res.data.data.totalBooking,
  //           isLoading: false,
  //           isEmpty: true,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       updateTableControls({
  //         ...tableControls,
  //         data: [],
  //         totalRecords: 0,
  //         isLoading: false,
  //         isEmpty: true,
  //       });
  //     });
  // };

  const getCustomersData = () => {
    updateTableControls({
      ...tableControls,
      isEmpty: false,
      isLoading: true,
    });
    axios
      .get(`/customers/${props.match.params.customerId}`, {
        params: removeEmptyKeys({
          // customerId: props.match.params.customerId,
          // limit: pagination.recordsPerPage,
          // skip: (pagination.currentPage - 1) * pagination.recordsPerPage,
        }),
      })
      .then((res) => {
        updateTableControls({
          ...tableControls,
          isLoading: false,
          isEmpty: res.data.data?.length === 0,
          data: nestedParseJSON(res.data.data) || [],
          count: res.data.data.length || 0,
        });

        updateCustomerData({
          ...res.data.data,
          isLoading: false,
        });

        updatePagination({
          ...pagination,
          delayedCurrentPage: pagination.currentPage,
          delayedRecordsPerPage: pagination.recordsPerPage,
        });
      })
      .catch((err) => {
        // toastr.error("Error", "Something Went Wrong");
        updateTableControls({
          ...tableControls,
          isEmpty: true,
          isLoading: false,
          data: [],
        });
        updateCustomerData({
          ...customerData,
          isLoading: false,
        });
      });
  };

  const handleImageError = (e) => {
    e.target.src = placeholderImg;
  };

  const editCustomer = (data) => {
    updateModalControls({
      ...modalControls,
      isLoading: true,
    })
    // data.customerId = modalControls.data.customerId;
    axios.put(`/customers/restore/${modalControls.data.customerId}`, data).then((res) => {
      updateModalControls({
        ...modalControls,
        formModalVisible: res.data.statusCode !== 200,
        isLoading: false,
      })

      if(res.data.statusCode === 200) {
        toastr.success(res.data.message);
        getCustomersData();
      } else {
        toastr.error('Error', res.data.message);
      }
    }).catch((err) => {
      updateModalControls({
        ...modalControls,
        formModalVisible: false,
      })
      // toastr.error('Error', intl.formatMessage({id: 'app.message.error'}));
    })
  }

  useEffect(() => {
    getCustomersData();
    // getOrdersData();
  }, []);
  const getMenu = (item) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            openOrderDetailsModal(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.view" })}
        </Menu.Item>
      </Menu>
    );
  };
  const columns = [
    {
      dataIndex: "",
      // sorter: true,
      title: intl.formatMessage({ id: "app.field.index" }),
      // fixed: 'left',
      render: (item, object, index) => {
        return (
          <span>
            {pagination.recordsPerPage * (pagination.currentPage - 1) + index + 1}
            {/* {pagination.delayedRecordsPerPage * (pagination.delayedCurrentPage - 1) + index + 1} */}
          </span>
        );
      },
    },
    {
      dataIndex: "bookingId",
      title: intl.formatMessage({ id: "app.field.bookingNumber" }),
      // fixed: 'left',
    },
    {
      dataIndex: "",
      title: intl.formatMessage({ id: "app.field.storeName" }),
      render: (item) => `${item.branch?.branchName}`,
    },
    {
      dataIndex: "",
      title: intl.formatMessage({ id: "app.field.deliveryAddress" }),
      render: (item) => `${item.address?.address}`,
    },
    {
      dataIndex: "bookingDateTime",
      title: intl.formatMessage({ id: "app.field.bookingTime" }),
      render: (i, item) => `${moment(item.bookingDateTime).format("MMMM Do YYYY")}`,
    },
    {
      dataIndex: "modeOfPaymentStatus",
      title: intl.formatMessage({ id: "app.field.paymentMode" }),
      render: (item) => PAYMENT_METHODS_MAPPING[item],
    },
    
    {
      dataIndex: "status",
      title: intl.formatMessage({ id: "app.field.status" }),
      render: (item) => ORDER_STATUS_MAPPING[item],

      // render: (item) => {
      //   // return item.isBlocked ? <Tag color="volcano">BLOCKED</Tag> : <Tag color="green">ACTIVE</Tag>
      //   return <CustomTag isBlocked={item.IsBlocked} />;
      // },
    },
    {
      title: intl.formatMessage({ id: "app.field.action" }),
      dataIndex: "",
      fixed: "right",
      width: 100,
      render: (item) => {
        return(
          <center>
            <Tooltip title={intl.formatMessage({ id: "app.field.viewDetails" })} placement="top">
              <span
                onClick={() => {
                  openOrderDetailsModal(item);
                }}
                style={{ display:"flex",justifyContent:"center"}}
                // className={styles.actionIcon}
              >
                <Icon name="DETAILS"  size="small" />
              </span>
            </Tooltip>
          </center>
        )
      },
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.backButton}>
      <BackButton open />
        </div>
      <OrderDetailsModal
        isVisible={modalControls.orderDetailsModalVisible}
        data={modalControls.data}
        onCloseIconClick={closeModal}
      />
      <CustomerDetailsFormModal
        isVisible={modalControls.formModalVisible}
        // data={modalControls.data}
        onCloseIconClick={closeModal}
        editCustomer={editCustomer}
        data={modalControls.data}
        isLoading={modalControls.isLoading}
        // modalType={modalControls.type}
      />
      <Header title="app.pageTitle.customerDetails" 
        crumbs={[getBaseURL("/customers")]}
        />
      <Panel isLoading={customerData.isLoading}>
        <div className={styles.formWrapper}>
          {/* <Tooltip placement="top" title={intl.formatMessage({ id: "app.field.edit" })}>
          <div className={styles.editIcon}>
            <Icon name="PEN" onClick={openFormModal} />
          </div>
          </Tooltip> */}
          <div className={styles.image}>
            <img
              src={
                customerData.profilePicURL
                  ? customerData.profilePicURL
                  : placeholderImg
              }
              onError={handleImageError}
              alt="Customer Profile Pic"
            />
          </div>
          <div className={styles.form}>
            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <label>
                  {intl.formatMessage({ id: "app.field.name" })}
                </label>
                <div className={styles.detailsField}>
                  {customerData.name ? customerData.name : "N/A"}
                </div>
              </div>
              <div className={styles.formItem}>
                <label>{intl.formatMessage({ id: "app.field.email" })}</label>
                <div className={styles.detailsField}>{customerData.email ? customerData.email : "N/A"}</div>
              </div>
            </div>
            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <label>
                  {intl.formatMessage({ id: "app.field.phoneNumber" })}
                </label>
                <div className={styles.detailsField}>
                  {customerData.phoneNo ? customerData.phoneNo : "N/A"}
                </div>
              </div>
              <div className={styles.formItem}>
                <label>
                  Total Certifications
                  {/* {intl.formatMessage({ id: "app.field.totalBookings" })} */}
                </label>
                <div className={styles.detailsField}>{tableControls.count ? tableControls.count : 0 }</div>
              </div>
            </div>
            <div className={styles.formGroup}>
              {/* <div className={styles.formItem}>
                <label>
                  {intl.formatMessage({ id: "app.field.walletAmount" })}
                </label>
                <div className={styles.detailsField}>
                  {customerData.walletAmount ? numberWithCommas(customerData.walletAmount) : "N/A" }
                </div>
              </div> */}
            </div>
            <div className={styles.formGroup}>
              {/* <div className={styles.formItem}>
                <label>
                  {intl.formatMessage({ id: "app.field.age" })}
                </label>
                <div className={styles.detailsField}>
                  {customerData.dateOfBirth ? moment().diff(customerData.dateOfBirth, 'years'): "N/A"}
                </div>
              </div> */}
              {customerData.gender && (<div className={styles.formItem}>
                <label>
                  {intl.formatMessage({ id: "app.field.gender" })}
                </label>
                <div className={styles.detailsField}>{customerData.gender ? customerData.gender : "N/A"}</div>
              </div>)}
            {customerData.feedback && (
              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <label>Feedback Rating</label>
                  <div className={styles.detailsField}>
                    <Rate disabled={true} allowHalf={true} value={customerData.feedback?.rating} />                    
                  </div>
                </div>
                <div className={styles.formItem}>
                  <label>Feedback Comments</label>
                  <div className={styles.detailsField}>
                    {customerData.feedback?.remark ? customerData.feedback.remark : "N/A"}
                  </div>
                </div>
              </div>
            )}
            </div>
          </div>
        </div>
        {true && (
        <div className={styles.formWrapper}>
            <Header title="Pet Details" />

          <div className={styles.image}>
            <img
              src={
                customerData.pet?.image
                  ? customerData.pet.image
                  : placeholderImg
              }
              onError={handleImageError}
              alt="Pet Profile Pic"
            />
          </div>
          <div className={styles.form}>
            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <label>
                  {intl.formatMessage({ id: "app.field.name" })}
                </label>
                <div className={styles.detailsField}>
                  {customerData.pet?.name ? customerData.pet.name : "N/A"}
                </div>
              </div>
              <div className={styles.formItem}>
                <label>Breed</label>
                <div className={styles.detailsField}>{customerData.pet?.breed ? customerData.pet.breed : "N/A"}</div>
              </div>
            </div>
            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <label>
                  Age
                </label>
                <div className={styles.detailsField}>
                  {customerData.pet?.age ? customerData.pet.age : "N/A"}
                </div>
              </div>
            </div>
          </div>
        </div>)}
      </Panel>
      {/* <Header title="app.pageTitle.orders" /> */}
      <div className={styles.menu}>
        {/* <div className={styles.recordsPerPage}>
        <label>{intl.formatMessage({id: 'app.field.recordsPerPage'})}</label>
          <Select
            onChange={handleRecordsPerPageChange}
            value={pagination.recordsPerPage}
          >
            {RECORDS_PER_PAGE.map((item) => (
              <Option key={item.key} value={item.value}>
                {item.value}
              </Option>
            ))}
          </Select>
        </div> */}

        {/* <div>
          <DelayedInput
            onChange={handleSearchChange}
            value={search}
            callback={handleSearch}
            delay={500}
            minLength={3}
            size="medium"
            prefixIcon={<SearchOutlined />}
            placeholder={intl.formatMessage({ id: "app.placeholder.search" })}
          />
        </div> */}
      </div>
      {/* <CustomerDetailsTable
        isLoading={tableControls.isLoading}
        isEmpty={tableControls.isEmpty}
        data={tableControls.data}
        viewDetails={openOrderDetailsModal}
        currentPage={pagination.delayedCurrentPage}
        recordsPerPage={pagination.delayedRecordsPerPage}
      /> */}
     {/* <div style={{margin: `0 32px`}}>
     <CustomTable
        isLoading={tableControls.isLoading}
        columns={columns}
        dataSource={tableControls.data}
        isEmpty={tableControls.isEmpty}
        // dataSource={tableControls.data}
        // toggleItemBlock={handleCustomerToggle}
        viewDetails={openOrderDetailsModal}
        pagination={updatePagination}
        onChange={handleTableChange}
        // onChange={handlePaginationChange}
        current={pagination.delayedCurrentPage}
        pageSize={pagination.delayedRecordsPerPage}
        />
     </div> */}

    </div>
  );
}
export default CustomerDetails;
