import React, { useState, useEffect } from "react";
import { Select, Dropdown, Button, Popconfirm } from "antd";
import { useIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import { SearchOutlined } from "@ant-design/icons";
import { connect } from "react-redux";

import {
  RECORDS_PER_PAGE,
  verticles,
  verticleType,
} from "../../shared/constants/dropdown";
import {
  Header,
  PaginationInfo,
  DelayedInput,
  Label,
} from "../../components/common";
import {
  PushNotificationTable,
  PushNotificationModal,
  CustomersTable,
} from "./components";
import axios from "../../shared/api/axiosConfig";
// import foodAxios from '../../shared/api/foodConfig';
import pnAxios from "../../shared/api/notifications";
import { removeEmptyKeys } from "../../shared/utils/object-manipulation";
import "./push-notification.scss";

const { Option } = Select;

const styles = {
  wrapper: "push-notification__wrapper",
  content: "custom-section",
  menu: "custom-menu",
  menuLeft: "push-notification__menu--left",
  menuRight: "push-notification__menu--right",
  pageSize: "push-notification__records-per-page",
};
const SEND_CRITERIA = [
  // {
  //   value: 0,
  //   text: "geofences",
  // },
  {
    value: 1,
    text: "customers",
  },
];

function PushNotification(props) {
  const intl = useIntl();

  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    data: "",
    type: "",
  });
  const [searchDelayed, updateSearchDelayed] = useState("");
  const [sendCriteria, changeSendCriteria] = useState(1);

  const [tableControls, updateTableControls] = useState({
    isLoading: false,
    data: [],
    count: 0,
    isEmpty: false,
  });
  const handleCriteraChange = (data) => {
    updateTableControls({
      ...tableControls,
      data: [],
    });
    changeSendCriteria(data);
    updateIds([]);
  };
  const [customerSearch, updateCustomerSearch] = useState("");
  const [geofenceSearch, updateGeofenceSearch] = useState("");

  const [ids, updateIds] = useState([]);
  const handleSearch = (text) => {
    if (sendCriteria === 1) {
      updatePagination({
        ...pagination,
        current: 1,
        skip: 0,
      });

      updateSearchDelayed(text);
    }
  };

  const handleSearchChange = (val) => {
    if (sendCriteria == 0) {
      updateGeofenceSearch(val);
    } else {
      updateCustomerSearch(val);
    }
  };

  const [pagination, updatePagination] = useState({
    recordsPerPage: 10,
    skip: 0,
    currentPage: 1,
  });
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (sendCriteria == 0) {
        updateIds(selectedRows.map((i) => i.geofenceId));
      } else {
        updateIds(selectedRows.map((i) => i.customerId));
      }
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    selectedRowKeys: ids,
  };

  const handlePaginationChange = (page) => {
    updatePagination({
      ...pagination,
      currentPage: page,
      skip: (page - 1) * pagination.recordsPerPage,
    });
  };

  const handleRecordsPerPageChange = (val) => {
    updatePagination({
      ...pagination,
      recordsPerPage: val,
    });
  };

  const openSendToAllModal = () => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      type: "SEND_TO_ALL",
    });
  };

  const openSendToSelectedModal = () => {
    if (!ids.length) {
      if (sendCriteria === 0) {
        toastr.warning(
          intl.formatMessage({ id: "app.warning.noGeofenceSelected" })
        );
      } else {
        toastr.warning(
          intl.formatMessage({ id: "app.message.noCustomersSelected" })
        );
      }
      return;
    }
    updateModalControls({
      ...modalControls,
      isVisible: true,
      type: "SEND_TO_SELECTED",
    });
  };

  const handleModalClose = () => {
    updateModalControls({
      ...modalControls,
      isVisible: false,
    });
  };

  const getGeofencesData = () => {
    updateTableControls({
      ...tableControls,
      isEmpty: false,
      isLoading: true,
      data: [],
    });
    axios
      .get("getAllGeoFenceOfBranch", {
        params: removeEmptyKeys({
          countryId: props.selectedCountry,
          cityIds: props.selectedCities,
        }),
      })
      .then((res) => {
        updateTableControls({
          ...tableControls,
          data: res.data.data.map((item) => {
            item.selected = false;
            return item;
          }),
          count: res.data.data.count,
          isEmpty: res.data.data.length === 0,
          isLoading: false,
        });
      })
      .catch((err) => {
        toastr.error("Error", "Something Went Wrong");
        updateTableControls({
          ...tableControls,
          isEmpty: true,
          isLoading: false,
        });
      });
  };

  const getCustomersData = () => {
    updateTableControls({
      ...tableControls,
      isEmpty: false,
      isLoading: true,
      data: [],
    });
    axios
      .get("/customers", {
        params: removeEmptyKeys({
          limit: pagination.recordsPerPage,
          skip: (pagination.currentPage - 1) * pagination.recordsPerPage,
          search: searchDelayed,
        }),
      })
      .then((res) => {
        updateTableControls({
          ...tableControls,
          isLoading: false,
          isEmpty: res.data.data.length === 0,
          data: res.data.data,
          count: res.data.data.length,
        });

        // updatePagination({
        //   ...pagination,
        //   // delayedCurrentPage: pagination.currentPage,
        //   // delayedRecordsPerPage: pagination.recordsPerPage,
        // });
      })
      .catch((err) => {
        // toastr.error('Error', intl.formatMessage({id: 'app.message.error'}))
        updateTableControls({
          ...tableControls,
          isEmpty: true,
          isLoading: false,
          data: [],
        });
      });
  };

  const toggleItem = (id) => {
    updateTableControls({
      ...tableControls,
      data: tableControls.data.map((item) => {
        if (item.geofenceId === id || item.customerId === id) {
          item.selected = !item.selected;
          return item;
        } else return item;
      }),
    });
  };

  const getSelectedCustomerIds = () =>
    tableControls.data.reduce((acc, val) => {
      if (val.selected) {
        if (sendCriteria === 0) {
          acc.push(val.geofenceId);
        }
        if (sendCriteria === 1) {
          acc.push(val.customerId);
        }
      }
      return acc;
    }, []);

  const getAllCustomerIds = () =>
    tableControls.data.reduce((acc, val) => {
      acc.push(val.geofenceId);
      return acc;
    }, []);

  const sendNotification = (data) => {
    let url = "";
    if (modalControls.type === "SEND_TO_ALL") {
      url = "notifications/sendAll";
      // data.geofence = getAllCustomerIds();
      // data.countryId = props.selectedCountry;
      // data.cityIds = props.selectedCities;
    }

    if (modalControls.type === "SEND_TO_SELECTED") {
      url = "notifications/send";
      // if (sendCriteria === 0) {
      //   data.geofence = ids;
      // }

      if (sendCriteria === 1) {
        data.customers = ids;
      }
    }
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });
    // pnAxios
    axios
      .post(`${url}`, data)
      .then((res) => {
        toastr.success(res.data.message);
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isVisible: false,
        });
        updateIds([]);
      })
      .catch((err) => {
        console.log(err);
        updateModalControls({
          ...modalControls,
          isLoading: false,
        });
      });
  };

  useEffect(() => {
    if (sendCriteria === 0) {
      getGeofencesData();
    }

    if (sendCriteria === 1) {
      getCustomersData();
    }
  }, [sendCriteria, pagination.currentPage, pagination.recordsPerPage,searchDelayed]);

  return (
    <div className={styles.wrapper}>
      <Header title="app.pageTitle.pushNotification" />
      <div className={styles.content}>
        <PushNotificationModal
          isVisible={modalControls.isVisible}
          onCloseIconClick={handleModalClose}
          sendNotification={sendNotification}
          isLoading={modalControls.isLoading}
        />
        <div className={styles.menu}>
          <header>
            <div className={styles.pageSize}>
              {/* <Label value="app.field.sendCriteria" horizontal />
              <Select onChange={handleCriteraChange} value={sendCriteria}>
                {SEND_CRITERIA.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {intl.formatMessage({ id: `app.field.${item.text}` })}
                  </Option>
                ))}
              </Select> */}
            </div>
          </header>
          <section>
            <div className={styles.menuRight}>
              <DelayedInput
                onChange={handleSearchChange}
                value={sendCriteria == 0 ? geofenceSearch : customerSearch}
                callback={handleSearch}
                delay={500}
                minLength={1}
                size="medium"
                prefixIcon={<SearchOutlined />}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.search",
                })}
              />
            </div>
            <Button
              type="primary"
              shape={undefined}
              onClick={openSendToSelectedModal}
            >
                {intl.formatMessage({
                id: "app.button.sendNotificationToSelected",
              })}
            </Button>
            <Button
              type="primary"
              shape={undefined}
              onClick={openSendToAllModal}
              disabled={tableControls.data.isEmpty}
            >
              {intl.formatMessage({ id: "app.button.sendToAll" })}
            </Button>
          </section>
        </div>
        {sendCriteria === 0 && (
          <PushNotificationTable
            data={tableControls.data}
            isLoading={tableControls.isLoading}
            isEmpty={tableControls.isEmpty}
            search={geofenceSearch}
            rowSelection={rowSelection}
            onChange={updatePagination}
            pagination={pagination}
            toggleItem={toggleItem}
          />
        )}
        {sendCriteria === 1 && (
          <CustomersTable
            data={tableControls.data}
            isLoading={tableControls.isLoading}
            isEmpty={tableControls.isEmpty}
            search={customerSearch}
            pagination={pagination}
            totalRecords={tableControls.count}
            onChange={updatePagination}
            rowSelection={rowSelection}
            toggleItem={toggleItem}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  // selectedCountry: state.user.selectedCountry,
  // selectedCities: state.user.selectedCities,
});
export default connect(mapStateToProps)(PushNotification);
