import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import "./addagent.scss";
import { Button, Input, Divider, Select } from "antd";

import {
  Panel,
  CustomModal,
  ErrorMessage,
  UploadButton,
} from "../../../components/common";
import { toastr } from "react-redux-toastr";
import axios from "../../../shared/api/axiosConfig";
import Validations from "../../../shared/validations/Validations";
import placeholderImg from "../../../assets/images/picker-placeholder.png";
import countryCodes from "../../../shared/constants/countries.json";

const styles = {
  formGroup: "custom-form-group",
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  formConatiner: "addAgent__form-container",
  imageConatiner: "addAgent__image-container",
};

const TRANSPORT_TYPES = [
  { name: "Bike", value: 0 },
  { name: "Truck", value: 1 },
];

const { Option } = Select;

function AddAgent(props) {
    const intl = useIntl();
  const closeModal = () => {
    props.onCloseIconClick();
  };

  const [teams, updateTeams] = useState({
    selected: "",
    data: [],
    isLoading: false,
    notFound: false,
  });
  const [team, assignTeam] = useState(Validations.validateMultiSelect([]));
  const [firstName, changeFirstName] = useState(
    Validations.validateTextField("")
  );
  const [lastName, changeLastName] = useState(
    Validations.validateTextField("")
  );
  const [countryCode, changeCountryCode] = useState(
    Validations.validateTextField(undefined)
  );
  const [phoneNo, changePhoneNo] = useState(
    Validations.validatePhoneNumber("")
  );
  const [address, changeAddress] = useState(Validations.validateTextField(""));
  const [transportType, changeTransportType] = useState(
    Validations.validateNumericValue(undefined)
  );
  const [licensePlate, changeLicensePlate] = useState(
    Validations.validateTextField("")
  );
  // const [description, changeDescription] = useState(
  //   Validations.validateTextField("")
  // );
  const [email, changeEmail] = useState(Validations.validateEmail(""));
  const [password, changePassword] = useState(Validations.validatePassword(""));

  const [isPristine, changeIsPristine] = useState(true);
  const [image, updateImage] = useState(Validations.validateTextField(""));

  const handleImageError = (e) => {
    e.target.src = placeholderImg;
  };

  const handleListChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    // console.log(value);
    switch (name) {
      case "firstName":
        changeFirstName(Validations.validateTextField(value));
        break;
      case "lastName":
        changeLastName(Validations.validateTextField(value));
        break;
      case "email":
        changeEmail(Validations.validateEmail(value));
        break;
      case "phone":
        changePhoneNo(Validations.validatePhoneNumber(value));
        break;
      case "password":
        changePassword(Validations.validatePassword(value));
        break;
      case "address":
        changeAddress(Validations.validateTextField(value));
        break;
      case "licencePlate":
        changeLicensePlate(Validations.validateTextField(value));
        break;
      // case "description":
      //   changeDescription(Validations.validateTextField(value));
      //   break;
    }
  };

  const handleImageChange = (image) => {
    updateImage(Validations.validateTextField(image));
  };

  const handleAssignTeam = (teamId) => {
    assignTeam(Validations.validateMultiSelect(teamId));
  };

  const updateCountryCode = (code) => {
    changeCountryCode(Validations.validateTextField(code));
  };
  const handleTransport = (value) => {
    changeTransportType(Validations.validateNumericValue(value));
  };

  const resetForm = () => {
    changeIsPristine(true);
    changeFirstName(Validations.validateTextField(""));
    changeLastName(Validations.validateTextField(""));
    changeEmail(Validations.validateEmail(""));
    changeCountryCode(Validations.validateTextField(undefined));
    changePhoneNo(Validations.validateTextField(""));
    changePassword(Validations.validatePassword(""));
    changeAddress(Validations.validateTextField(""));
    // changeDescription(Validations.validateTextField(""));
    changeLicensePlate(Validations.validateTextField(""));
    assignTeam(Validations.validateMultiSelect([]));
    changeTransportType(Validations.validateNumericValue(undefined));
    updateImage(Validations.validateTextField(""));
  };

  const setForm = () => {
    changeIsPristine(true);
    changeFirstName(Validations.validateTextField(props.data.firstName));
    changeLastName(Validations.validateTextField(props.data.lastName));
    changeEmail(Validations.validateEmail(props.data.email));
    changeCountryCode(Validations.validateTextField(props.data.countryCode));
    changePhoneNo(Validations.validateTextField(props.data.phoneNo));
    changePassword(Validations.validatePassword(""));
    changeAddress(Validations.validateTextField(props.data.address));
    // changeDescription(Validations.validateTextField(props.data.description));
    changeLicensePlate(Validations.validateTextField(props.data.vehicleNo));
    changeTransportType(
      Validations.validateNumericValue(
        props.data.transportType !== null ? props.data.transportType : undefined
      )
    );
    let tms = props.data.teamIds ? JSON.parse(props.data.teamIds) : [];
    let teamIds = tms.map((id) => id.toString());
    console.log("tmakasmdff", teamIds);
    assignTeam(Validations.validateMultiSelect(teamIds));
    updateImage(Validations.validateTextField(props.data.profilePicURL));
  };

  // useEffect(() => {
  //   if(props.selectedCities !== null && props.selectedCities !== undefined &&
  //     props.selectedCountry !== null && props.selectedCountry !== undefined){

  //     }
  //   axios
  //     .get(`/getTeam`, {
  //       params: {
  //         // onlyNames: 1,
  //         countryId: props.selectedCountry,
  //         cityIds: props.selectedCities,
  //       },
  //     })
  //     .then((res) => {
  //       console.log(res.data.data.data,'*****************************************************')
  //       if (res.data.data.data.count) {
  //         updateTeams({
  //           ...teams,
  //           selected: "",
  //           data: res.data.data.data.team,
  //           isLoading: false,
  //           notFound: false,
  //           count: res.data.data.data.count,
  //         });
  //       } else {
  //         toastr.error("Error", `${intl.formatMessage({ id: "app.message.teamNotFound" })}`);
  //         updateTeams({
  //           ...teams,
  //           selected: "",
  //           data: [],
  //           isLoading: false,
  //           notFound: true,
  //           count: 0,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       // console.log(err);
  //     });
  // }, [props.selectedCountry,props.selectedCities]);

  useEffect(() => {
    if (props.isVisible) {
      changeIsPristine(true);

      if (props.modalToShow === "EDIT") {
        setForm();
      } else {
        resetForm();
      }
    }
  }, [props.isVisible]);

  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const handleSubmit = () => {
    changeIsPristine(false);
    if (!isFormValid()) {
      toastr.warning("Error", intl.formatMessage({id: 'app.message.formCheck'}));
      return;
    }
    if (props.modalToShow === "ADD") {
      props.addDriver(getFormData());
    } else if (props.modalToShow === "EDIT") {
      props.editDriver(getFormData());
    }
  };

  const isFormValid = () => {
    let isValid = true;

    isValid =
      isValid &&
      firstName.isValid &&
      lastName.isValid &&
      phoneNo.isValid &&
      countryCode.isValid &&
      email.isValid &&
      image.isValid &&
      // description.isValid &&
      // transportType.isValid &&
      // team.isValid &&
      licensePlate.isValid &&
      address.isValid;
      
    if(transportType.value == 0 || transportType.value == 1){
      isValid = isValid;
    }else{
      isValid = false;
    }
    // if (props.modalToShow === "ADD") {
    //   isValid = isValid && password.isValid;
    // } else {
    //   if (
    //     password.value !== "" &&
    //     password.value !== null &&
    //     password.value !== undefined
    //   ) {
    //     isValid = isValid && password.isValid;
    //   }
    // }
    return isValid;
  };

  const getFormData = () => {
    const data = {
      // description: description.value,
      phoneNo: phoneNo.value,
      email: email.value,
      password: password.value,
      firstName: firstName.value,
      lastName: lastName.value,
      countryCode: countryCode.value,
      timezone: "+65",
      // teamIds: team.value,
      transportType: transportType.value,
      vehicleNo: licensePlate.value,
      address: address.value,
      profilePicURL: image.value,
    };
    return data;
  };
  return (
    <CustomModal
      size="tiy"
      width="40%"
      onCloseIconClick={closeModal}
      header={props.modalToShow === "ADD" ? `${intl.formatMessage({ id: "app.field.addAgent" })}` : `${intl.formatMessage({ id: "app.field.editAgent" })}`}
      isVisible={props.isVisible}
    >
      <Divider />
      <Panel isLoading={props.isLoading} className={styles.formModal}>
        <div className={styles.formGroup}>
          <div
            className={styles.formItem}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className={styles.imageConatiner}>
              <img
                src={image.value ? image.value : placeholderImg}
                alt=""
                onError={handleImageError}
              />
            </div>

            <UploadButton
            text={intl.formatMessage({ id: "app.field.uploadImage" })}
              onChange={handleImageChange}
              accept="image/*"
              resultType="url"
            />
            {!isPristine && <ErrorMessage field={image} />}
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.formItem}>
            <label>{intl.formatMessage({ id: "app.field.firstName" })}</label>
            <Input
              value={firstName.value}
              name="firstName"
              onChange={handleListChange}
              placeholder={intl.formatMessage({ id: "app.placeholder.firstName" })}
              size="medium"
            />
            {!isPristine && <ErrorMessage field={firstName} />}
          </div>
          <div className={styles.formItem}>
            <label>{intl.formatMessage({ id: "app.field.lastName" })}</label>
            <Input
              value={lastName.value}
              name="lastName"
              onChange={handleListChange}
              placeholder={intl.formatMessage({ id: "app.placeholder.lastName" })}
              size="medium"
            />
            {!isPristine && <ErrorMessage field={lastName} />}
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.formItem}>
            <label>{intl.formatMessage({ id: "app.field.email" })}</label>
            <Input
              value={email.value}
              name="email"
              onChange={handleListChange}
              placeholder={intl.formatMessage({ id: "app.placeholder.email" })}
              size="medium"
            />
            {!isPristine && <ErrorMessage field={email} />}
          </div>
          <div className={styles.formItem}>
            <label>{intl.formatMessage({ id: "app.field.password" })}</label>
            <Input.Password
              value={password.value}
              name="password"
              // type="password"
              onChange={handleListChange}
              placeholder={intl.formatMessage({ id: "app.placeholder.password" })}
              size="medium"
            />
            {props.modalToShow === "ADD" && !isPristine && (
              <ErrorMessage field={password} />
            )}
            {/* {props.modalToShow === "EDIT" &&
              !isPristine && <ErrorMessage field={password} />} */}
          </div>
        </div>
        <div className={styles.formGroup}>
          {/* <div className={styles.formItem}>
            <label>Assign Team</label>
            <Select
              showSearch
              mode="multiple"
              placeholder="Assign To Team"
              onChange={handleAssignTeam}
              value={team.value}
              optionFilterProp="children"
              filterOption={filterOption}
              size="medium"
            >
              {teams.data.map((team) => (
                <Option key={team.teamId}>{team.teamName}</Option>
              ))}
            </Select>
            {!isPristine && <ErrorMessage field={team} />}
          </div> */}
          <div className={styles.formItem}>
            <label>{intl.formatMessage({ id: "app.field.phoneNumber" })}</label>
            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <Select
                  showSearch
                  placeholder={intl.formatMessage({ id: "app.field.countryCode" })}
                  onChange={updateCountryCode}
                  value={countryCode.value}
                  optionFilterProp="children"
                  filterOption={filterOption}
                  size="medium"
                >
                  {countryCodes.map((item, index) => (
                    <Option
                      key={index}
                      value={item.dial_code}
                    >{`${item.dial_code}(${item.name})`}</Option>
                  ))}
                </Select>
                {!isPristine && <ErrorMessage field={countryCode} />}
              </div>
              <div className={styles.formItem}>
                <Input
                  value={phoneNo.value}
                  name="phone"
                  onChange={handleListChange}
                  placeholder={intl.formatMessage({ id: "app.placeholder.phoneNumber" })}
                  size="medium"
                />
                {!isPristine && <ErrorMessage field={phoneNo} />}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.formGroup} style={{ marginTop: "-1.1rem" }}>
          <div className={styles.formItem}>
            <label>{intl.formatMessage({ id: "app.field.address" })}</label>
            <Input
              value={address.value}
              name="address"
              onChange={handleListChange}
              placeholder={intl.formatMessage({ id: "app.placeholder.address" })}
              size="medium"
            />
            {!isPristine && <ErrorMessage field={address} />}
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.formItem}>
            <label>{intl.formatMessage({ id: "app.field.transportType" })}</label>
            <Select
              name="transportType"
              placeholder=" Transport Type"
              onChange={handleTransport}
              value={transportType.value}
              size="medium"
            >
              {TRANSPORT_TYPES.map((tr) => (
                <Option key={tr.value} value={tr.value}>
                  {tr.name}
                </Option>
              ))}
            </Select>
            {!isPristine && transportType.value == undefined && <ErrorMessage field={transportType} />}
          </div>
          <div className={styles.formItem}>
            <label>{intl.formatMessage({ id: "app.field.vehicleNumber" })}</label>
            <Input
              value={licensePlate.value}
              name="licencePlate"
              onChange={handleListChange}
              placeholder={intl.formatMessage({ id: "app.placeholder.vehicleNumber" })}
              size="medium"
            />
            {!isPristine && <ErrorMessage field={licensePlate} />}
          </div>
        </div>
        {/* <div className={styles.formGroup}>
          <div className={styles.formItem}>
            <label>Description</label>
            <Input
              value={description.value}
              name="description"
              onChange={handleListChange}
              placeholder="Description"
              size="medium"
            />
            {!isPristine && <ErrorMessage field={description} />}
          </div>
        </div> */}

        {/* <Col span={24}>
          <h4 style={{ fontWeight: 600, marginTop: 10 }}>TRANSPORT TYPE</h4>
        </Col> */}
        {/* <Col span={24} style={{ marginBottom: 10 }}>
            <Row className="icon-row">
              <Col span={4} className="icon-col">
                {" "}
                <div className="circle_icon">
                  <Icon name="TRUCK" size="medium" />
                </div>
              </Col>
              <Col span={4} className="icon-col">
                {" "}
                <div className="circle_icon">
                  <Icon name="CARTRANSPORT" size="medium" />
                </div>
              </Col>
              <Col span={4} className="icon-col">
                {" "}
                <div className="circle_icon">
                  <Icon name="MOTORCYCLE" size="medium" />
                </div>
              </Col>
              <Col span={4} className="icon-col">
                {" "}
                <div className="circle_icon">
                  <Icon name="BIKE" size="medium" />
                </div>
              </Col>
              <Col span={4} className="icon-col">
                {" "}
                <div className="circle_icon">
                  <Icon name="SCOOTER" size="medium" />
                </div>
              </Col>
              <Col span={4} className="icon-col">
                {" "}
                <div className="circle_icon">
                  <Icon name="WALK" size="medium" />
                </div>
              </Col>
              {TRANSPORT_TYPES.map((tr) => (
                <Col span={4} className="icon-col">
                  <div className="circle_icon">
                    <Icon name={tr.icon} size="medium" />
                  </div>
                </Col>
              ))}
            </Row>
          </Col> */}
        <div className={styles.formAction}>
          <Button onClick={props.onCloseIconClick} className="addagntbtn">
          {intl.formatMessage({ id: "app.button.cancel" })}
          </Button>
          <Button type="primary" loading={props.isLoading} onClick={handleSubmit} className="addagntbtn">
          {intl.formatMessage({ id: "app.button.submit" })}
          </Button>
        </div>
      </Panel>
    </CustomModal>
  );
}

export default AddAgent;
