import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Select, Dropdown, Button, Menu,Popconfirm } from "antd";
import { CaretDownOutlined, MoreOutlined, SearchOutlined } from "@ant-design/icons";
import { connect } from 'react-redux';

import { RECORDS_PER_PAGE } from "../../shared/constants/dropdown";
import {
  Header,
  PaginationInfo,
  ConfirmModal,
  Label,
  DelayedInput,
  CustomTable,
  CustomImage,
  CustomTag,
} from "../../components/common";

import { toastr } from "react-redux-toastr";
import { removeEmptyKeys } from "../../shared/utils/object-manipulation";
import axios from "../../shared/api/axiosConfig";
import AddEditBrand from "./components/add-edit-brand/add-edit-brand";
import BrandsTable from "./components/brands-table/brands-table";
import "./brands.scss";
import { checkRtl } from "../../shared/utils/rtl";

const styles = {
  wrapper: "brands__wrapper",
  upperMenu: "brands__upper-menu",
  upperMenuLeft: "brands__upper-menu__left",
  upperMenuRight: "brands__upper-menu__right",
  upperMenuDropdown: "brands__upper-menu__dropdown",
  recordsPerPage: "products__upper-menu__records-per-page",
};


const { Option } = Select;

function Brands(props) {
  const intl = useIntl();
  const [brands, updateBrands] = useState({
    data: [],
    notFound: false,
    isLoading: true,
    count: null,
  });

  const [search, updateSearch] = useState("");

  const handleSearchChange = (val) => {
    updateSearch(val);
  };

  const handleSearch = (val) => {
    updatePagination({
      ...pagination,
      currentPage: 1,
      search: val,
      skip: 0,
    });
  };

  const [stores, updateStores] = useState({
    selected: "",
    data: [],
    notFound: false,
    isLoading: true,
  });

  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    isDeleteModalVisible: false,
    isDisableModalVisible: false,
    data: {},
    type: "",
  });

  const [pagination, updatePagination] = useState({
    recordsPerPage: 10,
    skip: 0,
    currentPage: 1,
    search: "",
  });

  const [tableControls, updateTableControls] = useState({
    isTableLoading: true,
    isTableEmpty: false,
  });

  const openAddBrandModal = () => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      type: "ADD_BRAND",
    });
  };

  const openEditBrandModal = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      data,
      type: "EDIT_BRAND",
    });
  };

  const openDeleteBrandModal = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: false,
      isDeleteModalVisible: true,
      data,
      type: "",
    });
  };

  const handleModalClose = () => {
    updateModalControls({
      isVisible: false,
      isDeleteModalVisible: false,
      data: {},
    });
  };

  const handlePaginationChange = (page) => {
    updatePagination({
      ...pagination,
      currentPage: page,
      skip: (page - 1) * pagination.recordsPerPage,
    });
  };

  const handleRecordsPerPageChange = (val) => {
    updatePagination({
      ...pagination,
      recordsPerPage: val,
    });
  };

  const handleStoreChange = (selected) => {
    updateStores({
      ...stores,
      selected,
    });
    updateTableControls({
      ...tableControls,
      isTableLoading: true,
      isTableEmpty: false,
    });
    updatePagination({
      ...pagination,
      currentPage: 1,
      skip: 0,
    });
  };

  const getBrandsList = () => {
    updateBrands({
      ...brands,
      isLoading: true,
      notFound: false,
    });
    axios
      .get(`/getBrands`, {
        params: removeEmptyKeys({
          limit: pagination.recordsPerPage,
          skip: (pagination.currentPage - 1) * pagination.recordsPerPage,
          branchId: stores.selected,
          search: pagination.search,
        }),
      })
      .then((res) => {
        if (res.data.data.brandsCount) {
          updateBrands({
            ...brands,
            data: res.data.data.brands,
            isLoading: false,
            notFound: false,
            count: res.data.data.brandsCount,
          });

          updateTableControls({
            isTableEmpty: false,
            isTableLoading: false,
          });
        } else {
          toastr.error("Error", "No brands found");
          updateTableControls({
            isTableEmpty: true,
            isTableLoading: false,
          });
          updateBrands({
            ...brands,
            data: [],
            isLoading: false,
            notFound: true,
            count: 0,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        updateBrands({
          ...brands,
          isLoading: false,
        });
      });
  };

  useEffect(() => {
    getBrandsList();
  }, [
    pagination.recordsPerPage,
    pagination.currentPage,
    stores.selected,
    pagination.search,
  ]);

  useEffect(() => {
    axios
      .get("/getBranches", {
        params: {
          onlyNames: 1
        }
      })
      .then((res) => {
        if (res.data.data.branchesData.length) {
          updateStores({
            selected: "",
            data: res.data.data.branchesData,
            isLoading: false,
            notFound: false,
            count: res.data.data.branchCount,
          });
        } else {
          updateStores({
            ...stores,
            notFound: true,
          });
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
      });
  }, []);

  const handleBrandAdd = (data) => {
    console.log("add called");
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });

    axios
      .post("/addBrand", removeEmptyKeys(data))
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isVisible: false,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getBrandsList(stores.selected);
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateModalControls({
          ...modalControls,
          isLoading: false,
        });
      });
  };

  const handleBrandEdit = (data) => {
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });
    delete data.branchId;
    data.brandId = modalControls.data.brandId;
    axios
      .post("/updateBrand", removeEmptyKeys(data))
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isVisible: res.data.statusCode !== 200,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getBrandsList(stores.selected);
        } else {
          toastr.error("Error", res.data.message);
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateModalControls({
          ...modalControls,
          isLoading: false,
        });
      });
  };

  const handleBrandDelete = (item) => {
    const data = {
      brandId: item.brandId,
      isDeleted: 1,
    };
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });

    axios
      .post("/updateBrand", removeEmptyKeys(data))
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isDeleteModalVisible: res.data.statusCode !== 200,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getBrandsList(stores.selected);
        } else {
          toastr.error("Error", res.data.message);
          updateModalControls({
            ...modalControls,
            isDeleteModalVisible: false,
          });
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateModalControls({
          ...modalControls,
          isDeleteModalVisible: false,
        });
      });
  };

  const handleBrandToggle = (brand) => {
    const data = {
      brandId: brand.brandId,
      isEnabled: brand.isEnabled ? 0 : 1,
    };
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });

    axios
      .post("/updateBrand", removeEmptyKeys(data))
      .then((res) => {
        updateModalControls({
          ...modalControls,
          isLoading: false,
          isDisableModalVisible: res.data.statusCode !== 200,
        });
        if (res.data.statusCode === 200) {
          toastr.success(res.data.message);
          getBrandsList(stores.selected);
        } else {
          toastr.error("Error", res.data.message);
          updateModalControls({
            ...modalControls,
            isDisableModalVisible: false,
          });
        }
      })
      .catch((err) => {
        // toastr.error("Error", intl.formatMessage({ id: "app.message.error" }));
        updateModalControls({
          ...modalControls,
          isDisableModalVisible: false,
        });
      });
  };

  const getMenu = (item) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            openEditBrandModal(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.edit" })}
        </Menu.Item>
        {item.isEnabled == 0 && (
          <Menu.Item
            onClick={() => {
              handleBrandToggle(item);
            }}
          >
            {intl.formatMessage({ id: "app.field.enable" })}
          </Menu.Item>
        )}
        {item.isEnabled == 1 && (
          <Menu.Item
            onClick={() => {
              handleBrandToggle(item);
            }}
          >
            {intl.formatMessage({ id: "app.field.disable" })}
          </Menu.Item>
        )}

        <Menu.Item
        // onClick={() => {
        //   openDeleteBrandModal(item)
        // }}
        >
          <Popconfirm
            title={intl.formatMessage({
              id: "app.message.deleteWarning",
            })}
            onConfirm={() => {
              handleBrandDelete(item);
            }}
            placement="topRight"
            okText={intl.formatMessage({ id: "app.field.yes" })}
            cancelText={intl.formatMessage({ id: "app.field.no" })}
          >
            {intl.formatMessage({ id: "app.field.delete" })}
          </Popconfirm>
        </Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      dataIndex: "",
      // sorter: true,
      title: intl.formatMessage({ id: "app.field.sNo" }),
      // fixed: 'left',
      render: (e, item, index) => {
        return (
          <span>
            {pagination.recordsPerPage * (pagination.currentPage - 1) +
              index +
              1}
          </span>
        );
      },
    },
    {
      dataIndex: "brandLogo",
      title: intl.formatMessage({ id: "app.field.image" }),
      render: (i) => {
        return <CustomImage isTable src={i} />;
      },
    },
    {
      dataIndex: "brandName",
      title: intl.formatMessage({ id: "app.field.name" }),
    },

    // {
    //   dataIndex: "branchName",
    //   title: intl.formatMessage({ id: "app.field.store" }),
    // },
    {
      dataIndex: "isEnabled",
      title: intl.formatMessage({ id: "app.field.status" }),
      render: (i) => <CustomTag isEnabled={i} />,
    },
    {
      dataIndex: "",
      title: intl.formatMessage({ id: "app.field.action" }),
      fixed: "right",
      render: (item) => {
        return (
          <Dropdown overlay={getMenu(item)}>
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  return (
    <div className={styles.wrapper}>
      <AddEditBrand
        isVisible={modalControls.isVisible}
        isLoading={modalControls.isLoading}
        onClose={handleModalClose}
        onCloseIconClick={handleModalClose}
        addBrand={handleBrandAdd}
        editBrand={handleBrandEdit}
        modalToShow={modalControls.type}
        data={modalControls.data}
      />
      <ConfirmModal
        header={`Delete ${modalControls?.data?.brandName}?`}
        message={intl.formatMessage({
          id: "app.message.deleteWarning",
        })}
        onCloseIconClick={handleModalClose}
        onAction={handleBrandDelete}
        isVisible={modalControls.isDeleteModalVisible}
        actionText="Delete"
        isLoading={modalControls.isLoading}
      />
      <Header title="app.pageTitle.brands" />

      <div className="custom-section">
        <div className="custom-menu">
          <header>
            <div>
            {/* <Label  /> */}
            <Select
              placeholder={intl.formatMessage({ id: "app.placeholder.store" })}
              onChange={handleStoreChange}
              value={stores.selected}
            >
              <Option key="randomfkey" value="">
                All Stores
              </Option>
              {stores.data.map((store) => (
                <Option key={store.branchId} value={store.branchId}>
                  {store.branchName}
                </Option>
              ))}
            </Select>
            </div>
          </header>

          <section>
            <DelayedInput
              onChange={handleSearchChange}
              value={search}
              callback={handleSearch}
              delay={500}
              minLength={3}
              size="medium"
              prefixIcon={<SearchOutlined />}
              placeholder={intl.formatMessage({ id: "app.placeholder.search" })}
            />
            <Button
              onClick={openAddBrandModal}
              shape=""
              size="medium"
              disabled={props.isAddDisabled}
              type="primary"
            >
              {intl.formatMessage({ id: "app.field.addBrand" })}
            </Button>
          </section>
        </div>
        <CustomTable
          isLoading={brands.isLoading}
          dataSource={brands.data}
          columns={columns}
          pagination={pagination}
          totalRecords={brands.count}
          onChange={updatePagination}
        />
      </div>
    </div>
  );
}


const mapStateToProps = (state) => ({
  userInfo: state.user.userInfo,
  // selectedCountry: state.user.selectedCountry,
  isAddDisabled: state.user.isAddDisabled,
  isRTL: checkRtl(state.user.locale),
});
export default connect(mapStateToProps)(Brands);

