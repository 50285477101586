import React, { useState, useEffect } from "react";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import {useIntl} from 'react-intl';
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import { Button } from "antd";

import { Panel } from "../../../../components/common";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./editor.scss";
import { toastr } from "react-redux-toastr";

const styles = {
  wrapper: "content-management__editor__wrapper",
  editorToolbar: "content-management__editor__toolbar",
  editorText: "content-management__editor__text",
  action: "content-management__editor__action",
};

function ManagementEditor(props) {
  const intl = useIntl();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleSubmit = () => {
    try {
      console.log(editorState.getCurrentContent().getPlainText())
      props.handleSubmit(
        props.menu[props.selected].dataKey,
        stateToHTML(editorState.getCurrentContent())
      );
    } catch (e) {
      toastr.error("Error", "Something went wrong");
    }
  };

  useEffect(() => {
    console.log(props.selected, props.data);
    if (props.selected !== props.menu.CONTACT_US.key) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(props.data[props.menu[props.selected].dataKey] || '')
          )
        )
      );
    }
  }, [props.selected, props.data]);
  return (
    <>
      <Panel isLoading={props.isLoading} className={styles.wrapper}>
        <Editor
          toolbarClassName={styles.editorToolbar}
          editorClassName={styles.editorText}
          placeholder="Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut consequuntur maxime suscipit libero incidunt aspernatur provident, quae, mollitia at eum harum alias labore quos eaque consequatur, blanditiis cupiditate hic voluptatum."
          editorState={editorState}
          onEditorStateChange={setEditorState}
        />
      </Panel>
      <div className={styles.action}>
      <Button onClick={handleSubmit} type="primary" shape={undefined}>
        {intl.formatMessage({id: 'app.button.submit'})}
        </Button>
      </div>
    </>
  );
}

export default ManagementEditor;
