import React from "react";
import { connect } from "react-redux";
import Logout from "./logout/logout";
import SessionExpired from './session-expired/session-expired';
import modalActions from '../../../redux/modal/modal-actions';
import userActions from '../../../redux/user-info/user-actions';
class GlobalModal extends React.Component {

    handleCancel = () => {
    this.props.dispatch(modalActions.hideModal())
    }

    handleLogout = () => {
        this.props.dispatch(modalActions.hideModal());
        this.props.dispatch(userActions.logout());
    }

  render() {
    switch(this.props.modalType) {
        case 'LOGOUT':
          return <Logout isVisible={this.props.isVisible} logout={this.handleLogout} cancel={this.handleCancel} />;
        case 'SESSION_EXPIRED':
          return <SessionExpired isVisible={this.props.isVisible} />
        default:
          return null;
    }
  }
}

const mapStateToProps = (state) => ({
  modalType: state.modal.modalType,
  isVisible: state.modal.isVisible,
  modalProps: state.modal.modalProps,
});

export default connect(mapStateToProps)(GlobalModal);
