import React, { useState, useEffect } from "react";
import { Button, Input, Select } from "antd";
import { useIntl } from "react-intl";

import Validations from "../../../../shared/validations/Validations";
import { ErrorMessage, Label } from "../../../../components/common";
import "./geofence-add-form.scss";
const styles = {
  wrapper: "geofence-add-form-wrapper",
  formItem: "custom-form-item",
  formGroup: "custom-form-group",
  formAction: "custom-form-action",
  spanImp: "span-img",
  menu: "custom-menu",
};

const { Option } = Select;

function GeofenceAddForm(props) {
  const intl = useIntl();

  const [store, updateStore] = useState(
    Validations.validateTextField(undefined)
  );
  const [isPristine, changeIsPristine] = useState(true);
  const [areaName, changeAreaName] = useState(
    Validations.validateTextField(null)
  );
  const [areaDescription, changeAreaDescription] = useState(
    Validations.validateTextField(null)
  );
  const [deliveryCharge, changeDeliveryCharge] = useState(
    Validations.validateNumericValue(null)
  );
  const [minOrder, changeMinOrder] = useState(
    Validations.validateNumericValue(null)
  );

  const handleStoreChange = (store) => {
    props.handleStoreChange(store);
    updateStore(Validations.validateTextField(store));
  };

  const resetForm = () => {
    updateStore(Validations.validateTextField(null));
    changeIsPristine(true);
    changeAreaName(Validations.validateTextField(null));
    changeAreaDescription(Validations.validateTextField(null));
    changeDeliveryCharge(Validations.validateNumericValue(null));
    changeMinOrder(Validations.validateNumericValue(null));
  };

  const isFormValid = () => {
    return (
      store.isValid && areaName.isValid && areaDescription.isValid
      // deliveryCharge.isValid &&
      // minOrder.isValid
    );
  };

  const handleSubmit = () => {
    changeIsPristine(false);
    if (isFormValid()) {
      const data = {
        name: areaName.value,
        tags: areaDescription.value,
        branchId: store.value * 1,
        // "deliveryCharge": deliveryCharge.value*1,
        // "minimumOrderValue": minOrder.value*1,
      };
      props.handleSubmit(data);
    }
  };

  const handleFieldChange = (e) => {
    const { value, name } = e.target;
    switch (name) {
      case "areaName":
        changeAreaName(Validations.validateTextField(value));
        break;
      case "areaDescription":
        changeAreaDescription(Validations.validateTextField(value));
        break;
      case "deliveryCharge":
        changeDeliveryCharge(Validations.validateNumericValue(value));
        break;
      case "minOrder":
        changeMinOrder(Validations.validateNumericValue(value));
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    if (props.hack !== undefined) {
      resetForm();
    }
  }, [props.hack]);

  return (
    <div className={styles.wrapper}>
      <header className={styles.formGroup}>
        <div className={styles.formItem}>
          <Label value="app.placeholder.store" />
          {/* <label>{intl.formatMessage({ id: "app.placeholder.store" })}&nbsp;<span className={styles.spanImp}>*</span></label> */}
          <Select
            showSearch
            placeholder={intl.formatMessage({ id: "app.placeholder.store" })}
            onChange={handleStoreChange}
            value={store.value}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {props.stores.map((store) => (
              <Option key={store.branchId}>{store.branchName}</Option>
            ))}
          </Select>
          {!isPristine && <ErrorMessage float field={store} />}
        </div>

        <div className={styles.formItem}>
          <Label value="app.field.areaName" />
          <Input
            name="areaName"
            value={areaName.value}
            onChange={handleFieldChange}
            placeholder={intl.formatMessage({
              id: "app.placeholder.areaName",
            })}
          />
          {!isPristine && <ErrorMessage float field={areaName} />}
        </div>
        <div className={styles.formItem}>
          <Label value="app.field.areaDescription" />
          <Input
            name="areaDescription"
            value={areaDescription.value}
            onChange={handleFieldChange}
            placeholder={intl.formatMessage({
              id: "app.placeholder.areaDescription",
            })}
          />
          {!isPristine && <ErrorMessage float field={areaDescription} />}
        </div>
      </header>
      <section>
        <Button
          loading={props.isLoading}
          onClick={handleSubmit}
          type="primary"
          disabled={props.isAddDisabled}
          shape={undefined}
        >
          {intl.formatMessage({ id: "app.pageTitle.addGeofence" })}{" "}
        </Button>
      </section>

      {/* <div className={styles.formAction}>
     
      </div> */}
    </div>
  );
}

export default React.memo(GeofenceAddForm);
