import React, { useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { Input, Button, Select, DatePicker } from "antd";
import moment from "moment";
import { toastr } from "react-redux-toastr";

import {
  Panel,
  CustomModal,
  UploadButton,
  FullPageDrawer,
  ErrorMessage,
} from "../../../../components/common";
import placeholderImg from "../../../../assets/images/item-placeholder.png";
import Validations from "../../../../shared/validations/Validations";
import axios from "../../../../shared/api/axiosConfig";
import "./add-edit-item-modal.scss";

const errorText = "Field cannot be empty";

const styles = {
  wrapper: "add-edit-item-modal__wrapper",
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  imageContainer: "add-edit-item-modal__image-container",
  leftForm: "add-edit-item-modal__left-form",
  rightForm: "add-edit-item-modal__right-form",
  form: "add-edit-item-modal__form",
};

const { Option } = Select;

function AddEditItemModal(props) {
  const intl = useIntl();

  const [categories, updateCategories] = useState({
    selected: undefined,
    isLoading: false,
    data: [],
    notFound: false,
  });

  const [subCategories, updateSubCategories] = useState({
    selected: undefined,
    isLoading: false,
    data: [],
    notFound: false,
  });

  const [products, updateProducts] = useState({
    selected: undefined,
    isLoading: false,
    data: [],
    notFound: false,
  });

  const getCategories = (category) => {
    updateCategories({
      ...categories,
      isLoading: true,
      notFound: false,
    });

    console.log("getCategories");
    axios
      .get("/listCatOrSubCategoriesBranch", {
        params: {
          branchId: props.branchId,
          onlyNames: 1,
        },
      })
      .then((res) => {
        if (res.data.data.categories.length) {
          console.log("chingChong");
          console.log({ ...categories });
          console.log(props.modalType);

          // HACKED. ISSUE PENDING WITH CATEGORY ID => undefined
          const allIds = res.data.data.categories.map((c) => c.categoryId);
          console.log("caaaetiaithaeitieat", category);
          if (props.modalType === "EDIT") {
            console.log("inside edit", allIds);
            if (allIds.indexOf(category) === -1) {
              updateCategories({
                ...categories,
                selected: undefined,
                data: res.data.data.categories,
                isLoading: false,
                notFound: false,
              });
              toastr.error("Category no longer exists");
              return;
            }
          }
          updateCategories({
            ...categories,
            selected: category ? category : undefined,
            data: res.data.data.categories,
            isLoading: false,
            notFound: false,
          });
        } else {
          console.log("else blockkk");
          updateCategories({
            ...categories,
            selected: undefined,
            data: [],
            isLoading: false,
            notFound: true,
          });
        }
      })
      .catch((err) => {});
  };

  const handleCategoryChange = (category) => {
    console.log("handleCategoryChagne");
    updateCategories({
      ...categories,
      selected: category,
    });

    updateSubCategories({
      ...subCategories,
      selected: undefined,
      data: [],
      notFound: false,
    });
    updateProducts({
      ...products,
      selected: undefined,
      data: [],
      notFound: false,
    });
  };

  const [selectedProductData, updateSelectedProductData] = useState({
    quantity: undefined,
    isOriginal: false,
    maxQuantity: 0,
  });

  const [price, updatePrice] = useState(Validations.validateNumericValue(null));

  const handleSubCategoryChange = (subCategory) => {
    updateSubCategories({
      ...subCategories,
      selected: subCategory,
    });
    updateProducts({
      ...products,
      selected: undefined,
      data: [],
      notFound: false,
    });
  };

  const handleProductChange = (product) => {
    const [selectedData] = products.data.filter(
      (item) => item.productId === product
    );

    updatePrice(Validations.validateNumericValue(selectedData.actualPrice));
    updateSelectedProductData({
      quantity: null,
      maxQuantity: getMaxQuantity(selectedData),
    });
    updateProducts({
      ...products,
      selected: product,
    });
  };

  const resetForm = () => {
    updateCategories({
      selected: undefined,
      data: [],
      notFound: false,
    });
    updateSubCategories({
      selected: undefined,
      data: [],
      notFound: false,
    });
    updateProducts({
      selected: undefined,
      data: [],
      notFound: false,
    });
    updateSelectedProductData({
      maxQuantity: 0,
      quantity: null,
    });
  };

  const isFormValid = () => {
    return categories.selected && subCategories.selected && products.selected;
  };

  const handleSubmit = () => {
    if (!categories.selected) {
      toastr.error("Error", "Please select a category");
      return;
    }

    if (!subCategories.selected) {
      toastr.error("Error", "Please select a sub-category");
      return;
    }

    if (!products.selected) {
      toastr.error("Error", "Please select a product");
      return;
    }

    if (selectedProductData.quantity < 1) {
      toastr.error("Error", `Quantity cannot be less than 1`);
      return;
    }
    if (!selectedProductData.quantity) {
      toastr.error("Error", `Please enter a valid quantity`);
      return;
    }

    if (props.modalType === "ADD") {
      props.addItem(getFormData());
    } else {
      props.editItem(getFormData());
    }
  };

  const handleClose = () => {
    resetForm();
    props.onCloseIconClick();
  };

  const initEdit = () => {
    console.log("initEdit", props.data.categoryId);
    updateCategories({
      ...categories,
      selected: props.data.categoryId,
    });

    updateSubCategories({
      ...subCategories,
      selected: props.data.subCategoryId,
    });

    updateProducts({
      ...products,
      selected: props.data.productIdOnBooking,
    });
  };

  const getMaxQuantity = (product) => {
    // If item already in the order
    if (props.modalType === "EDIT") {
      if (props.data.isPicked !== undefined && props.data.isRedeemable) {
        return props.data.quantity;
      }
      if (props.data.isPicked !== undefined) {
        return product.quantity + props.data.quantity;
      }
    }
    if (product.quantity === 0) {
      toastr.error(
        "Error",
        intl.formatMessage({ id: "app.message.outOfStockWarning" })
      );
    }

    return product.quantity;
  };

  const handlePriceChange = (e) => {
    updatePrice(Validations.validateNumericValue(e.target.value));
  };

  useEffect(() => {
    console.log("props", props);
    if (props.isVisible) {
      if (props.modalType === "ADD") {
        getCategories();
        resetForm();
      }

      if (props.modalType === "EDIT") {
        //  HACK

        getCategories(props.data.categoryId);
        initEdit();
      }
    }
  }, [props.isVisible]);

  // CATEGORY CHANGE
  useEffect(() => {
    console.log("category effect");
    console.log(categories);
    if (categories.selected) {
      console.log("categories.selected", categories);
      updateSubCategories({
        ...subCategories,
        isLoading: true,
        notFound: false,
        data: [],
      });
      // updateProducts({
      //   ...products,
      //   data: [],
      // });
      axios
        .get("/listCatOrSubCategoriesBranch", {
          params: {
            branchId: props.branchId,
            parentCategoryId: categories.selected,
            onlyNames: 1,
          },
        })
        .then((res) => {
          if (res.data.data) {
            updateSubCategories({
              ...subCategories,
              //   selected: res.data.data.categories[0]._id,
              data: res.data.data.subcategories,
              isLoading: false,
              notFound: false,
            });
          } else {
            updateSubCategories({
              ...subCategories,
              selected: undefined,
              data: [],
              isLoading: false,
              notFound: true,
            });
            updateProducts({
              ...products,
              data: [],
            });
          }
        })
        .catch((err) => {});
    }
  }, [categories.selected]);

  // SubCategory Change
  useEffect(() => {
    console.log("subcategory effect");
    // return;
    if (subCategories.selected) {
      updateProducts({
        ...products,
        isLoading: true,
        notFound: false,
      });
      axios
        .get("/listProductsBranch", {
          params: {
            subCategoryId: subCategories.selected,
            onlyNames: 1,
            branchId: props.branchId,
          },
        })
        .then((res) => {
          if (res.data.data.products.length) {
            if (props.modalType === "EDIT" && products.selected) {
              const [selectedProduct] = res.data.data.products.filter(
                (item) => item.productId === products.selected
              );
              updatePrice(
                Validations.validateNumericValue(selectedProduct.actualPrice)
              );
              const maxQ = getMaxQuantity(selectedProduct);
              updateSelectedProductData({
                quantity:
                  maxQ < props.data.quantityBooked
                    ? maxQ
                    : props.data.quantityBooked,
                maxQuantity: maxQ,
              });
            }
            console.log("prodidiodods", res.data.data.products);
            updateProducts({
              ...products,
              data: res.data.data.products.map((item) => {
                return {
                  ...item,
                  isOutOfStock: false,
                };
              }),
              isLoading: false,
              notFound: false,
            });
          } else {
            updateProducts({
              ...products,
              data: [],
              isLoading: false,
              notFound: true,
            });
          }
        })
        .catch((err) => {});
    }
  }, [subCategories.selected]);

  const handleQuantityChange = (e) => {
    if (e.target.value > selectedProductData.maxQuantity) {
      toastr.error(
        "Error",
        `Only ${selectedProductData.maxQuantity} items in stock`
      );
      return;
    }
    updateSelectedProductData({
      ...selectedProductData,
      quantity: parseInt(e.target.value),
    });
  };

  const getFormData = () => {
    const [selectedProduct] = products.data.filter(
      (item) => item.productId === products.selected
    );
    const data = {
      productId: selectedProduct.productId,
      productIdOnBooking: selectedProduct.productId,
      image: selectedProduct.image,
      name: selectedProduct.name,
      quantityBooked: selectedProductData.quantity * 1,
      actualPrice: price.value * 1,
      categoryId: categories.selected,
      subCategoryId: subCategories.selected,
    };
    console.log("FORORORORM DATA", data);
    if (props.modalType === "EDIT") {
      data.isPicked = props.data.isPicked;
    }

    return data;
  };

  console.log(
    "Loading statuses",
    categories.isLoading,
    subCategories.isLoading,
    products.isLoading,
    props.isLoading
  );
  return (
    <CustomModal
      // onClose={props.onClose}
      onCloseIconClick={handleClose}
      header={
        props.modalType === "ADD"
          ? "app.pageTitle.addItem"
          : "app.pageTitle.editItem"
      }
      isVisible={props.isVisible}
    >
      <Panel
        isLoading={
          categories.isLoading ||
          subCategories.isLoading ||
          products.isLoading ||
          props.isLoading
        }
        className={styles.formModal}
      >
        <div className={styles.form}>
          <div className={styles.rightForm}>
            <div className={styles.formItem}>
              <label>
                {intl.formatMessage({ id: "app.placeholder.category" })}
              </label>
              <Select
                placeholder={intl.formatMessage({
                  id: "app.placeholder.category",
                })}
                onChange={handleCategoryChange}
                value={categories.selected}
              >
                {categories.data.map((category) => (
                  <Option key={category.categoryId} value={category.categoryId}>
                    {category.categoryName}
                  </Option>
                ))}
              </Select>
            </div>
            {categories.selected && (
              <div className={styles.formItem}>
                <label>
                  {intl.formatMessage({ id: "app.placeholder.subCategory" })}
                </label>
                <Select
                  placeholder={intl.formatMessage({
                    id: "app.placeholder.subCategory",
                  })}
                  onChange={handleSubCategoryChange}
                  value={subCategories.selected}
                >
                  {subCategories.data.map((subCategory) => (
                    <Option
                      key={subCategory.categoryId}
                      value={subCategory.categoryId}
                    >
                      {subCategory.categoryName}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
            {subCategories.selected && (
              <div className={styles.formItem}>
                <label>
                  {intl.formatMessage({ id: "app.placeholder.product" })}
                </label>
                <Select
                  placeholder={intl.formatMessage({
                    id: "app.placeholder.product",
                  })}
                  onChange={handleProductChange}
                  value={products.selected}
                >
                  {products.data.map((product) => (
                    <Option key={product.productId} value={product.productId}>
                      {product.name}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
            {products.selected && (
              <>
                <div className={styles.formItem}>
                  <label>
                    {intl.formatMessage({ id: "app.field.quantity" })}
                  </label>
                  <Input
                    type="number"
                    value={selectedProductData.quantity}
                    onChange={handleQuantityChange}
                    max={selectedProductData.maxQuantity}
                    min={1}
                    placeholder={intl.formatMessage({
                      id: "app.placeholder.quantity",
                    })}
                  />
                </div>

                <div className={styles.formItem}>
                  <label>
                    {intl.formatMessage({ id: "app.field.actualPrice" })}
                  </label>
                  <Input
                    type="number"
                    value={price.value}
                    onChange={handlePriceChange}
                    // max={selectedProductData.actualPrice}
                    min={0}
                    placeholder={intl.formatMessage({
                      id: "app.field.actualPrice",
                    })}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className={styles.formAction}>
          <Button shape="" onClick={handleClose}>
            {intl.formatMessage({ id: "app.button.cancel" })}
          </Button>
          <Button type="primary" shape="" onClick={handleSubmit}>
            {intl.formatMessage({ id: "app.button.submit" })}
          </Button>
        </div>
      </Panel>
    </CustomModal>
  );
}

export default AddEditItemModal;
