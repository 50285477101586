import React, { useState } from "react";
import { Popconfirm, Menu, Dropdown, Tooltip, Switch } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";
import classNames from "classnames";
import moment from "moment";

import {
  Panel,
  Icon,
  CustomImage,
  CustomTable,
  CustomTag,
} from "../../../../components/common";
import placeholderImg from "../../../../assets/images/item-placeholder.png";
import "./advertisements-table.scss";

const styles = {
  table: "custom-table",
  tableActions: "custom-table__table-actions",
  actionIcon: "custom-table__action-icon",
  panelContainer: "advertisements-table__panel-container",
};
// const bannerTypes = ["Category", "Sub-Category", "Product"];
const bannerTypes = [
  {
    key: "advertisement",
    value: "Advertisement",
  },
  {
    key: "informational",
    value: "Informational",
  },
  {
    key: "update",
    value: "Update",
  },
];
function AdvertisementsTable(props) {
  const intl = useIntl();
  const handleImageError = (e) => {
    e.target.src = placeholderImg;
  };

  const columns = [
    {
      dataIndex: "",
      // sorter: true,
      title: intl.formatMessage({ id: "app.field.sNo" }),
      fixed: 'left',
      render: (e, item, index) => {
        return (
          <span>
            {index+1}
          </span>
        );
      },
    },
    {
      fixed: "left",
      dataIndex: "bannerType",
      title: intl.formatMessage({ id: "app.field.type" }),
      render: (item) => (
        bannerTypes.map((bt) => {
          if(item == bt.key){
           return <span>{bt.value}</span>
          }
        })
      )
      // render: (item) => (bannerTypes[item] || '---')
    },

    {
      dataIndex: "image",
      title: intl.formatMessage({ id: "app.field.image" }),
      render: (item) => <CustomImage src={item} isTable />
    },
    {
      dataIndex: "startDate",
      title: intl.formatMessage({ id: "app.field.startDate" }),
      render: (item) => moment.utc(item).format("MMMM Do YYYY"),
    },

    {
      dataIndex: "endDate",
      title: intl.formatMessage({ id: "app.field.endDate" }),
      render: (item) => moment.utc(item).format("MMMM Do YYYY"),
    },
    {
      dataIndex: "link",
      title: "Advertisement Link",
      render: (i) => {
        return (
            <div>
            <a href={i} target="_blank">{i}</a>
            </div>
        )
      },
    },
    // {
    //   dataIndex: "isActive",
    //   title: intl.formatMessage({ id: "app.field.status" }),
    //   render: (i) => {
    //     return <CustomTag isEnabled={i} />;
    //   },
    // },
    // {
    //   dataIndex: "isLocked",
    //   title: intl.formatMessage({ id: "app.field.lockStatus" }),
    //   render: (i) => {
    //     return <CustomTag isLocked={i} />;
    //   },
    //   filterOut: props.panelType != 0,
    // },
    {
      title: intl.formatMessage({ id: "app.field.action" }),
      fixed: "right",
      render: (item) => {
        return (
          <Dropdown overlay={getMenu(item)}>
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  const getMenu = (item) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            props.handleEdit(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.edit" })}
        </Menu.Item>
        {/* {item.isActive == 0 && (
          <Menu.Item
            onClick={() => {
              props.toggleAdvertisement(item, "active");
            }}
          >
            {intl.formatMessage({ id: "app.field.enable" })}
          </Menu.Item>
        )}
        {item.isActive == 1 && (
          <Menu.Item
            onClick={() => {
              props.toggleAdvertisement(item, "active");
            }}
          >
            {intl.formatMessage({ id: "app.field.disable" })}
          </Menu.Item>
        )}
        {props.panelType == 0 && item.isLocked == 0 && (
          <Menu.Item
            onClick={() => {
              props.toggleAdvertisement(item, "lock");
            }}
          >
            {intl.formatMessage({ id: "app.field.lock" })}
          </Menu.Item>
        )}
        {props.panelType == 0 && item.isLocked == 1 && (
          <Menu.Item
            onClick={() => {
              props.toggleAdvertisement(item, "lock");
            }}
          >
            {intl.formatMessage({ id: "app.field.unlock" })}
          </Menu.Item>
        )} */}

        <Menu.Item
          // onClick={() => {
          //   props.toggleAdvertisement(item, "delete");
          // }}
        >
        <Popconfirm
          title={intl.formatMessage({
            id: "app.message.deleteWarning",
          })}
          onConfirm={() => {
            props.toggleAdvertisement(item, "delete");
          }}
          placement="topRight"
          okText={intl.formatMessage({ id: "app.field.yes" })}
          cancelText={intl.formatMessage({ id: "app.field.no" })}
        >
          {intl.formatMessage({ id: "app.field.delete" })}
        </Popconfirm>
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <>
      <CustomTable
       dataSource={props.data.filter((ad) => ad.isDeleted !== 1)} 
       columns={columns}
       isLoading={props.isLoading}
       pagination={false}
       />
    </>
  );
}

export default AdvertisementsTable;
