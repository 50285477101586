import React from "react";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import classNames from "classnames";
import { Input, Button } from "antd";
import { injectIntl } from "react-intl";
import sidebarData from "../../shared/constants/sidebar-data.json";
import { ErrorMessage, Icon, Label } from "../../components/common";
import Validations from "../../shared/validations/Validations";
import "./login.scss";
import LogoImage from "../../assets/images/logo.png";
import axiosClient from "../../shared/api/branchConfig";
import userActions from "../../redux/user-info/user-actions";
import { getPanelType } from "../../shared/constants/dropdown";

const styles = {
  wrapper: "login__wrapper",
  contentContainer: "login__content-container",
  input: "login__input",
  companyName: "login__company-name",
  logoImage: "login__logo-image",
  loginButton: "login__login-button",
  formItem: "login__form-item",
  formContainer: "login__form-container",
  logoContainer: "login__logo-container",
  welcomeContainer: "login__welcome-container",
  welcomeTitle: "login__welcome-container__title",
  welcomeSubTitle: "login__welcome-container__sub-title",
  password: "login__form-item__password",
};

class Login extends React.Component {
  state = {
    email: Validations.validateEmail(""),
    password: Validations.validateTextField("", "Password"),
    isLoading: false,
    isPristine: true,
    isPasswordVisible: false,
  };


  handleRoles = (e) => {
    let roles = [];
      axiosClient
        .get("/site-data", {
        })
        .then((res) => {
          roles = res.data.data.roles;
        })
        .catch((err) => {
        });

        return roles;
    }
  // };

  handleLogin = (e) => {
    e.preventDefault();
    this.setState({
      isPristine: false,
    });

    if (this.state.email.isValid && this.state.password.isValid) {
      this.setState({
        isLoading: true,
      });

      axiosClient
        .post("/loginAdmin", {
          email: this.state.email.value,
          password: this.state.password.value,
          // phoneNo: "0000000000",
          // name: "Admin",
          // countryCode: "91",
          // enterpriceReferenceId: "c0367056fc679a370b5b453128c09f20",
          // timezone: new Date().getTimezoneOffset(),
        })
        .then((res) => {
          this.setState({
            isLoading: false,
          });
          if (res.data.statusCode !== 200) {
            toastr.error("Error", res.data.message);
            return;
          }
          if (res.data.data.isBlocked) {
            toastr.error("Error", "Account has been blocked by an admin");
            return;
          }
          const panel = getPanelType();

          // let countries = res.data.data.enabledCountries;

          // if(typeof countries === 'string') {
          //   countries = JSON.parse(countries);
          // }
          // let enabledCountries = countries;
          // let selectedCountry = null;
          let allRoles = res?.data?.data?.adminroles?.map((i) => {
            i.status = parseInt(i.status);
            return i;
          });

          // let allRoles = this.handleRoles();

          // if (panel === 1) {
          //   // enabledCountries = [];
          //   // selectedCountry = res.data.data.countryId;
          //   // res.data.data.cityIds = [res.data.data.cityId]
          //   allRoles = sidebarData.filter((item) => {
          //     return item.onStore ? true : false;
          //   });
          // } else {
          //   // selectedCountry = enabledCountries[0]?.countryId
          // }
          // debugger;

          // res.data.data.enabledCountries = enabledCountries;
          this.props.dispatch(userActions.setUserInfo(res.data.data));
          // this.props.dispatch(userActions.changeCountry(selectedCountry));

          // debugger;
          const accessToken =
            res?.data?.data["access-token"] || res?.data?.data?.["api-token"];
          // debugger;
          localStorage.setItem("trollyAdmin_jwt", accessToken);
          localStorage.setItem("trollyAdmin_roles", JSON.stringify(allRoles));
          localStorage.setItem(
            "trollyAdmin_userInfo",
            JSON.stringify(res.data.data)
          );
          toastr.success(
            `${this.props.intl.formatMessage({ id: "app.message.success" })}`,
            `${
              this.props.intl.formatMessage({ id: "app.message.loggingIn" }) +
              "..."
            }`,
            { timeOut: 1500 }
          );
          const rolesMapping = sidebarData.reduce((acc, val) => {
            acc[val.menuId] = val.url;
            return acc;
          }, {});

          let validRoles = allRoles.filter((i) => {
            if (panel === 0) {
              return i.status === 1;
            }

          
          });

          if(panel === 1) {
            validRoles = sidebarData.filter(i => !!i.onStore)
          }
          setTimeout(() => {
            this.props.history.replace(rolesMapping[validRoles[0]?.menuId]);
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            isLoading: false,
          });
        });
    }
  };

  togglePassword = () => {
    this.setState((prev) => ({
      ...prev,
      isPasswordVisible: !prev.isPasswordVisible,
    }));
  };

  createAdmin = () => {
    axiosClient
    .post("/createAdmin", {
      email: "pawadmin@yopmail.com",
      password: "Qwerty@123",
      phoneNo: "0000000000",
      name: "Admin",
      countryCode: "91",
      // enterpriceReferenceId: "c0367056fc679a370b5b453128c09f20",
      // timezone: new Date().getTimezoneOffset(),
    })
    .then((res) => {
      if(res.data.statusCode === 200) {
        toastr.success("Admin Created: pawadmin@yopmail.com")
      }
      if (res.data.statusCode !== 200) {
        toastr.error("Error", res.data.message);
        return;
      }
      if (res.data.data.isBlocked) {
        toastr.error("Error", "Account has been blocked by an admin");
        return;
      }
      // roles = res.data.data.roles;
    })
    .catch((err) => {
    });
  }

  handleEmailChange = (e) => {
    this.setState({
      email: Validations.validateEmail(e.target.value),
    });
  };

  handlePasswordChange = (e) => {
    this.setState({
      password: Validations.validateTextField(e.target.value, "Password"),
    });
  };

  render() {
    return (
      <form onSubmit={this.handleLogin} className={styles.wrapper}>
        <div className={styles.logoContainer}>
          {/* <Label value="Teddy" /> */}
          <label className={styles.companyName}>Teddy</label>
          {/* <img className={styles.logoImage} src={LogoImage} /> */}
        </div>
        <div className={styles.welcomeContainer}>
          <h1 className={styles.welcomeTitle}>
            {this.props.intl.formatMessage({ id: "app.auth.welcomeTitle" })}
          </h1>
          <h2 className={styles.welcomeSubTitle}>
            {this.props.intl.formatMessage({ id: "app.auth.welcomeSubTitle" })}
          </h2>
        </div>
        <div className={styles.formContainer}>
          <div className={styles.formItem}>
            <Input
              style={{ border:"1px solid gainsboro"}}
              onChange={this.handleEmailChange}
              value={this.state.email.value}
              icon="user"
              iconPosition="left"
              placeholder={this.props.intl.formatMessage({
                id: "app.placeholder.email",
              })}
            />
            {!this.state.isPristine && (
              <ErrorMessage field={this.state.email} />
            )}
          </div>
          <div className={classNames([styles.formItem, styles.password])}>
            <Input.Password
              onChange={this.handlePasswordChange}
              value={this.state.password.value}
              type={this.state.isPasswordVisible ? "text" : "password"}
              icon="key"
              iconPosition="left"
              placeholder={this.props.intl.formatMessage({
                id: "app.placeholder.password",
              })}
            />
            {/* <Icon onClick={this.togglePassword} name="EYE" /> */}
            {!this.state.isPristine && (
              <ErrorMessage field={this.state.password} />
            )}
          </div>
          <Button
            loading={this.state.isLoading}
            className={styles.loginButton}
            type="primary"
            shape=""
            size="medium"
            htmlType="submit"
          >
            {this.props.intl.formatMessage({ id: "app.button.signIn" })}
          </Button>
          {/* <label style={{ color:"#2671E1", cursor:"pointer",marginTop:"10px",marginLeft:"70px"
          }} onClick={this.createAdmin}>Create as pawadmin@yopmail.com</label> */}
        </div>
          {/* <Button
            loading={this.state.isLoading}
            // className={styles.loginButton}
            type="primary"
            shape=""
            size="medium"
            onClick={this.createAdmin}
          >Create As pawadmin@yopmail.com
          </Button> */}
      </form>
    );
  }
}

export default connect()(injectIntl(Login));
